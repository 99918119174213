import React from "react";
import type { MenuProps } from "antd";
import { Dropdown, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import styles from './styles.module.scss'

interface IDropDown {
  items?: Array<{
    key: string | number;
    label: React.ReactNode;
  }>;
}


interface DropDownProps {
  dropDownProps: MenuProps;
  children?: React.ReactNode;
  placement?:"topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight" | "top" | "bottom" | undefined
}

function CustomDropDown({ dropDownProps, placement ,children }: DropDownProps) {
  

  return (
    <div>
      <Dropdown
        menu={{
          ...dropDownProps,

        }}
        placement={placement}
      >
        <Link to="#">
          <Space className={styles.body}>{children}</Space>
        </Link>
      </Dropdown>
    </div>
  );
}

export default CustomDropDown;
