import styles from "./withdraw-funds.module.scss";
import { Col, Row, Checkbox, Select } from "antd";

import { CustomInputField } from "../../customInput";
import CustomSelect from "../../customSelect";
import { CustomButton } from "../../customButton";
import CustomPasswordBox from "../../customPasswordBox";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { IDedicatedAccount } from "../../../interfaces/apiResponse";
import { useMyWallet, useWithdrawalFundsMutation } from "@/data/wallet";
import { WITHDRAWAL_FUNDS_SCHEMA } from "@/validationSchemas/schema";
import useWallet from "@/pages/Host/Profile/components/Wallet/useWallet";
import { toast } from "react-toastify";
import { handleError } from "@/services/networkRequests";
import { isPasswordValid } from "../../../firebase/utils";
import { cn } from "@/lib/utils";
import { CloseCircleIcon } from "@/components/icons/close-circle";
import { useModalAction } from "@/components/modal-view/context";
import { useMe } from "@/data/user";

export interface FormValues {
  account_id: string;
  password: string;
  confirm_password: string;
  amount: number;
  privacy_policy: boolean;
}

function Form({
  accounts,
  formik,
}: {
  accounts: IDedicatedAccount[];
  formik: FormikProps<FormValues>;
}) {
  const disabled = Object.keys(formik.errors).length > 0;
  return (
    <>
      <div className={styles.form}>
        <Row gutter={[45, 24]}>
          <Col md={12} xs={24}>
            <CustomSelect
              value={formik.values.account_id}
              showSearch
              onChange={(value) => formik.setFieldValue("account_id", value)}
              onBlur={() => formik.setFieldTouched("account_id")}
              label={"Bank Name"}
              placeHolder="Select your Bank"
              size="large"
              error={formik.touched.account_id && formik.errors.account_id}
            >
              {accounts.map((account: IDedicatedAccount) => (
                <Option value={account.account_id} key={account.account_id}>
                  {account.bank_name}
                </Option>
              ))}
            </CustomSelect>
          </Col>
          <Col md={12} xs={24}>
            <CustomInputField
              type={"text"}
              label="Withdrawal Amount"
              placeholder="Enter Amount"
              {...formik.getFieldProps("amount")}
              error={formik.touched.amount && formik.errors.amount}
            />
          </Col>

          <Col md={12} xs={24}>
            <CustomPasswordBox
              type={"password"}
              name={"password"}
              label="Password"
              placeholder="Enter your Co-spire Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && formik.errors.password}
            />
          </Col>
          <Col md={12} xs={24}>
            <CustomPasswordBox
              type={"password"}
              name={"confirm_password"}
              label="Confirm Password"
              placeholder="Confirm your Co-spire Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirm_password &&
                formik.errors.confirm_password
              }
            />
          </Col>
        </Row>
      </div>

      <Row>
        <Col span={6} xs={24}>
          <Checkbox
            onChange={(e) => {
              formik.setFieldValue("privacy_policy", e.target.checked);
            }}
            checked={formik.values.privacy_policy}
          >
            <span className={styles.terms}>
              I confirm that I have read and agree to Co-Spire’s Privacy Policy
              and consent to sharing my information.
            </span>
          </Checkbox>
        </Col>
      </Row>
      <div className={styles.btn}>
        <CustomButton
          children={"Withdraw"}
          onClick={formik.handleSubmit}
          disabled={
            disabled || formik.isSubmitting || !formik.values.privacy_policy
          }
          loading={formik.isSubmitting}
        />
      </div>
    </>
  );
}

const { Option } = Select;

export const WithdrawFunds = () => {
  const { wallet, isLoading } = useMyWallet();

  const { me: loggedInUser } = useMe();

  const initialValues = {
    account_id:
      wallet?.withdrawal_banks.find((account) => account.preferred)
        ?.account_id ?? "",
    amount: 0,
    password: "",
    confirm_password: "",
    privacy_policy: false,
  };

  const { closeModal } = useModalAction();

  const { mutateAsync: withdrawFunds } = useWithdrawalFundsMutation();

  function handleWithdrawal(values: FormValues) {
    if (!loggedInUser) return;
    isPasswordValid(loggedInUser.email!, values.password).then((res) => {
      if (res.valid) {
        toast.promise(
          withdrawFunds(
            { ...values },
            {
              onSuccess: () => {
                toast.success("Withdrawal requested!");
                closeModal();
              },
              onError: (err) => {
                handleError(err);
                closeModal();
              },
            }
          ),
          {
            pending: "Withdrawing...",
          }
        );
      } else {
        toast.error("Invalid password", { updateId: "invalid-password" });
      }
    });
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div
        className={cn(
          styles.page,
          "bg-white rounded-md w-full max-w-3xl mx-auto p-6"
        )}
      >
        <div className="relative">
          <div className={styles.modalheading}>Withdraw Funds</div>
          <p className={styles.modalSubHeading}>
            Enjoy fast and easy funds payment to your preferred bank account
          </p>
          <button
            onClick={closeModal}
            title="Close modal"
            className="absolute top-0 right-0"
          >
            <CloseCircleIcon className="text-primaryText" />
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={WITHDRAWAL_FUNDS_SCHEMA}
          onSubmit={(
            values: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>
          ) => {
            handleWithdrawal({ ...values });
            setTimeout(() => setSubmitting(false), 400);
          }}
        >
          {(formik) => {
            return (
              <Form formik={formik} accounts={wallet?.withdrawal_banks ?? []} />
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
