import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { TransactionTable } from "./table";
import { Paginator } from "./paginator";
import { useTransactionsQuery } from "@/data/transaction";
import { Transaction } from "@/types/transaction";

const onChange = (key: string) => {
  console.log(key);
};

const LIMIT = 5;

export function TransactionHistory() {
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(1);

  function handlePagination(current: number) {
    setPage(current);
  }

  const filterTransactionByPurpose = React.useCallback(
    ({ data: transactions, ...rest }: { data: Transaction[] }) => {
      if (search === "") return { ...rest, data: transactions };
      return {
        ...rest,
        data: transactions.filter((trxn) => trxn.purpose.search(search) != -1),
      };
    },
    [search]
  );

  const { transactions, paginatorInfo, loading } = useTransactionsQuery(
    { limit: LIMIT, page: page },
    {
      select: filterTransactionByPurpose,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const inflows = React.useMemo(() => {
    return transactions.filter((transaction) => {
      return transaction.transaction_type === "inflow";
    });
  }, [transactions]);
  const outflows = React.useMemo(() => {
    return transactions.filter((transaction) => {
      return transaction.transaction_type === "outflow";
    });
  }, [transactions]);

  const items: TabsProps["items"] = React.useMemo(
    () => [
      {
        key: "all",
        label: <div className="text-lg">All</div>,
        children: <TransactionTable transactions={transactions} />,
      },
      {
        key: "inflows",
        label: <div className="text-lg">Inflows</div>,
        children: <TransactionTable transactions={inflows} />,
      },
      {
        key: "outflows",
        label: <div className="text-lg">Outflows</div>,
        children: <TransactionTable transactions={outflows} />,
      },
    ],
    [transactions, inflows, outflows]
  );

  return (
    <div className="bg-white border-[0.5px] border-grey-alpha rounded-xl p-6 w-full">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-semibold text-coSpireBlack">
          Transaction history
        </h1>
        <div className="flex items-center space-x-2">
          <span className="text-gray-600">Sort by:</span>
          <select
            title="Transactions filter"
            className="border border-gray-300 rounded-md p-2"
          >
            <option>Payment</option>
          </select>
        </div>
      </div>

      <Tabs
        tabBarExtraContent={
          <div className="flex items-center">
            <div className="relative border border-gray-300 rounded-md">
              <input
                type="text"
                placeholder="Search Transactions"
                className="p-2 pl-8"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(1);
                }}
              />
              <i className="fas fa-search absolute left-2 top-2.5 text-gray-400"></i>
            </div>
          </div>
        }
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />

      <Paginator
        current={paginatorInfo?.currentPage ?? page}
        pageSize={paginatorInfo?.perPage ?? LIMIT}
        lastPage={paginatorInfo?.lastPage ?? 1}
        onPagination={handlePagination}
      />
    </div>
  );
}
