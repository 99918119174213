import React, { HTMLInputTypeAttribute, useEffect } from "react";
import styles from "./index.module.scss";
import { Input, AutoComplete } from "antd";
import { useGeoTag } from "./useGeoTag";
import { EnvironmentOutlined, SearchOutlined } from "@ant-design/icons";
import { FormikBag, FormikHelpers, FormikProps } from "formik";
import { IWorkSpaceForm } from "../../interfaces/forms";

type BaseInputProps = {
   id?: string;
   type: HTMLInputTypeAttribute;
   name?: string;
   value?: string;
   onChange?: (value: string) => void;
   onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
   placeholder?: string;
   autoComplete?: string;
   error?: string | undefined | number | boolean;
   disabled?: boolean;
   backgroundColor?: string;
   action?: React.ReactNode;
   label?: string;
   secLabel?: string;
   extraStyles?: Record<string, string>;
   onKeyUp?: any;
   min?: number;
   alt?: string;
   maxLength?: number;
   icon?: any;
   pattern?: RegExp;
   prefix?: React.ReactNode;
   suffix?: React.ReactNode;
   className?: string;
   formik: FormikProps<IWorkSpaceForm>
};

export const GeoTagInput: React.FC<BaseInputProps> = ({
   id,
   type,
   name,
   placeholder,
   value,
   onChange,
   autoComplete,
   error,
   icon,
   disabled,
   min = 0,
   action,
   label,
   extraStyles,
   secLabel,
   onKeyUp,
   maxLength,
   alt,
   onBlur,
   prefix,
   suffix,
   className,
   formik
}) => {
   const { handleSearch, handleSelect, options } = useGeoTag({ formik: formik });

   // useEffect(() => {
   //       formik.setFieldValue("map", latLOng);     
   // // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [latLOng])
   return (
      <div className={styles.container}>
         {label && <label className={styles.label}>{label}</label>}
         {secLabel && <p className={styles.secondaryLabel}>{secLabel}</p>}
         <div className={styles.inputContainer}>
            <AutoComplete
               // popupClassName={styles.dropDown}
               // dropdownMatchSelectWidth={500}
               // dropdownStyle={{ width: "500px" }}
               allowClear
               onSearch={handleSearch}
               onSelect={handleSelect}
               placeholder={placeholder}
               value={value}
               onChange={onChange}
               onBlur={onBlur}
               disabled={disabled}
               className={styles.autoComplete}
               options={options}
               bordered={false}
            />
         </div>
         {error && <p className={styles.error}>{error}</p>}
      </div>
   );
};
