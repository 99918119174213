import styles from "./index.module.scss";
import bank_icon from "../../../assets/svgs/bankIcon.svg";

interface AccountCardProps {
  title: string;
  preferred?: boolean;

  account: {
    account_name: string;
    account_number: string;
    bank_name: string;
    bank_code: string;
  };
}

const AccountCard = ({ title, preferred, account }: AccountCardProps) => {
  return (
    <div className={styles.page}>
      <div className={styles.info}>
        <div className={styles.icon}>
          <img src={bank_icon} alt="bank_icon" />
        </div>
        <div className={styles.content}>
          <span className={styles.title}>{title}</span>
          <div className={styles.sub}>
            <span>
              {account?.account_number?.substring(0, 4) + "********"}{" "}
            </span>{" "}
            |{" "}
            <span className={styles.subtitle}>{account?.bank_name || ""}</span>
          </div>
        </div>
      </div>
      {preferred && (
        <div className={styles.preferred}>
          <span className={styles.text}>Preferred</span>
        </div>
      )}
    </div>
  );
};

export default AccountCard;
