import { auth } from "@/firebase/utils";
import React, { useEffect, useState } from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { setToken } from "@/utils/token";

type AuthContextType = {
  userLoggedIn: boolean;
  // isEmailUser: boolean;
  // isGoogleUser: boolean;
  loading: boolean;
  currentUser: User | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
};

export const AuthContext = React.createContext({} as AuthContextType);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  //   const [isEmailUser, setIsEmailUser] = useState(false);
  //   const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  async function initializeUser(user: User | null) {
    if (user) {
      const token = await user.getIdToken();
      token && setToken(token);
      setCurrentUser({ ...user });

      // check if provider is email and password login
      //   const isEmail = user.providerData.some(
      //     (provider) => provider.providerId === "password"
      //   );
      //   setIsEmailUser(isEmail);

      // check if the auth provider is google or not
      //   const isGoogle = user.providerData.some(
      //     (provider) => provider.providerId === GoogleAuthProvider.PROVIDER_ID
      //   );
      //   setIsGoogleUser(isGoogle);

      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }

    setLoading(false);
  }

  const value = {
    userLoggedIn,
    // isEmailUser,
    // isGoogleUser,
    currentUser,
    loading,
    setCurrentUser,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
