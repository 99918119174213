import React, { useState } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";
import Logo from "@/assets/svgs/logo-cospire-svg.svg";
import Frame from "@/assets/svgs/Frame.svg";
import {
  login,
  resetPassword,
} from "../../../../services/networkRequests/authService";
import { updateUserData } from "../../../../services/networkRequests/userService";
import { useMe } from "@/data/user";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { me: user } = useMe();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    // Add logic to handle form submission (e.g., update password)
    setLoading(true);
    if (password === confirmPassword && user?.email) {
      const payload = { password, email: user.email };
      try {
        const passwordreset = resetPassword(password);
        const updateuser = updateUserData({ force_password: false });
        const [response1, response2] = await Promise.all([
          passwordreset,
          updateuser,
        ]);
        console.log(response1, response2, "opo");
        await login(payload);
        form.resetFields();
        setLoading(false);

        // navigate(HOME);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.main}>
      {/* <div className={styles.close}>
               <img src={Close} />
            </div> */}
      <div className={styles.box}>
        <img src={Logo} alt="..." />
        <h1>Reset Password</h1>
        <p>Set a new password to proceed.</p>
        <Form
          layout="vertical"
          size={"large"}
          autoComplete="off"
          className={styles.form}
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name="password"
            className={styles.formItem}
            label={<span className={styles.label}>Password</span>}
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input
              type="password"
              className={styles.input}
              placeholder="Enter your Password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            className={styles.formItem}
            label={<span className={styles.label}>Confirm Password</span>}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input
              type="password"
              className={styles.input}
              placeholder="Confirm your Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className={styles.button}
            loading={loading}
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            Save and Continue
          </Button>
        </Form>
      </div>
      <div className={styles.frame}>
        <img src={Frame} alt="..." />
      </div>
    </div>
  );
};

export default ResetPassword;
