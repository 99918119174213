import React, { useState, useRef } from "react";
import "./setupPage.scss";
import styles from "./imageUpload.module.scss";
import camera from "@/assets/svgs/camera.svg";
import CropModal from "@/components/CropModal";

export const ContactDetails = ({
  values: { contactImage, displayName, emailAddress },
  setValue,
}: any) => {
  return (
    <div className="page-one page-three page-eight">
      <div className="heading-div">
        <h2>Add your Contact details</h2>
      </div>
      <ProfileImageUpload
        image={contactImage}
        setImage={(image: unknown) => setValue("contactImage", image)}
      />

      <div className="page-input-field">
        <label>Display Name</label>
        <div className="page-input-filed-input-wrapper">
          <input
            type="text"
            placeholder="Enter owner’s name"
            value={displayName}
            onChange={(e) => setValue("displayName", e.target.value)}
          />
        </div>
      </div>
      <div className="page-input-field">
        <label>Email Address</label>
        <div className="page-input-filed-input-wrapper">
          <input
            readOnly
            type="email"
            placeholder="Enter email address"
            value={emailAddress}
            onChange={(e) => setValue("emailAddress", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

interface ProfileImageUploadProps {
  image: string | null;
  setImage: (image: string | null) => void;
}

const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({
  image,
  setImage,
}) => {
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageToCrop(reader.result as string);
        setIsCropModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (croppedImage: string) => {
    setImage(croppedImage);
    setIsCropModalOpen(false);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={styles.circularUploadImage}>
      <div className={styles.imageContainer}>
        {image ? (
          <img src={image} alt="Profile" className={styles.profileImage} />
        ) : (
          <div className={styles.profilePlaceholder}></div>
        )}

        <div className={styles.uploadButton} onClick={handleButtonClick}>
          <img src={camera} alt="camera" />
        </div>
      </div>
      <input
        title="Image upload"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        className={styles.uploadInput}
        style={{ display: "none" }}
      />

      {isCropModalOpen && (
        <CropModal
          isOpen={isCropModalOpen}
          closeModal={() => setIsCropModalOpen(false)}
          imageToCrop={imageToCrop}
          onCropComplete={handleCropComplete}
        />
      )}
    </div>
  );
};

export default ProfileImageUpload;
