import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './reduxSlice/authSlice';
import hostWorkSpaceSlice from './reduxSlice/hostWorkSpacesSlice';
import walletSlice from './reduxSlice/walletSlice';
import userSlice from './reduxSlice/userSlice';

const rootReducer = combineReducers({
    auth: authSlice,
    hostWorkSpace: hostWorkSpaceSlice,
    wallet: walletSlice,
    user: userSlice,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;

export { reset, setUser, loginStatus, resetUser } from './reduxSlice/authSlice'
export { clearHostWorkspaceData, setEditModeOn } from './reduxSlice/hostWorkSpacesSlice'
export default rootReducer;
