import React from "react";
import styles from "./index.module.scss";
import { Col, Divider, Row } from "antd";
import { CustomInputField } from "@/components/customInput";
import { CustomButton } from "@/components/customButton";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { changePassword } from "@/firebase/utils";
import { useMe } from "@/data/user";

const schema = yup.object({
  old_password: yup.string().required("Please Enter your password"),
  password: yup
    .string()
    .required("Please Enter your password")
    .min(6, "Password is too short - should be 8 chars minimum."),
  confirm_password: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ProfileSettings = () => {
  const { me: loggedInUser } = useMe();

  function handleSubmit(
    values: { password: string },
    actions: FormikHelpers<{
      old_password: string;
      password: string;
      confirm_password: string;
    }>
  ) {
    toast.promise(
      changePassword(values.password)
        .then(() => {
          actions.resetForm();
        })
        .finally(() => {
          actions.setSubmitting(false);
        }),
      {
        pending: "Changing Password",
        success: "Password Changed Successfully",
      }
    );
  }

  return (
    <div className={styles.page}>
      <h2 className={styles.title}>Edit Username</h2>
      <Divider orientation="left" className={styles.divider}></Divider>
      <div className={styles.form}>
        <Row gutter={[45, 24]} align="bottom">
          <Col span={8} xs={16}>
            <CustomInputField
              read-only
              type={"text"}
              name={"full_name"}
              label="Full Name"
              placeholder={loggedInUser?.displayName || "Enter your full name"}
              onChange={() => {}}
              disabled
            />
          </Col>
          <Col span={8} xs={16}>
            <CustomInputField
              read-only
              type={"email"}
              name={"email"}
              label="Email Address"
              placeholder={loggedInUser?.email || "Enter your Email address"}
              onChange={() => {}}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
            <CustomButton
              children={"Save Changes"}
              className={styles.btn}
              onClick={() => {}}
            />
          </Col>
        </Row>
      </div>
      <Divider orientation="left" className={styles.divider}></Divider>
      <div className={styles.form}>
        <h2 className={styles.title}>Change Password</h2>
        <Formik
          initialValues={{
            old_password: "",
            password: "",
            confirm_password: "",
          }}
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}
          validationSchema={schema}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Row gutter={[45, 24]} align="bottom">
                <Col span={8} xs={16}>
                  <CustomInputField
                    type={"password"}
                    name={"old_password"}
                    label="Old Password"
                    placeholder="Enter your Password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.old_password}
                  />
                </Col>
                <Col span={8} xs={16}>
                  <CustomInputField
                    type={"password"}
                    name={"password"}
                    label="New Password"
                    placeholder="Set a strong Password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.password}
                  />
                </Col>
                <Col span={8} xs={16}>
                  <CustomInputField
                    type={"password"}
                    name={"confirm_password"}
                    label="Confirm New Password"
                    placeholder="Confirm New Password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.confirm_password}
                  />
                </Col>
              </Row>
              <Row>
                <Col flex="auto">
                  <CustomButton
                    children={"Save Changes"}
                    className={styles.btn}
                    htmlType="submit"
                    loading={props.isSubmitting}
                  />
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProfileSettings;
