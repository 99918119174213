import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowRight from "@/assets/svgs/chevron-right.svg";
import Button from "@/components/c/Button";
import Location from "@/assets/svgs/map-pin-black";
import Star from "@/assets/svgs/Vector.svg";
import ArrorRight from "@/assets/svgs/arrow-long-right-blue.svg";

import {
  Photos,
  Services,
  Review,
  Contact,
  CreateReview,
  Getquote,
  GetquoteConfirmation,
} from "@/components/a/Modals";
import GoBack from "@/assets/svgs/Subtract.svg";
import { Splide, SplideSlide, SplideProps } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import {
  cancelPolicy,
  listAllReview,
  listReview,
} from "@/services/networkRequests/workspaceService";
import { LWorkspace, Policies } from "@/types";
import { Spin } from "antd";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useLoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import { Avatar } from "antd";
import { StarFilled, StarOutlined, UserOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import ServicesWeb from "@/components/a/Modals/AmenitiesModal/ServicesWeb";
import img from "@/assets/img/inner1.webp";
import share from "@/assets/img/share.png";
import heart from "@/assets/img/heart.png";
import linkImage from "@/assets/img/link-2.png";
import message from "@/assets/img/messages-2.png";
import verified from "@/assets/img/Subtract.png";
import dayjs from "dayjs";

import Similar from "./Similar";
import AdditionalOffer from "@/components/a/Modals/AdditionalOffer/AdditionalOffer";
import MessageHost from "@/components/a/Modals/MessageHost";
import { useWorkspaceQuery } from "@/data/workspace";
import { WorkspaceSocialShare } from "@/components/workspace/workspace-social-share";
import BookingForm, { FormValues } from "@/components/booking-schedule-form";
import { calculateAverage, calculatePrice } from "@/utils/helpers";
import { WORKSPACES } from "@/routes/routes";
import { useMe } from "@/data/user";

const WorkspacePage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [loadingPolicy, setLoadingPolicy] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedRental, setSelectedRental] = useState(null);
  const [cancelPolicyIds, setCancelPolicyIds] = useState<string[]>([]);
  const [policies, setPolicies] = useState<Policies[] | null>(null);

  const handleBookNow = (values: FormValues) => {
    const payload = {
      isRecurrent: values.re_currency,
      dated: [
        dayjs(values.start_date).format("YYYY/MM/DD"),
        dayjs(values.end_date).format("YYYY/MM/DD"),
      ],
      dates: values.dates,
      startTime: values.start_time,
      endTime: values.end_time,
      numberOfPeople: values.num_of_people,
    };
    navigate(`/book_now/${workspace!.workspace_id}`, { state: payload });
  };

  const { workspace, isLoading, error } = useWorkspaceQuery(params.id!);
  const [loadingReview, setLoadingReview] = useState<boolean>(false);
  const [reviews, setReviews] = useState<any[]>([]);
  const prices = {
    hourly_price: workspace?.pricing?.hourly || 0,
    daily_price: workspace?.pricing?.daily || 0,
    weekly_price: workspace?.pricing?.weekly || 0,
    monthly_price: workspace?.pricing?.monthly || 0,
  };
  const validPrices = Object.values(prices).filter((price) => price > 0);
  const minimumPrice = validPrices.length > 0 ? Math.min(...validPrices) : 0;

  const id = workspace?.workspace_id;

  async function getReviews(id: string) {
    setLoadingReview(true);
    try {
      const response = await listAllReview(id);
      console.log(response);
      if (
        response.data.status === "success" &&
        Array.isArray(response.data.data)
      ) {
        setReviews(response.data.data);
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (err) {
      console.error("Error fetching Reviews data:", err);
    } finally {
      setLoadingReview(false);
    }
  }

  useEffect(() => {
    if (id) {
      getReviews(id);
      console.log(reviews);
    } else {
      console.error("Workspace ID is missing. Please provide a workspace ID.");
    }
  }, [id]);

  useEffect(() => {
    async function getPolicies() {
      try {
        if (
          workspace?.cancellation_policy &&
          workspace?.cancellation_policy?.length > 0
        ) {
          const policyPromises = workspace?.cancellation_policy.map(
            (id: string) => cancelPolicy(id)
          );
          const resArray = await Promise.all(policyPromises);

          console.log("resArray", resArray);

          const successfulResponses = resArray
            .filter((res: any) => res.data.code === 200)
            .map((res: any) => res.data.data || null);

          console.log("Successful Responses:", successfulResponses); // Debug log

          setPolicies(successfulResponses);
        }
        setLoadingPolicy(false);
      } catch (error) {
        console.error("Error fetching cancellation policy data:", error);
        setLoadingPolicy(false);
      }
    }
    if (workspace?.cancellation_policy) {
      getPolicies();
      console.log("policies", policies);
    }
  }, [cancelPolicyIds]);

  const { me: loggedInUser } = useMe();

  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (ref.current) {
        const position = ref.current.getBoundingClientRect();
        const bookingCard = window.innerHeight * 1.2;
        const threshold = position.top - bookingCard;

        if (
          window.scrollY > threshold &&
          window.scrollY < position.top + bookingCard
        ) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openModal = (type: string, rental?: any) => {
    setIsOpen(true);
    setModalType(type);
    setSelectedRental(rental || null);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY as any,
  });

  const handleLogin = () => {
    navigate("/login");
    toast.error("Please Login To Check Availability");
  };

  if (isLoading && loadingReview) {
    return (
      <div className={styles.loader}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      {workspace && (
        <div className={styles.main}>
          <div className={styles.link}>
            <Link to={WORKSPACES}>
              <p>Workspace Listing</p>
            </Link>
            <img src={ArrowRight} alt="..." />
            <p>{workspace?.title}</p>
          </div>
          <div className={styles.first}>
            <div className={styles.innerImage}>
              {workspace?.photos
                ?.slice(0, 4)
                .map((item: any, index: number) => (
                  <div key={index} className={styles.imgs}>
                    <img src={item} alt="..." />
                  </div>
                ))}
              <div className={styles.button}>
                <Button type="white" onClick={() => openModal("photo")}>
                  View Photos
                </Button>
              </div>
              {modalType === "photo" && (
                <Photos
                  isOpen={isOpen}
                  closeModal={closeModal}
                  image={workspace?.photos}
                  name={workspace?.title}
                />
              )}
              <div className={styles.share}>
                <img src={share} alt="..." />
                <p> Share Space</p>
              </div>
            </div>
            <div className={styles.innerImage_mobile}>
              <div className={styles.img}>
                <img
                  src={GoBack}
                  alt="goback"
                  className={styles.back}
                  onClick={() => navigate(-1)}
                />
                <Splide
                  aria-labelledby="listing"
                  options={{
                    rewind: true,
                    perPage: 1,
                    gap: "1.5rem",
                    classes: {
                      arrows: "splide__arrows splide__arrows-list",
                      arrow: "splide__arrow",
                      prev: "splide__arrow--prev splide-list-prev",
                      next: "splide__arrow--next splide-list-next",
                      pagination: "splide__pagination splide-list-pagination",
                    },
                    arrowPath:
                      "M18.9342 0.599936L26.4009 8.0666C26.5342 8.19993 26.6285 8.34438 26.6836 8.49994C26.7396 8.65549 26.7676 8.82216 26.7676 8.99994C26.7676 9.17771 26.7396 9.34438 26.6836 9.49994C26.6285 9.65549 26.5342 9.79993 26.4009 9.93327L18.9342 17.3999C18.6898 17.6444 18.384 17.7666 18.0169 17.7666C17.6507 17.7666 17.3342 17.6444 17.0676 17.3999C16.8009 17.1333 16.6676 16.8168 16.6676 16.4506C16.6676 16.0835 16.8009 15.7666 17.0676 15.4999L22.2342 10.3333L2.00091 10.3333C1.62313 10.3333 1.30669 10.2057 1.05158 9.9506C0.795578 9.6946 0.667578 9.37771 0.667578 8.99994C0.667578 8.62216 0.795578 8.30571 1.05158 8.0506C1.30669 7.7946 1.62313 7.6666 2.00091 7.6666L22.2342 7.6666L17.0676 2.4666C16.8231 2.22216 16.6951 1.91682 16.6836 1.5506C16.6729 1.18349 16.8009 0.866602 17.0676 0.599936C17.312 0.355492 17.6231 0.233267 18.0009 0.233267C18.3787 0.233267 18.6898 0.355492 18.9342 0.599936Z",
                  }}
                >
                  {workspace?.photos
                    ?.slice(0, 4)
                    .map((item: string, index: number) => (
                      <SplideSlide key={index}>
                        <img
                          alt="..."
                          key={index}
                          src={item}
                          className={styles.workspace}
                        />
                      </SplideSlide>
                    ))}
                </Splide>

                {workspace?.featured && (
                  <div className={styles.feature}>
                    <Button type="nav">FEATURED SPACE</Button>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.second}>
              <div className={styles.textSection}>
                <p className={styles.spaceType}>{workspace.space_category}</p>
                <div className={styles.head}>
                  <div className={styles.headtext}>
                    <div>
                      <h2>{workspace.title}</h2>
                      <img src={heart} alt="..." />
                      {/* {workspace.verified_space && (
                        <img alt="..." src={ChekBadge} />
                      )} */}
                    </div>

                    {reviews?.length > 0 && (
                      <div>
                        <img src={verified} alt="..." />
                        <p>{reviews?.length} Verified Users</p>
                      </div>
                    )}

                    <div>
                      <Location />{" "}
                      <p>
                        {workspace.address.state +
                          ", " +
                          workspace.address.country}{" "}
                        |
                      </p>
                      <span onClick={handleClick}>View on Map </span>
                      <span className={styles.shareSpan}>| Share Space</span>
                    </div>
                  </div>
                  {workspace?.featured && (
                    <div className={styles.feature}>
                      <Button type="nav">FEATURED SPACE</Button>
                    </div>
                  )}
                </div>
                <div className={styles.info}>
                  <p>About</p>
                  <p className={styles.infoText}>{workspace?.about}</p>
                </div>

                <div className={styles.quote}>
                  <div className={styles.top}>
                    <div
                      onClick={() =>
                        loggedInUser ? handleLogin() : openModal("message-host")
                      }
                    >
                      <p>Message Host</p>
                      <img src={linkImage} alt="" />
                    </div>

                    <div>
                      <img src={workspace.host?.avatar || img} alt="..." />
                      <div>
                        <img src={workspace.host?.avatar || img} alt="" />
                        <div>
                          {workspace.host?.name}
                          <span className={styles.line}></span>{" "}
                          {reviews.length > 0 ? (
                            <img src={Star} alt="" />
                          ) : (
                            <StarOutlined />
                          )}{" "}
                          <span className={styles.rate}>
                            {calculateAverage(
                              reviews?.map((item: any) => {
                                return item.review_rating;
                              })
                            )}
                          </span>
                        </div>
                      </div>

                      <div>
                        <p className={styles.response}>Response time:</p>{" "}
                        <span></span>{" "}
                        <p className={styles.sub}>Typically in 30mins</p>
                      </div>
                      <div>
                        <p className={styles.response}>Response rate:</p>{" "}
                        <span></span> <p className={styles.sub}>Excellent</p>
                      </div>
                    </div>

                    <div>
                      <p className={styles.response}>Response time:</p>{" "}
                      <span></span>{" "}
                      <p className={styles.sub}>Typically in 30mins</p>
                    </div>
                    <div>
                      <p className={styles.response}>Response rate:</p>{" "}
                      <span></span> <p className={styles.sub}>Excellent</p>
                    </div>
                  </div>

                  <div></div>
                  <div>
                    {reviews?.length > 0 && (
                      <div className={styles.price}>
                        <p>Minimum of {workspace?.minimum_hours || 1}hr </p>
                        <div>
                          <p>₦{minimumPrice}</p>
                          <p>
                            <img src={message} alt="..." />
                            {reviews?.length} Verified Reviews
                          </p>
                        </div>
                      </div>
                    )}
                    <div className={styles.mobile_form}>
                      <BookingForm
                        handleSubmit={handleBookNow}
                        pricing={workspace?.pricing}
                      />
                    </div>
                  </div>

                  {/* <div className={styles.service}>
                      <h3>Space details, Amenities & Accessibility</h3>
                      <div>
                        {workspace.service_amenities?.map(
                          (item: any, index: any) => {
                            return (
                              <div key={index}>
                                <img src={item.icon} />
                                <p>{item.name}</p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div> */}
                </div>

                <div
                  className={
                    !isSticky
                      ? [styles.sticky].join(" ")
                      : [styles.sticky, styles.stuck].join(" ")
                  }
                >
                  <div className={styles.top}>
                    <div>
                      <p>Minimum of {workspace?.minimum_hours || 1}hr</p>
                      <p className={styles.dot}></p>
                      <p>Daily rates available</p>
                    </div>
                    <div>
                      <p>₦{minimumPrice}</p>
                      {/* <Dropdown menu={{ items }} trigger={["click"]}>
                          <a onClick={(e) => e.preventDefault()}>
                            <Space>
                              Click me
                              <DownOutlined />
                            </Space>
                          </a>
                        </Dropdown> */}
                    </div>
                  </div>

                  <div className={styles.form}>
                    <BookingForm
                      handleSubmit={handleBookNow}
                      pricing={workspace?.pricing}
                    />
                  </div>

                  <div className={styles.bottom}>
                    {/* <div>
                          <p>Message Host</p>
                          <img src={linkImage} alt="" />
                        </div> */}

                    <div>
                      <img src={workspace.host?.avatar || img} alt="" />
                      <div>
                        {/* <img src={workspace.host?.avatar || img} alt="" /> */}
                        <div>
                          {workspace.host?.name}
                          <span className={styles.line}></span>{" "}
                          {reviews.length > 0 ? (
                            <img src={Star} alt="" />
                          ) : (
                            <StarFilled style={{ color: "#d9d9d9" }} />
                          )}{" "}
                          <span className={styles.rate}>
                            {calculateAverage(
                              reviews?.map((item: any) => {
                                return item.review_rating;
                              })
                            )}
                          </span>
                        </div>
                      </div>

                      {/* <div>
                        <p className={styles.response}>Response time:</p>{" "}
                        <span></span>{" "}
                        <p className={styles.sub}>Typically in 30mins</p>
                      </div>
                      <div>
                        <div className={styles.message_right}>
                          <p className={styles.response}>Response rate:</p>{" "}
                          <span></span> <p className={styles.sub}>Excellent</p>
                        </div>

                        <div className={styles.message_left}>
                          <div
                            onClick={() =>
                              loggedInUser === null
                                ? handleLogin()
                                : openModal("message-host")
                            }
                          >
                            <p>Message Host</p>
                            <img src={message} alt="" />
                          </div>
                        </div>
                      </div> */}
                    </div>

                    <div>
                      <p className={styles.response}>Response time:</p>{" "}
                      <span></span>{" "}
                      <p className={styles.sub}>Typically in 30mins</p>
                    </div>
                    <div>
                      <div className={styles.message_right}>
                        <p className={styles.response}>Response rate:</p>{" "}
                        <span></span> <p className={styles.sub}>Excellent</p>
                      </div>

                      <div className={styles.message_left}>
                        <div
                          onClick={() =>
                            loggedInUser === null
                              ? handleLogin()
                              : openModal("message-host")
                          }
                        >
                          <p>Message Host</p>
                          <img src={message} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.service}>
                  <h3>Space details, Amenities & Accessibility</h3>
                  <div>
                    {workspace.service_amenities?.map(
                      (item: any, index: any) => {
                        return (
                          <div key={index}>
                            <img alt="..." src={item.icon} />
                            <p>{item.name}</p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.description}>
              <p>{workspace?.about}</p>
            </div>

            {/* <div className={styles.services_offered}>
                <p className={styles.para1}>Services Offered</p>

                <div className={styles.services}>
                  {workspace.services.map((item: any, i: any) => (
                    <p
                      onClick={() => {
                        openModal("services-web");

                        setSelectedService(item);
                      }}
                    >
                      {item.name}
                    </p>
                  ))}
                </div>
              </div> */}
            <div className={styles.amenity}>
              <p className={styles.para1}>Amenities and Features</p>

              <div className={styles.amenities}>
                {workspace?.service_amenities?.slice(0, 5).map((item: any) => (
                  <p>{item.name}</p>
                ))}
                {workspace?.service_amenities.length > 5 && (
                  <p
                    onClick={() => openModal("services")}
                    style={{ textDecoration: "underline" }}
                  >
                    See more
                  </p>
                )}
              </div>
            </div>

            {workspace?.rentals?.length > 0 && (
              <div className={styles.rental}>
                <h2>Additional Offer And Rental</h2>
                <p className={styles.subHead}>
                  Rent Items you would need for your event
                </p>

                <div className={styles.rentalData}>
                  {workspace?.rentals?.map((item: any, i: number) => (
                    <div
                      key={i}
                      className={styles.rentalItem}
                      onClick={() => openModal("AdditionalOffer", item)}
                    >
                      <img src={item.img} alt={item.title} />
                      <div>
                        <p className={styles.rentalTitle}>{item.title}</p>
                        <p className={styles.rentalBody}>{item.address}</p>
                        <p className={styles.rentalPrice}>
                          ₦ {item.price} <span>per Item</span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className={styles.spaceRule}>
              <p className={styles.spaceRule_header}>Space Rule</p>
              <p className={styles.spaceRule_text}>{workspace?.rules}</p>
            </div>

            {reviews && (
              <div className={styles.reviews}>
                <div className={styles.top}>
                  <h3>{reviews?.length} Verified Reviews</h3>

                  {reviews.length > 0 && (
                    <div onClick={() => openModal("review")}>
                      <p>Show all Reviews</p>
                      <img src={ArrorRight} />
                    </div>
                  )}
                </div>

                <div className={styles.review}>
                  {reviews?.slice(0, 4)?.map((item: any, index: number) => (
                    <div key={index}>
                      <div className={styles.left}>
                        <Avatar
                          style={{ backgroundColor: "#87d068" }}
                          icon={<UserOutlined />}
                          size="large"
                        />
                      </div>
                      <div className={styles.right}>
                        <p>{item.review}</p>
                        <div>
                          <h3>{item.user_name}</h3>
                          <span></span> <img alt="..." src={Star} />
                          {`${item.review_rating}.0`}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className={styles.review_mobile}>
                  <Splide
                    aria-labelledby="listing"
                    options={{
                      rewind: true,
                      perPage: 1,
                      gap: "1.5rem",
                      classes: {
                        arrows: "splide__arrows splide__arrows-review",
                        arrow: "splide__arrow",
                        prev: "splide__arrow--prev",
                        next: "splide__arrow--next ",
                        pagination:
                          "splide__pagination splide-review-pagination",
                      },
                      arrowPath:
                        "M18.9342 0.599936L26.4009 8.0666C26.5342 8.19993 26.6285 8.34438 26.6836 8.49994C26.7396 8.65549 26.7676 8.82216 26.7676 8.99994C26.7676 9.17771 26.7396 9.34438 26.6836 9.49994C26.6285 9.65549 26.5342 9.79993 26.4009 9.93327L18.9342 17.3999C18.6898 17.6444 18.384 17.7666 18.0169 17.7666C17.6507 17.7666 17.3342 17.6444 17.0676 17.3999C16.8009 17.1333 16.6676 16.8168 16.6676 16.4506C16.6676 16.0835 16.8009 15.7666 17.0676 15.4999L22.2342 10.3333L2.00091 10.3333C1.62313 10.3333 1.30669 10.2057 1.05158 9.9506C0.795578 9.6946 0.667578 9.37771 0.667578 8.99994C0.667578 8.62216 0.795578 8.30571 1.05158 8.0506C1.30669 7.7946 1.62313 7.6666 2.00091 7.6666L22.2342 7.6666L17.0676 2.4666C16.8231 2.22216 16.6951 1.91682 16.6836 1.5506C16.6729 1.18349 16.8009 0.866602 17.0676 0.599936C17.312 0.355492 17.6231 0.233267 18.0009 0.233267C18.3787 0.233267 18.6898 0.355492 18.9342 0.599936Z",
                    }}
                  >
                    {reviews?.length > 0 &&
                      reviews.map((item: any, index: number) => (
                        <SplideSlide key={index} className={styles.splide}>
                          <div className={styles.top}>
                            <p>{item.review}</p>
                          </div>
                          <div className={styles.bottom}>
                            <div className={styles.left}>
                              {/* <img src={item.img} /> */}
                              <Avatar
                                style={{ backgroundColor: "#87d068" }}
                                icon={<UserOutlined />}
                                size="large"
                              />
                            </div>
                            <div className={styles.bottom}>
                              <div className={styles.left}>
                                {/* <img src={item.img} /> */}
                                <Avatar
                                  style={{ backgroundColor: "#87d068" }}
                                  icon={<UserOutlined />}
                                  size="large"
                                />
                              </div>

                              <div className={styles.right}>
                                <h3>{item.user_name}</h3>
                                <div>
                                  <span></span> <img alt="..." src={Star} />
                                  {`${item.review_rating}.0`}
                                </div>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                      ))}
                  </Splide>
                </div>

                <div className={styles.button}>
                  <Button
                    onClick={() => openModal("create-review")}
                    type="transparent-dark"
                  >
                    Review Workspace
                  </Button>
                </div>
              </div>
            )}
            <div className={styles.location}>
              <div className={styles.top}>
                <h3>Location</h3>
                <div>
                  <Location />
                  <p>{workspace.address?.city + ", " + workspace.address?.country}</p>
                </div>
              </div>
              <div className={styles.map} ref={ref}>
                {isLoaded ? (
                  <GoogleMap
                    // onLoad={loadHandler}
                    mapContainerStyle={containerStyle}
                    center={{
                      lat: Number(workspace?.map?.lat || "6.602560"),
                      lng: Number(workspace?.map?.lng || "3.351630"),
                      // lat: Number(1002),
                      // lng: Number(1082),
                    }}
                    zoom={18}
                  >
                    <Marker
                      position={{
                        lat: Number(1002),
                        lng: Number(1082),
                        // lat: Number(workspace?.map?.lat || "6.602560"),
                        // lng: Number(workspace?.map?.lng || "3.351630"),
                      }}
                    />
                  </GoogleMap>
                ) : null}
              </div>
            </div>
            {policies?.map((item, i) => (
              <div key={i} className={styles.policies}>
                <h3>{item.policy_name}</h3>
                <p className={styles.subHead}>{item.policy_description}</p>
                <p className={styles.policy}>{item.body}</p>
              </div>
            ))}
            <div className={styles.policies}>
              <h3>Cancellation Policies</h3>
              {workspace?.other_policy && (
                <ul>
                  {workspace?.other_policy?.is_smoking_allowed !==
                    undefined && (
                    <li className={styles.subHead}>
                      {workspace?.other_policy?.is_smoking_allowed
                        ? "Smoking is allowed"
                        : "Smoking is not allowed"}
                    </li>
                  )}
                  {workspace?.other_policy?.is_pet_allowed !== undefined && (
                    <li className={styles.subHead}>
                      {workspace?.other_policy?.is_pet_allowed
                        ? "Pets allowed"
                        : "No Pets allowed"}
                    </li>
                  )}
                  {workspace?.other_policy?.is_overnight_allowed !==
                    undefined && (
                    <li className={styles.subHead}>
                      {workspace?.other_policy?.is_overnight_allowed
                        ? "Overnights are allowed"
                        : "Overnights are not allowed"}
                    </li>
                  )}
                  {workspace?.other_policy?.is_music_allowed !== undefined && (
                    <li className={styles.subHead}>
                      {workspace?.other_policy?.is_music_allowed
                        ? "Music is allowed"
                        : "Music is not allowed"}
                    </li>
                  )}
                  {workspace?.other_policy?.is_alcohol_allowed !==
                    undefined && (
                    <li className={styles.subHead}>
                      {workspace?.other_policy?.is_alcohol_allowed
                        ? "Alcohol is allowed"
                        : "Alcohol is not allowed"}
                    </li>
                  )}
                  {workspace?.other_policy?.is_adult_filming_allowed !==
                    undefined && (
                    <li className={styles.subHead}>
                      {workspace?.other_policy?.is_adult_filming_allowed
                        ? "Adult filming is allowed"
                        : "Adult filming is not allowed"}
                    </li>
                  )}
                  {workspace?.other_policy?.age_restriction && (
                    <li className={styles.subHead}>
                      {`Age restrictions: ${workspace?.other_policy.age_restriction}+ allowed`}
                    </li>
                  )}
                </ul>
              )}

              <h3>Food Policies</h3>
              {workspace?.food_policy && (
                <ul>
                  {workspace?.food_policy?.is_catering_available !==
                    undefined && (
                    <li className={styles.subHead}>
                      {workspace?.food_policy?.is_catering_available
                        ? "Catering is available"
                        : "Catering is not available"}
                    </li>
                  )}
                  {workspace?.food_policy?.is_external_food_allowed !==
                    undefined && (
                    <li className={styles.subHead}>
                      {workspace?.food_policy?.is_external_food_allowed
                        ? "External food is allowed"
                        : "External food is not allowed"}
                    </li>
                  )}
                  {workspace?.food_policy?.food_policy_description && (
                    <li className={styles.subHead}>
                      {workspace?.food_policy?.food_policy_description}
                    </li>
                  )}
                </ul>
              )}
            </div>

            <WorkspaceSocialShare workspaceId={params.id!} />

            {modalType === "contact" && (
              <Contact isOpen={isOpen} closeModal={closeModal} />
            )}

            {modalType === "review" && (
              <Review
                isOpen={isOpen}
                closeModal={closeModal}
                // openModal={openModal}
                reviews={reviews}
              />
            )}

            {modalType === "services-web" && (
              <ServicesWeb
                isOpen={isOpen}
                services={selectedService}
                closeModal={closeModal}
              />
            )}

            {modalType === "services" && (
              <Services
                isOpen={isOpen}
                closeModal={closeModal}
                services={workspace?.services}
                otherService={workspace?.service_amenities}
              />
            )}
            {modalType === "create-review" && (
              <CreateReview
                isOpen={isOpen}
                closeModal={closeModal}
                workspace={workspace}
                reviews={reviews}
              />
            )}

            {modalType === "get-quote" && (
              <Getquote
                isOpen={isOpen}
                closeModal={closeModal}
                reviews={reviews}
                services={workspace?.services}
              />
            )}

            {modalType === "message-host" && (
              <MessageHost
                isOpen={isOpen}
                closeModal={closeModal}
                services={workspace.services}
              />
            )}

            {modalType === "AdditionalOffer" && (
              <AdditionalOffer
                isOpen={isOpen}
                closeModal={closeModal}
                rental={selectedRental}
              />
            )}
          </div>
        </div>
      )}
      <div>
        <Similar />
      </div>
    </div>
  );
};

export default WorkspacePage;
