export const TickCircleIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.7044 30.1549C24.0378 30.1549 30.0378 24.1549 30.0378 16.8216C30.0378 9.48828 24.0378 3.48828 16.7044 3.48828C9.37109 3.48828 3.37109 9.48828 3.37109 16.8216C3.37109 24.1549 9.37109 30.1549 16.7044 30.1549Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0391 16.8212L14.8124 20.5945L22.3724 13.0479"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
