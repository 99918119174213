export const ChevronDownIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0303 16.2803C12.7374 16.5732 12.2626 16.5732 11.9697 16.2803L4.46967 8.78033C4.17678 8.48744 4.17678 8.01256 4.46967 7.71967C4.76256 7.42678 5.23744 7.42678 5.53033 7.71967L12.5 14.6893L19.4697 7.71967C19.7626 7.42678 20.2374 7.42678 20.5303 7.71967C20.8232 8.01256 20.8232 8.48744 20.5303 8.78033L13.0303 16.2803Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
