import React, { useState, useEffect } from 'react';
import { Calendar, Modal, Form, Input, Button, Select } from 'antd';
import dayjs from 'dayjs';
import './calendar.css';
import styles from "./bookingCalendar.module.scss";


const { Option } = Select;
interface Booking {
	date: string;
	service: string;
	duration: string;
	seats: number;
	user: string;
}

const serviceColors: Record<string, string> = {
	'Private Office': '#1890ff',
	'Shared Desk': '#52c41a',
	'Dedicated Desk': '#faad14',
	'Meeting Room': '#722ed1',
};


const BookingCalendar = () => {
	const [visible, setVisible] = useState(false);
	const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
	const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
	const [bookings, setBookings] = useState<Booking[]>([]);

	useEffect(() => {
		// Simulated data (you can replace this with actual data)
		setBookings([
			{
				date: dayjs().format('YYYY-MM-DD'),
				service: 'Private Office',
				duration: '2 hours',
				seats: 1,
				user: 'User1',
			},
			{
				date: dayjs().add(1, 'day').format('YYYY-MM-DD'),
				service: 'Shared Desk',
				duration: '1 day',
				seats: 2,
				user: 'User2',
			},
		]);
	}, []);



	const onDayClick = (value: dayjs.Dayjs) => {
		console.log('on day click')
		setVisible(true);
		const date = value.format('YYYY-MM-DD');
		setSelectedDate(value);
		const booking = bookings.find((booking) => booking.date === date);
		setSelectedBooking(booking || null);
	};

	const onAddBookingClick = (value: dayjs.Dayjs) => {
		// Clear the selected booking
		setSelectedBooking(null);
		setVisible(true);
		setSelectedDate(value);
		setSelectedBooking(null);
	};


	const closeModal = () => {
		setVisible(false);
		setSelectedDate(null);
		setSelectedBooking(null);
	};


	const onFinish = (values: Booking) => {
		if (selectedDate) {
			// Add a new booking to the list
			setBookings([
				...bookings,
				{
					date: selectedDate.format('YYYY-MM-DD'),
					service: values.service,
					duration: values.duration,
					seats: values.seats,
					user: 'UserX',
				},
			]);
			// setSelectedDate(null);
			// setSelectedBooking(null);
			closeModal()
		}
	};


	const dateCellRender = (value: dayjs.Dayjs) => {
		const date = value.format('YYYY-MM-DD');
		const bookedBookings = bookings.filter((booking) => booking.date === date);
		const color = bookedBookings.length ? serviceColors[bookedBookings[0].service] : undefined;

		return (
			<div
				className={`booked-day${bookedBookings.length === 0 ? ' add-booking' : ''}`}
				style={{ backgroundColor: color }}
				onClick={() => {
					if (bookedBookings.length === 0) {
						onAddBookingClick(value);
					} else {
						onDayClick(value);
					}
				}}
			>
				{bookedBookings.map((booking, index) => (
					<div key={index} className="booking-info">
						{booking.service} - {booking.duration}
					</div>
				))}
			</div>
		);
	};

	return (
		<div className="your-calendar">
			<Calendar
				value={selectedDate ? selectedDate : undefined}
				dateCellRender={dateCellRender}
				onSelect={onDayClick}
			/>
			<Modal
				title="Booking Details"
				// visible={!!selectedBooking}
				visible={visible}
				onCancel={closeModal}
				footer={null}
				// className="custom-fullscreen-modal"
			>
				{selectedBooking && (
					<div>
						<div>Service: {selectedBooking.service}</div>
						<div>Duration: {selectedBooking.duration}</div>
						<div>Seats: {selectedBooking.seats}</div>
					</div>
				)}
				<Form onFinish={onFinish} layout="vertical">
					<Form.Item
						label="Service"
						name="service"
						rules={[{ required: true, message: 'Please select a service' }]}
					>
						<Select placeholder="Select a service">
							{Object.keys(serviceColors).map((service) => (
								<Option key={service} value={service}>
									{service}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Duration"
						name="duration"
						rules={[{ required: true, message: 'Please select a duration' }]}
					>
						<Select placeholder="Select a duration">
							<Option value="2 hours">2 hours</Option>
							<Option value="1 day">1 day</Option>
						</Select>
					</Form.Item>
					<Form.Item
						label="Seats"
						name="seats"
						rules={[
							{ required: true, message: 'Please enter the number of seats' },
						]}
					>
						<Input type="number" min={1} />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Add Booking
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};
export default BookingCalendar;
