import React, { useEffect, useState } from "react";
import MultiDate, { Value } from "react-multi-date-picker";
import dayjs, { Dayjs } from "dayjs";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { addDateToBooking } from "@/services/networkRequests/bookingService";
import styles from "./confirmDatePicker.module.scss";
import { Button, Divider } from "antd";
import { toast } from "react-toastify";

interface CustomDatePickerProp {
  bookingId?: string;
}

const CustomDatePicker: React.FC<CustomDatePickerProp> = ({ bookingId }) => {
  const [selectedDates, setSelectedDates] = useState<Dayjs[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [availableDates, setAvailableDates] = useState([]);
  const handleDateChange = (dates: Dayjs[]) => {
    setSelectedDates(dates);
  };

  // Convert the array of Dayjs objects to the Value type
  const convertedValue: any = selectedDates.map(
    (date) =>
      // date.format("YYYY-MM-DD")
      date
  );

  const handleDateSubmit = async () => {
    if (convertedValue.length > 0) {
      setIsSaving(true);
      try {
        const res = await addDateToBooking(bookingId, {
          availability: convertedValue,
        });
        toast.success("Date is added succecessfully");
        console.log(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsSaving(false);
      }
    } else {
      console.log("select one or more date");
      toast.error("select one or more date");
    }
  };

  return (
    <div className={styles.datesContainer}>
      <div>
        <p>Select Multiple Dates</p>
        <MultiDate
          value={convertedValue}
          // @ts-ignore
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          plugins={[<DatePanel />]}
          className={styles.datePanel}
        />
        <Button
          className={styles.submitBtn}
          disabled={isSaving}
          onClick={handleDateSubmit}
        >
          {isSaving ? "Saving......" : "Save"}
        </Button>
      </div>
      <Divider className={styles.divider}></Divider>
      <div className={styles.selectedDates}>
        <p>Selected Dates:</p>
        <ul>
          {selectedDates.map((date) => (
            <li key={date.format("YYYY-MM-DD")}>
              {date.format("YYYY-MM-DD")}{" "}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CustomDatePicker;
