import { API_ENDPOINTS } from "@/data/client/api-endpoints";
import { RequestResponse } from "../../types";
import {
  GET_USER,
  UPDATE_USER,
  VERIFICATION_EMAIL,
  KYC_VERIFICATION,
} from "../apiEndpoints";
import { axiosPrivate } from "../axiosPrivate";

export const getUserData = async () => {
  const endpoint = process.env.REACT_APP_BASE_URL + API_ENDPOINTS.USERS_ME;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response.data?.data;
};

export const updateUserData = async (payload: any) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL + UPDATE_USER}`;
  const response: RequestResponse = await axiosPrivate.patch(endpoint, payload);
  return response.data?.data;
};

export const sendUserVerifyEmail = async (payload: any) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL + VERIFICATION_EMAIL}`;
  const response: RequestResponse = await axiosPrivate.post(endpoint, payload);
  return response.data?.data;
};

export const userKycVerification = async (payload: any) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL + KYC_VERIFICATION}`;
  const response: RequestResponse = await axiosPrivate.post(endpoint, payload);
  return response.data?.data;
};
