

const Introduction = () => {
  return (
    <div className="grid place-items-center gap-2 text-textColor">
        <p className="text-4xl lg:text-6xl font-normal leading-10 text-center">Subscription Plans</p>
        <div className="grid leading-8 place-items-center text-xs lg:text-base">
        <p className="text-center block">Grow and mange your event business with CO-SPIRE</p>
        <p className="text-center">Clear, straightforward plans tailored to your needs.</p>
        </div>   
    </div>
  )
}

export default Introduction