const TOKEN_NAME = `token`;

export const getToken = (): string | null => {
  const token = localStorage.getItem(TOKEN_NAME) ?? null;
  return token;
};

export const setToken = (token: string): void => {
  localStorage.setItem(TOKEN_NAME, JSON.stringify(token));
};
