import { useEffect, useState } from "react";
import "antd/dist/reset.css";
import Router from "./routes";
import "@/assets/styles/index.scss";
import "@splidejs/react-splide/css";
import WhatsApp from "./components/whatsApp/whatsApp";
import BrevoChatWidget from "./components/brevoChat/BrevoChatWidget";
import { ModalProvider } from "./components/modal-view/context";
import { QueryClient, QueryClientProvider } from "react-query";
import ModalsContainer from "./components/modal-view/container";
import { AuthProvider } from "./context/auth-context";

//

function App() {
  const [queryClient] = useState(() => new QueryClient());

  useEffect(() => {
    if (document && document.querySelector("html")) {
      document.querySelector("html")!.style.scrollBehavior = "auto";
      window.scroll({ top: 0 });
      document.querySelector("html")!.style.scrollBehavior = "";
    }
  }, [window.location.pathname]); // triggered on route change

  return (
    <>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ModalProvider>
            <Router />
            {/* < WhatsApp/> */}
            {/* <BrevoChatWidget/> */}
            <ModalsContainer />
          </ModalProvider>
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}

export default App;
