import { useState } from "react";
import { ConfigValues } from "@/config";
import { useCopyToClipboard } from "react-use";
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";

import { FacebookIcon } from "@/components/icons/facebook-icon";
import { WhatsappIcon } from "@/components/icons/whatsapp-icon";
import { EmailIcon } from "@/components/icons/email-icon";
import { LinkIcon } from "@/components/icons/link-icon";
import { WORKSPACE_URL } from "@/routes/routes";

interface Props {
  workspaceId: string;
}

export function WorkspaceSocialShare({ workspaceId }: Props) {
  const workspaceUrl = `${ConfigValues.APP_URL}${WORKSPACE_URL(workspaceId)}`;

  let [copyButtonStatus, setCopyButtonStatus] = useState("Copy Link");
  let [_, copyToClipboard] = useCopyToClipboard();
  const handleCopyToClipboard = () => {
    copyToClipboard(workspaceUrl);
    setCopyButtonStatus("Copied...");
    setTimeout(() => {
      setCopyButtonStatus(copyButtonStatus);
    }, 1000);
  };
  return (
    <div className="flex items-center gap-x-8 py-10 mt-10 md:mt-16">
      <p className="text-lg">Share: </p>
      <div className="flex flex-wrap gap-5">
        <EmailShareButton className="flex items-center" url={workspaceUrl}>
          <EmailIcon className="shrink-0 text-md h-6 w-6 transition-all text-primary mr-1.5" />
          <span className="text-lg text-black">Email</span>
        </EmailShareButton>
        <WhatsappShareButton className="flex items-center" url={workspaceUrl}>
          <WhatsappIcon className="shrink-0 text-md h-6 w-6 transition-all text-primary mr-1.5" />
          <span className="text-lg text-black">Whatsapp</span>
        </WhatsappShareButton>
        <FacebookShareButton className="flex items-center" url={workspaceUrl}>
          <FacebookIcon className="shrink-0 text-md h-6 w-6 transition-all text-primary mr-1.5" />
          <span className="text-lg text-black">Facebook</span>
        </FacebookShareButton>
        <button
          className="flex flex-shrink-0 items-center"
          onClick={handleCopyToClipboard}
        >
          <LinkIcon className="h-6 w-6 text-primary mr-1.5" />
          <span className="text-lg text-black">{copyButtonStatus}</span>
        </button>
      </div>
    </div>
  );
}
