import React, { useState } from "react";
import styles from "./index.module.scss";
import { Divider, Col, Row } from "antd";
import CustomBox from "@/components/customBox";
import CustomTable from "@/components/tableComponent/Table/Table";
import CustomTag from "@/components/customTag";
import useCustomer from "./Customer";
import arrorRight from "../../../assets/svgs/arrow-long-right.svg";
import star from "../../../assets/svgs/Vector.svg";
import verifyIcon from "../../../assets/svgs/verifiedIcon.svg";
import bookMark from "../../../assets/svgs/bookMark.svg";
import location from "../../../assets/svgs/mapIcon.svg";
import { Carousel } from "antd";
import { Link } from "react-router-dom";
import Preview from "@/components/Preview/Preview";
import bookPreview from "../../../assets/svgs/bookinPreview.svg";
import { WORKSPACES } from "@/routes/routes";

function HomeCustomer() {
  const imageStyle = {
    marginRight: "10px",
  };
  const { data, getColor, spaces, isFetching } = useCustomer();
  const booking = [
    {
      title: (
        <span style={{ textTransform: "uppercase", color: "#131416" }}>
          Customer Informations
        </span>
      ),
      dataIndex: "customerInformation",
      key: "workspaceInformation",
      render: (workspaceInfo: { name: string; createdDate: Date }) => (
        <div className={`${styles.customerInfo}`}>
          <h1 className={`${styles.name}`}>{workspaceInfo.name}</h1>
          <p>Booked on : Friday 15th June, 2023</p>
        </div>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Booking Status
        </span>
      ),
      dataIndex: "bookingStatus",
      key: "bookingStatus",
      render: (text: string) => (
        <CustomTag color={getColor(text)} text={text} />
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => (
        <span className={`${styles.amount}`}>{`₦${amount}`}</span>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Payment Status
        </span>
      ),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text: string) => (
        <CustomTag color={getColor(text)} text={text} />
      ),
    },
  ];
  const onChangeSlide = (currentSlide: number) => {
    console.log(currentSlide);
  };

  return (
    <div>
      <div className={styles.top}>
        <p>Hi Frank,</p>
        <p className={styles.para2}>Lorem Ipsum dolor</p>
      </div>
      <Divider orientation="left" />

      <Row gutter={[24, 24]} className={styles.wrapper}>
        <Col span={24} xs={24} lg={13} className={styles.customBox}>
          <div className={styles.para}>
            <p className={styles.para_1}>Book a Co-Spire workspace today!</p>
            <p className={styles.para_2}>
              Best deals, amazing spaces suitable for you
            </p>
            <Link to={WORKSPACES} className={styles.para_3}>
              Explore Available Workspaces <img src={arrorRight} alt="" />
            </Link>
          </div>
        </Col>
        <Col span={24} xs={24} lg={10} className={styles.spaces}>
          <Carousel afterChange={onChangeSlide} dots={true} autoplay>
            {spaces.map((item, i) => (
              <div className={styles.carousel}>
                <p className={styles.para_1}>Workspaces to check out</p>

                <div className={styles.container}>
                  <div className={styles.container_space}>
                    <div className={styles.space_text}>
                      <p className={styles.feature}>feature space</p>
                      <p>
                        <img src={bookMark} alt="bookMark" />{" "}
                      </p>
                    </div>
                  </div>
                  <div className={styles.container_text}>
                    <p className={styles.para_1}>{item.locationName}</p>
                    <p className={styles.para_2}>
                      {" "}
                      <img src={star} alt="" style={imageStyle} /> {item.rating}
                    </p>
                    <p className={styles.para_3}>
                      <img src={verifyIcon} alt="" style={imageStyle} />{" "}
                      'Verified Workspace'{" "}
                    </p>
                    <p className={styles.para_3}>
                      {" "}
                      {item.verifyReview} verified Review
                    </p>

                    <p className={styles.para_4}>Starting from $200/month</p>

                    <p className={styles.para_5}>
                      {" "}
                      <img src={location} alt="..." style={imageStyle} />{" "}
                      {item.location}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>

      {/* fixing starts from here */}
      <Divider orientation="left" />
      <div className={styles.recentBooking_title}>
        <p className={styles.para_1}>Your Recent Booking</p>
        <Link
          to={"/customer-profile/booking_history"}
          className={styles.para_2}
        >
          See all
        </Link>
      </div>
      <Divider orientation="left" />
      {isFetching ? (
        // <div>1</div>
        <Preview
          image={bookPreview}
          bookingText="You have not booked a space yet"
          labelText="Your recent bookings will be displayed here."
        />
      ) : (
        <div className={styles.table_container}>
          <CustomTable columns={booking} data={data} />
        </div>
      )}
    </div>
  );
}

export default HomeCustomer;
