import * as Yup from "yup";
import { InferType } from "yup";

const firstStepSchema = Yup.object({
  countryRegion: Yup.string().required().label("Country / Region"),
  state: Yup.string().required().label("State"),
  city: Yup.string().required().label("City"),
  streetAddress: Yup.string().required().label("Street Address"),
  zipCode: Yup.string().required().label("Zip Code"),
  map: Yup.object({
    lat: Yup.number().optional().default(0),
    lng: Yup.number().optional().default(0),
  }),
});

const secondStepSchema = Yup.object({
  overnightStay: Yup.boolean().nullable().default(null).required(),
  parkingSpaces: Yup.boolean()
    .nullable()
    .default(null)
    .required()
    .label("Parking Spaces"),
  parkingOption: Yup.mixed<"free" | "paid">()
    .oneOf(["free", "paid"])
    .required(),
  parkingSlots: Yup.number().required().default(0).label("Parking Slots"),
  nearbyParkingSpaces: Yup.boolean().nullable().default(null).required(),
  securityCameras: Yup.boolean().nullable().default(null).required(),
  restRooms: Yup.boolean().nullable().default(null).required(),
  twentyFourSeven: Yup.boolean().nullable().default(null).required(),
});

const thirdStepSchema = Yup.object({
  foodPolicy: Yup.boolean().nullable().default(null).required(),
  alcoholPolicy: Yup.boolean().nullable().default(null).required(),
  petPolicy: Yup.boolean().nullable().default(null).required(),
  smokingPolicy: Yup.boolean().nullable().default(null).required(),
  adultFilming: Yup.boolean().nullable().default(null).required(),
  noisePolicy: Yup.boolean().nullable().default(null).required(),
  musicPolicyOne: Yup.boolean().nullable().default(false).optional(),
});

const fourthStepSchema = Yup.object({
  spaceTitle: Yup.string().required(),
  about: Yup.string().required(),
  numberOfPeoples: Yup.number().required().default(0),
  spaceRules: Yup.string().required(),
  hasAgeRestriction: Yup.boolean().required(),
  agesAllowed: Yup.array(Yup.string()).when("hasAgeRestriction", {
    is: true,
    then: Yup.array(Yup.string()).required(),
    otherwise: Yup.array(Yup.string()).notRequired(),
  }),
  facilities: Yup.array(Yup.string()).default([]),
});

const fifthStepSchema = Yup.object({
  minHoursOption: Yup.string().required(),
  currency: Yup.mixed<"USD" | "EUR" | "GBP" | "NGN">()
    .oneOf(["USD", "EUR", "GBP", "NGN"])
    .required(),
  daysOpen: Yup.array(Yup.string()).min(1),
  pricing: Yup.object({
    hourly: Yup.number().required(),
    daily: Yup.number().required(),
    weekly: Yup.number(),
    monthly: Yup.number(),
  }),
  securityDepositPer: Yup.number().required(),
});

const sixthStepSchema = Yup.object({
  lorem: Yup.string().optional(),
});

const seventhStepSchema = Yup.object({
  spacePictures: Yup.array(Yup.mixed().required("File is required")),
  availableDays: Yup.array(Yup.string().required()).min(1),
  openingTime: Yup.string().required(),
  closingTime: Yup.string().required(),
});

const contactDetailsStepSchema = Yup.object({
  contactImage: Yup.mixed().required().nullable(),
  displayName: Yup.string().required(),
  emailAddress: Yup.string().required(),
});

export const formSchema = [
  firstStepSchema,
  secondStepSchema,
  thirdStepSchema,
  fourthStepSchema,
  fifthStepSchema,
  sixthStepSchema,
  seventhStepSchema,
  contactDetailsStepSchema,
];

export type SecondStepValues = {
  overnightStay: boolean | null;
  parkingSpaces: boolean | null;
  parkingOption: "free" | "paid";
  parkingSlots: number;
  nearbyParkingSpaces: boolean | null;
  securityCameras: boolean | null;
  restRooms: boolean | null;
  twentyFourSeven: boolean | null;
};

export type ThirdStepValues = {
  foodPolicy: boolean | null;
  alcoholPolicy: boolean | null;
  petPolicy: boolean | null;
  smokingPolicy: boolean | null;
  adultFilming: boolean | null;
  noisePolicy: boolean | null;
  musicPolicyOne: boolean | null;
};

export type AddSpaceVales = Yup.InferType<typeof firstStepSchema> &
  SecondStepValues &
  ThirdStepValues &
  Yup.InferType<typeof fourthStepSchema> &
  InferType<typeof fifthStepSchema> &
  InferType<typeof sixthStepSchema> &
  InferType<typeof seventhStepSchema> &
  InferType<typeof contactDetailsStepSchema>;
