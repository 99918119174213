import React, { HTMLInputTypeAttribute } from "react";
import styles from './index.module.scss'
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Input, Space } from "antd";

type BaseInputProps = {
    type?: HTMLInputTypeAttribute;
    name?: string;
    value?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
      onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    placeholder?: string;
    autoComplete?: string;
    error?: string | undefined | number | boolean;
    disabled?: boolean;
    backgroundColor?: string;
    action?: React.ReactNode;
    label?: string;
    secLabel?: string;
    extraStyles?: Record<string, string>;
    onKeyUp?: any;
    min?: number;
    alt?: string;
    maxLength?: number;
    icon?: any;
    pattern?: RegExp;
    status?: 'error' | 'warning';
  };

const CustomPasswordBox: React.FC<BaseInputProps> = ({
    type,
    name,
    placeholder,
    value,
    onChange,
    autoComplete,
    error,
    disabled,
    min = 0,
    label,
    extraStyles,
    secLabel,
    onKeyUp,
    maxLength,
    status,
    onBlur
}) => {
   const [passwordVisible, setPasswordVisible] = React.useState(false);

   return (
      <div className={styles.container}>
         {label && <label className={styles.label}>{label}</label>}
         {secLabel && <p className={styles.secondaryLabel}>{secLabel}</p>}
         <Space direction='vertical'>
            <Input.Password 
                type={type}
                name={name}
                placeholder={placeholder} 
                disabled={disabled}
                className={styles.input}
                status={status}
                value={value}
                min={min}
                maxLength={maxLength}
                onChange={onChange}
                onBlur={onBlur}
                autoComplete={autoComplete}
                style={extraStyles}
                onKeyUp={onKeyUp}
                onWheel={(e: any) => e.target.blur()}                
                />
         </Space>
         {error && typeof error === "string" && (
        <p className={styles.error}>{error}</p>
      )}
      </div>
   );
};

export default CustomPasswordBox;
