import React, { useEffect, useRef, useState } from "react";
import "./update-space.scss";
import {
  StepFour,
  PageFour,
  ConfirmPricing,
  ContactDetails,
  PageSix,
  PageThree,
  Address,
  PricingStep,
} from "@/components/listing-space/steps";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cn } from "@/lib/utils";
import { StepperComponent } from "@/components/stepper/_StepperComponent";
import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  FormikTouched,
  FormikValues,
  useFormik,
} from "formik";
import { useUpdateWorkspaceMutation } from "@/data/workspace";
import { UpdateSpacePayload, Workspace } from "@/types/workspace";
import { formatAddress } from "@/lib/format-address";
import { AddSpaceVales, formSchema } from "./schema";
import { toast } from "react-toastify";
import { handleError } from "@/services/networkRequests";
import { useMe } from "@/data/user";

export function UpdateSpaceForm({ workspace }: { workspace: Workspace }) {
  const { me: loggedInUser } = useMe();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const step = searchParams.get("step") ?? 1;

  const { mutate: update, isLoading } = useUpdateWorkspaceMutation();

  console.log({ workspace });

  const availableDays = Object.entries(workspace.working_hours)
    .filter(
      ([day, hours]) =>
        hours.open_time !== "closed" && hours.close_time !== "closed"
    )
    .map(([day]) => day);

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(formSchema[0]);
  const [initValues] = useState<AddSpaceVales>({
    countryRegion: workspace?.address?.country ?? "",
    state: workspace?.address?.state ?? "",
    city: workspace?.address?.city ?? "",
    streetAddress: workspace?.address?.street_address ?? "",
    zipCode: workspace?.address?.zip_code ?? "",
    overnightStay: workspace?.other_policy?.is_overnight_allowed ?? false,
    parkingSpaces: workspace.parking_options.is_park_space_available ?? false,
    parkingOption: workspace.parking_options.is_park_space_free
      ? "free"
      : "paid",
    parkingSlots: workspace.parking_options.number_of_parking_space ?? 0,
    nearbyParkingSpaces:
      workspace.parking_options.nearby_parking_lot.length > 0 ?? false,
    securityCameras: workspace.security_cameras ?? false,
    restRooms: workspace.restroom ?? false,
    twentyFourSeven: workspace.round_the_clock_service ?? false,
    foodPolicy: false,
    adultFilming: false,
    alcoholPolicy: workspace?.other_policy?.is_alcohol_allowed ?? false,
    noisePolicy: false,
    musicPolicyOne: workspace?.other_policy?.is_music_allowed ?? false,
    petPolicy: workspace?.other_policy?.is_pet_allowed ?? false,
    smokingPolicy: workspace?.other_policy?.is_smoking_allowed ?? false,
    spaceTitle: workspace.title,
    about: workspace?.about ?? "",
    numberOfPeoples: Number(workspace.space_size),
    spaceRules: workspace?.rules ?? "",
    hasAgeRestriction: false,
    agesAllowed: [],
    facilities: workspace.service_amenities.map((amenity) => amenity.name),
    minHoursOption: workspace.minimum_hours,
    currency: "NGN",
    pricing: {
      hourly: workspace.pricing.hourly,
      daily: workspace.pricing.daily,
      weekly: workspace.pricing.weekly,
      monthly: workspace.pricing.monthly,
    },
    securityDepositPer: Number(workspace?.percentage_of_security_deposit) ?? 0,
    spacePictures: workspace?.photos?.map((photo) => ({ src: photo })),
    daysOpen: [],
    availableDays: availableDays,
    openingTime: availableDays.length
      ? workspace.working_hours[availableDays[0]].open_time
      : "00:00",
    closingTime: availableDays.length
      ? workspace.working_hours[availableDays[0]].close_time
      : "00:00",
    contactImage: null,
    displayName: workspace.host.name ?? loggedInUser?.displayName,
    emailAddress: workspace?.space_contacts?.space_email ?? loggedInUser?.email,
    lorem: "",
    map: {
      lat: 0,
      lng: 0,
    },
  });

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) return;

    if (stepper.current.currentStepIndex === 1) {
      navigate(-1);
    }

    stepper.current.goPrev();

    setCurrentSchema(formSchema[stepper.current.currentStepIndex - 1]);
  };

  async function submitStep(
    values: AddSpaceVales,
    actions: FormikHelpers<AddSpaceVales>,
    touched: FormikTouched<AddSpaceVales>
  ) {
    if (!stepper.current) {
      return;
    }

    setCurrentSchema(formSchema[stepper.current.currentStepIndex]);

    const { openingTime, closingTime, availableDays } = values;

    console.log(touched);

    const payload: Partial<UpdateSpacePayload> = {
      is_park_space_available: values.parkingSpaces!,
      is_park_space_free: values.parkingOption === "free",
      is_external_food_allowed: values.foodPolicy!,
      is_catering_available: values.foodPolicy!,
      title: values.spaceTitle,
      about: values.about,
      is_alcohol_allowed: values.alcoholPolicy!,
      display_name: values.displayName,
      restroom: values.restRooms!,
      is_overnight_allowed: values.overnightStay!,
      security_cameras: values.securityCameras!,
      space_email: values.emailAddress,
      space_phone_number: "",
      whatsapp_phone_number: "",
      website_url: "",
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      space_amenity_obj: values.facilities.length
        ? (values.facilities as string[])
        : [],
      pricing: {
        hourly: values.pricing.hourly,
        daily: values.pricing.daily,
        weekly: values.pricing.weekly ?? 0,
        monthly: values.pricing.monthly ?? 0,
      },
      holidays: [],
      cancellation_policy_ids: ["qIXXAAtNPEvDoZAdGliV"],
      number_of_parking_space: Number(values.parkingSlots),
      nearby_parking_lot: [],
      round_the_clock_service: values.twentyFourSeven!,
      food_policy_description: "",
      percentage_of_security_deposit: values.securityDepositPer,
      electricity_usage: "use-electricity",
      rules: values.spaceRules,
      images: values.spacePictures?.map((img: any) => img.src)!,
      formatted_address:
        formatAddress({
          country: values.countryRegion,
          state: values.state,
          city: values.city,
          street_address: "",
          zip: values.zipCode,
        }) ?? "",
      address: {
        country: values.countryRegion,
        state: values.state,
        city: values.city,
        street_address: values.streetAddress,
        zip_code: values.zipCode,
      },
      operation_hours: {
        monday: {
          open_time: availableDays?.includes("monday") ? openingTime : "closed",
          close_time: availableDays?.includes("monday")
            ? closingTime
            : "closed",
        },
        tuesday: {
          open_time: availableDays?.includes("tuesday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("tuesday")
            ? closingTime
            : "closed",
        },
        wednesday: {
          open_time: availableDays?.includes("wednesday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("wednesday")
            ? closingTime
            : "closed",
        },
        thursday: {
          open_time: availableDays?.includes("thursday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("thursday")
            ? closingTime
            : "closed",
        },
        friday: {
          open_time: availableDays?.includes("friday") ? openingTime : "closed",
          close_time: availableDays?.includes("friday")
            ? closingTime
            : "closed",
        },
        saturday: {
          open_time: availableDays?.includes("saturday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("saturday")
            ? closingTime
            : "closed",
        },
        sunday: {
          open_time: availableDays?.includes("sunday") ? openingTime : "closed",
          close_time: availableDays?.includes("sunday")
            ? closingTime
            : "closed",
        },
      },
      space_size: values.numberOfPeoples,
      capacity: values.numberOfPeoples,
      minimum_hours: Number(values.minHoursOption),
      map: {
        latitude: 0,
        longitude: 0,
      },
      age_restriction: values.hasAgeRestriction
        ? values.agesAllowed?.join(", ")
        : "nil",
      is_adult_filming_allowed: values.adultFilming!,
      is_music_allowed: values.musicPolicyOne!,
      is_pet_allowed: values.petPolicy!,
      is_smoking_allowed: values.smokingPolicy!,
      currency: values.currency,
    };

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      // if (
      //   !Object.keys(touched).some((key) =>
      //     Object.keys(currentSchema.fields).includes(key)
      //   )
      // ) {
      //   stepper?.current?.goNext();
      // } else {
      update(
        { ...payload, id: workspace.workspace_id },
        {
          onSuccess: () => {
            toast.success("Space updated successfully");
            stepper?.current?.goNext();
          },
          onError: (error) => handleError(error),
        }
      );
      // }
    } else {
      update(
        { ...payload, id: workspace.workspace_id },
        {
          onSuccess: () => {
            toast.success("Space updated successfully");
            navigate("/my-listing/preview/" + workspace?.workspace_id);
          },
          onError: (error) => handleError(error),
        }
      );
    }
  }

  useEffect(() => {
    if (!stepperRef.current) return;
    loadStepper();
  }, [stepperRef]);

  useEffect(() => {
    if (!stepper.current) return;
    stepper.current?.goto(Number(step));
  }, [stepper, step]);

  const [wifiService, setWifiService] = useState(false);
  const [staffSupport, setStaffSupport] = useState(false);

  const progress = ((stepper.current?.currentStepIndex ?? 0) / 9) * 100;
  //
  async function handleSave(values: AddSpaceVales) {
    const { openingTime, closingTime, availableDays } = values;

    const payload: Partial<UpdateSpacePayload> = {
      is_park_space_available: values.parkingSpaces!,
      is_park_space_free: values.parkingOption === "free",
      is_external_food_allowed: values.foodPolicy!,
      is_catering_available: values.foodPolicy!,
      title: values.spaceTitle,
      about: values.about,
      is_alcohol_allowed: values.alcoholPolicy!,
      display_name: values.displayName,
      restroom: values.restRooms!,
      is_overnight_allowed: values.overnightStay!,
      security_cameras: values.securityCameras!,
      space_email: values.emailAddress,
      space_phone_number: "",
      whatsapp_phone_number: "",
      website_url: "",
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      space_amenity_obj: values.facilities.length
        ? (values.facilities as string[])
        : [],
      pricing: {
        hourly: values.pricing.hourly,
        daily: values.pricing.daily,
        weekly: values.pricing.weekly ?? 0,
        monthly: values.pricing.monthly ?? 0,
      },
      holidays: [],
      cancellation_policy_ids: ["qIXXAAtNPEvDoZAdGliV"],
      number_of_parking_space: Number(values.parkingSlots),
      nearby_parking_lot: [],
      round_the_clock_service: values.twentyFourSeven!,
      food_policy_description: "",
      percentage_of_security_deposit: values.securityDepositPer,
      electricity_usage: "use-electricity",
      rules: values.spaceRules,
      images: values.spacePictures?.map((img: any) => img.src)!,
      formatted_address:
        formatAddress({
          country: values.countryRegion,
          state: values.state,
          city: values.city,
          street_address: "",
          zip: values.zipCode,
        }) ?? "",
      address: {
        country: values.countryRegion,
        state: values.state,
        city: values.city,
        street_address: values.streetAddress,
        zip_code: values.zipCode,
      },
      operation_hours: {
        monday: {
          open_time: availableDays?.includes("monday") ? openingTime : "closed",
          close_time: availableDays?.includes("monday")
            ? closingTime
            : "closed",
        },
        tuesday: {
          open_time: availableDays?.includes("tuesday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("tuesday")
            ? closingTime
            : "closed",
        },
        wednesday: {
          open_time: availableDays?.includes("wednesday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("wednesday")
            ? closingTime
            : "closed",
        },
        thursday: {
          open_time: availableDays?.includes("thursday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("thursday")
            ? closingTime
            : "closed",
        },
        friday: {
          open_time: availableDays?.includes("friday") ? openingTime : "closed",
          close_time: availableDays?.includes("friday")
            ? closingTime
            : "closed",
        },
        saturday: {
          open_time: availableDays?.includes("saturday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("saturday")
            ? closingTime
            : "closed",
        },
        sunday: {
          open_time: availableDays?.includes("sunday") ? openingTime : "closed",
          close_time: availableDays?.includes("sunday")
            ? closingTime
            : "closed",
        },
      },
      space_size: values.numberOfPeoples,
      capacity: values.numberOfPeoples,
      minimum_hours: Number(values.minHoursOption),
      map: {
        latitude: 0,
        longitude: 0,
      },
      age_restriction: values.hasAgeRestriction
        ? values.agesAllowed?.join(", ")
        : "nil",
      is_adult_filming_allowed: values.adultFilming!,
      is_music_allowed: values.musicPolicyOne!,
      is_pet_allowed: values.petPolicy!,
      is_smoking_allowed: values.smokingPolicy!,
      currency: values.currency,
    };
    update(
      { ...payload, id: workspace.workspace_id },
      {
        onSuccess: () => {
          toast.success("Space updated successfully");
          navigate("/my-listing/preview/" + workspace.workspace_id);
        },
        onError: (error) => handleError(error),
      }
    );
  }

  return (
    <div ref={stepperRef} className="stepper" id="kt_add_space_stepper">
      <div className="w-full bg-[#f0f0f0]">
        <div style={{ width: `${progress}%` }} className="h-2.5 bg-[#007bff]" />
      </div>
      <div className="numbered-progress space-x-4">
        {Array.from({ length: formSchema.length }).map((item, index) => {
          return (
            <div
              data-kt-stepper-element="nav"
              className={cn(
                "flex items-center gap-x-3",
                index === 0 ? "current" : ""
              )}
            >
              <p className={cn("progress-indication-number ")}>{index + 1}</p>
              <div className="progress-indication-bar !w-10">
                <div className="blue-bar"></div>
              </div>
            </div>
          );
        })}
      </div>
      <Formik
        validationSchema={currentSchema}
        initialValues={initValues}
        onSubmit={(values, actions) => {}}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, values, dirty, ...rest }) => {
          return (
            <Form id="kt_add_space_form">
              <div className="workspace-setup-page-content">
                <div className="pages-parent">
                  <div className="current" data-kt-stepper-element="content">
                    <Address values={values} />
                  </div>
                  <div data-kt-stepper-element="content">
                    <PageThree
                      values={values}
                      handleChange={rest.handleChange}
                      wifiService={wifiService}
                      setWifiService={setWifiService}
                      staffSupport={staffSupport}
                      setStaffSupport={setStaffSupport}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <PageFour
                      values={values}
                      handleChange={rest.handleChange}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <StepFour
                      values={values}
                      handleChange={rest.handleChange}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <PricingStep
                      values={values}
                      handleChange={rest.handleChange}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <ConfirmPricing
                      values={values}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <PageSix
                      values={values}
                      setValue={rest.setFieldValue}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <ContactDetails
                      values={values}
                      setValue={rest.setFieldValue}
                    />
                  </div>
                </div>

                <p
                  className={cn(
                    "error-in-listing",
                    "text-red-600 px-4 py-6",
                    Object.keys(errors).length ? "visible" : "hidden"
                  )}
                >
                  Please complete all the required fields
                </p>

                <div className="add-workspace-button-wrapper">
                  <button
                    onClick={prevStep}
                    type="button"
                    data-kt-stepper-action="previous"
                    className="btn button-outline"
                  >
                    Back
                  </button>

                  <button
                    disabled={isLoading}
                    type="button"
                    className="btn"
                    onClick={() => submitStep(values, rest, rest.touched)}
                  >
                    {isLoading ? "Updating..." : "Continue"}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
