import { Button, Col, DatePicker, Divider, Form, Input } from "antd";
import styles from "./index.module.scss";
import CustomModal from "@/components/Modal";
import { Fragment, useState } from "react";
import CustomSelect from "@/components/customSelect";
import { ALL_COUNTRIES } from "@/mocks";
import { CustomInputField } from "@/components/customInput";
import CustomIcons from "@/components/icons";
import SuccessModal from "@/components/a/Modals/Success";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "@/components/customButton";
import { success } from "@/assets/img";
import dayjs, { Dayjs } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
// import { toast } from "react-toastify";
// import { createBooking } from "@/Services/networkRequests/bookingService";
// import { useParams } from "react-router-dom";

dayjs.extend(LocalizedFormat);

const minDate = dayjs();

// interface FormValues {
// 	typeOfSpace: string;
// 	duration: string;
// 	numberOfPeople: number;
// 	startDate: Date;
// 	firstName: string;
// 	lastName: string;
// 	companyName?: string;
// }

interface Iprops {
  isModalOpen: boolean;
  setIsModalOpen: (arg: boolean) => void;
}

interface RoleType {
  startDate?: Dayjs | null | undefined;
}

const initialState: RoleType = {
  startDate: dayjs(),
};

console.log(initialState);

const ConfirmationModal = ({ isModalOpen, setIsModalOpen }: Iprops) => {
  const [startData, setStartData] = useState<RoleType>(initialState);
  const disabledDate = (current: Dayjs | null) => {
    if (!current || !minDate) {
      return false;
    }
    return current.isBefore(minDate, "day");
  };

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOk = () => {
    setIsModalOpen(false);
    setIsSuccessModalOpen(true);
  };

  const typeOfSpace = [
    { label: "Office Desk", value: "office-desk" },
    { label: "Private office", value: "private-office" },
    { label: "Meeting Room", value: "meeting-room" },
    { label: "Conference Rooms", value: "conference-room" },
  ];

  const duration = [
    { label: "1 Month", value: "1" },
    { label: "2 Months", value: "2" },
    { label: "6 Months", value: "6" },
    { label: "12 Months", value: "12" },
  ];

  return (
    <Fragment>
      <SuccessModal
        openModal={isSuccessModalOpen}
        setOpenModal={setIsSuccessModalOpen}
        heading={"Enquiry Received!"}
        closeModal={() => {
          setIsSuccessModalOpen(false);
          navigate(`/customer-profile/booking_history`);
        }}
        icon={success}
        description={
          "Lorem ipsum dolor sit amet consectetur. Amet vitae odio elit a pharetra. Vulputate aliquam purus orci turpis odio in morbi netus. "
        }
      />
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleOk}
        title={"Confirm Availability"}
        subTitle={
          "Thank you for your interest in our space, please fill in your information."
        }
        footer={
          <Button
            key="back"
            type="primary"
            style={{
              width: "100%",
              padding: "0.7rem",
              height: "auto",
              fontSize: "16px",
            }}
            onClick={handleOk}
          >
            Confirm Availability
          </Button>
        }
        width={800}
      >
        <div className={styles.main}>
          <Form
            layout="vertical"
            size={"large"}
            autoComplete="off"
            className={styles.form}
          >
            <div className={styles.formItems}>
              <Form.Item
                name="numberOfPeople"
                label={
                  <span className={styles.label}>
                    How many people is this space for?
                  </span>
                }
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of people",
                  },
                ]}
              >
                <CustomInputField type="number" />
              </Form.Item>

              <Form.Item
                name="typeOfSpace"
                label={
                  <span className={styles.label}>
                    What type of space do you want?
                  </span>
                }
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: "Please enter the type of space that you want...",
                  },
                ]}
              >
                <CustomSelect
                  options={typeOfSpace}
                  placeHolder="Select an Option"
                />
              </Form.Item>
            </div>

            <div className={styles.formItems}>
              <Form.Item
                name="duration"
                label={
                  <span className={styles.label}>
                    How long do you want to book the space for ?
                  </span>
                }
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: "Please state how long you want to book for...",
                  },
                ]}
              >
                <CustomSelect
                  options={duration}
                  placeHolder="Select an Option"
                />
              </Form.Item>

              <Form.Item
                name="startDate"
                label={<span className={styles.label}>Start Date?</span>}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter the date you want to start using the space...",
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY/MM/DD"}
                  disabledDate={disabledDate}
                  // value={startData.startDate}
                  placeholder="Set a start date"
                  className={styles.datepicker}
                />
              </Form.Item>
            </div>
            <div className={styles.formItems}>
              <Form.Item
                name="firstName"
                className={styles.formItem}
                label={<span className={styles.label}>First Name</span>}
                rules={[
                  {
                    required: true,
                    message: "Please input your first name",
                  },
                ]}
              >
                <Input type="text" className={styles.input} placeholder="" />
              </Form.Item>

              <Form.Item
                name="lastName"
                className={styles.formItem}
                label={<span className={styles.label}>Last Name</span>}
                rules={[
                  {
                    required: true,
                    message: "Please input your last name",
                  },
                ]}
              >
                <Input type="text" className={styles.input} placeholder="" />
              </Form.Item>
            </div>

            {/* company's name locatio  */}
            <div className={styles.formItems}>
              <Form.Item
                name="companyName"
                className={styles.formItem}
                label={
                  <span className={styles.label}>
                    What is your Company Name
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please input conmpanys' name",
                  },
                ]}
              >
                <Input type="text" className={styles.input} placeholder="" />
              </Form.Item>

              <Form.Item
                name="typeOfSpace"
                label={<span className={styles.label}>Location</span>}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: "Please enter your preferred location...",
                  },
                ]}
              >
                <CustomSelect
                  options={ALL_COUNTRIES}
                  placeHolder="Select your preferred Location"
                />
              </Form.Item>
            </div>
            <div className={styles.formItems}>
              <Form.Item
                name="companyName"
                className={styles.formItem}
                label={<span className={styles.label}>Promo Code</span>}
                rules={[
                  {
                    required: true,
                    message: "Please input conmpanys' name",
                  },
                ]}
              >
                <Input
                  type="text"
                  className={styles.input}
                  placeholder="Enter your Promo code"
                />
              </Form.Item>
            </div>

            <Divider />
            <div className={styles.cospireCredit}>
              <Form.Item
                name="companyName"
                className={styles.formItem}
                label={""}
                rules={[
                  {
                    required: false,
                    message: "Please input conmpanys' name",
                  },
                ]}
              >
                <div className={styles.ins}>
                  <Input
                    prefix={<CustomIcons name="bankNotesIcon" />}
                    type="text"
                    className={styles.input}
                    placeholder="Enter Co-spire Credit here"
                  />
                  <p>Apply here</p>
                </div>
                <p className={styles.sub}>Available Co-spire Credit: 10000</p>
              </Form.Item>
            </div>
          </Form>
          <div className={styles.summary}>
            <div>
              <small>TOTAL</small>
              <p className={styles.amount}>$200</p>
            </div>
            <div className={styles.more}>
              <CustomIcons name="flashIcon" />
              <span>
                <CustomIcons name="verifiedIcon" />
                <p>200 Verified Reviews</p>
              </span>
            </div>
          </div>
        </div>
      </CustomModal>
    </Fragment>
  );
};

export default ConfirmationModal;
