import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import CustomIcons from "@/components/icons";
import { Button, DatePicker, Divider, Form, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import submit from "../../../../assets/svgs/play-button.svg";

import {
  addDateToBooking,
  getAllBookings,
  getSingleBooking,
  listBooking,
} from "@/services/networkRequests/bookingService";
import { Dayjs, default as dayjs } from "dayjs";
import ConfirmDatePicker from "@/components/confirmDatePicker/comfirmDatePicker";
import { toast } from "react-toastify";
const { RangePicker } = DatePicker;

function HostBookingDetails() {
  interface IBookingData {
    id: any;
    fullname: string;
    company_info: string;
    start_date: string;
    no_of_seats: string;
    service_type: string;
    phone_number: string;
    amount: string;
    payment_status: string;
  }

  const navigate = useNavigate();
  const params = useParams();
  const [bookingData, setBookingData] = useState<IBookingData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const id = params.id;

  useEffect(() => {
    const getBooking = async () => {
      try {
        const res = await getSingleBooking(id);

        console.log(res.data, "single pagme");
        setBookingData(res.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getBooking();
  }, [id]);

  console.log(bookingData, "booking datssssss");

  return (
    <div>
      <div className={styles.top}>
        <div className={styles.book}>
          {" "}
          <span
            onClick={() => {
              navigate(-1);
            }}
          >
            {" "}
            <CustomIcons name="backIcon" />{" "}
          </span>{" "}
          Book Summary
        </div>
        <div className={styles.delete}>
          {" "}
          <span>
            <CustomIcons name="deleteIcon" />
          </span>{" "}
          Delete
        </div>
      </div>

      <Divider orientation="left"></Divider>
      <>
        <div className={styles.date_conatainer}>
          <div>
            <ConfirmDatePicker bookingId={bookingData?.id} />
          </div>
        </div>
      </>
      <Divider orientation="left"></Divider>

      {isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      ) : (
        <div className={styles.bookingInformation}>
          <p className={styles.infoTitle}>Customer Information</p>
          <div className={styles.infoItems}>
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>FULLNAME</small>

              <p className={styles.infoItemDetail}>{bookingData?.fullname}</p>
            </div>
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>COMPANY NAME</small>
              <p className={styles.infoItemDetail}>
                {bookingData?.company_info}
              </p>
            </div>
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>LOCATION</small>
              <p className={styles.infoItemDetail}>Country/State</p>
            </div>
          </div>

          <Divider orientation="left"></Divider>
          <p className={styles.infoTitle}>Booking Information</p>
          <div className={styles.infoItems}>
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>START DATE</small>
              <p className={styles.infoItemDetail}>
                {dayjs(bookingData?.start_date).format("dddd, Do MMM, YYYY")}
              </p>
            </div>
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>NUMBER OF SEAT</small>
              <p className={styles.infoItemDetail}>
                {bookingData?.no_of_seats}
              </p>
            </div>
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>TYPE OF SPACE</small>
              <p className={styles.infoItemDetail}>
                {bookingData?.service_type}
              </p>
            </div>
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>PHONE NUMBER</small>
              <p className={styles.infoItemDetail}>
                {bookingData?.phone_number}
              </p>
            </div>
          </div>

          <Divider orientation="left"></Divider>

          <p className={styles.infoTitle}>Payment Information</p>
          <div className={styles.infoItems}>
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>AMOUNT</small>
              <p className={styles.infoItemDetail}>{bookingData?.amount} NGN</p>
            </div>
            {/* <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>PAYMENT DATE</small>
              <p className={styles.infoItemDetail}>Monday, 1st May, 2023</p>
            </div>
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>TRANSACTION ID</small>
              <p className={styles.infoItemDetail}>123436</p>
            </div> */}
            <div className={styles.infoItem}>
              <small className={styles.infoItemTitle}>TRANSACTION STATUS</small>
              <p className={styles.infoItemDetail}>
                {bookingData?.payment_status}
              </p>
            </div>
          </div>

          <div className={styles.delete}>
            {" "}
            <span style={{ marginTop: "3px", marginRight: "5px" }}>
              <CustomIcons name="deleteIcon" />
            </span>{" "}
            Delete
          </div>
        </div>
      )}
    </div>
  );
}

export default HostBookingDetails;
