import dayjs from "dayjs";

function arrayMin(arr: number[]): number {
  console.log("get arr here", arr);
  return Math.min(...arr);
}
export const calculatePrice = (prices: []) => {
  const hourlyPrice =
    arrayMin(
      prices
        .map((item: any) => item.hourly_price)
        .filter((hourlyPrice) => hourlyPrice !== "")
    ) ?? 0;
  const dailyPrice =
    arrayMin(
      prices
        .map((item: any) => item.daily_price)
        .filter((dailyPrice) => dailyPrice !== "")
    ) ?? 0;

  const weeklyPrice =
    arrayMin(
      prices
        .map((item: any) => item.weekly_price)
        .filter((weeklyPrice) => weeklyPrice !== "")
    ) ?? 0;

  const monthlyPrice =
    arrayMin(
      prices
        .map((item: any) => item.monthly_price)
        .filter((monthlyPrice) => monthlyPrice !== "")
    ) ?? 0;

  if (hourlyPrice < dailyPrice && dailyPrice < weeklyPrice) {
    return hourlyPrice;
  } else if (dailyPrice < weeklyPrice && dailyPrice < monthlyPrice) {
    return dailyPrice;
  } else if (weeklyPrice < dailyPrice && weeklyPrice < monthlyPrice) {
    return weeklyPrice;
  } else {
    return monthlyPrice;
  }
};

const pluralize = (quantity: number, unit: string) => {
  return `${quantity} ${unit}${quantity > 1 ? "s" : ""}`;
};

interface PriceWithLabel {
  price: number;
  label: string;
  durationLength: string;
}

interface Prices {
  hourly_price: number;
  daily_price: number;
  weekly_price: number;
  monthly_price: number;
}

export const newCalculatePrice = (
  prices: Prices,
  isRecurrent: boolean,
  dates: string[],
  recurrentDiff: number,
  formattedStartDates: string,
  formattedEndDates: string
): [number, number, string, string] => {
  const arrayMin = (arr: number[]) =>
    Math.min(...arr.filter((num) => num !== undefined));

  const hourlyPrice = prices?.hourly_price;
  const dailyPrice = prices?.daily_price;
  const weeklyPrice = prices?.weekly_price;
  const monthlyPrice = prices?.monthly_price;

  console.log({ hourlyPrice, dailyPrice, weeklyPrice, monthlyPrice });

  const startDate = dayjs(formattedStartDates);
  const endDate = dayjs(formattedEndDates);

  console.log({ startDate, endDate });

  const diffInHours = Math.ceil(endDate.diff(startDate, "minute") / 60);
  const diffInDays = Math.ceil(endDate.diff(startDate, "hour") / 24);
  const diffInWeeks = Math.ceil(endDate.diff(startDate, "day") / 7);
  const diffInMonths = Math.ceil(endDate.diff(startDate, "day") / 30);

  console.log({ diffInHours, diffInDays, diffInWeeks, diffInMonths });

  const pricesWithLabels = [
    {
      price: hourlyPrice * diffInHours,
      label: "hr",
      durationLength: pluralize(diffInHours, "hour"),
    },
    {
      price: dailyPrice * diffInDays,
      label: "day",
      durationLength: pluralize(diffInDays, "day"),
    },
    {
      price: weeklyPrice * diffInWeeks,
      label: "week",
      durationLength: pluralize(diffInWeeks, "week"),
    },
    {
      price: monthlyPrice * diffInMonths,
      label: "month",
      durationLength: pluralize(diffInMonths, "month"),
    },
  ];

  let priceValue = 0;
  let totalCost = 0;
  let finalPriceLabel = "hr";
  let duration = "";

  console.log("pricesWithLabels", pricesWithLabels);

  let {
    price: costForRange,
    label: priceLabel,
    durationLength,
  } = pricesWithLabels.reduce<PriceWithLabel>(
    (min, current) =>
      current.price < min.price && current.price > 0 ? current : min,
    { price: Infinity, label: "", durationLength: "1 hr" }
  );
  console.log("{ price: costForRange, label: priceLabel }", {
    price: costForRange,
    label: priceLabel,
  });

  if (isRecurrent) {
    const tmp = dates.length * hourlyPrice * recurrentDiff;
    const recurrentCost = tmp;
    priceValue = tmp;
    totalCost = recurrentCost + recurrentCost * 0.1;
    finalPriceLabel = "hr";
  } else {
    const tmp = costForRange;
    priceValue = tmp;
    totalCost = priceValue + priceValue * 0.1;
  }
  totalCost = Math.ceil(totalCost * 100) / 100;
  priceValue = Math.ceil(priceValue * 100) / 100;

  return [totalCost, priceValue, finalPriceLabel, duration];
};

export const minimumPrice = (pricing: any) => {
  if (pricing) {
    const { hourly, daily, weekly, monthly } = pricing;

    const minPrice = Math.min(hourly, daily, weekly, monthly);

    let unit = "";

    switch (minPrice) {
      case hourly:
        unit = " / hour";
        break;
      case daily:
        unit = " / day";
        break;
      case weekly:
        unit = " / week";
        break;
      case monthly:
        unit = " / month";
        break;
      default:
        unit = "";
    }

    return `${minPrice.toString()}${unit}`;
  } else {
    return "0";
  }
};

export function calculateAverage(numbers: number[]) {
  if (numbers.length === 0) {
    return 0;
  }

  const sum = numbers.reduce((total, num) => total + num, 0);
  const average = Math.floor(sum / numbers.length);

  return average;
}
