import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  registerUser,
  login,
} from "../../services/networkRequests/authService";
import { REGISTER } from "../reduxSlice/actionkeys";
import { IRegister } from "../../interfaces/apiResponse";
import { handleError } from "../../services/networkRequests/error";
import { toast } from "react-toastify";

export const createUser = createAsyncThunk(
  REGISTER,
  async (payload: IRegister, { rejectWithValue }) => {
    try {
      const response = await registerUser(payload);
      console.log("Redux response ", response);

      const user = response.data?.data;
      toast.success("registered successfully");
      return user;
    } catch (error: any) {
      handleError(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
