import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGIN } from "../routes/routes";
import { useAuth } from "@/components/auth/use-auth";
import { useMe } from "@/data/user";

interface ProtectedRouteProps {
  allowedRoles: string[];
  fallback: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  allowedRoles,
  fallback,
}) => {
  const { userLoggedIn } = useAuth();
  const { me: user, isLoading: loading } = useMe();
  console.log({ user });

  const role = user?.role_type;
  const location = useLocation();
  if (!userLoggedIn && !loading) {
    return <Navigate to={LOGIN} state={{ from: location.pathname }} />;
  }

  // Check if the user's role is allowed to access the route
  if (!allowedRoles.includes(role as string)) {
    return fallback;
  }

  return <Outlet />;
};
