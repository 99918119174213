import { createSlice } from "@reduxjs/toolkit";
import { createUser } from "../actions/authActions";
import {
  ILoginResponse,
  IRegisterResponse,
} from "../../interfaces/apiResponse";

interface AuthState {
  userIsLoading: boolean;
  userIsError: boolean;
  user: IRegisterResponse | null;
  errorMessage?: string;
  success: boolean;
  loggedInUser: ILoginResponse | null;
  isLoading: boolean;
  isError: boolean;
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  user: null,
  userIsLoading: false,
  userIsError: false,
  errorMessage: "",
  success: false,
  loggedInUser: null,
  isLoading: false,
  isError: false,
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    // ? Logout the user by returning the initial state
    reset: () => initialState,
    // setUser returned from action
    setUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
    // update login status
    loginStatus: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    resetUser: (state, action) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state) => {
      state.userIsLoading = true;
      state.userIsError = false;
      state.success = false;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.user = action.payload ?? null;
      state.userIsLoading = false;
      state.userIsError = false;
      state.success = true;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.userIsLoading = false;
      state.userIsError = true;
      state.success = false;
      state.errorMessage = action.payload as string;
    });
  },
});

export const { reset, setUser, loginStatus, resetUser } = authSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default authSlice.reducer;
