import { CSSProperties } from "react";
import styles from "./index.module.scss";
import { Tag } from "antd";

interface CustomTagProps {
	color: CSSProperties; 
	text: string;
	className?: string;
}

const CustomTag: React.FC<CustomTagProps> = ({ color, text, }) => {
	return (
		<div className={styles.tag} style={color}>
			{text}
		</div>
	);
};

export default CustomTag;
