import React, { useEffect } from "react";
import { Radio } from "antd";
import { TrashIcon } from "@/components/icons/trash-icon";
import bank_icon from "@/assets/svgs/bankIcon.svg";
import { CloseCircleIcon } from "@/components/icons/close-circle";
import { useModalAction } from "@/components/modal-view/context";
import { useMyWallet, useSetPreferredWithdrawalAccount } from "@/data/wallet";
import { toast } from "react-toastify";
import { handleError } from "@/services/networkRequests";

export function WithdrawalMethods() {
  const { closeModal, openModal } = useModalAction();
  const { wallet } = useMyWallet();

  const [preferred, setPreferred] = React.useState<string | null>(null);

  const { mutateAsync: setPreferredBank, isLoading } =
    useSetPreferredWithdrawalAccount();

  function handleSetPreferredBank(accountId: string) {
    toast.promise(
      setPreferredBank(accountId, {
        onSuccess: () => {
          toast.success("Preferred account set successfully");
          setPreferred(null);
        },
        onError: (err) => {
          handleError(err);
        },
      }),
      {
        pending: "Setting account...",
      }
    );
  }

  useEffect(() => {
    if (wallet) {
      const preferredBank = wallet.withdrawal_banks.find((account) => {
        return account.preferred;
      });
      if (preferredBank) setPreferred(preferredBank.account_id);
    }

    return () => {
      setPreferred(null);
    };
  }, [wallet]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-[#F7F9FB] p-6 rounded-md w-full max-w-2xl mx-auto">
        <div className="mb-6 relative">
          <h3 className="font-semibold text-2xl mb-2.5">Withdrawal Method</h3>
          <p className="text-lg text-primaryText">
            Select default withdrawal account
          </p>
          <button
            onClick={closeModal}
            title="Close modal"
            className="absolute top-0 right-0"
          >
            <CloseCircleIcon className="text-primaryText" />
          </button>
        </div>
        <ul className="space-y-7">
          {wallet?.withdrawal_banks.map((account) => (
            <li key={account?.account_id}>
              <div className="bg-white py-2 px-4 flex justify-between items-center">
                <div className="flex">
                  <div className={"w-10 h-10 rounded-full flex mr-2"}>
                    <img src={bank_icon} alt="bank_icon" />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-lg text-[#131416] font-semibold">
                      Bank transfer
                    </p>
                    <div className={"text-sm "}>
                      <span className="text-[#131416]">
                        {account?.account_number?.substring(0, 4) + "********"}{" "}
                      </span>{" "}
                      |{" "}
                      <span className={"text-primaryText"}>
                        {account?.bank_name || ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <Radio
                    onChange={() => setPreferred(account.account_id)}
                    checked={preferred === account.account_id}
                  ></Radio>
                </div>
              </div>
              <button
                onClick={() => openModal("CONFIRM_REMOVE_BANK", account)}
                className="flex items-center"
                title="Remove account"
              >
                <TrashIcon className="text-primaryText" />
                <span className="ml-1 text-sm text-primaryText">
                  Delete this account
                </span>
              </button>
            </li>
          ))}
        </ul>
        <div className="flex justify-end mmt-8">
          <button
            className="bg-primary py-2.5 px-7 rounded-3xl text-white font-medium text-lg disabled:bg-opacity-70"
            onClick={() => handleSetPreferredBank(preferred!)}
            disabled={isLoading}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
}
