import { FormEvent, MouseEventHandler } from "react";
import styles from "./index.module.scss";
import { Button, Tooltip, Space } from 'antd';

export interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  extraStyles?: Record<string, string>;
  size?: "small" | "large" ;
  icon?: React.ReactNode;
  type?: "primary" | "ghost" | "dashed" | "link" | "text" | "default" ;
  onClick?: () => void
  tooltip?: string;
  shape?: "default" | "circle" | "round" ;
  bgColour?: string;
  textColour?: string;
  className?: string
  htmlType?: "button" | "submit" | "reset" | undefined
  // onSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
}

export const CustomButton: React.FC<Props> = ({
  loading,
  children,
  disabled,
  extraStyles,
  size,
  icon, 
  type,
  onClick,
  tooltip,
  shape,
  bgColour ,
  textColour,
  className,
  htmlType,
}) => {
  return (

        <Button 
          shape={shape}
          icon={icon} 
          style={{...extraStyles, backgroundColor: bgColour, color: textColour}}
          className={className ? className : disabled ? styles.btnDisabled : styles.btn}
          disabled={disabled}
          size={size}
          type={type}
          onClick={onClick}
          loading={loading}
          htmlType={htmlType}
          >{children}</Button>
  );
};