export const TrashIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.80168 5.69727L8.59976 10.9473M5.80649 10.9473L5.60457 5.69727M11.4193 3.8251C11.6187 3.85522 11.8175 3.88746 12.0156 3.92177M11.4193 3.8251L10.7963 11.9229C10.7437 12.6067 10.1735 13.1348 9.48771 13.1348H4.91854C4.23271 13.1348 3.66251 12.6067 3.60991 11.9229L2.987 3.8251M11.4193 3.8251C10.7505 3.7241 10.074 3.64676 9.39062 3.59395M2.39062 3.92177C2.58871 3.88746 2.78751 3.85522 2.987 3.8251M2.987 3.8251C3.65574 3.7241 4.33225 3.64676 5.01562 3.59395M9.39062 3.59395V3.05954C9.39062 2.37158 8.85941 1.79723 8.1718 1.77524C7.85017 1.76495 7.52725 1.75977 7.20312 1.75977C6.879 1.75977 6.55608 1.76495 6.23445 1.77524C5.54684 1.79723 5.01562 2.37158 5.01562 3.05954V3.59395M9.39062 3.59395C8.66878 3.53817 7.93927 3.50977 7.20312 3.50977C6.46698 3.50977 5.73747 3.53817 5.01562 3.59395"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
