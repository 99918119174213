import React, { useState, useEffect, ReactElement } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile, UploadFileStatus } from "antd/es/upload/interface";
import { IWorkSpaceForm } from "../../interfaces/forms";
import { FormikErrors, FormikHelpers, FormikProps } from "formik";
import styles from "./index.module.scss";

const getBase64 = (file: RcFile): Promise<string> =>
   new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
   });

interface IProps {
   formik?: FormikHelpers<IWorkSpaceForm>;
   error?: string | string[] | FormikErrors<UploadFile<any>>[] | undefined;
   thumbnails: UploadFile[];
}

export interface IFile {
   uid: string;
   name: string;
   status?: UploadFileStatus | undefined;
   url?: string;
   thumbUrl?: string;
}

const ImageUploader = ({ formik, error, thumbnails }: IProps) => {
   const [previewOpen, setPreviewOpen] = useState(false);
   const [previewImage, setPreviewImage] = useState("");
   const [previewTitle, setPreviewTitle] = useState("");

   const handleCancel = () => setPreviewOpen(false);

   const handlePreview = async (file: UploadFile) => {
      if (!file.url && !file.preview) {
         file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
   };

   const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
      formik?.setFieldValue("fileList", newFileList);
   };

   useEffect(() => {
      const getThumbNails = async () => {
         if (thumbnails.length > 0) {
            const files = thumbnails.map(async (file) => {
               if (!file.thumbUrl) {
                  file.thumbUrl = await getBase64(file.originFileObj as RcFile);
               }
               return file.thumbUrl;
            });
            const data = await Promise.allSettled(files);
            const imageUrls = data.map((item) => {
               if (item.status === "fulfilled") {
                  return item.value;
               }
            });
            formik?.setFieldValue("spaceThumbnail", imageUrls);
         }
      };
      getThumbNails();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [thumbnails]);

   const uploadButton = (
      <div>
         <PlusOutlined />
         <div style={{ marginTop: 8 }}>Upload</div>
      </div>
   );
   return (
      <>
         <Upload
            listType='picture-card'
            fileList={thumbnails}
            onPreview={handlePreview}
            onChange={handleChange}
            multiple
            maxCount={4}
            beforeUpload={() => false}
         >
            {thumbnails.length >= 4 ? null : uploadButton}
         </Upload>
         {error && typeof error === "string" && <p className={styles.error}>{error}</p>}
         <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img alt='example' style={{ width: "100%" }} src={previewImage} />
         </Modal>
      </>
   );
};

export default ImageUploader;
