import React, { ReactNode, useState } from "react";
import styles from "./index.module.scss";
import { TabTitle } from "@/utils/titleFunction";
import { BlueSettingsIcon, DefaultSettingsIcon } from "@/components/SVGs";
import dayjs from "dayjs";
import { TickCircleIcon } from "@/components/icons/tick-circle";
import { cn } from "@/lib/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useMyWorkspaceQuery } from "@/data/workspace";
import { formatAddress } from "@/lib/format-address";
import { UserOutlined } from "@ant-design/icons";

interface Stage {
  title: string;
  description: string;
  display?: ReactNode;
  dropdown?: {
    label: string;
    value: string;
  }[];
  action: number;
  valid: boolean;
}

const PreviewDetailsPage = () => {
  TabTitle("co-spire | Preview Space Details");
  const [activeState, setActiveState] = useState<{
    [key: string]: boolean;
  }>({
    "Stage 1": false,
    "Stage 2": false,
    "Stage 3": false,
    "Stage 4": false,
    "Stage 5": false,
    "Stage 6": false,
    "Stage 7": false,
    "Stage 8": false,
  });

  const params = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { workspace, isLoading: loading } = useMyWorkspaceQuery(params.id!, {
    enabled: !!params.id,
  });

  console.log({ workspace });

  const handleStateUpdate = (title: string) => {
    setActiveState((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const handlePageNavigation = (action: number) => {
    navigate(`/my-listing/${params.id}?step=${action}`);
  };

  const stages: Stage[] = [
    {
      title: "Stage 1",
      description: "Space location",
      dropdown: [
        {
          label: "How would you describe your space?",
          value: workspace?.about ?? "",
        },
        {
          label: "What type activities that you’d like to host in the space?",
          value: workspace?.activity?.join(", ") || "",
        },
        {
          label: "Location",
          value:
            formatAddress({
              country: workspace?.address.country || "",
              city: workspace?.address.city || "",
              state: workspace?.address.state || "",
              street_address: workspace?.address.street_address || "",
              zip: workspace?.address.zip_code || "",
            }) ?? "",
        },
      ],
      action: 1,
      valid:
        !!workspace?.about &&
        workspace?.activity?.length > 0 &&
        !!workspace?.formatted_address,
    },
    {
      title: "Stage 2",
      description: "Amenities, Short description, and more",
      dropdown: [
        {
          label: "Do you offer overnight stay at this place?",
          value: workspace?.other_policy?.is_overnight_allowed ? "Yes" : "No",
        },
        {
          label: "Are there parking spaces?",
          value: workspace?.parking_options.is_park_space_available
            ? "Yes" +
              " - " +
              `${workspace.parking_options.is_park_space_available}(${
                workspace.parking_options.is_park_space_free ? "Free" : "Paid"
              })`
            : "No",
        },
        {
          label: "Available parking lot & parking structure nearby?",
          value: workspace?.parking_options.nearby_parking_lot.length
            ? "Yes"
            : "No",
        },
        {
          label: "Are there security cameras?",
          value: workspace?.security_cameras ? "Yes" : "No",
        },
        {
          label: "Are there restrooms available?",
          value: workspace?.restroom ? "Yes" : "No",
        },
        {
          label: "Are there 24/7 Service?",
          value: workspace?.round_the_clock_service ? "Yes" : "No",
        },
        {
          label: "What amenities does your location offer?",
          value:
            workspace?.service_amenities?.map((item) => item.name).join(", ") ||
            "",
        },
      ],
      action: 2,
      valid:
        !!workspace?.service_amenities?.length &&
        !!workspace.other_policy &&
        !!workspace.parking_options,
    },
    {
      title: "Stage 3",
      description: "Food policy, Alcohol policy and more",
      dropdown: [
        {
          label: "External Food Allowed?",
          value: workspace?.food_policy.is_external_food_allowed ? "Yes" : "No",
        },
        {
          label: "Alcohol policy",
          value: workspace?.other_policy.is_alcohol_allowed ? "Yes" : "No",
        },
        {
          label: "Pet policy",
          value: workspace?.other_policy.is_pet_allowed ? "Yes" : "No",
        },
        {
          label: "Smoking policy",
          value: workspace?.other_policy.is_smoking_allowed ? "Yes" : "No",
        },
        {
          label: "Electricity Usage",
          value: workspace?.electricity_usage ? "Yes" : "No",
        },
        {
          label: "Noise policy",
          value: workspace?.other_policy.is_music_allowed ? "Yes" : "No",
        },
        {
          label: "Adult Filming",
          value: workspace?.other_policy.is_adult_filming_allowed
            ? "Yes"
            : "No",
        },
      ],
      action: 3,
      valid:
        !!workspace?.food_policy &&
        !!workspace.other_policy &&
        !!workspace.electricity_usage,
    },
    {
      title: "Stage 4",
      description: "Space title, Space rules and more",
      dropdown: [
        {
          label: "Space Title",
          value: workspace?.title || "",
        },
        {
          label: "Guest size",
          value: String(workspace?.space_size) || "0",
        },
        {
          label: "Age Restriction",
          value: workspace?.other_policy?.age_restriction || "No",
        },
        {
          label: "Space Rules",
          value: workspace?.rules || "",
        },
      ],
      action: 4,
      valid:
        !!workspace?.title &&
        !!workspace?.space_size &&
        !!workspace?.other_policy?.age_restriction &&
        !!workspace?.rules,
    },
    {
      title: "Stage 5",
      description: "Pricing",
      dropdown: [
        {
          label: "Hourly",
          value: String(workspace?.pricing.hourly) || "",
        },
        {
          label: "Daily",
          value: String(workspace?.pricing.daily) || "",
        },
        {
          label: "Weekly",
          value: String(workspace?.pricing.weekly) || "",
        },
        {
          label: "Security Deposit",
          value: String(workspace?.percentage_of_security_deposit) || "",
        },
      ],
      action: 5,
      valid: !!workspace?.percentage_of_security_deposit && !!workspace.pricing,
    },
    {
      title: "Stage 6",
      description: "Photos, Operation hours..",
      display: (
        <div className="grid grid-cols-3">
          {workspace?.photos?.map((item, index: number) => (
            <img src={item} alt={`Uploaded ${index}`} />
          ))}
        </div>
      ),
      dropdown: [
        {
          label: "Operating Hours",
          value: Object.values(workspace?.working_hours ?? {})
            .map(
              (item: { open_time: string; close_time: string }, index) =>
                `${String(
                  Object.keys(workspace?.working_hours ?? {}).at(index)
                )} ${
                  item?.open_time !== "closed" && item?.close_time !== "closed"
                    ? dayjs(item.open_time, "HH:mm").format("h:mm A") +
                      "-" +
                      dayjs(item.close_time, "HH:mm").format("h:mm A")
                    : "Closed"
                }`
            )
            .join("\n"),
        },
      ],
      action: 7,
      valid: !!workspace?.photos?.length && !!workspace?.working_hours,
    },
    {
      title: "Stage 7",
      description: "Contact Details",
      action: 8,
      display: (
        <div className="grid gap-2">
          <p className="text-base font-medium text-darkBlue">Contact Details</p>
          <div className="flex items-center gap-2">
            {workspace?.host?.avatar ? (
              <img
                src={workspace?.host?.avatar}
                alt={workspace?.host?.name}
                className="rounded-full w-12 h-12"
              />
            ) : (
              <UserOutlined className="rounded-full flex items-center justify-center w-12 h-12 border" />
            )}

            <div className="grid">
              <p className="font-medium">{workspace?.host?.name}</p>
              <p>{workspace?.space_contacts?.space_email}</p>
            </div>
          </div>
        </div>
      ),
      valid: !!workspace?.host && !!workspace?.space_contacts,
    },
  ];

  const progress =
    (stages.filter((stage) => stage.valid).length / stages.length) * 100;

  const disabled = stages.some((stage) => !stage.valid);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={styles.page}>
        <section className={styles.container}>
          <div className={styles.progressContainer}>
            <h3 className={styles.title}>
              {disabled ? "Great progress!" : "Your Listing Is 100% Done!"}
            </h3>
            <div className={styles.progressBarWrapper}>
              <div className={styles.progressBarBackground}>
                <div
                  className={styles.progressBar}
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
            <p className={styles.description}>
              Well done. Now let’s publish your listing.
            </p>
          </div>
        </section>
        <section className="grid border border-alpha-blue rounded-2xl">
          <div className={styles.stages}>
            <div className={styles.mainHeaderRow}>
              <h2>Listing Checklist</h2>
              <button
                disabled={disabled}
                type="submit"
                className={cn(
                  styles.btn,
                  "!bg-primary disabled:!bg-opacity-40"
                )}
              >
                Preview
              </button>
            </div>
            <div className="grid gap-y-2 divide-y w-5/6">
              {stages.map((stage, index) => (
                <React.Fragment key={index}>
                  <div className="flex justify-between items-center py-2">
                    <div className="flex items-center justify-center gap-4">
                      <TickCircleIcon
                        stroke="#fff"
                        className={cn(
                          stage.valid
                            ? "text-white fill-primary"
                            : "text-[#A59797]"
                        )}
                      />

                      <div>
                        <p className="font-bold text-2xl">{stage.title}</p>
                        <p>{stage.description}</p>
                      </div>
                    </div>

                    <button
                      type="button"
                      onClick={() => handleStateUpdate(stage.title)}
                    >
                      {activeState[stage.title] ? (
                        <BlueSettingsIcon />
                      ) : (
                        <DefaultSettingsIcon />
                      )}
                    </button>
                  </div>

                  {activeState[stage.title] && (
                    <div className="grid p-4 border-t gap-4">
                      {stage.display && <div className="">{stage.display}</div>}

                      {stage?.dropdown?.map((item, index) => (
                        <div className="grid gap-2" key={index}>
                          <p className="text-base font-medium text-darkBlue">
                            {item.label}
                          </p>
                          <p className="whitespace-pre-wrap">{item.value}</p>
                        </div>
                      ))}

                      <div className="flex flex-start">
                        <button
                          type="button"
                          className={cn(styles.btn, "!bg-primary")}
                          onClick={() => handlePageNavigation(stage.action)}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PreviewDetailsPage;
