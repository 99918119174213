import { useEffect } from "react";
import styles from "./index.module.scss";
import { MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { HOME_URL, ADD_SPACE_DETAILS, HOME } from "@/routes/routes";
import { useMe } from "@/data/user";
import { useAuth } from "@/components/auth/use-auth";

const VerifyEmail = () => {
  const { me: loggedInUser } = useMe();
  const { currentUser: user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.emailVerified) {
      loggedInUser?.role_type === "Host"
        ? navigate(`${ADD_SPACE_DETAILS}/description`)
        : navigate(HOME);
    }
  }, [user?.emailVerified]);

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <MailOutlined className={styles.icon} />
        <h1>Verify your email</h1>
        <p>
          A verification email has been sent to your inbox.
          <br />
          Please click the link provided to verify your email address.
        </p>
      </div>
    </div>
  );
};

export default VerifyEmail;
