import React from "react";
import { Spin } from "antd";
import { SpinIndicator } from "antd/es/spin";
import styles from "./Spinner.module.scss"

interface ISpin {
   children?: React.ReactNode;
   size: "small" | "default" | "large" | undefined;
   tip?: React.ReactNode;
   className?: string;
   delay?: number;
   indicator?: SpinIndicator | undefined;
   spinning?: boolean;
}

const Spinner = ({ children, size, tip, className, delay, indicator, spinning }: ISpin) => {
   return (
    <div className={styles.page}>
      <Spin size={size} tip={tip} className={className} delay={delay} indicator={indicator} spinning={spinning}>
         {children}
      </Spin>
      </div>
   );
};

export default Spinner;
