import { Radio } from "antd";
import { CardIcon } from "@/components/SVGs";
import { useState } from "react";
import { usePaymentMethodsQuery } from "@/data/subscription";

const SelectCard = ({ setMethod }: { setMethod: (id: string) => void }) => {
  const { methods, isLoading: loading } = usePaymentMethodsQuery();
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const handleChange = (card: string) => {
    setMethod(card);
    setSelectedCard(card);
  };

  return (
    <div className="grid gap-2">
      {methods?.map((card, index) => (
        <Radio.Group
          onChange={(e) => handleChange(e.target.value)}
          value={selectedCard}
          className="flex rounded border border-alpha-blue p-4 w-full lg:w-2/3"
        >
          <div
            className="flex justify-between w-full text:xs lg:text-sm"
            key={index}
          >
            <div className="flex gap-2 items-center">
              <CardIcon />
              <div className="grid">
                <p className="font-semibold capitalize">
                  {card.card_type} {card.channel}
                </p>
                <div className="flex gap-2 items-center justify-center">
                  <p className="">
                    {card.bin}****{card.last4}{" "}
                  </p>
                  <span> | </span>
                  <p className=""> {card.bank}</p>
                </div>
              </div>
            </div>

            <div className="grid place-item-center">
              <Radio value={card.id}></Radio>
            </div>
          </div>
        </Radio.Group>
      ))}
    </div>
  );
};

export { SelectCard };
