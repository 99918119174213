import { CustomButton } from "@/components/customButton";
import "./setupPage.scss";
import { AddSpaceVales } from "../forms/schema";

export const ConfirmPricing = ({
  values,
  saveContinue,
  saving,
}: {
  values: Partial<AddSpaceVales>;
  saveContinue: () => void;
  saving?: boolean;
}) => {
  const displayPricing = [
    {
      label: "Minimum Hour",
      value: values.minHoursOption,
    },
    {
      label: "Currency",
      value: values.currency,
    },
    {
      label: "Hourly Rate",
      value: values?.pricing?.hourly,
    },
    {
      label: "Daily Rate",
      value: values?.pricing?.daily,
    },
  ];
  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <div>
          <h2>Confirm your price setting</h2>
        </div>
        <CustomButton
          children={"Save and Exit"}
          type={"link"}
          className="save-continue-btn"
          onClick={saveContinue}
          disabled={saving}
        />
      </div>

      <div className="confirmation-box">
        <h3>Confirm your price settings</h3>
        <label>Days</label>
        <div className="day-container">
          {values?.daysOpen?.map((day) => (
            <div className="day" key={day}>
              {day}
            </div>
          ))}
        </div>

        <div className="pricing-container">
          {displayPricing.map((item) => (
            <div className="pricing-item" key={item.label}>
              <p>{item.label}</p>
              <p>{item.value}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="deposit-container">
        <h2>Security Deposit</h2>
        <p>{values.securityDepositPer}%</p>
      </div>
    </div>
  );
};
