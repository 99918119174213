import React from "react";
import styles from "./index.module.scss";
import { TabTitle } from "@/utils/titleFunction";
import AddWorkSpace from "./AddWorkSpace";
import HostRegisterForm from "./HostRegisterForm";
import { useMe } from "@/data/user";

const OldWorkSpace: React.FC = () => {
  TabTitle("Co-spire | WorkSpace");
  const { isLoggedIn } = useMe();

  return (
    <>
      <div className={styles.page}>
        {isLoggedIn ? <AddWorkSpace /> : <HostRegisterForm />}
      </div>
    </>
  );
};

export default OldWorkSpace;
