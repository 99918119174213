import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import "./setupPage.scss";
import { CustomButton } from "@/components/customButton";
import { OptionButton } from "@/components/optionButton";
import { Checkbox, Col, Row } from "antd";
import CustomTextArea from "@/components/customTextArea";
import { CustomInputField } from "@/components/customInput";

import { Radio, RadioGroup } from "@headlessui/react";

export const PageThree = ({
  saveContinue,
  setWifiService,
  wifiService,
  setStaffSupport,
  setAlwaysAvailable,
  staffSupport,
  values,
  handleChange,
  saving,
}: any) => {
  // Function to toggle the active selection
  const toggleSelection = (
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setter((prevState) => !prevState);
  };

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <CustomButton
          children={"Save and Exit"}
          type={"link"}
          className="save-continue-btn"
          onClick={saveContinue}
          disabled={saving}
        />
      </div>

      <div className="flex flex-col gap-y-6">
        <h3>Do you offer overnight stay at this place?</h3>
        <RadioGroup
          name="overnightStay"
          value={values.overnightStay}
          onChange={(value) => {
            handleChange({ target: { name: "overnightStay", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      <div className="flex flex-col gap-y-6">
        <h3>Are there parking spaces?</h3>
        <RadioGroup
          name="parkingSpaces"
          value={values.parkingSpaces}
          onChange={(value) => {
            handleChange({ target: { name: "parkingSpaces", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      {values.parkingSpaces && (
        <div className="flex flex-col gap-y-6">
          <h3>Is it free or paid?</h3>
          <RadioGroup
            name="parkingOption"
            value={values.parkingOption}
            onChange={(value) => {
              handleChange({ target: { name: "parkingOption", value } });
            }}
            className="flex justify-start px-4 gap-6"
          >
            <Radio
              value={"free"}
              className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
            >
              <span>Parking spaces are free</span>
            </Radio>
            <Radio
              value={"paid"}
              className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
            >
              <span>Parking spaces are paid</span>
            </Radio>
          </RadioGroup>
        </div>
      )}

      {values.parkingSpaces && (
        <CustomInputField
          secLabel="How many parking slots available?"
          type={"number"}
          placeholder="Eg: 100"
          name="parkingSlots"
          value={values.parkingSlots}
          onChange={handleChange}
        />
      )}

      <div className="flex flex-col gap-y-6">
        <h3>Available parking lot & parking structure nearby?</h3>
        <RadioGroup
          name="nearbyParkingSpaces"
          value={values.nearbyParkingSpaces}
          onChange={(value) => {
            handleChange({ target: { name: "nearbyParkingSpaces", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      <div className="flex flex-col gap-y-6">
        <h3>Are there security cameras?</h3>
        <RadioGroup
          name="securityCameras"
          value={values.securityCameras}
          onChange={(value) => {
            handleChange({ target: { name: "securityCameras", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      <div className="flex flex-col gap-y-6">
        <h3>Are there restrooms available?</h3>
        <RadioGroup
          name="restRooms"
          value={values.restRooms}
          onChange={(value) => {
            handleChange({ target: { name: "restRooms", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      <div className="flex flex-col gap-y-6">
        <h3>Are there 24/7 service?</h3>
        <RadioGroup
          name="twentyFourSeven"
          value={values.twentyFourSeven}
          onChange={(value) => {
            handleChange({ target: { name: "twentyFourSeven", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      {/* <OptionButton
        title="Do you have Wifi available?"
        // description="Tell us about"
        optionOne="Free Wifi"
        optionTwo="No Wifi"
        optionThree="Wifi on demand"
      onClick={() => toggleSelection(setWifiService)}
      optionType={wifiService}
      /> */}

      {/* <OptionButton
        title="Are there Staff Support?"
        // description="Tell us about"
        onClick={() => toggleSelection(setStaffSupport)}
        optionType={staffSupport}
      /> */}

      {/*<div>
        <div className="amenities">
        <h3>Access available</h3>
          <Checkbox.Group
            style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
            // onChange={onChange}
            // value={formik.values.spaceAmenity}
            defaultValue={["24/7 Customer Support"]}
          >
            <Row gutter={[8, 8]} wrap>
              <Col md={24} xs={24} >
                <Checkbox value="elevator">
                  Elevator
                </Checkbox>
              </Col>
              <Col md={24} xs={24} >
                <Checkbox value="freight-elevator">
                  Freight Elevator
                </Checkbox>
              </Col>
              <Col md={24} xs={24} >
                <Checkbox value="stairs">
                  Stairs
                </Checkbox>
              </Col>

            </Row>
          </Checkbox.Group>
        </div>
      </div> */}

      {/* <div>
        <div className="amenities">
          <h3>What amenities does your location offer</h3>
          <Checkbox.Group
            style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
          // onChange={onChange}
          // value={formik.values.spaceAmenity}
          // defaultValue={["24/7 Customer Support"]}
          >
            <Row gutter={[8, 8]} wrap>
              <Col md={24} xs={24} >
                <Checkbox value="elevator">
                  Air condition
                </Checkbox>
              </Col>
              <Col md={24} xs={24} >
                <Checkbox value="freight-elevator">
                  Projectors
                </Checkbox>
              </Col>
              <Col md={24} xs={24} >
                <Checkbox value="stairs">
                  Hair/Makeup area
                </Checkbox>
              </Col>

            </Row>
          </Checkbox.Group>
        </div>
      </div> */}
      {/* <button className="skip-button">Skip</button> */}
    </div>
  );
};
