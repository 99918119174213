import React, { CSSProperties, ReactNode, useState } from "react";
import { Modal } from "antd";
import styles from "./CustomModal.module.scss";
import { CloseCircleOutlined } from "@ant-design/icons";
import './modalOverRide.css'

export interface IModal {
   openModal?: boolean;
   title?: ReactNode;
   closeModal?: () => void;
   children?: ReactNode;
   centered?: boolean;
   extraStyles?: CSSProperties;
   loading?: boolean;
   onOk?: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
   width?: string | number | undefined;
   footer?: ReactNode;
   afterClose?: () => void;
   mask?: boolean,
   maskStyle?: CSSProperties;
   zIndex?: number;
   childrenStyles?: string | undefined,
   bodyStyle?: CSSProperties,
   wrapClassName?: string
   className?: string
 
}

const CustomModal = ({ bodyStyle, className, mask, childrenStyles, zIndex, maskStyle, afterClose, openModal, closeModal, title, children, centered, extraStyles, onOk, loading, width, footer, wrapClassName }: IModal) => {
   return (
      <Modal
         className={className}
         title={title}
         centered={centered}
         open={openModal}
         onCancel={closeModal}
         footer={footer}
         closeIcon={<CloseCircleOutlined />}
         style={extraStyles}
         onOk={onOk}
         confirmLoading={loading}
         width={width}
         afterClose={afterClose}
         mask={mask}
         maskStyle={maskStyle}
         zIndex={zIndex}
         bodyStyle={bodyStyle}
         wrapClassName={wrapClassName}
      >
         <div className={childrenStyles ? childrenStyles : styles.contents}>{children}</div>
      </Modal>
   );
};

export default CustomModal;
