import React, { useEffect, useState } from "react";
import styles from "./confirmationPage.module.scss";

import {
  chair,
  laptop,
  office_icon,
  swimming,
  community,
  brunch,
  time_blue,
} from "@/assets/img";
import { Col, Row, Divider, Checkbox, Space } from "antd";
import { CustomButton } from "../../../../../components/customButton";
import wifi from "../../../../../assets/img/wifi.png";
import useCheckIsMobileScreen from "../../../../../hooks/useCheckIsMobileScreen";
import { ISuccessModal } from "../../../../../components/a/Modals/Success";
import { IWorkSpaceForm } from "../../../../../interfaces/forms";
import { FormikProps } from "formik";
import { TabsProp } from "../../useHostForm";
import { ADD_SPACE_DETAILS } from "../../../../../routes/routes";
import { useNavigate } from "react-router-dom";
import Splider from "../../../../../components/splide/splide";
import { useMe } from "@/data/user";

interface IProps {
  formik: FormikProps<IWorkSpaceForm>;
  isSubmitting: boolean;
}

const ConfirmationPage = ({ formik, isSubmitting }: IProps) => {
  const isMobile = useCheckIsMobileScreen();
  const navigate = useNavigate();
  const [operationDays, setOperationDays] = useState<{ [key: string]: string }>(
    {}
  );
  const { me: loggedInUser } = useMe();
  const disabled = Object.keys(formik.errors).length > 0;
  console.log("values", formik.values);
  useEffect(() => {
    const { values } = formik;
    const selectedDays = {
      monday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      tuesday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      wednesday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      thursday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      friday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      saturday: `${values.saturday_from} - ${values.saturday_to}`,
      sunday: `${values.sunday_from} - ${values.sunday_to}`,
    };

    const allDays = {
      monday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      tuesday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      wednesday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      thursday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      friday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      saturday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      sunday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
    };

    const operationHours = values.selectDays ? selectedDays : allDays;
    setOperationDays(operationHours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.selectDays]);

  return (
    <div className={styles.page}>
      {isMobile ? (
        <>
          <div className={styles.slider}>
            <Splider
              thumbnails={formik.values.spaceThumbnail}
              space={formik.values.spaceName}
              className={styles.img}
            />
          </div>
          <div className={styles.sections}>
            <Row>
              <Col className={styles.heading}>
                <span>YOUR ENQUIRY</span>
              </Col>
            </Row>
            <Row wrap>
              <Col span={24} className={styles.text}>
                <p className={styles.text}>{formik.values.spaceDescription}</p>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <section className={styles.gridImages}>
            {formik.values.spaceThumbnail.map((thumbnail, i) => {
              return (
                <div
                  className={styles[`items${i}`]}
                  style={{
                    background: `url(${thumbnail}) no-repeat center`,
                    backgroundSize: "cover",
                  }}
                ></div>
              );
            })}
          </section>
          <div className={styles.header}>
            <Row justify="space-between">
              <Col className={styles.heading}>
                <span>WORKSPACE DETAILS</span>
              </Col>
              <Col>
                <CustomButton
                  children={"Edit  Details"}
                  className={styles.btnWhite}
                  onClick={() => navigate(`${ADD_SPACE_DETAILS}/description`)}
                />
              </Col>
            </Row>
            <Row wrap>
              <Col span={24} className={styles.text}>
                <p className={styles.text}>{formik.values.spaceDescription}</p>
              </Col>
            </Row>
          </div>
        </>
      )}

      <div className={styles.sections}>
        <Row gutter={[24, 24]} wrap>
          <Col md={5} xs={12}>
            <div className={styles.textContainer}>
              <h1 className={styles.subHeading}>Workspace Name</h1>
              <p className={styles.text}>{formik.values.spaceName}</p>
            </div>
          </Col>
          <Col md={5} xs={12}>
            <div className={styles.textContainer}>
              <h1 className={styles.subHeading}>Email Address</h1>
              <p className={styles.text}>{formik.values.spaceEmailAddress}</p>
            </div>
          </Col>
          <Col md={5} xs={12}>
            <div className={styles.textContainer}>
              <h1 className={styles.subHeading}>Location</h1>
              <p className={styles.text}>{formik.values.spaceLocation}</p>
            </div>
          </Col>
          <Col md={5} xs={12}>
            <div className={styles.textContainer}>
              <h1 className={styles.subHeading}>Owner’s Full Name</h1>
              <p className={styles.text}>{loggedInUser?.displayName}</p>
            </div>
          </Col>
          <Col md={5} xs={12}>
            <div className={styles.textContainer}>
              <h1 className={styles.subHeading}>Website</h1>
              <p className={styles.text}>{formik.values.spaceWebsite}</p>
            </div>
          </Col>
        </Row>
        <Divider orientation="left"></Divider>
      </div>

      {/* socials */}
      <div className={styles.sections}>
        <Row wrap>
          <Col className={styles.heading}>
            <span>SOCIAL MEDIA INFORMATION</span>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col md={6} xs={12}>
            <div className={styles.textContainer}>
              <h1 className={styles.subHeading}>FACEBOOK</h1>
              <a
                href={formik.values.facebook}
                target="_blank"
                rel="noreferrer"
                className={styles.text}
              >
                {formik.values.facebook}
              </a>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className={styles.textContainer}>
              <h1 className={styles.subHeading}>TWITTER</h1>
              <a
                href={formik.values.twitter}
                target="_blank"
                rel="noreferrer"
                className={styles.text}
              >
                {formik.values.twitter}
              </a>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className={styles.textContainer}>
              <h1 className={styles.subHeading}>INSTAGRAM</h1>
              <a
                href={formik.values.instagram}
                target="_blank"
                rel="noreferrer"
                className={styles.text}
              >
                {formik.values.instagram}
              </a>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className={styles.textContainer}>
              <h1 className={styles.subHeading}>LINKEDIN</h1>
              <a
                href={formik.values.linkedin}
                target="_blank"
                rel="noreferrer"
                className={styles.text}
              >
                {formik.values.linkedin}
              </a>
            </div>
          </Col>
        </Row>
        <Divider orientation="left"></Divider>
      </div>

      {/* services */}
      <div className={styles.sections}>
        <Row>
          <Col className={styles.heading}>
            <span>SERVICES OFFERED</span>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          {formik.values.spaceType.length > 0 &&
            formik.values.spaceType.map((space) => {
              return (
                <Col md={6} xs={12} flex="auto">
                  <div className={styles.iconText}>
                    <img
                      className={styles.icon}
                      src={office_icon}
                      alt="laptop"
                    />
                    <p className={styles.text}>{space.space_type_name}</p>
                  </div>
                </Col>
              );
            })}
          {formik.values.spaceType.length === 0 && (
            <Col flex="auto" className={styles.iconText}>
              <p className={styles.text}>N/A</p>
            </Col>
          )}
        </Row>
        <Divider orientation="left"></Divider>
      </div>
      {/* Amenities */}
      <div className={styles.sections}>
        <Row>
          <Col className={styles.heading}>
            <span>AMENITIES</span>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          {formik.values.spaceAmenity.map((amenity, i) => {
            return (
              <Col flex="auto" className={styles.iconText} key={i}>
                <img className={styles.icon} src={time_blue} alt="icon" />
                <p className={styles.text}>{amenity}</p>
              </Col>
            );
          })}
        </Row>
        <Divider orientation="left"></Divider>
      </div>

      {/* OPENING HOURS */}
      <div className={styles.sections}>
        <Row>
          <Col className={styles.heading}>
            <span>OPENING HOURS</span>
          </Col>
        </Row>
        <div className={styles.time}>
          <Col className={styles.tag}>
            <span className={styles.span}>Monday</span>
            <span className={styles.time}>{operationDays.monday}</span>
          </Col>
          <Col className={styles.tag}>
            <span className={styles.span}>Tuesday</span>
            <span className={styles.time}>{operationDays.tuesday}</span>
          </Col>
          <Col className={styles.tag}>
            <span className={styles.span}>Wednesday</span>
            <span className={styles.time}>{operationDays.wednesday}</span>
          </Col>
          <Col className={styles.tag}>
            <span className={styles.span}>Thursday</span>
            <span className={styles.time}>{operationDays.thursday}</span>
          </Col>
          <Col className={styles.tag}>
            <span className={styles.span}>Friday</span>
            <span className={styles.time}>{operationDays.friday}</span>
          </Col>
          <Col className={styles.tag}>
            <span className={styles.span}>Saturday</span>
            <span className={styles.time}>{operationDays.saturday}</span>
          </Col>
          <Col className={styles.tag}>
            <span className={styles.span}>Sunday</span>
            <span className={styles.time}>{operationDays.sunday}</span>
          </Col>
        </div>
        <Divider orientation="left"></Divider>
      </div>

      {/* HOLIDAYS */}
      <div className={styles.sections}>
        <Row gutter={24}>
          <Col className={styles.heading}>
            <span>HOLIDAYS</span>
          </Col>
        </Row>
        <div className={styles.time}>
          {formik.values.holidays.map((holiday, i) => {
            return (
              <Col className={styles.tag} key={i}>
                <span className={styles.span}>{holiday.holidayName}</span>
                <span className={styles.time}>{holiday.holidayDate}</span>
              </Col>
            );
          })}
        </div>
        <Divider orientation="left"></Divider>
      </div>

      {/* Button */}
      <Row className={isMobile ? styles.sections : ""}>
        <Col>
          <CustomButton
            children={"Submit"}
            onClick={formik.handleSubmit}
            loading={formik.isSubmitting || isSubmitting}
            disabled={disabled}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmationPage;
