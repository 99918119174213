import React, { HTMLInputTypeAttribute } from "react";
import styles from "./index.module.scss";
import { Input, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";

type BaseInputProps = {
  id?: string;
  type: HTMLInputTypeAttribute;
  name?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autoComplete?: string;
  error?: string | undefined | number | boolean;
  disabled?: boolean;
  backgroundColor?: string;
  action?: React.ReactNode;
  label?: string;
  secLabel?: string;
  extraStyles?: React.CSSProperties;
  onKeyUp?: any;
  min?: number;
  alt?: string;
  maxLength?: number;
  icon?: any;
  pattern?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  className?: string;
  inputMode?: "email" | "search" | "tel" | "text" | "url" | "none" | "numeric" | "decimal" | undefined;
  loading?: boolean;
  format?: boolean;
  formatter?: (value: string) => string;
};

export const CustomInputField: React.FC<BaseInputProps> = ({
  id,
  type,
  name,
  placeholder,
  value,
  onChange,
  autoComplete,
  error,
  icon,
  disabled,
  min = 0,
  action,
  label,
  extraStyles,
  secLabel,
  onKeyUp,
  maxLength,
  alt,
  onBlur,
  prefix,
  suffix,
  className,
  pattern,
  inputMode,
  loading,
  format = false,
  formatter,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (format && formatter) {
      e.target.value = formatter(inputValue);
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      {secLabel && <p className={styles.secondaryLabel}>{secLabel}</p>}

      <div className={styles.inputContainer}>
        {icon && <img src={icon} alt={alt} className={styles.img} />}
        <Input
          allowClear
          id={id}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          min={min}
          maxLength={maxLength}
          onChange={handleChange}
          autoComplete={autoComplete}
          disabled={disabled}
          style={extraStyles}
          onKeyUp={onKeyUp}
          onWheel={(e: any) => e.target.blur()}
          className={className ? className : styles.input}
          onBlur={onBlur}
          prefix={prefix}
          suffix={suffix}
          pattern={pattern}
          inputMode={inputMode}
          
        />
        {action && <div className={styles.action}>{action}</div>}
        {loading && (
          <Spin
            size={"small"}
            indicator={<LoadingOutlined style={{ fontSize: 12 }} spin className={styles.loading} />}
          />
        )}
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
