import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../redux/hook";
import NotLoggedInHeader from "./NotLoggedInHeader/NotLoggedInHeader";
import LoggedInHeader from "./LoggedInHeader/LoggedInHeader";
import { useAuth } from "@/components/auth/use-auth";

const Header: React.FC = () => {
  const { userLoggedIn } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [mobileMenu, showMobileMenu] = React.useState<boolean>(false);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (navRef.current && !navRef.current.contains(event.target)) {
        showMobileMenu(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const resize = window.innerWidth > 768;
    if (resize) {
      setIsMobile(false);
    } else setIsMobile(true);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        setIsMobile(false);
      } else setIsMobile(true);
    });
  }, []);

  const showMenu = () => {
    showMobileMenu(!mobileMenu);
  };

  const navRef: any = React.useRef();

  function handleClick(e: any) {
    if (navRef && navRef.current) {
      const ref: any = navRef.current;
      if (!ref.contains(e.target)) {
        // showMobileMenu(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  const openModal = (type: string) => {
    setIsOpen(true);
    setModalType(type);
    showMobileMenu(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {userLoggedIn ? (
        <LoggedInHeader />
      ) : (
        <NotLoggedInHeader openModal={openModal} />
      )}
    </>
  );
};

export default Header;
