import React, { useState } from "react";
import styles from "./index.module.scss";
import { Divider } from "antd";
import { TabTitle } from "@/utils/titleFunction";
import CustomTag from "@/components/customTag";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import settingsDone from "@/assets/svgs/setting-4.svg";
import arrowRight from "@/assets/svgs/arrow-right.svg";

const PreviewDetails = () => {
  TabTitle("co-spire | Preview Space Details");
  const [currentStage, setCurrentStage] = useState(1);
  const progress = (currentStage / 7) * 100;
  const stages = [
    { title: "Stage 1", description: "Space type, location" },
    { title: "Stage 2", description: "Amenities, Short description, and more" },
    { title: "Stage 3", description: "Food policy, Alcohol policy and more" },
    { title: "Stage 4", description: "Space title, Space rules and more" },
    { title: "Stage 5", description: "Photos, Operation hours.." },
    { title: "Stage 6", description: "Pricing" },
    { title: "Stage 7", description: "Contact Details" },
  ];

  const disabled = currentStage >= 7;

  return (
    <>
      <div className={styles.page}>
        <section className={styles.container}>
          <div className={styles.progressContainer}>
            <h3 className={styles.title}>
              {!disabled ? "Great progress!" : "Your Listing Is 100% Done!"}
            </h3>
            <div className={styles.progressBarWrapper}>
              <div className={styles.progressBarBackground}>
                <div
                  className={styles.progressBar}
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
            <p className={styles.description}>
              Your listing is {Math.ceil(progress)}% done.
              <br />
              Now let's get more details about your space so you can publish
              your listing.
            </p>
          </div>
        </section>
        <section className={styles.stages}>
          <div className={styles.mainHeaderRow}>
            <h2>Listing Checklist</h2>
            {disabled && (
              <button type="submit" className={styles.btn}>
                Preview
              </button>
            )}
          </div>
          <div className={styles.stagesTable}>
            {stages.map((stage, index) => (
              <React.Fragment key={index}>
                <div className={styles.stageItem}>
                  <div className={styles.headerRow}>
                    {index + 1 <= currentStage ? (
                      <CheckCircleFilled />
                    ) : (
                      <CheckCircleOutlined />
                    )}
                    <div>
                      <h2>{stage.title}</h2>
                      <p>{stage.description}</p>
                    </div>
                  </div>
                  <div className={styles.mobileIcons}>
                    {index + 1 <= currentStage ? (
                      <img src={settingsDone} alt="" />
                    ) : (
                      <img src={arrowRight} className={styles.arrow} alt="" />
                    )}
                  </div>
                  <div className={styles.desktopIcons}>
                    {index + 1 <= currentStage ? (
                      <img src={settingsDone} alt="" />
                    ) : (
                      <CustomTag
                        color={{ backgroundColor: "#DDEBFF", color: "#0047AB" }}
                        text={"Continue"}
                      />
                    )}
                  </div>
                </div>
                {index < stages.length - 1 && <Divider orientation="left" />}
              </React.Fragment>
            ))}
          </div>
        </section>
        <section className={styles.buttonBox}>
          <button
            type="submit"
            className={styles.btn}
            disabled={disabled}
            style={{ backgroundColor: !disabled ? "#8cb4db" : "" }}
          >
            Publish
          </button>
        </section>
      </div>
    </>
  );
};

export default PreviewDetails;
