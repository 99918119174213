import React, { useRef, useState } from "react";
import styles from "./index.module.scss";
import { Dialog } from "@/components/b/Dialog";
import Close from "@/assets/svgs/Close.svg";
import { Form, Input, Select, Checkbox, Button } from "antd";
import PhoneInput from "@/components/phoneNumberInput/CustomPhoneNoInput";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
}

const Contact = ({ isOpen, closeModal }: Prop) => {
  const [phoneNumber, setPhoneNumber] = useState(""); // Local state for phone number

  return (
    <Dialog isOpen={isOpen} close={closeModal}>
      <div className={styles.main}>
        <div className={styles.top}>
          <h2>Contact Form</h2>
          <div className={styles.close} onClick={closeModal}>
            <img src={Close} />
          </div>
        </div>
        <p>
          Thank you for your interest in our space, please leave us a message,
          we are always available to respond.
        </p>

        <Form
          layout="vertical"
          size={"large"}
          autoComplete="off"
          className={styles.form}
        >
          <div className={styles.formItems}>
            <Form.Item
              name="firstName"
              className={styles.formItem}
              label={<span className={styles.label}>First Name</span>}
              rules={[
                {
                  required: true,
                  message: "Please input your first name",
                },
              ]}
            >
              <Input type="text" className={styles.input} placeholder="" />
            </Form.Item>
            <Form.Item
              name="lastName"
              className={styles.formItem}
              label={<span className={styles.label}>Last Name</span>}
              rules={[
                {
                  required: true,
                  message: "Please input your last name",
                },
              ]}
            >
              <Input type="text" className={styles.input} placeholder="" />
            </Form.Item>
          </div>

          <div className={styles.formItems}>
            <Form.Item
              name="email"
              className={styles.formItem}
              label={<span className={styles.label}>Email Address</span>}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                type="email"
                className={styles.input}
                placeholder="Enter your Email Address"
              />
            </Form.Item>
            <Form.Item
              name="number"
              className={styles.formItem}
              label={<span className={styles.label}>Phone Number</span>}
              rules={[
                { required: true, message: "Please input your Phone Number" },
              ]}
            >
              <PhoneInput
                value={phoneNumber} // Use local state value
                onChange={(value) => setPhoneNumber(value)} // Update local state
                className={styles.input}
                placeholder="Enter Phone Number"
              />
            </Form.Item>
          </div>

          <div className={styles.formTextItems}>
            <Form.Item
              name="message"
              className={styles.formItem}
              label={<span className={styles.label}>Your Message</span>}
              //   rules={[
              //     {
              //       required: true,
              //       message: "Please input your country",
              //     },
              //   ]}
            >
              <Input.TextArea className={styles.text} placeholder="" />
            </Form.Item>
          </div>

          <div className={styles.policy}>
            <Form.Item name="policy" valuePropName="checked" noStyle>
              <Checkbox className={styles.policyText}>
                I confirm that I have read and agree to Co-Spire’s Privacy
                Policy and consent to sharing my information.
              </Checkbox>
            </Form.Item>
          </div>

          <Button type="primary" htmlType="submit" className={styles.button}>
            Submit Message
          </Button>
        </Form>
      </div>
    </Dialog>
  );
};

export default Contact;
