import * as Yup from "yup";

const MIN_AMOUNT = process.env.REACT_APP_WALLET_MIN_AMOUNT;

export const REGISTER_HOST_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required("Please enter your first name").trim(),
  lastName: Yup.string().required("Please enter your last name").trim(),
  email: Yup.string().email().required("Enter valid email-id").trim(),
  jobRole: Yup.string().required("Required"),
  phone_number: Yup.string().required("Phone number is required").min(10),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/,
      "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character, no spaces"
    )
    .required("Please enter valid password"),
  confirmPassword: Yup.string()
    .required("Required")
    .test("password-match", "Password must match", function (value) {
      return this.parent.password === value;
    }),
});

export const BOOK_SCHEMA = (availableSpace: number) =>
  Yup.object().shape({
    firstName: Yup.string().required("Please enter your first name").trim(),
    lastName: Yup.string().required("Please enter your last name").trim(),
    jobRole: Yup.string().required("Please enter your job title").trim(),
    email: Yup.string().email().required("Enter valid email-id").trim(),
    company: Yup.string(),
    about: Yup.string(),
    activity: Yup.string().required("Enter valid activity").trim(),
    projectName: Yup.string(),
    phone_number: Yup.string().required("Phone number is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/,
        "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character, no spaces"
      )
      .required("Please enter valid password"),
    confirmPassword: Yup.string()
      .required("Required")
      .test("password-match", "Password must match", function (value) {
        return this.parent.password === value;
      }),
    // card_number: Yup.string().required("Card number is required"),
    numberOfPeople: Yup.number()
      .required("How many people for space required")
      .max(availableSpace, `Number of people cannot exceed ${availableSpace}`),
    // cvv: Yup.string().matches(/^\d{3}$/, 'CVV must be 3 digits').required("cvv is required"),
    // expiring: Yup.string().required("Expiring date is required"),
    startTime: Yup.string().required("Enter valid start time"),
    endTime: Yup.string().required("Enter valid end time"),
    date: Yup.object().shape({
      startDate: Yup.string().required("Enter start date"),
      endDate: Yup.string().required("Enter end date"),
    }),
    dates: Yup.array()
      .of(Yup.string().required("Enter a valid date"))
      .required("Dates are required"),
  });

export const LOGIN_FORM_SCHHEMA = Yup.object().shape({
  email: Yup.string().email().required("Enter valid email-id"),
  password: Yup.string().required("Please enter your password"),
});

export const SPACE_FORM_SCHEMA = Yup.object().shape({
  spaceName: Yup.string().required("Please enter space name"),
  spaceLocation: Yup.string().required("Please select space location"),
  spaceDescription: Yup.string().required("Please add a description"),
  spaceThumbnail: Yup.array()
    .min(1, "please upload at least 1 image")
    .required(),
  spaceEmailAddress: Yup.string()
    .email("Invalid email")
    .required("Please enter your official space email address"),
  spacePhoneNumber: Yup.string().required(
    "Please enter your official space phone number"
  ),
  spaceWhatsappPhoneNumber: Yup.string(),
  address: Yup.string().required("Please enter an address"),
  spaceWebsite: Yup.string().url("enter a valid url"),
  facebook: Yup.string(),
  instagram: Yup.string(),
  twitter: Yup.string(),
  linkedin: Yup.string(),
  selectDays: Yup.boolean(),
  monday_sunday_from: Yup.string().when("selectDays", {
    is: (selectDays: boolean) => selectDays === false,
    then: Yup.string().required("Please select a time"),
    otherwise: Yup.string(),
  }),
  monday_sunday_to: Yup.string().when("selectDays", {
    is: (selectDays: boolean) => selectDays === false,
    then: Yup.string().required("Please select a time"),
    otherwise: Yup.string(),
  }),
  monday_friday_from: Yup.string().when("selectDays", {
    is: (selectDays: boolean) => selectDays === true,
    then: Yup.string().required("Please select a time"),
    otherwise: Yup.string(),
  }),
  monday_friday_to: Yup.string().when("selectDays", {
    is: (selectDays: boolean) => selectDays === true,
    then: Yup.string().required("Please select a time"),
    otherwise: Yup.string(),
  }),
  saturday_from: Yup.string().when("selectDays", {
    is: (selectDays: boolean) => selectDays === true,
    then: Yup.string(),
    otherwise: Yup.string(),
  }),
  saturday_to: Yup.string().when("selectDays", {
    is: (selectDays: boolean) => selectDays === true,
    then: Yup.string(),
    otherwise: Yup.string(),
  }),
  sunday_from: Yup.string().when("selectDays", {
    is: (selectDays: boolean) => selectDays === true,
    then: Yup.string(),
    otherwise: Yup.string(),
  }),
  sunday_to: Yup.string().when("selectDays", {
    is: (selectDays: boolean) => selectDays === true,
    then: Yup.string(),
    otherwise: Yup.string(),
  }),
  holidays: Yup.array()
    .min(1, "*Please add a holiday name and date*")
    .required(),
  spaceType: Yup.array().of(
    Yup.object().shape({
      price_per_month: Yup.number().required("Please enter price per month"),
      price_per_week: Yup.number().required("Please enter price per week"),
      price_per_day: Yup.number().required("Please enter price per day"),
      // price_per_hour: Yup.number(),
      space_type_available: Yup.number().required(
        "Please enter space type available"
      ),
      space_type_capacity: Yup.number().required(
        "Please enter space type capacity"
      ),
      space_type_name: Yup.string().required("Enter a name for this space"),
      space_type: Yup.string().required(
        "Please select a space type from the list"
      ),
      space_type_total: Yup.number().required("Please enter space type total"),
      // space_type_description: Yup.string(),
    })
  ),
  spaceAmenity: Yup.array().min(1, "*Please select an amenity*").required(),
});

export const BANK_ACCOUNT_SCHEMA = Yup.object().shape({
  account_number: Yup.string()
    .matches(/^\d{10}$/, "Account number must be 10 digits")
    .required("Account number is required"),
  bank_name: Yup.string().required("Select your Bank"),
  privacy_policy: Yup.boolean().required(
    "please accept privacy policy agrrement"
  ),
});
export const WITHDRAWAL_FUNDS_SCHEMA = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  account_id: Yup.string().required("Select your Bank"),
  privacy_policy: Yup.boolean().required(
    "please accept privacy policy agrrement"
  ),
  amount: Yup.number()
    .min(2000)
    .required("minimum amount to withdraw is 2000 naira"),
});
