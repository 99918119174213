import { useState } from "react";
import styles from "./index.module.scss";
import { Dialog } from "@/components/b/Dialog";
import Close from "@/assets/svgs/Close.svg";
import Location from "@/assets/svgs/map-pin-black";
import ChekBadge from "@/assets/svgs/check-badge.svg";
import Star from "@/assets/svgs/Vector.svg";
import chekbadge from "@/assets/svgs/check-badge.svg";
import { Button, Form, Input, Checkbox, Divider, Rate, Select } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { createReview } from "@/services/networkRequests/workspaceService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useMe } from "@/data/user";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  workspace: any | null;
  // fetchWorkspace: () => void;
  reviews: any | [];
}

// const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// 	console.log(`checked = ${e.target.checked}`);
// };

const desc = [
  "Good Service",
  "I can live with It",
  "Great Service",
  "Excellent Service",
  "Absolutely Fantastic",
];

const Review = ({ isOpen, closeModal, workspace, reviews }: Prop) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [rating, setRating] = useState(1);
  const { me: loggedInUser, isLoggedIn } = useMe();
  const params = useParams();
  const id = params?.id;
  console.log(id);

  const onChange = (value: number) => {
    setRating(value);
  };

  const handleReview = async (values: any) => {
    // const fullName = form.getFieldValue("fullName");
    const review = form.getFieldValue("review");
    const payload = {
      user_id: loggedInUser?.uid,
      space_review_content: review,
      workspace_id: id,
      review_rating: rating,
      reviewers_name: loggedInUser?.displayName,

      // fullName: fullName,
    };
    console.log(payload);
    try {
      await createReview(payload);
      console.log("Successfully Submitted");

      // Clear the form fields after successful submission
      form.resetFields();

      // Show success toast message
      toast.success("Review submitted successfully!");

      // Close the modal
      closeModal();
      // fetchWorkspace();
    } catch (error) {
      toast.error("An error occured, please try again");
    }

    console.log("values", form.getFieldsValue());
  };

  return (
    <Dialog isOpen={isOpen} close={closeModal}>
      <div className={styles.main}>
        <div className={styles.top}>
          <div>
            <div className={styles.location1}>
              <h2>Review {workspace?.title} Space</h2>
              {reviews.length > 0 && (
                <img className={styles.icon} src={chekbadge} />
              )}
            </div>
            <div className={styles.location}>
              <Location />
              <p>Lagos, Nigeria</p>
            </div>
          </div>
          <div className={styles.close} onClick={closeModal}>
            <img src={Close} />
          </div>
        </div>
        <Divider />
        <div className={styles.review}>
          <Form
            layout="vertical"
            size={"large"}
            autoComplete="off"
            form={form}
            onFinish={async () => {
              console.log(form.getFieldsValue());

              await handleReview(form.getFieldsValue());
              console.log("Done");
            }}
            className={styles.form}
          >
            <Form.Item
              name="rating"
              className={styles.formItem}
              label={
                <span className={styles.label}>
                  What is your overall rating of the workspace?
                </span>
              }
              rules={[
                {
                  required: false,
                  message: "Select your rating",
                },
              ]}
            >
              <Rate
                tooltips={desc}
                onChange={onChange}
                value={rating}
                style={{ fontSize: "30px" }}
                // character={<img src={Star} alt='Star' />}
              />
              {rating ? (
                <span className="ant-rate-text">{desc[rating - 1]}</span>
              ) : (
                ""
              )}
            </Form.Item>

            <Form.Item
              name="fullName"
              className={styles.formItem}
              label={<span className={styles.label}>Fullname</span>}
              rules={[
                { required: false, message: "Please input your full name!" },
              ]}
            >
              <Input
                type="text"
                className={styles.input}
                placeholder="Enter your Fullname"
              />
            </Form.Item>

            <Form.Item
              name="review"
              className={styles.formItem}
              label={
                <span className={styles.label}>
                  Describe your experience at this Space
                </span>
              }
              rules={[
                {
                  required: false,
                  message: "Type in your Review about the workspace",
                },
              ]}
            >
              <TextArea className={styles.input} rows={4} />
            </Form.Item>

            <Form.Item className={styles.formItem} name="checkbox">
              <Checkbox className={styles.checkbox}>
                I confirm that I have read and agree to Co-Spire’s Privacy
                Policy and consent to sharing my information.
              </Checkbox>
            </Form.Item>
            <Button type="primary" htmlType="submit" className={styles.button}>
              Post a Review
            </Button>
          </Form>
        </div>
      </div>
    </Dialog>
  );
};

export default Review;
