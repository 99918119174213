import React from "react";
import styles from "./tableNav.module.scss";
import CustomSelect from "../../customSelect";
import { Search } from "../../Search/Search";
import type { SelectProps } from "antd";

interface Option {
  [key: string]: string;
}

interface ITableNav {
  tabLists: string[];
  onTabChange: (tab: string) => void;
  currentTab: string;
  options: SelectProps["options"];
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectPlaceHolder: string;
  searchPlaceHolder: string;
  onSelectPaymentStatus: (status: string) => void;
}

const TableNav = ({
  tabLists,
  onTabChange,
  currentTab,
  options,
  onSearch,
  onSelectPaymentStatus,
  selectPlaceHolder,
  searchPlaceHolder,
}: ITableNav) => {
  return (
    <>
      <div className={styles.page}>
        <div className={styles.tablists}>
          {tabLists.map((tab) => {
            const isActive = currentTab === tab;
            return (
              <p
                onClick={() => onTabChange(tab)}
                className={`${styles.tab} ${isActive && styles.activeTab}`}
                key={tab}
              >
                {tab}
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TableNav;
