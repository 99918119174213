import { toast } from "react-toastify";

export const handleError = (error: any) => {
   if (error.response) {
      // Request made and server responded
      const { data, status } = error.response;
      toast.error(data.message);
      return { data, status };
   } else if (error.request) {
      // The request was made but no response was received
      toast.error("Please check your internet connection");
      return error.request;
   } else if (error.message) {
      toast.error(error.message);
      return error.message;
   } else {
      // Something happened in setting up the request that triggered an Error
      toast.error("Something went wrong");
      return error;
   }
};
