export const FacebookIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 16.2794C22 19.9194 19.83 22.0894 16.19 22.0894H15C14.45 22.0894 14 21.6394 14 21.0894V15.3194C14 15.0494 14.22 14.8194 14.49 14.8194L16.25 14.7894C16.39 14.7794 16.51 14.6794 16.54 14.5394L16.89 12.6294C16.92 12.4494 16.78 12.2794 16.59 12.2794L14.46 12.3094C14.18 12.3094 13.96 12.0894 13.95 11.8194L13.91 9.36935C13.91 9.20935 14.04 9.06937 14.21 9.06937L16.61 9.02936C16.78 9.02936 16.91 8.89937 16.91 8.72937L16.87 6.32935C16.87 6.15935 16.74 6.02936 16.57 6.02936L13.87 6.06937C12.21 6.09937 10.89 7.45935 10.92 9.11935L10.97 11.8694C10.98 12.1494 10.76 12.3694 10.48 12.3794L9.28 12.3994C9.11 12.3994 8.98001 12.5293 8.98001 12.6993L9.01001 14.5994C9.01001 14.7694 9.14 14.8994 9.31 14.8994L10.51 14.8794C10.79 14.8794 11.01 15.0994 11.02 15.3694L11.11 21.0694C11.12 21.6294 10.67 22.0894 10.11 22.0894H7.81C4.17 22.0894 2 19.9193 2 16.2693V7.89935C2 4.25935 4.17 2.08936 7.81 2.08936H16.19C19.83 2.08936 22 4.25935 22 7.89935V16.2794Z"
        fill="currentColor"
      />
    </svg>
  );
};
