export const useLocale = (): { language: string } => {
  const locale = navigator.language;
  let lang;
  if (locale === "en") {
    lang = "en";
  } else {
    if (locale === "fr") {
      lang = "fr";
    } else {
      lang = "en";
    }
  }
  return { language: lang };
};
