import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { Dialog } from "@/components/b/Dialog";
import Close from "@/assets/svgs/Close.svg";
import Star from "@/assets/svgs/Vector.svg";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  reviews: any;
}

const Review = ({ isOpen, closeModal, reviews }: Prop) => {
  const [search, setSearch] = useState("");
  const [filterReview, setFilterReview] = useState([]);

  useEffect(() => {
    setFilterReview(reviews);
  }, [reviews]);

  const handleSearch = (text: string) => {
    if (text) {
      setSearch(text);
      const newData = reviews?.filter((item: any) => {
        const itemData = item.review
          ? item.review.toUpperCase()
          : "".toUpperCase();
        const input = text.toUpperCase();
        return itemData.indexOf(input) > -1;
      });
      setFilterReview(newData);
    } else {
      setSearch(text);
      setFilterReview(reviews);
    }
  };
  return (
    <Dialog isOpen={isOpen} close={closeModal}>
      <div className={styles.main}>
        <div className={styles.top}>
          <h2>Reviews</h2>
          <div className={styles.close} onClick={closeModal}>
            <img src={Close} />
          </div>
        </div>

        <input
          type="search"
          className={styles.input}
          placeholder="Search  Reviews"
          onChange={(e) => handleSearch(e.target.value)}
          value={search}
        />

        <div className={styles.review}>
          {filterReview?.map((item: any, index: number) => (
            <div key={index} className={styles.view}>
              <div className={styles.left}>
                {/* <img src={item.img} /> */}
                <Avatar
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserOutlined />}
                  size="large"
                />
              </div>
              <div className={styles.right}>
                <p>{item.review}</p>
                <div>
                  <h3>{item.user_name}</h3> <span></span> <img src={Star} />
                  5.0{" "}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default Review;
