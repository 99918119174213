import { createTransactionOld } from "@/services/networkRequests/transactionService";
import React, { useState } from "react";
import { PaystackButton } from "react-paystack";
import { toast } from "react-toastify";
import styles from "./paybutton.module.scss";
import SuccessModal from "@/components/a/Modals/Success";
import { success } from "@/assets/img";
import { useNavigate } from "react-router-dom";
import { ConfigValues } from "@/config";

export default function PayButton({
  email,
  amount,
  name,
  phone,
  workspace_id,
  className,
  disabled,
}: {
  email: any;
  amount: any;
  name: any;
  phone: any;
  workspace_id: any;
  className?: string;
  disabled?: boolean;
}) {
  const navigate = useNavigate();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const handlePaystackSuccess = async (values: any) => {
    if (values.status === "success") {
      console.log(email, amount, name, phone, workspace_id);

      const { message, reference } = values;

      const transactionResponse = await createTransactionOld({
        email,
        amount,
        name,
        phone,
        workspace_id,
        message,
        reference,
      });

      if (transactionResponse?.status === "success") {
        toast.success("Transaction added successfully");
        setIsSuccessModalOpen(true);
        <SuccessModal
          openModal={isSuccessModalOpen}
          setOpenModal={setIsSuccessModalOpen}
          heading={"Now, that was so easy!"}
          closeModal={() => {
            setIsSuccessModalOpen(false);
            navigate("/customer-profile/booking_history");
          }}
          icon={success}
          description={
            "We are confirming availability for the space you’ve requested and will update you shortly. Thanks for choosing CO-SPIRE!"
          }
        />;
      }
    }
  };

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
      custom_fields: [
        {
          display_name: "Workspace ID",
          variable_name: "workspace_id",
          value: workspace_id,
        },
      ],
    },
    publicKey: ConfigValues.PAYSTACK_PUBLIC_KEY,
    text: `Pay ₦${amount}`, // Removed unnecessary function call
    onSuccess: (value: any) => {
      handlePaystackSuccess(value);
    },
    onClose: () => alert("Wait! You need this oil, don't go!!!!"),
    disabled: { disabled },
  };

  return (
    <div>
      <PaystackButton
        {...componentProps}
        className={
          className ? className : disabled ? styles.btnDisabled : styles.btn
        }
      />
    </div>
  );
}
