import React from "react";
import styles from "./noDataPage.module.scss";

interface INoData {
   icon?: string;
   title?: string;
   subtitle?: string;
}

const NoDataPage = ({ icon, title, subtitle }: INoData) => {
   return (
      <div className={styles.page}>
         {icon && (
            <div className={styles.icon}>
               <img src={icon} alt='icon' />
            </div>
         )}
         <h1 className={styles.title}>{title}</h1>
         <p className={styles.subtitle}>{subtitle}</p>
      </div>
   );
};

export default NoDataPage;
