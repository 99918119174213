import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import { Breadcrumb, Row, Avatar, Divider } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link, Outlet, useNavigate } from "react-router-dom";
import HostWorkSpaces from "../Host/Profile/components/MyWorkspaces";
import VerifyHostWorkspace from "../Host/Profile/components/VerifyWorkspace";
import { ADD_SPACE_DETAILS } from "../../routes/routes";
import { CustomButton } from "../../components/customButton";
// import Bookings from ""

import AccountSettings from "./Account";
import { useDashBoard } from "./useDashBoard";
import Bookings from "./Bookings";
import TransactionsPayments from "./Transactions";
import HomeHost from "@/pages/Host/Profile/components/HomeHost";
import { signOut } from "../../firebase/utils";
import { logout } from "@/assets/img";
import { TabTitle } from "@/utils/titleFunction";
import RevenueCard from "@/pages/Host/Profile/components/RevenueCard/RevenueCard";
import SubMenuNav from "../../components/subMenuNav/SubMenuNav";

const Profile = () => {
  TabTitle("co-spire | Profile");

  const {
    loggedInUser,
    activeTab,
    tabs,
    tablist,
    navigate,
    currentTab,
    path,
    pathname,
  } = useDashBoard();
  const links = [
    {
      title: (
        <Link
          to={
            loggedInUser?.role_type === "Host" ? `${path}/host_dashboard` : "/"
          }
        >
          Home
        </Link>
      ),
    },
    {
      title: currentTab,
      link: "",
    },
  ];

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Breadcrumb
          separator=">"
          items={links}
          className={styles.breadcrumbs}
        />
        {/* {loggedInUser?.role_type === "Host" && <CustomButton children={"Add New Workspace"} onClick={() => navigate(`${ADD_SPACE_DETAILS}/description`)} />} */}
      </div>

      <div className={styles.content}>
        {/* Start Page Sidebar  */}
        {/* <div className={styles.sideNav}>
               <div className={styles.profile}>
                  {loggedInUser?.photoURL ? <Avatar size={64} src={loggedInUser?.photoURL} /> : <Avatar size={64} icon={<UserOutlined />} />}
                  <div className={styles.profileText}>
                     <h3 className={styles.title}>{loggedInUser?.displayName}</h3>
                     <p className={styles.description}>Joined April, 2023</p>
                  </div>
               </div>

               {tablist.map((tab) => {
                  if (tab.subNav) return <SubMenuNav key={tab.slug} item={tab} pathname={pathname} />;
                  return (
                     <Link
                        className={styles.tabs}
                        key={tab.slug}
                        to={tab.path}>
                        <div className={` ${pathname === tab.path ? styles.active : ""} ${styles.tabItem}`}>
                           <img className={ tab.path } src={tab.icon} alt='' />
                           <span className={styles.label}>{tab.label}</span>
                        </div>
                     </Link>
                  );
               }
               )}
               <div className={styles.tabs} role="button" onClick={() => signOut()}>
                  <div className={styles.tabItem}>
                     <img src={logout} alt={"logout"} />
                     <p className={styles.label}>{'Logout'}</p>
                  </div>
               </div>
               <div className={styles.revenueTab}>
                  <div className={styles.revenueTabItem}>
                     {
                        loggedInUser?.role_type === 'Host' && (
                           <RevenueCard />
                        )
                     }
                  </div>
               </div>

            </div> */}
        {/* End Page Sidebar  */}

        <div className={styles.details}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Profile;
