import { SpinnerIcon } from "@/components/icons/spinner-icon";
import { useCompleteBookingMutation } from "@/data/booking";
import React from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";

export default function CompleteBooking() {
  const [searchParams] = useSearchParams();

  const params = useParams<{ booking_id: string }>();

  const reference = searchParams.get("reference") || "";

  const {
    isLoading,
    data,
    error,
    mutate: completeBooking,
  } = useCompleteBookingMutation();

  const location = useLocation();

  React.useEffect(() => {
    completeBooking(
      { reference, booking_id: params.booking_id! },
      {
        onSuccess: () => {
          toast.success(<b>{"Booking completed successful"}</b>);
        },
      }
    );
  }, []);

  if (data) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  if (error) {
    const message = "An error occurred while completing booking";
    toast.error((error as any)?.response?.data?.message || message);
    return <Navigate to="/" replace state={{ from: location }} />;
  }
  return (
    <div className="bg-light min-h-screen grid place-content-center">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-lg md:text-xl font-semibold text-primary">
          {isLoading ? <SpinnerIcon /> : "Completing booking. Please wait ..."}
        </h1>
      </div>
    </div>
  );
}
