import React, { useState } from "react";
import styles from "./index.module.scss";
import { Col, Divider, Form, Row } from "antd";
import { CustomInputField } from "../../../../components/customInput";
import CustomSelect from "../../../../components/customSelect";
import { CustomButton } from "../../../../components/customButton";
import SuccessModal from "@/components/a/Modals/Success";
import tick_circle from "@/assets/svgs/success-verified.svg";
import loading_gif from "@/assets/svgs/spinner.gif";
import error_vector from "@/assets/svgs/errorVector.svg";
import { userKycVerification } from "@/services/networkRequests/userService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Verified from "../Verified";
import { useMe } from "@/data/user";

interface FormValues {
  idMethod: string;
  idNumber: number;
}

const Verification = () => {
  const [form] = Form.useForm();
  const { me: loggedInUser, isLoggedIn } = useMe();
  const navigate = useNavigate();
  const idMethodType = [
    { label: "BVN", value: "bvn" },
    { label: "NIN", value: "nin", disabled: true },
    { label: "Driver's License", value: "Driver's License", disabled: true },
    {
      label: "International Passport",
      value: "International Passport",
      disabled: true,
    },
  ];
  const modalContent = [
    {
      heading: "Hold On",
      description: "We are checking your detail. It won’t be long!",
      icon: loading_gif,
    },
    {
      heading: "Welldone",
      description: "Your identity has been verified successfully!",
      icon: tick_circle,
      button: (
        <CustomButton
          children={"Great!"}
          className={styles.btn}
          onClick={() => {
            setIsSuccessModalOpen(false);
            navigate("verified");
          }}
        />
      ),
    },
    {
      heading: "Oops!",
      description: "Something went wrong. Please try again",
      icon: error_vector,
      button: (
        <CustomButton
          children={"Try Again"}
          className={styles.btn}
          onClick={() => {
            setIsSuccessModalOpen(false);
          }}
        />
      ),
    },
  ];
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedIdMethod, setSelectedIdMethod] = useState<string>("bvn");

  const onFinish = (values: FormValues) => {
    console.log("Form valuessss:", values);
    handleVerification(values);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Form errors:", errorInfo);
  };

  const handleVerification = async (values: FormValues) => {
    const user_id = loggedInUser?.uid;
    if (user_id) {
      const payload = {
        user_id: user_id,
        type: values.idMethod,
        user_kyc: values.idNumber,
      };
      setIsSuccessModalOpen(true);
      try {
        await userKycVerification(payload);
        form.resetFields();
        setProgress(1);
        toast.success("Your identity has been verified successfully!");
      } catch (error) {
        setProgress(2);
        toast.error("Something went wrong. Please try again");
      }
    } else {
      toast.error(
        "User ID or Workspace ID is missing. Please log in or provide a workspace ID."
      );
    }
  };

  return (
    <>
      {!loggedInUser?.kyc_verification ? (
        <div className={styles.page}>
          <h2 className={styles.title}>
            <span
              onClick={() =>
                navigate(
                  loggedInUser?.role_type === "Host"
                    ? "/host-profile/account_settings"
                    : "/customer-profile/account_settings"
                )
              }
            >
              Account settings &gt;{" "}
            </span>
            Verification
          </h2>
          <p className={styles.subheading}>
            Verify your account with either of the verification numbers.{" "}
          </p>
          <Divider orientation="left" className={styles.divider}></Divider>
          <Form<FormValues>
            name="verifyKYC"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className={styles.form}
          >
            <h2 className={styles.subheading}>
              <b>Verify your Identity</b>
            </h2>
            <Row gutter={[45, 24]} align="bottom">
              <Col span={8} xs={16}>
                <Form.Item
                  name="idMethod"
                  className={styles.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the type of ID verification...",
                    },
                  ]}
                >
                  <CustomSelect
                    allowClear={false}
                    options={idMethodType}
                    label="Select Identification method"
                    placeHolder="Choose the type of ID for verification"
                  />
                </Form.Item>
              </Col>
              <Col span={8} xs={16}>
                <Form.Item
                  name="idNumber"
                  className={styles.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the ID Number...",
                    },
                  ]}
                >
                  <CustomInputField
                    type={"idNumber"}
                    name={"idNumber"}
                    label="Enter ID number"
                    placeholder="Enter ID number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col flex="auto">
                <CustomButton
                  children={"Verify Identity"}
                  htmlType="submit"
                  className={styles.btn}
                  onClick={() => {}}
                />
              </Col>
            </Row>
          </Form>

          {isSuccessModalOpen && (
            <SuccessModal
              openModal={isSuccessModalOpen}
              closeModal={() => setIsSuccessModalOpen(false)}
              setOpenModal={setIsSuccessModalOpen}
              heading={modalContent[progress].heading}
              icon={modalContent[progress].icon}
              description={modalContent[progress].description}
              button={progress && modalContent[progress].button}
            />
          )}
        </div>
      ) : (
        <Verified />
      )}
    </>
  );
};

export default Verification;
