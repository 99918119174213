import React from "react";
import styles from "./contact.module.scss";
import { CustomInputField } from "../../../../../components/customInput";
import { CustomButton } from "../../../../../components/customButton";
import { IActions } from "../../../../../types";

import { Col, Row, Divider } from "antd";

import { IWorkSpaceForm } from "../../../../../interfaces/forms";
import { FormikProps } from "formik";
import { TabsProp } from "../../useHostForm";
import { useNavigate } from "react-router-dom";
import { ADD_SPACE_DETAILS } from "../../../../../routes/routes";
import PhoneInput from "../../../../../components/phoneNumberInput/CustomPhoneNoInput";

interface IProps {
   formik: FormikProps<IWorkSpaceForm>;
}

export const Contact = ({ formik }: IProps) => {
   const navigate = useNavigate();
   return (
      <div className={styles.page}>
         <div className={styles.title}>
            <h1 className={styles.heading}>Add your Contact details</h1>
            <p className={styles.subHeading}>Fill in your Official Contact details.</p>
         </div>

         <div className={styles.form}>
            <Divider orientation='left' className={styles.divider}></Divider>
            <Row gutter={[45, 24]}>
               <Col md={12} xs={24}>
                  <CustomInputField
                     type={"email"}
                     label='Official Email Address'
                     placeholder='Enter official Email address'
                     {...formik.getFieldProps("spaceEmailAddress")}
                     error={formik.touched.spaceEmailAddress && formik.errors.spaceEmailAddress}
                  />
               </Col>
               <Col md={12} xs={24}>
                  <PhoneInput
                     label='Phone Number'
                     placeholder='Enter Phone Number'
                     value={formik.values.spacePhoneNumber}
                     onChange={(value) => formik.setFieldValue("spacePhoneNumber", value)}
                     error={formik.touched.spacePhoneNumber && formik.errors.spacePhoneNumber}
                     onBlur={() => formik.setFieldTouched("spacePhoneNumber")}
                  />
               </Col>
            </Row>
            <Row gutter={[45, 24]}>
               <Col md={12} xs={24}>
                  <PhoneInput
                     label='WhatsApp Number'
                     placeholder='Enter WhatsApp Number'
                     value={formik.values.spaceWhatsappPhoneNumber}
                     onChange={(value) => formik.setFieldValue("spaceWhatsappPhoneNumber", value)}
                     onBlur={() => formik.setFieldTouched("spaceWhatsappPhoneNumber")}
                     error={formik.touched.spaceWhatsappPhoneNumber && formik.errors.spaceWhatsappPhoneNumber}
                  />
               </Col>
               <Col md={12} xs={24}>
                  <CustomInputField
                     type={"url"}
                     label='Website URL (If any)'
                     placeholder='Enter Website URL'
                     {...formik.getFieldProps("spaceWebsite")}
                     error={formik.touched.spaceWebsite && formik.errors.spaceWebsite}
                  />
               </Col>
            </Row>

            <div className={styles.title}>
               <Divider orientation='left'></Divider>
               <h1 className={styles.heading}>Social Media Links</h1>
               <p>Copy and paste your official social media links here</p>
            </div>

            <Row gutter={[45, 24]}>
               <Col md={12} xs={24}>
                  <CustomInputField
                     type={"url"}
                     label='Facebook'
                     placeholder='Enter Facebook Link'
                     {...formik.getFieldProps("facebook")}
                     error={formik.touched.facebook && formik.errors.facebook}
                  />
               </Col>
               <Col md={12} xs={24}>
                  <CustomInputField
                     type={"url"}
                     label='Twitter'
                     placeholder='Enter Twitter Link'
                     {...formik.getFieldProps("twitter")}
                     error={formik.touched.twitter && formik.errors.twitter}
                  />
               </Col>
            </Row>

            <Row gutter={[45, 24]}>
               <Col md={12} xs={24}>
                  <CustomInputField
                     type={"url"}
                     label='Instagram'
                     placeholder='Enter Instagram Link'
                     {...formik.getFieldProps("instagram")}
                     error={formik.touched.instagram || formik.errors.instagram}
                  />
               </Col>
               <Col md={12} xs={24}>
                  <CustomInputField
                     type={"url"}
                     label='LinkedIn'
                     placeholder='Enter LinkedIn Link'
                     {...formik.getFieldProps("linkedin")}
                     error={formik.touched.linkedin || formik.errors.linkedin}
                  />
               </Col>
            </Row>
         </div>

         <div className={styles.buttonContainer}>
            <div
               className={styles.previous}
               onClick={() => {
                  navigate(`${ADD_SPACE_DETAILS}/description`);
               }}
            >
               Previous
            </div>
            <CustomButton
               children={"Next"}
               disabled={Boolean(formik.errors.spaceEmailAddress) || Boolean(formik.errors.spacePhoneNumber)}
               onClick={() => {
                  navigate(`${ADD_SPACE_DETAILS}/operation_hours`);
               }}
               extraStyles={{ width: "150px" }}
            />
         </div>
      </div>
   );
};
