import React, { useState, useCallback } from "react";
import { Input } from "antd";
import styles from "./CustomPhoneNoInput.module.scss";

interface PhoneInputProps {
  onChange: (value: string) => void;
  value: string | number;
  label?: string;
  secLabel?: string;
  error?: string | undefined | number | boolean;
  placeholder?: string;
  autoComplete?: string;
  name?: string;
  disabled?: boolean;
  extraStyles?: Record<string, string>;
  className?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onValidate?: (isValid: boolean) => void; // Add a validation callback
  setError?: (field: string, message: string | undefined) => void;
}

const PhoneInput = ({
  onChange,
  value,
  name,
  placeholder,
  autoComplete,
  label,
  secLabel,
  extraStyles,
  disabled,
  className,
  onBlur,
  onValidate, // Pass the validation callback
  error,
}: PhoneInputProps) => {
  const [formattedValue, setFormattedValue] = useState(value);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;

      // Remove all non-numeric characters from the input
      const numericValue = inputValue.replace(/\D/g, "");

      // Handle numbers starting with "+"
      if (numericValue.startsWith("+")) {
        setFormattedValue(numericValue);
        onChange(numericValue);
        return;
      }

      // Handle numbers starting with "0"
      if (numericValue.startsWith("0")) {
        setFormattedValue(numericValue);
        onChange(numericValue);
        return;
      }

      // Handle other cases (e.g., standard phone numbers)
      if (!numericValue.startsWith("0")) {
        setFormattedValue(`${numericValue}`);
        onChange(`${numericValue}`);
      }

      // Call the validation callback with the result
    },
    [onChange, onValidate]
  );
  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      {secLabel && <p className={styles.secondaryLabel}>{secLabel}</p>}

      <div className={styles.inputContainer}>
        <Input
          allowClear
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          autoComplete={autoComplete}
          disabled={disabled}
          style={extraStyles}
          onWheel={(e: any) => e.target.blur()}
          className={className ? className : styles.input}
          onBlur={onBlur}
        />
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default PhoneInput;
