import React from "react";
import { Transaction } from "@/types/transaction";
import { formatMoney } from "@/utils/format-money";

type TransactionTableProps = {
  transactions: Transaction[];
};

export function TransactionTable({ transactions }: TransactionTableProps) {
  console.log(transactions);

  return (
    <table className="w-full text-left">
      <thead>
        <tr className="border border-[#F7F7F7]">
          <th className="py-2 text-sm font-medium text-coSpireBlack">AMOUNT</th>
          <th className="py-2 text-sm font-medium text-coSpireBlack">
            DESCRIPTION
          </th>
          <th className="py-2 text-sm font-medium text-coSpireBlack">
            TRANSACTION REFERENCE
          </th>
          <th className="py-2 text-sm font-medium text-coSpireBlack">TYPE</th>
          <th className="py-2 text-sm font-medium text-coSpireBlack">STATUS</th>
        </tr>
      </thead>
      <tbody className="border border-[#F7F7F7]">
        {transactions.map((trxn) => (
          <tr key={trxn.id} className="">
            <td className="py-2 text-base text-primaryText font-normal">
              ₦{formatMoney(trxn.amount)}
            </td>
            <td className="py-2">
              <div className="text-base text-coSpireBlack capitalize">
                {trxn?.purpose}
              </div>
              <div className="text-primaryText text-xs mt-1">
                {trxn.purpose === "booking" ? "Booked on :" : ""}{" "}
                {new Date(trxn.created_ts).toLocaleDateString("en-US", {
                  dateStyle: "medium",
                })}
              </div>
            </td>
            <td className="py-2 text-base text-primaryText font-normal">
              {trxn.id}
            </td>
            <td className="py-2 text-base text-coSpireBlack capitalize">
              {trxn?.transaction_type}
            </td>
            <td className="py-2">
              <span className="bg-blue-100 text-blue-600 py-1 px-3 rounded-full text-sm capitalize">
                {trxn.status}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
