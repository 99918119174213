import {
  FeaturedCities,
  FeaturedCities1,
  FeaturedCities2,
  inner1,
  inner2,
  inner3,
  inner4,
  list1,
  list2,
  list3,
  list4,
  logout,
  office_icon,
  office_icon_bw,
  dashboard_icon,
  Property1,
  Property2,
  Space1,
  Space2,
  Space3,
  services,
  settings,
  review1,
  review2,
  review3,
  review4,
  time,
} from "@/assets/img/";
import booking from "@/assets/svgs/booking.svg";
import transactions from "@/assets/svgs/transactions.svg";
import verify from "@/assets/img/verify.png";
import Meeting from "@/assets/svgs/laptop-account.svg";
import Clock from "@/assets/svgs/clock.svg";
import Offices from "@/assets/svgs/seat.svg";
import Dashboard from "@/assets/svgs/dashboard.svg";
import Wifi from "@/assets/svgs/wifi.svg";
import Conference from "@/assets/svgs/building-office-2-blue.svg";
import Brunch from "@/assets/svgs/noodles.svg";
import Community from "@/assets/svgs/user-group-blue.svg";
import Swim from "@/assets/svgs/swim.svg";
import {
  ADD_SPACE_DETAILS,
  HOST_PROFILE,
  CUSTOMER_PROFILE,
} from "../routes/routes";

export const locations = [
  {
    wants: "Room",
    img: FeaturedCities,
    city: "Lagos, Nigeria",
  },
  {
    wants: "Ofice",
    img: FeaturedCities1,
    city: "Newyork, USA",
  },
  {
    wants: "brains Storm",
    img: FeaturedCities2,
    city: "Wuhan, China",
  },
  {
    wants: "Hall",
    img: Property1,
    city: "Cape town, South Africa",
  },
  {
    wants: "Class",
    img: Property2,
    city: "Accra, Ghana",
  },
];

export const featuredSpaces = [
  {
    img: Space1,
    location: "Lagos, Nigeria",
    space: "Kelsie Workspace",
  },
  {
    img: Space2,
    location: "FLORIDA, USA",
    space: "Modern  house",
  },
  {
    img: Space3,
    location: "KAMPALA, UGANDA",
    space: "Residential project",
  },
  {
    img: Space2,
    location: "CAPETOWN, SOUTH AFRICA",
    space: "Modern  house",
  },
];

export const footerLinks = [
  {
    title: "Company",
    links: [
      { name: "About", path: "/about-us" },
      { name: "Our Story", path: "/careers" },
    ],
  },
  {
    title: "Spaces",
    links: [
      // { name: "Add my workspace", path: "/" },
      { name: "Pricing", path: "/workspace" },
      { name: "Featured Spaces", path: "/workspace" },
      { name: "Search Spaces", path: "/" },
    ],
  },
  {
    title: "Support",
    links: [
      { name: "Help center", path: "/" },
      { name: "FAQ", path: "/faq" },
    ],
  },
  {
    title: "Connect with Us",
    links: [
      {
        name: "Facebook",
        path: "https://www.facebook.com/profile.php?id=61551460977898&mibextid=ZbWKwL",
      },
      { name: "Twitter", path: "/" },
      {
        name: "Instagram",
        path: "https://www.instagram.com/p/CyuxpkEMJ3G/?utm_source=ig_web_copy_link",
      },
      {
        name: "LinkedIn",
        path: "https://www.linkedin.com/company/cospirehub/  ",
      },
    ],
  },
];

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "land Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const HOST_TABS = [
  {
    label: "Description",
    icon: office_icon,
    slug: "description",
    path: `${ADD_SPACE_DETAILS}/description`,
    id: 1,
  },
  {
    label: "Contact Details",
    icon: settings,
    slug: "contact_details",
    path: `${ADD_SPACE_DETAILS}/contact_details`,
    id: 2,
  },
  {
    label: "Operation Schedule",
    icon: time,
    slug: "operation_hours",
    path: `${ADD_SPACE_DETAILS}/operation_hours`,
    id: 3,
  },
  {
    label: "Services and Amenities",
    icon: services,
    slug: "services_amenities",
    path: `${ADD_SPACE_DETAILS}/services_amenities`,
    id: 4,
  },
];

export const AMENITIES = [
  "24/7 Customer Support",
  "Constant Power Supply",
  "Wifi Service",
  "Parking Service",
  "Customer Program Support",
  "Kitchenette",
];

export const WorkspaceList = [
  {
    id: 0,
    imgs: [list1, list1, list1],
    title: "Kelsie Workspace",
    rating: 5,
    reviews: 200,
    amount: "300",
    location: "Lagos Nigeria",
    country: "NG",
    featured: true,
    inner: [inner1, inner2, inner4, inner3],
    info: "Kelsie’s Workspace based in Lagos Nigeria. Enjoy a premier location in the centre of excellence, with numerous corporate headquarters in the vicinity, the area is home to many big players in the legal, research, technology, communications and media sectors. The office space has a conducive reception area to welcome you and your guests, easily book meeting rooms seating up to 16 participants. There is a mini breakfast service on offer to eat at your desk and if work over-runs the centre provides 24/7 and weekend access.",
    verifiedReviews: [
      {
        img: review1,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review2,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review3,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review4,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
    ],
    services: [
      { icon: Meeting, name: "Meeting space" },
      { icon: Clock, name: "24/7 service" },
      { icon: Offices, name: "Private Offices" },
      { icon: Wifi, name: "Wifi Service" },
      { icon: Conference, name: "Conference Rooms" },
      { icon: Community, name: "Community Support" },
    ],
    otherServices: [
      { icon: Brunch, name: "Brunch" },
      { icon: Swim, name: "Relaxation and Spa" },
    ],
    services_amenities: [
      "Meeting Space",
      "Private Offices",
      "Dedicated Desk",
      "Hot Desk",
      "Event space",
      "Virtual Office",
      "Breakout space",
      "WifI Service",
      "Coffee machine",
      "Free parking",
      "Free Print/copy/scan",
    ],
    map: { lat: 55.80562399999999, lng: 37.641239 },
  },
  {
    id: 1,
    imgs: [list2, list2, list2],
    name: "Frankiefied Workspace",
    rating: 5,
    reviews: 200,
    amount: "600",
    location: "Lagos Nigeria",
    country: "US",
    featured: false,
    inner: [inner1, inner2, inner3, inner4],
    info: "Kelsie’s Workspace based in Lagos Nigeria. Enjoy a premier location in the centre of excellence, with numerous corporate headquarters in the vicinity, the area is home to many big players in the legal, research, technology, communications and media sectors. The office space has a conducive reception area to welcome you and your guests, easily book meeting rooms seating up to 16 participants. There is a mini breakfast service on offer to eat at your desk and if work over-runs the centre provides 24/7 and weekend access.",
    verifiedReviews: [
      {
        img: review1,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review2,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review3,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review4,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
    ],
    services: [
      { icon: Meeting, name: "Meeting Space" },
      { icon: Clock, name: "24/7 service" },
      { icon: Community, name: "Community Support" },
    ],
    otherServices: [
      { icon: Brunch, name: "Brunch" },
      { icon: Swim, name: "Relaxation and Spa" },
    ],
    services_amenities: [
      "Meeting Space",
      "Private Offices",
      "Dedicated Desk",
      "Breakout space",
      "24/7 WIFI",
      "Coffee machine",
      "Free parking",
      "Free Print/copy/scan",
    ],
    map: { lat: 55.66497999999999, lng: 37.857464 },
  },
  {
    id: 2,
    imgs: [list3, list3, list3],
    name: "Hubspot 230",
    rating: 5,
    reviews: 200,
    amount: "3000",
    location: "Lagos Nigeria",
    country: "US",
    featured: false,
    inner: [inner1, inner2, inner3, inner4],
    info: "Kelsie’s Workspace based in Lagos Nigeria. Enjoy a premier location in the centre of excellence, with numerous corporate headquarters in the vicinity, the area is home to many big players in the legal, research, technology, communications and media sectors. The office space has a conducive reception area to welcome you and your guests, easily book meeting rooms seating up to 16 participants. There is a mini breakfast service on offer to eat at your desk and if work over-runs the centre provides 24/7 and weekend access.",
    verifiedReviews: [
      {
        img: review1,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review2,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review3,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review4,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
    ],
    services: [
      { icon: Wifi, name: "Wifi Service" },
      { icon: Conference, name: "Conference Rooms" },
      { icon: Community, name: "Community Support" },
    ],
    otherServices: [
      { icon: Brunch, name: "Brunch" },
      { icon: Swim, name: "Relaxation and Spa" },
    ],
    services_amenities: [
      "Meeting Space",
      "Private Offices",
      "Dedicated Desk",
      "Hot Desk",
      "Event space",
      "Virtual Office",
    ],
    map: { lat: 55.68271799999999, lng: 37.544263 },
  },
  {
    id: 3,
    imgs: [list4, list4, list4],
    name: "Kamal and Co",
    rating: 5,
    reviews: 200,
    amount: "2000",
    location: "Lagos Nigeria",
    country: "NG",
    featured: true,
    inner: [inner1, inner2, inner3, inner4],
    info: "Kelsie’s Workspace based in Lagos Nigeria. Enjoy a premier location in the centre of excellence, with numerous corporate headquarters in the vicinity, the area is home to many big players in the legal, research, technology, communications and media sectors. The office space has a conducive reception area to welcome you and your guests, easily book meeting rooms seating up to 16 participants. There is a mini breakfast service on offer to eat at your desk and if work over-runs the centre provides 24/7 and weekend access.",
    verifiedReviews: [
      {
        img: review1,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review2,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review3,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
      {
        img: review4,
        name: "Name Surname",
        review:
          "“All base UI elements are made using Nested Symbols and shared styles that are logically connected.",
      },
    ],
    services: [
      { icon: Offices, name: "Private Offices" },
      { icon: Wifi, name: "Wifi Service" },
    ],
    otherServices: [
      { icon: Brunch, name: "Brunch" },
      { icon: Swim, name: "Relaxation and Spa" },
    ],
    services_amenities: [
      "Breakout space",
      "24/7 WIFI",
      "Coffee machine",
      "Free parking",
      "Free Print/copy/scan",
    ],
    map: { lat: 55.68271799999999, lng: 37.544263 },
  },
];

export const ALL_COUNTRIES = [
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Albania", value: "Albania" },
  { label: "Algeria", value: "Algeria" },
  { label: "Andorra", value: "Andorra" },
  { label: "Angola", value: "Angola" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: "Argentina", value: "Argentina" },
  { label: "Armenia", value: "Armenia" },
  { label: "Australia", value: "Australia" },
  { label: "Austria", value: "Austria" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "Belgium", value: "Belgium" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Brazil", value: "Brazil" },
  { label: "Brunei", value: "Brunei" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cabo Verde", value: "Cabo Verde" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "Cameroon", value: "Cameroon" },
  { label: "Canada", value: "Canada" },
  { label: "Central African Republic", value: "Central African Republic" },
  { label: "Chad", value: "Chad" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoros", value: "Comoros" },
  { label: "Congo (Congo-Brazzaville)", value: "Congo (Congo-Brazzaville)" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cuba", value: "Cuba" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Czechia (Czech Republic)", value: "Czechia (Czech Republic)" },
  {
    label: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo",
  },
  { label: "Denmark", value: "Denmark" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypt", value: "Egypt" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estonia", value: "Estonia" },
  { label: "Eswatini (fmr. Swaziland)", value: "Eswatini (fmr. Swaziland)" },
  { label: "Ethiopia", value: "Ethiopia" },
  { label: "Fiji", value: "Fiji" },
  { label: "Finland", value: "Finland" },
  { label: "France", value: "France" },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambia", value: "Gambia" },
  { label: "Georgia", value: "Georgia" },
  { label: "Germany", value: "Germany" },
  { label: "Ghana", value: "Ghana" },
  { label: "Greece", value: "Greece" },
  { label: "Grenada", value: "Grenada" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guinea", value: "Guinea" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Haiti", value: "Haiti" },
  { label: "Holy See", value: "Holy See" },
  { label: "Honduras", value: "Honduras" },
  { label: "Hungary", value: "Hungary" },
  { label: "Iceland", value: "Iceland" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Iran", value: "Iran" },
  { label: "Iraq", value: "Iraq" },
  { label: "Ireland", value: "Ireland" },
  { label: "Israel", value: "Israel" },
  { label: "Italy", value: "Italy" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Japan", value: "Japan" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kenya", value: "Kenya" },
  { label: "Kiribati", value: "Kiribati" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  { label: "Laos", value: "Laos" },
  { label: "Latvia", value: "Latvia" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Lesotho", value: "Lesotho" },
  { label: "Liberia", value: "Liberia" },
  { label: "Libya", value: "Libya" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Lithuania", value: "Lithuania" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Madagascar", value: "Madagascar" },
  { label: "Malawi", value: "Malawi" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mali", value: "Mali" },
  { label: "Malta", value: "Malta" },
  { label: "Marshall Islands", value: "Marshall Islands" },
  { label: "Mauritania", value: "Mauritania" },
  { label: "Mauritius", value: "Mauritius" },
  { label: "Mexico", value: "Mexico" },
  { label: "Micronesia", value: "Micronesia" },
  { label: "Moldova", value: "Moldova" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "Montenegro", value: "Montenegro" },
  { label: "Morocco", value: "Morocco" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar (formerly Burma)", value: "Myanmar (formerly Burma)" },
  { label: "Namibia", value: "Namibia" },
  { label: "Nauru", value: "Nauru" },
  { label: "Nepal", value: "Nepal" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "North Korea", value: "North Korea" },
  { label: "North Macedonia", value: "North Macedonia" },
  { label: "Norway", value: "Norway" },
  { label: "Oman", value: "Oman" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palau", value: "Palau" },
  { label: "Palestine State", value: "Palestine State" },
  { label: "Panama", value: "Panama" },
  { label: "Papua New Guinea", value: "Papua New Guinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Philippines", value: "Philippines" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "Qatar", value: "Qatar" },
  { label: "Romania", value: "Romania" },
  { label: "Russia", value: "Russia" },
  { label: "Rwanda", value: "Rwanda" },
  { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: "Saint Lucia", value: "Saint Lucia" },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbia", value: "Serbia" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapore", value: "Singapore" },
  { label: "Slovakia", value: "Slovakia" },
  { label: "Slovenia", value: "Slovenia" },
  { label: "Solomon Islands", value: "Solomon Islands" },
  { label: "Somalia", value: "Somalia" },
  { label: "South Africa", value: "South Africa" },
  { label: "South Korea", value: "South Korea" },
  { label: "South Sudan", value: "South Sudan" },
  { label: "Spain", value: "Spain" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Sudan", value: "Sudan" },
  { label: "Suriname", value: "Suriname" },
  { label: "Sweden", value: "Sweden" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Syria", value: "Syria" },
  { label: "Tajikistan", value: "Tajikistan" },
  { label: "Tanzania", value: "Tanzania" },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Togo", value: "Togo" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Turkey", value: "Turkey" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Uganda", value: "Uganda" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "United States of America", value: "United States of America" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Vietnam", value: "Vietnam" },
  { label: "Yemen", value: "Yemen" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" },
];

export const Timestamps = [
  { label: "12:00 AM", value: "00:00" },
  { label: "1:00 AM", value: "01:00" },
  { label: "2:00 AM", value: "02:00" },
  { label: "3:00 AM", value: "03:00" },
  { label: "4:00 AM", value: "04:00" },
  { label: "5:00 AM", value: "05:00" },
  { label: "6:00 AM", value: "06:00" },
  { label: "7:00 AM", value: "07:00" },
  { label: "8:00 AM", value: "08:00" },
  { label: "9:00 AM", value: "09:00" },
  { label: "10:00 AM", value: "10:00" },
  { label: "11:00 AM", value: "11:00" },
  { label: "12:00 PM", value: "12:00" },
  { label: "1:00 PM", value: "13:00" },
  { label: "2:00 PM", value: "14:00" },
  { label: "3:00 PM", value: "15:00" },
  { label: "4:00 PM", value: "16:00" },
  { label: "5:00 PM", value: "17:00" },
  { label: "6:00 PM", value: "18:00" },
  { label: "7:00 PM", value: "19:00" },
  { label: "8:00 PM", value: "20:00" },
  { label: "9:00 PM", value: "21:00" },
  { label: "10:00 PM", value: "22:00" },
  { label: "11:00 PM", value: "23:00" },
];

export const MONTHS = [
  { label: "1 Month", value: "1" },
  { label: "2 Months", value: "2" },
  { label: "6 Months", value: "6" },
  { label: "12 Months", value: "12" },
];

export const WEEKS = [
  { label: "1 Week", value: "1" },
  { label: "2 Weeks", value: "2" },
  { label: "6 Weeks", value: "6" },
  { label: "12 Weeks", value: "12" },
];

export const PROFILE_TABS_HOST = [
  {
    label: "Dashboard",
    icon: dashboard_icon,
    slug: "host_dashboard",
    id: 1,
    path: `${HOST_PROFILE}/host_dashboard`,
  },
  {
    label: "My Workspaces",
    icon: office_icon_bw,
    slug: "workspaces",
    id: 1,
    path: `${HOST_PROFILE}/workspaces`,
    // subNav: [
    //    { label: "My workspaces", link: "orders" },
    //    { label: "verify workspaces", link: "returnes" },
    // ],
  },
  // {
  //   label: "Verify Workspace",
  //   icon: verify,
  //   slug: "verify_workspace",
  //   id: 3,
  //   path: `${HOST_PROFILE}/verify_workspace`,
  // },
  {
    label: "Bookings",
    icon: booking,
    slug: "bookings",
    id: 4,
    path: `${HOST_PROFILE}/bookings`,
  },
  {
    label: "Payments and Billing",
    icon: transactions,
    slug: "payments",
    id: 9,
    path: `${HOST_PROFILE}/wallet`,
    subNav: [
      {
        label: "Wallet",
        icon: transactions,
        slug: "wallet",
        id: 6,
        path: `${HOST_PROFILE}/wallet`,
      },
      {
        label: "Transactions",
        icon: transactions,
        slug: "transactions_payments",
        id: 5,
        // path: `${HOST_PROFILE}/transactions_payments`,
      },
    ],
  },
  // {
  //   label: "Transactions and Payments",
  //   icon: transactions,
  //   slug: "transactions_payments",
  //   id: 5,
  //   path: `${HOST_PROFILE}/transactions_payments`,
  // },
  //  {
  //     label: "Wallet",
  //     icon: transactions,
  //     slug: "wallet",
  //     id: 6,
  //     path: `${HOST_PROFILE}/wallet`,
  //  },
  {
    label: "Account Settings",
    icon: settings,
    slug: "account_settings",
    id: 7,
    path: `${HOST_PROFILE}/account_settings`,
  },
  // {
  //   label: "Workspaces",
  //   icon: office_icon_bw,
  //   slug: "workspaces",
  //   id: 8,
  //   path: `${HOST_PROFILE}/workspaces`,
  //   subNav: [
  //     { label: "My workspaces", link: "orders" },
  //     { label: "verify workspaces", link: "returnes" },
  //   ],
  // },
];

export const CUSTOMER_MENU = [
  // {
  //   label: "Saved Spaces",
  //   icon: saved,
  //   slug: "saved_spaces",
  //   path: `${CUSTOMER_PROFILE}/saved_spaces`,
  //   id: 1,
  // },
  // {
  //   label: "Home",
  //   icon: dashboard_icon,
  //   slug: "customer_dashboard",
  //   path: `${CUSTOMER_PROFILE}/customer_dashboard`,
  //   id: 2,
  // },
  {
    label: "Booking History",
    icon: booking,
    slug: "booking_history",
    path: `${CUSTOMER_PROFILE}/booking_history`,
    id: 2,
  },
  {
    label: "Account Setting",
    icon: settings,
    slug: "account_settings",
    path: `${CUSTOMER_PROFILE}/account_settings`,
    id: 3,
  },
];

export const SERVICES = [
  {
    space_type_name: "",
    space_type: "PRIVATE OFFICE",
    price_per_month: "",
    price_per_week: "",
    price_per_day: "",
    price_per_hour: "",
    space_type_capacity: "",
    space_type_total: "",
    space_type_available: "",
    space_type_description: "",
  },
  // {
  //   space_type_name: "Meeting space",
  //   price_per_month: "",
  //   price_per_week: "",
  //   price_per_day: "",
  //   space_type_capacity: "",
  //   space_type_total: "",
  //   space_type_available: "",
  // },
  // {
  //   space_type_name: "Conference rooms",
  //   price_per_month: "",
  //   price_per_week: "",
  //   price_per_day: "",
  //   space_type_capacity: "",
  //   space_type_total: "",
  //   space_type_available: "",
  // },
];

export const DURATION = [
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
];

export const SPACE_TYPES = [
  { label: "PRIVATE OFFICE", value: "PRIVATE OFFICE" },
  { label: "SHARED SPACE", value: "SHARED SPACE" },
  { label: "DEDICATED SPACE", value: "DEDICATED SPACE" },
  { label: "MEETING ROOM", value: "MEETING ROOM" },
  { label: "EVENT SPACE", value: "EVENT SPACE" },
  { label: "VIRTUAL OFFICE", value: "VIRTUAL OFFICE" },
  { label: "OTHERS", value: "OTHERS" },
];
