import React from "react";
import { Button, Col, Row, Typography } from "antd";
import styles from "./AgeRestriction.module.scss";

interface AgeRestrictionProps {
  ageRange: string[];
  allowedAges: string[];
  handleSelectActivity: (index: number) => void;
}

const AgeRestriction: React.FC<AgeRestrictionProps> = ({
  ageRange,
  allowedAges,
  handleSelectActivity,
}) => {
  return (
    <div className={styles["page-input-field"]}>
      <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <label>What age is allowed?</label>
          <div className={styles["space-activity-parent"]}>
            {ageRange.map((spaceActivity, index) => {
              const isActive = allowedAges.includes(spaceActivity);
              const className = isActive
                ? `${styles["space-activity"]} ${styles["active"]}`
                : styles["space-activity"];

              return (
                <Button
                  key={index}
                  className={className}
                  onClick={() => handleSelectActivity(index)}
                >
                  <p>{spaceActivity}</p>
                </Button>
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AgeRestriction;
