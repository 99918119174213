import React from "react";
import bank_icon from "@/assets/svgs/bankIcon.svg";
import { CloseCircleIcon } from "@/components/icons/close-circle";
import { useModalAction, useModalState } from "@/components/modal-view/context";
import { useRemoveWithdrawalAccountMutation } from "@/data/wallet";
import { handleError } from "@/services/networkRequests";
import { toast } from "react-toastify";

export function ConfirmRemoveBank() {
  const { closeModal, openModal } = useModalAction();
  const { data: account } = useModalState();
  const { mutateAsync: remove, isLoading } =
    useRemoveWithdrawalAccountMutation();

  function handleRemove(accountId: string) {
    toast.promise(
      remove(accountId, {
        onSuccess: () => {
          toast.success("Account removed successfully");
          closeModal();
        },
        onError: (err) => {
          handleError(err);
          closeModal();
        },
      }),
      {
        pending: "Removing account...",
      }
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-[#F7F9FB] p-6 rounded-md w-full max-w-xl mx-auto">
        <div className="mb-6 relative">
          <h3 className="font-semibold text-2xl mb-2.5">Delete account?</h3>
          <p className="text-lg text-primaryText">
            You can no longer send money to this account when you delete it.
          </p>
          <button
            onClick={closeModal}
            title="Close modal"
            className="absolute top-0 right-0"
          >
            <CloseCircleIcon className="text-primaryText" />
          </button>
        </div>
        <div className="space-y-7">
          <div className="bg-white py-2 px-4 flex justify-between items-center">
            <div className="flex">
              <div className={"w-10 h-10 rounded-full flex mr-2"}>
                <img src={bank_icon} alt="bank_icon" />
              </div>
              <div className="flex flex-col">
                <p className="text-lg text-[#131416] font-semibold">
                  Bank transfer
                </p>
                <div className={"text-sm "}>
                  <span className="text-[#131416]">
                    {account?.account_number?.substring(0, 4) + "********"}{" "}
                  </span>{" "}
                  |{" "}
                  <span className={"text-primaryText"}>
                    {account?.bank_name || ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-4 mt-8">
          <button
            onClick={() => openModal("WITHDRAWAL_METHODS")}
            className="text-lg font-medium text-primary disabled:text-opacity-70"
            disabled={isLoading}
          >
            No, go back
          </button>

          <button
            className="bg-danger py-2.5 px-7 rounded-3xl text-white font-medium text-lg disabled:bg-opacity-70"
            onClick={() => handleRemove(account?.account_id)}
            disabled={isLoading}
          >
            Yes, delete account
          </button>
        </div>
      </div>
    </div>
  );
}
