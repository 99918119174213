import React from "react";
import { IButton } from "./types";
import styles from "./index.module.scss";

const Button: React.FC<IButton> = ({
  disabled = false,
  type,
  children,
  ...props
}) => {
  return (
    <button
      disabled={disabled}
      className={`${styles.button} ${styles[`button--${type}`]}`}
      {...props}
      type="submit"
    >
      {children}
    </button>
  );
};

export default Button;
