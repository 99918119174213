import { useMe } from "@/data/user";
import React, { useState } from "react";

enum tabs {
  all_payments = "All Payments",
  ongoing = "Ongoing",
  cancelled = "Cancelled",
}

const paymentStatus = [
  { label: "Approved", value: "approved" },
  { label: "Pending", value: "Pending" },
];

interface DataType {
  key: string;
  workspaceInformation: {
    name: string;
    createdDate: Date;
  };
  bookingStatus: string;
  amount: number;
  paymentStatus: string;
  more: string;
}

// const data: DataType[] = [
//    {
//       key: "1",
//       workspaceInformation: {
//          name: "John Wick",
//          createdDate: new Date("2023-07-25"),
//       },
//       bookingStatus: "Ongoing",
//       amount: 5000,
//       paymentStatus: "Pending",
//       more: "",
//    },
//    {
//       key: "2",
//       workspaceInformation: {
//          name: "John Deo",
//          createdDate: new Date("2023-07-25"),
//       },
//       bookingStatus: "Cancelled",
//       amount: 5000,
//       paymentStatus: "Approved",
//       more: "",
//    },
// ];

const data: DataType[] = [];

const useTransactions = () => {
  const { isLoggedIn, me: loggedInUser } = useMe();
  const [transactionData, setTransactionData] = useState([]);
  const [currentTab, setCurrentTab] = useState<string>("All Payments");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<
    string | null
  >(null);

  const tabLists = [tabs.all_payments, tabs.ongoing, tabs.cancelled];

  const onTabClick = (tab: string) => {
    setCurrentTab(tab);
  };

  const onSelectPaymentStatus = (status: string) => {
    setSelectedPaymentStatus(status);
  };

  const filterData = () => {
    let filteredData = data;

    if (selectedPaymentStatus) {
      filteredData = filteredData.filter(
        (item) => item.paymentStatus === selectedPaymentStatus
      );
    }

    if (currentTab !== tabs.all_payments) {
      filteredData = filteredData.filter(
        (item) => item.bookingStatus === currentTab
      );
    }

    return filteredData;
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  };

  const getColor = (text: string): { [key: string]: string } => {
    switch (text) {
      case "Ongoing":
        return {
          color: "rgba(30, 98, 41, 1)",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "Cancelled":
        return {
          color: "rgba(255, 75, 85, 1)",
          backgroundColor: "rgba(253, 222, 222, 1)",
        };
      case "Approved":
        return {
          color: "rgba(30, 98, 41, 1)",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "Pending":
        return {
          color: "rgba(254, 155, 14, 1)",
          backgroundColor: "rgba(251, 240, 223, 1)",
        };
      default:
        return {
          color: "rgba(30, 98, 41, 1)",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
    }
  };

  return {
    transactionData,
    currentTab,
    onTabClick,
    paymentStatus,
    onSelectPaymentStatus,
    onSearch,
    tabLists,
    getColor,
    data,
    loggedInUser,
  };
};

export default useTransactions;
