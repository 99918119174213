import "./setupPage.scss";
import SelectInput from "@/components/selectInputDropdown";
import { CustomButton } from "@/components/customButton";
import { Checkbox, Input } from "antd";
import tickCircle from "@/assets/svgs/tick-circle.svg";

const MIN_HOURS_OPTIONS = [
  { name: "1 hour", value: "1" },
  { name: "2 hours", value: "2" },
  { name: "3 hours", value: "3" },
];

const CURRENCY_OPTIONS = [
  { name: "NGN", value: "NGN", disabled: false },
  { name: "GBP", value: "GBP", disabled: true },
  { name: "USD", value: "USD", disabled: true },
];

const currencySymbolMap: { [x: string]: string } = {
  NGN: "₦",
  GBP: "£",
  USD: "$",
};

const SECURITY_DEPOSIT_OPTIONS = [
  { value: "5", name: "5" },
  { value: "10", name: "10" },
  { value: "15", name: "15" },
  { value: "20", name: "20" },
];

export const PricingStep = ({
  saveContinue,
  values,
  handleChange: onChange,
  saving,
}: any) => {
  const rates = [
    { label: "Hour", type: "hourly" },
    { label: "Daily", type: "daily" },
    { label: "Weekly", type: "weekly" },
    { label: "Monthly", type: "monthly" },
  ];

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <div>
          <h2>Set a price for your space</h2>
          <p className="sub-title">
            Set a price for your space to get more visibility to your space
          </p>
        </div>
        <CustomButton
          children={"Save and Exit"}
          type={"link"}
          className="save-continue-btn"
          onClick={saveContinue}
          disabled={saving}
        />
      </div>

      <div className="container-wrapper">
        <div className="content-wrapper">
          <SelectInput
            name="minHoursOption"
            label="Minimum Hour"
            options={MIN_HOURS_OPTIONS}
            value={values.minHoursOption}
            onChange={onChange}
          />
          <SelectInput
            name="currency"
            label="Currency"
            options={CURRENCY_OPTIONS}
            value={values.currency}
            onChange={onChange}
          />

          {rates.map((input) => (
            <div className="page-input-field" key={input.type}>
              <label>{input.label}</label>
              <Input
                size="large"
                className="page-input-filed-input-wrapper"
                placeholder=""
                value={values.pricing[input.type]}
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "pricing",
                      value: {
                        ...values.pricing,
                        [input.type]: e.target.value,
                      },
                    },
                  });
                }}
                suffix={
                  <span>
                    {currencySymbolMap[values?.currency] ??
                      currencySymbolMap["NGN"]}
                  </span>
                }
                type="number"
              />
            </div>
          ))}
        </div>

        <Checkbox.Group
          style={{
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          name="daysOpen"
          onChange={(val) => {
            onChange({ target: { name: "daysOpen", value: val } });
          }}
          defaultValue={values.daysOpen}
        >
          <Checkbox value="Sun">Sun</Checkbox>
          <Checkbox value="Mon">Mon</Checkbox>
          <Checkbox value="Tue">Tue</Checkbox>
          <Checkbox value="Wed">Wed</Checkbox>
          <Checkbox value="Thu">Thu</Checkbox>
          <Checkbox value="Fri">Fri</Checkbox>
          <Checkbox value="Sat">Sat</Checkbox>
        </Checkbox.Group>
      </div>

      {/* <Row>
        <Col span={6}>
          <CustomButton
            children={open.services ? "Save" : "Add more"}
            icon={<PlusOutlined />}
            // className={styles.btn}
            onClick={open.services ? handleAddServices : handleAddServices}
          />
        </Col>
      </Row> */}
      <div className="deposit">
        <h2>Security Deposit</h2>
        <SelectInput
          name="securityDepositPer"
          label="Deposit percentage"
          options={SECURITY_DEPOSIT_OPTIONS}
          value={values.securityDepositPer}
          onChange={onChange}
        />
      </div>
      <div className="steps-remaining">
        <img src={tickCircle} alt="tickCircle" className="hover" />
        <p>Only 4 steps left!</p>
      </div>
    </div>
  );
};
