import React from "react";
import CustomSelect from "../customSelect";
import styles from "./index.module.scss";
import { Select, Space } from "antd";

interface Option {
   [key: string]: string;
}

interface ISelectTime {
   rangeType: string;
   onChange: (value: string) => void;
   className?: string;
   header?: string;
   options?: Option[];
   error?: string | undefined | number | boolean;
   onBlur?: () => void;
   value?: string
   name?: string
}

const SelectTime = ({ rangeType, onChange, className, header, options, error, onBlur, value, name }: ISelectTime) => {
   return (
      <div className={styles.page}>
         {header && <h1 className={styles.heading}>{header}</h1>}
         <div className={styles.selectContainer}>
            <div className={styles.range}>{rangeType}</div>
            <div className={styles.select}>
               <Space style={{ width: "100%" }} direction='vertical' wrap>
                  <Select
                     showSearch
                     virtual={false}
                     allowClear
                     bordered={false}
                     style={{ width: "100%" }}
                     onChange={onChange}
                     onBlur={onBlur}
                     className={className}
                     options={options}
                     value={value}
                  />
               </Space>
            </div>       
         </div>
         {error && <p className={styles.error}>{error}</p>}
      </div>
   );
};

export default SelectTime;
