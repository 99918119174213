import React, { useState, useEffect, useRef } from "react";
import "./setupPage.scss";

import arrowDown from "@/assets/svgs/arrow-down.svg";
import countryData from "@/assets/data/countries+states+cities.json";
import cloud from "@/assets/svgs/cloud-add.svg";
import greentick from "@/assets/svgs/green-tick.svg";
import { CustomButton } from "@/components/customButton";
import { Col, Row } from "antd";
import CustomSelect from "@/components/customSelect";
import { CustomInputField } from "@/components/customInput";
import TimePickerComponent from "@/components/customTimePicker";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport"; // ES 2015
dayjs.extend(objectSupport);

export const PageSix = ({
  selectedActivities,
  setSelectedActivities,
  selectedDates,
  values,
  setValue,
  saving,
  saveContinue,
}: any) => {
  const [open, setOpen] = useState({
    services: false,
    amenities: false,
  });

  const handleAddServices = () => {
    setOpen({
      ...open,
      services: true,
    });
  };
  // Define function to handle selection of space activity
  const handleSelectActivity = (index: number) => {
    const selectedActivity = spaceActivities[index];
    const isSelected = selectedActivities.includes(selectedActivity);

    if (isSelected) {
      // If activity is already selected, remove it from the array
      setSelectedActivities(
        selectedActivities.filter(
          (activity: any) => activity !== selectedActivity
        )
      );
    } else {
      // Otherwise, add it to the array
      setSelectedActivities([...selectedActivities, selectedActivity]);
    }
  };

  const handleDateChange = (date: string) => {
    if (values.availableDays.includes(date)) {
      // If the date is already selected, remove it from the array
      setValue(
        "availableDays",
        selectedDates.filter((d: string) => d !== date)
      );
    } else {
      // If the date is not selected, add it to the array
      setValue("availableDays", [...values.availableDays, date]);
    }
  };

  const spaceActivities = [
    "Content Creation",
    "Podcast studio",
    "Party space",
    "Event Spaces",
    "Filming venues",
    "Outdoor wedding",
    "Video shooting venues",
    "Work meetings",
    "Other",
  ];

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <CustomButton
          children={"Save and Exit"}
          type={"link"}
          className="save-continue-btn"
          onClick={saveContinue}
          disabled={saving}
        />
      </div>
      <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <h2>Upload your space picture</h2>
          <p className="sub-title">
            Add images of your workspace. For the best results, follow the
            instructions below;
          </p>

          <div className="double-list">
            <ul>
              <li>Upload a minimum of 4 images</li>
              <li>Ensure your images are horizontal</li>
              <li>Screenshots are not allowed</li>
            </ul>
            <ul>
              <li>We recommend uploading up to 10 images</li>
              <li>Ensure you upload coloured photos, not Black and white</li>
              <li>Ensure yor images are of high quality</li>
            </ul>
          </div>
          <ImageUploader
            uploadedImages={values.spacePictures}
            setUploadedImages={(images: unknown) => {
              setValue("spacePictures", images);
            }}
          />
        </Col>
      </Row>
      <hr />
      <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <h2>Add space operating hours</h2>
          <div className="page-input-field">
            <label>Choose your open days and hours for your space.</label>
            <div className="date-input-field">
              <div className="page-input-field-double ">
                <Row gutter={[45, 24]}>
                  <Col md={12} lg={12} xs={24}>
                    <TimePickerComponent
                      placeholder="16:00"
                      selectedTime={dayjs(values.openingTime, "HH:mm")}
                      size="large"
                      onTimeChange={(dayjs) => {
                        if (dayjs)
                          setValue(
                            "openingTime",
                            dayjs.hour() + ":" + dayjs.minute()
                          );
                      }}
                      label="Open At"
                      className="time-picker-wrapper page-input-filed-input-wrapper"
                    />
                  </Col>

                  <Col md={12} lg={12} xs={24}>
                    <TimePickerComponent
                      placeholder="Select Time"
                      selectedTime={dayjs(values.closingTime, "HH:mm")}
                      size="large"
                      onTimeChange={(item) => {
                        if (item)
                          setValue(
                            "closingTime",
                            item.hour() + ":" + item.minute()
                          );
                      }}
                      label="Close At"
                      className="time-picker-wrapper page-input-filed-input-wrapper"
                    />
                  </Col>
                </Row>
              </div>
              <div className="date-input-wrapper">
                {[
                  "sunday",
                  "monday",
                  "tuesday",
                  "wednesday",
                  "thursday",
                  "friday",
                  "saturday",
                ].map((item, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      checked={values.availableDays.includes(item)}
                      onChange={() => handleDateChange(item)}
                      title="Day"
                    />
                    <span className="capitalize">{item.slice(0, 3)}</span>
                  </div>
                ))}
              </div>
            </div>

            {open.services && (
              // <div className={styles.addNewService}>
              <div className={""}>
                {/* <Divider orientation="left"></Divider> */}

                <Row gutter={[24, 24]} align="bottom" wrap>
                  <Col md={6} lg={8} xs={12}>
                    <p>Hello new</p>
                    {/* <CustomSelect
                    label="Space Type"
                    placeHolder="Select a Space type"
                    // options={SPACE_TYPES}
                    allowClear={false}
                    showSearch={false}
                    onChange={(value) => {
                      setNewService({
                        ...newService,
                        space_type: value,
                      });
                    }}
                    name={"space_type"}
                  /> */}
                  </Col>
                </Row>
              </div>
            )}

            {/* another one */}
            {/* <Row>
              <Col span={6}>
                <CustomButton
                  children={open.services ? "Save" : "Add more"}
                  icon={<PlusOutlined />}
                  // className={styles.btn}
                  // onClick={
                  //   open.services ? handleAddServices : handleAddServices
                  // }
                />
              </Col>
            </Row> */}
          </div>
        </Col>
      </Row>
      {/* <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <h2>Choose pricing type</h2>

          <CustomSelect
            options={pricingTypex}
            placeHolder="Select your Position"
            label={"Choose pricing type"}
            // onChange={(e) => {
            //   formik.setFieldValue("jobRole", e);
            // }}
            value={pricingType}
            onSelect={handlePriceType}
          />


        </Col>
      </Row> */}
      {/* <div className="page-input-field">
        <label>Tell us about ...</label>
        <div className="page-input-filed-input-wrapper">
          <select
            value={pricingType}
            onChange={(e) => {
              setPricingType(e.target.value);
            }}
          >

          </select>
          <img src={arrowDown} alt="search" className="hover clicker " />
        </div>
      </div> */}

      {/* <h2>Add base pricing</h2>
      <div className="page-input-field">
        <label>Tell us about ...</label>

        <div className="page-input-filed-input-wrapper">
          <input
            type="number"
            placeholder="e.g 2000"
            value={basePrice}
            onChange={(e) => {
              setBasePrice(e.target.value);
            }}
          />
        </div>
      </div> */}
    </div>
  );
};

const ImageUploader = ({ uploadedImages, setUploadedImages }: any) => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleImageUpload = (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const imagesArray: { src: string; title: string; size: string }[] = [];
    const uploadPromises: Promise<unknown>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      const uploadPromise = new Promise((resolve, reject) => {
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target!.result as string;

          img.onload = () => {
            if (img.width > img.height) {
              // Image is horizontal, proceed with upload
              imagesArray.push({
                src: img.src,
                title: file.name,
                size: getFileSize(file.size),
              });
              resolve({});
            } else {
              // Image is not horizontal, display error message
              reject("Please upload horizontal images only.");
            }
          };
        };

        reader.readAsDataURL(file);
      });

      uploadPromises.push(uploadPromise);
    }

    Promise.all(uploadPromises)
      .then(() => {
        setUploadedImages([...uploadedImages, ...imagesArray]);
        setErrorMessage("");
        console.log("All images uploaded successfully");
        // Perform additional upload logic here if needed
      })
      .catch((error) => setErrorMessage(error));
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleImageUpload(files);
  };

  const handleBrowseClick = () => {
    document.getElementById("fileInput")?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const acceptedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!acceptedTypes.includes(file.type)) {
          setErrorMessage("Please upload PNG, JPEG, or JPG files only.");
          return;
        }
        if (file.size > maxSize) {
          setErrorMessage(`Maximum file size exceeded (${maxSize} bytes).`);
          return;
        }
      }
      setErrorMessage("");
      handleImageUpload(files);
    }
  };

  const handleDeleteImage = (index: number) => {
    const newImages = [...uploadedImages];
    newImages.splice(index, 1);
    setUploadedImages(newImages);
  };

  const getFileSize = (bytes: number) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  };

  return (
    <div className="image-uploader-parent">
      <div
        className="image-uploader"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <img src={cloud} alt="upload" className="cloud-svg" />
        <p className="bold-p">Choose a file or drag & drop it here</p>
        <p className="faint-p">PNG, JPEG, or JPG files up to {100}</p>
        <button
          type="button"
          onClick={handleBrowseClick}
          className="cancel browseFiles"
        >
          Browse Files
        </button>
        <input
          title="Image Upload"
          id="fileInput"
          type="file"
          className="hidden"
          onChange={handleFileChange}
          accept=".png,.jpg,.jpeg" // Limit accepted file types
          multiple // Allow multiple file selection
        />
      </div>
      {errorMessage && <p className="text-red-600">{errorMessage}</p>}
      <div className="uploaded-images-parent">
        {uploadedImages.map((imageData: any, index: number) => (
          <div className="uploaded-image-div" key={index} style={{}}>
            <button
              onClick={() => handleDeleteImage(index)}
              style={{
                position: "absolute",
                top: "15px",
                right: "30px",
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "red",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <img src={imageData.src} alt={`Uploaded ${index}`} />
            <div className="uploaded-image-data">
              <p className="title">{imageData.title}</p>
              <div className="bottom">
                <p>
                  {imageData.size} of {imageData.size}.
                </p>
                <div className="status">
                  <img src={greentick} alt="" />
                  <p>Completed</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
