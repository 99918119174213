import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IGetHostWorkSpaces,
  IHostWorkSpaceDetails,
} from "../../interfaces/apiResponse";

interface HostWorkspaceState {
  workspace: IHostWorkSpaceDetails | null;
  isEditing: boolean;
  workspaceIsLoading: boolean;
  workspaceIsError: boolean;
  workspaces: IWorkspacesPayload | null;
  workspacesIsLoading: boolean;
  workspacesIsError: boolean;
  searchValue: string;
  location: string;
  workspaceFilter: string;
}

export interface IWorkspacesPayload {
  data: IGetHostWorkSpaces[] | undefined;
  currentPage: string | number;
  pageSize: string | number;
  totalPages: string | number;
}

const initialState: HostWorkspaceState = {
  workspace: null,
  isEditing: false,
  workspaceIsLoading: false,
  workspaceIsError: false,
  workspaces: null,
  workspacesIsLoading: false,
  workspacesIsError: false,
  searchValue: "",
  location: "",
  workspaceFilter: "",
};

export const hostWorkspaceSlice = createSlice({
  name: "hostWorkSpaceSlice",
  initialState: initialState,
  reducers: {
    clearHostWorkspaceData: () => initialState,

    setEditModeOn: (state, action) => {
      state.isEditing = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setWorkspaceFilter: (state, action) => {
      state.workspaceFilter = action.payload;
    },
  },
});

export const {
  clearHostWorkspaceData,
  setEditModeOn,
  setSearchValue,
  setLocation,
  setWorkspaceFilter,
} = hostWorkspaceSlice.actions;
export default hostWorkspaceSlice.reducer;
