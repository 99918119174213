import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { fetchBanks } from "@/redux/actions/walletActions";
import { withdrawFunds } from "@/services/networkRequests/walletService";
import { toast } from "react-toastify";
import { useMe } from "@/data/user";

const initialValues = {
  account_number: "",
  bank_name: "",
  bank_code: "",
  bank_id: "",
  currency: "",
  type: "",
  account_name: "",
  privacy_policy: false,
  amount: "",
};

export enum types {
  add_payment = "Add payment",
  withdraw_funds = "Withdraw funds",
}

export type TypeProp = typeof types;

const useWallet = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState<string>("");
  const { me: loggedInUser } = useMe();
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [progress, setProgress] = useState(0);
  const { banks, banksIsLoading, banksIsError } = useAppSelector(
    (state) => state.wallet
  );

  const dispatch = useAppDispatch();

  const handleOpenModal = (type: string) => {
    setType(type);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleWithdrawFunds = async (values: any) => {
    try {
      setLoading(false); // Start loading spinner
      setProgress(0);
      const withdrawFundsResponse = await withdrawFunds(values);
      console.log(withdrawFundsResponse);
      if (withdrawFundsResponse?.status === "success") {
        toast.success("Transfer in progress");
        setModalOpen(false);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setProgress(2);
    } finally {
      setLoading(true); // Stop loading spinner
      setProgress(1);
    }
  };

  console.log(loggedInUser);
  useEffect(() => {
    if (modalOpen && type === types.add_payment && banks.length === 0) {
      dispatch(fetchBanks());
    }
  }, [modalOpen]);

  useEffect(() => {
    if (
      modalOpen &&
      type === types.withdraw_funds &&
      !!loggedInUser?.kyc_verification
    ) {
      // if (modalOpen && type === types.withdraw_funds) {
      setIsActive(false);
    }
  }, [modalOpen]);

  return {
    modalOpen,
    handleOpenModal,
    handleCloseModal,
    type,
    banks,
    banksIsLoading,
    banksIsError,
    initialValues,
    handleWithdrawFunds,
    types,
    loggedInUser,
    isActive,
    progress,
  };
};

export default useWallet;
