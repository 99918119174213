import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";

type ComponentProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  ariaLabel?: string;
  close?: () => void;
  hasInput?: boolean;
  modalTitle?: string;
  modalDescription?: string;
  css?: string;
};

type StyleProps = {
  overlayShade?: "light" | "dark";
};

type Props = StyleProps & ComponentProps;

type DivElement = HTMLDivElement;

export const Dialog = React.forwardRef<DivElement, Props>((props, ref) => {
  const {
    children,
    isOpen,
    ariaLabel,
    overlayShade = "dark",
    close,
    hasInput = true,
    modalTitle,
    modalDescription,
    ...rest
  } = props;

  const handleKeyPress = (event: React.KeyboardEvent) => {
    const callback = {
      Escape: close,
    }[event.key];

    !hasInput && event.preventDefault();
    callback?.();
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // if the modal doesn't have an input, don't auto focus the modal, in most cases, there will be an input so I defaulted to true
    if (wrapperRef.current && isOpen && !hasInput) {
      wrapperRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className={styles.dialog}>
      {isOpen && (
        <aside
          role="dialog"
          className={styles.modal}
          aria-labelledby={modalTitle}
          aria-describedby={modalDescription}
          {...rest}
        >
          <div
            ref={wrapperRef}
            onKeyDown={handleKeyPress}
            tabIndex={0}
            role="presentation"
          >
            <div
              onClick={close}
              role="dialog"
              className={
                overlayShade === "dark"
                  ? [styles.overlay, styles.dark].join(" ")
                  : [styles.overlay].join(" ")
              }
            />

            <div
              ref={ref}
              role="dialog"
              id="content"
              aria-modal="true"
              aria-label={ariaLabel}
              className={styles.content}
            >
              {children}
            </div>
          </div>
        </aside>
      )} 
    </div>
  );
});
