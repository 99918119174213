import React from "react";
import type { DatePickerProps } from "antd";
import { Col, Row, Divider, Checkbox, DatePicker } from "antd";
import styles from "./index.module.scss";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

interface IDatePickerProps {
  label?: string;
  secLabel?: string;
  placeholder?: string;
  value?: Dayjs | null | undefined;
  onChange?: DatePickerProps["onChange"];
  onBlur?: DatePickerProps["onBlur"];
  error?: string | undefined | number | boolean;
  minDate?: Dayjs;
}

const CustomDatePicker = ({
  label,
  secLabel,
  placeholder,
  onChange,
  onBlur,
  error,
  value,
  minDate,
}: IDatePickerProps) => {
  // handle date change with custom format using dayjs
  const disabledDate = (current: Dayjs | null) => {
    if (!current || !minDate) {
      return false;
    }
    return current.isBefore(minDate, "day");
  };

  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      {secLabel && <p className={styles.secondaryLabel}>{secLabel}</p>}
      <DatePicker onChange={onChange} className={styles.datepicker} onBlur={onBlur} disabledDate={disabledDate} value={value}/>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default CustomDatePicker;
