import React from "react";
import "./setupPage.scss";

import { CustomButton } from "@/components/customButton";
import { Col, Row, Space, Checkbox } from "antd";
import { CustomInputField } from "@/components/customInput";
import CustomTextArea from "@/components/customTextArea";
import AgeRestriction from "./AgeRestriction";

import { Radio, RadioGroup } from "@headlessui/react";

export const StepFour = ({
  saveContinue,
  values,
  handleChange,
  saving,
}: any) => {
  // State variables to track the active selections
  const ageRange = ["0-12", "13-18", "19-30", "31-50", "51+"];

  const handleSelectAgeRange = (index: number) => {
    const selectedActivity = ageRange[index];
    const isSelected = values.agesAllowed.includes(selectedActivity);

    if (isSelected) {
      // If activity is already selected, remove it from the array
      handleChange({
        target: {
          name: "agesAllowed",
          value: values.agesAllowed.filter(
            (age: string) => age !== selectedActivity
          ),
        },
      });
    } else {
      // Otherwise, add it to the array
      handleChange({
        target: {
          name: "agesAllowed",
          value: [...values.agesAllowed, selectedActivity],
        },
      });
    }
  };

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <CustomButton
          children={"Save and Exit"}
          type={"link"}
          className="save-continue-btn"
          onClick={saveContinue}
          disabled={saving}
        />
      </div>
      <h3>Add space title</h3>
      <p className="sub-title">
        Choose a unique space title to get more visibility to your space
      </p>
      <ul>
        <li>Name should be company name</li>
        <li>
          Title should clearly describe the activities that the space allows
        </li>
        <li>Title can describe unique features of the space</li>
        <li>[Example title: All white elegant studio with rooftop space]</li>
      </ul>
      <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <CustomInputField
            type={"text"}
            label="Space Title"
            name="spaceTitle"
            placeholder="Enter Space Title"
            value={values.spaceTitle}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Space direction="vertical" size="middle" className="flex"></Space>
      <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <CustomTextArea
            label="About this space"
            name="about"
            placeholder="Type here"
            value={values.about}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Space direction="vertical" size="middle" className="flex"></Space>
      <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <CustomInputField
            name="numberOfPeoples"
            type={"numeric"}
            label="How many guests can this space accommodate"
            placeholder="E.g 5"
            value={values.numberOfPeoples}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <CustomTextArea
            label="Enter space rules"
            name="spaceRules"
            placeholder="Type here"
            value={values.spaceRules}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {/* <Row gutter={[45, 24]}>
        <Col md={24} xs={24}>
          <OptionButton
            title="Are there age restrictions for this space?"
            onClick={() => toggleSelection(setAgeRestriction)}
            optionType={ageRestriction}
            optionOne="Yes"
            optionTwo="No"
          />
        </Col>
      </Row> */}

      <div className="flex flex-col gap-y-6">
        <h3>Are there age restrictions for this space?</h3>
        <RadioGroup
          name="hasAgeRestriction"
          value={values.hasAgeRestriction}
          onChange={(value) => {
            handleChange({ target: { name: "hasAgeRestriction", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      {values.hasAgeRestriction && (
        <AgeRestriction
          ageRange={ageRange}
          allowedAges={values.agesAllowed}
          handleSelectActivity={handleSelectAgeRange}
        />
      )}

      <div className="amenities">
        <h3>What type of general studio facilities do you have?</h3>
        <Checkbox.Group
          style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
          onChange={(val) => {
            handleChange({ target: { name: "facilities", value: val } });
          }}
          defaultValue={values.facilities}
        >
          <Row gutter={[8, 8]} wrap>
            <Col md={24} xs={24}>
              <Checkbox value="conference-room">Conference room</Checkbox>
            </Col>
            <Col md={24} xs={24}>
              <Checkbox value="air-conditioning">Air conditioning </Checkbox>
            </Col>
            <Col md={24} xs={24}>
              <Checkbox value="green-room">Green room </Checkbox>
            </Col>
            <Col md={24} xs={24}>
              <Checkbox value="hair">Hair/Makeup area</Checkbox>
            </Col>
            <Col md={24} xs={24}>
              <Checkbox value="outdoor">Outdoor space</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </div>
    </div>
  );
};
