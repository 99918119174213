import React, { CSSProperties } from "react";
import styles from "./preview.module.scss";
import book from "../../assets/svgs/bookinPreview.svg";
import { Button } from "antd";
import { CustomButton } from "@/components/customButton";

export interface IPreview {
  bodyStyle?: string | undefined;
  image: string;
  bookingStyle?: string | undefined;
  bookingText?: string;
  labelStyle?: string | undefined;
  labelText?: string;
}

function Preview({
  bodyStyle,
  image,
  bookingStyle,
  bookingText,
  labelStyle,
  labelText,
}: IPreview) {
  return (
    <div className={bodyStyle ? bodyStyle : styles.preview}>
      <div>
        <img src={image} alt="image" />
      </div>
      <div className={bookingStyle ? bookingStyle : styles.booking}>
        {bookingText}
      </div>
      <div className={labelStyle ? labelStyle : styles.label}>{labelText} </div>
      <CustomButton
        children={"Start a message"}
        extraStyles={{
          width: "30%",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      />
    </div>
  );
}

export default Preview;
