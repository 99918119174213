import { GetParams } from "@/types";
import { HttpClient } from "./http-client";
import { API_ENDPOINTS } from "./api-endpoints";
import {
  CreateSpacePayload,
  Recommended,
  UpdateSpacePayload,
  Workspace,
  WorkspacePaginator,
  WorkspaceQueryOptions,
} from "@/types/workspace";
import {
  ActivitiesPaginator,
  ActivitiesQueryOptions,
} from "@/types/activities";
import { CategoriesPaginator, CategoriesQueryOptions } from "@/types/category";
import {
  TransactionPaginator,
  TransactionQueryOptions,
} from "@/types/transaction";
import { AddBankPayload, Wallet, WithdrawFundsPayload } from "@/types/wallet";
import {
  BookingPaginator,
  BookingQueryOptions,
  CreateBookingPayload,
  CreateBookingResponse,
} from "@/types/booking";
import { User } from "@/types/user";
import {
  CreateSubscriptionPayload,
  PaymentMethod,
  Subscription,
} from "@/types/subscription";

class Client {
  workspace = {
    get: ({ id: workspaceId }: GetParams) => {
      return HttpClient.get<{ data: Workspace }>(
        `${API_ENDPOINTS.BASE}${API_ENDPOINTS.WORKSPACE.ROOT}/${workspaceId}`
      );
    },
    all: async ({
      // categories,
      // tags,
      // name,
      // shop_id,
      // price,
      ...query
    }: Partial<WorkspaceQueryOptions> = {}) => {
      const result = await HttpClient.get<{ data: WorkspacePaginator }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WORKSPACE.ALL,
        {
          // searchJoin: "and",
          // with: "shop",
          // orderBy: "updated_at",
          // sortedBy: "ASC",
          ...query,
          pageSize: query.limit,
          // search: HttpClient.formatSearchParams({
          //   categories,
          //   tags,
          //   name,
          //   shop_id,
          //   price,
          //   status: "publish",
          // }),
        }
      );
      console.log({ result });

      return result.data;
    },
    paginated: async ({
      // categories,
      // tags,
      // name,
      // shop_id,
      // price,
      ...query
    }: Partial<WorkspaceQueryOptions> = {}) => {
      const result = await HttpClient.get<{ data: WorkspacePaginator }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WORKSPACE.ALL,
        {
          // searchJoin: "and",
          // with: "shop",
          // orderBy: "updated_at",
          // sortedBy: "ASC",
          ...query,
          pageSize: query.limit,
          // search: HttpClient.formatSearchParams({
          //   categories,
          //   tags,
          //   name,
          //   shop_id,
          //   price,
          //   status: "publish",
          // }),
        }
      );
      console.log({ result });

      return result.data;
    },
    me: async ({ ...query }: Partial<WorkspaceQueryOptions> = {}) => {
      const result = await HttpClient.get<{ data: WorkspacePaginator }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WORKSPACE.ME,
        {
          ...query,
        }
      );
      console.log({ result });

      return result.data;
    },
    my: ({ id: workspaceId }: GetParams) => {
      return HttpClient.get<{ data: Workspace }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WORKSPACE.MY + workspaceId
      );
    },
    update({ id, ...input }: Partial<UpdateSpacePayload> & { id: string }) {
      return HttpClient.patch(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WORKSPACE.ROOT + "/" + id,
        input
      );
    },
    create({ ...input }: CreateSpacePayload) {
      return HttpClient.post<{ data: Workspace }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WORKSPACE.ROOT,
        input
      );
    },
  };
  activities = {
    paginated: async ({ ...query }: Partial<ActivitiesQueryOptions> = {}) => {
      return await HttpClient.get<ActivitiesPaginator>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.ACTIVITIES.BASE,
        {
          // searchJoin: "and",
          // with: "shop",
          // orderBy: "updated_at",
          // sortedBy: "ASC",
          ...query,
          // search: HttpClient.formatSearchParams({
          //   categories,
          //   tags,
          //   name,
          //   shop_id,
          //   price,
          //   status: "publish",
          // }),
        }
      );
    },
  };
  categories = {
    paginated: async ({ ...query }: Partial<CategoriesQueryOptions> = {}) => {
      return await HttpClient.get<CategoriesPaginator>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.CATEGORIES,
        {
          // searchJoin: "and",
          // with: "shop",
          // orderBy: "updated_at",
          // sortedBy: "ASC",
          ...query,
          // search: HttpClient.formatSearchParams({
          //   categories,
          //   tags,
          //   name,
          //   shop_id,
          //   price,
          //   status: "publish",
          // }),
        }
      );
    },
  };
  recommended = {
    get: ({ id: workspaceId }: GetParams) => {
      return HttpClient.get<{ data: { data: Recommended[] } }>(
        `${API_ENDPOINTS.BASE}${API_ENDPOINTS.RECOMMENDED.GET}/${workspaceId}`
      );
    },
  };
  transactions = {
    paginated: ({ ...params }: Partial<TransactionQueryOptions>) => {
      return HttpClient.get<TransactionPaginator>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.TRANSACTIONS,
        {
          ...params,
        }
      );
    },
  };
  wallet = {
    remove_bank: (accountId: string) => {
      return HttpClient.delete(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WALLET.BANK_ACCOUNT + "/" + accountId
      );
    },
    set_preferred_bank: (accountId: string) => {
      return HttpClient.post(
        API_ENDPOINTS.BASE +
          API_ENDPOINTS.WALLET.PREFERRED_ACCOUNT +
          "/" +
          accountId,
        {}
      );
    },
    me: () => {
      return HttpClient.get<{ data: Wallet }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WALLET.ME
      );
    },
    add_bank: (input: AddBankPayload) => {
      return HttpClient.post(
        API_ENDPOINTS.BASE + API_ENDPOINTS.ADD_WITHDRAWAL_BANK,
        input
      );
    },
    withdraw_fund: (input: WithdrawFundsPayload) => {
      return HttpClient.post(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WALLET.WITHDRAW_FUNDS,
        input
      );
    },
    add_payment_method: (input: { reference: string }) => {
      return HttpClient.post(
        API_ENDPOINTS.BASE + API_ENDPOINTS.WALLET.PAYMENT_METHOD,
        input
      );
    },
  };
  bookings = {
    create: (payload: CreateBookingPayload) => {
      return HttpClient.post<{ data: CreateBookingResponse }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.BOOKINGS.INITIATE,
        payload
      );
    },
    complete_booking: (payload: { booking_id: string; reference: string }) => {
      return HttpClient.post(
        API_ENDPOINTS.BASE + API_ENDPOINTS.BOOKINGS.COMPLETE_BOOKING,
        payload
      );
    },
    paginated: ({ ...params }: Partial<BookingQueryOptions>) => {
      return HttpClient.get<BookingPaginator>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.BOOKINGS.ME,
        {
          ...params,
        }
      );
    },
  };
  users = {
    me: () => {
      return HttpClient.get<{ data: User }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.USERS_ME
      );
    },
  };
  subscriptions = {
    payment_methods: () => {
      return HttpClient.get<{ data: PaymentMethod[] }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.SUBSCRIPTIONS.PAYMENT_METHODS
      );
    },
    create: (input: CreateSubscriptionPayload) => {
      return HttpClient.post(
        API_ENDPOINTS.BASE + API_ENDPOINTS.SUBSCRIPTIONS.BASE,
        input
      );
    },
    me: () => {
      return HttpClient.get<{ data: Subscription }>(
        API_ENDPOINTS.BASE + API_ENDPOINTS.SUBSCRIPTIONS.ME
      );
    },
  };
}

export default new Client();
