import React from "react";
import { TimePicker } from "antd";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import styles from "./index.module.scss";

interface TimePickerComponentProps {
  label?: string;
  selectedTime: Dayjs | null;
  defaultOpenValue?: Dayjs | undefined;
  onTimeChange: (newTime: Dayjs | null) => void;
  error?: string | undefined | number | boolean;
  className?: string;
  placeholder?: string;
  name?: string;
  size?: any;
  disableHours?: number[];
}

const TimePickerComponent: React.FC<TimePickerComponentProps> = ({
  label,
  selectedTime,
  onTimeChange,
  error,
  placeholder,
  defaultOpenValue,
  className,
  name,
  size,
  disableHours = [],
}) => {
  const handleTimeChange = (newTime: Dayjs | null) => {
    onTimeChange(newTime);
  };

  return (
    <div className="container">
      {label && <label className={styles.label}>{label}</label>}
      <TimePicker
        size={size}
        value={selectedTime}
        suffixIcon={null}
        onChange={handleTimeChange}
        minuteStep={30}
        defaultOpenValue={defaultOpenValue}
        placeholder={placeholder}
        name={name}
        // minuteStep={30}
        className={className} // Apply the same styling class
        format="hh:mm A" // Set the time format to 'hh:mm A' for 12-hour format with AM/PM
        use12Hours // Enable 12-hour format with AM/PM selection
        disabledHours={() => disableHours}
        renderExtraFooter={() => null} // Removes the footer - doesn't work
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default TimePickerComponent;
