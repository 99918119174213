import React, { Fragment, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import "./index.module.scss";

import { SearchBar } from "@/components/a";
import workspace from "@/assets/img/workspace.png";
import arrowright from "@/assets/svgs/arrow-long-right.svg";
import arrowrightlight from "@/assets/svgs/heroicons-outline.svg";
import arrowrightblack from "@/assets/svgs/arrowblack.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import hostlisting from "@/assets/img/host.png";
import discountCoupons from "@/assets/img/discountCoupons.svg";
import signUp from "@/assets/svgs/signUp.svg";
import publicSpace from "@/assets/svgs/publicSpace.svg";
import receiveBooking from "@/assets/svgs/receiveBooking.svg";
import enterSpace from "@/assets/svgs/enterSpace.svg";

import titanium from "@/assets/img/titanium.svg";

import hostConsulting from "@/assets/img/host-two.png";
import hostIcon from "@/assets/img/host-icon.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { TabTitle } from "@/utils/titleFunction";
import {
  FeaturedCities,
  FeaturedCities1,
  FeaturedCities2,
  Property1,
  Property2,
  reimagine_one,
  reimagine_two,
} from "@/assets/img/";
import {
  setLocation,
  setWorkspaceFilter,
} from "@/redux/reduxSlice/hostWorkSpacesSlice";
import { useDispatch } from "react-redux";

import { Spin } from "antd";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { useMe } from "@/data/user";

const Listing: React.FC = () => {
  const navigate = useNavigate();

  const { me: loggedInUser } = useMe();

  return (
    <div className={styles.main}>
      {/* Reimagine your space */}
      <section className={styles.firstx}>
        <div className={styles.reimagineBox}>
          <div className={styles.reimagineTexts}>
            <h2 className={styles.head}>Reimagine your space and earn</h2>
            <p className={styles.text}>
              Put your space to work and earn extra income by creatively
              repurposing your space to host personal and professional
              gatherings, shoots, etc. Leverage our targeted and quality
              audience to increase your visibility, rapidly boost sales and
              repeated patronage.
            </p>
            <Link
              to={
                loggedInUser && loggedInUser?.role_type === "Host"
                  ? "/space-description"
                  : "/register"
              }
            >
              <button type="button" className={styles.btn}>
                List your space
              </button>
            </Link>
          </div>
          <div className={styles.card}>
            <div className={styles.cardBackground}></div>
            <div className={styles.cardBackground1}></div>
            <div className={styles.content}>
              <div className={styles.front}>
                <div className={styles.cardMedia}>
                  <img src={reimagine_one} alt="Restaurant" />
                </div>
                <div className={styles.textCard}>
                  <p>It is not just a Restaurant by the sea</p>
                </div>
              </div>
              <div className={styles.back}>
                <div className={styles.cardMedia}>
                  <img src={reimagine_two} alt="Room" />
                </div>
                <div className={styles.textCard1}>
                  <p>It is also a Fashion Photo Shoot location</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Book your space */}
      <section className={styles.fourthx}>
        <h2>Listing your space is easy!</h2>
        <div className={styles.spaceproperties}>
          <div>
            <img src={signUp} alt="" />
            <h4>Sign up</h4>
            <p>Sign up on the website. This is the first step to... </p>
            <hr></hr>
          </div>
          <div>
            <img src={enterSpace} alt="" />
            <h4>Enter space details</h4>
            <p>Search and find your space with our smart search </p>
            <hr></hr>
          </div>
          <div>
            <img src={publicSpace} alt="" />
            <h4>Make space public</h4>
            <p>Our team will review and confirm your space to go live. </p>
            <hr></hr>
          </div>
          <div>
            <img src={receiveBooking} alt="" />
            <h4>Receive bookings</h4>
            <p>Search and find your space with our smart search </p>
            <hr></hr>
          </div>
        </div>
        <Link
          to={
            loggedInUser && loggedInUser?.role_type === "Host"
              ? "/space-description"
              : "/register"
          }
        >
          <button type="submit" className={styles.btn}>
            Start Listing now
          </button>
        </Link>
      </section>
      {/* perfect space your space */}
      <section className={styles.fifthx}>
        <h2>CO-SPIRE your partner in efficiency and revenue</h2>
        <div className={styles.perfecttop}>
          <div className={styles.topText}>
            <h2>Easy Recieve and Monitor Payments</h2>
            <p className={styles.text}>
              Effectively track your customer data and their booking history for
              each of your workspace and amenities, accept payments and generate
              invoices at the snap of a finger and enjoy effective relationships
              with your customers totally hassle-free!.
            </p>
          </div>

          <div className={styles.perfectspace}></div>
        </div>
        <div className={styles.perfectbottom}>
          <div className={styles.perfectbottomleft}>
            <div className={styles.bottomText}>
              <h2>Boost Sales</h2>
              <p
                className={styles.text}
                style={{
                  color: "#fff",
                }}
              >
                With clear hourly rates and no surprise fees, you’ll know what
                you’re paying for, right from the get-go.
              </p>
            </div>

            {/* <div className={styles.perfectspacebottomleft}></div> */}
            <img
              src={discountCoupons}
              className={styles.img}
              alt="Monetize your space"
              style={{ width: "63%", maxWidth: "460px" }}
            />
          </div>
          <div className={styles.perfectbottomright}>
            <div className={styles.bottomText}>
              <h2>Access Quality Data</h2>
              <p className={styles.text}>
                Get access to space management tools and know which space
                generate the most revenue , access other quality data, make
                better informed decisions, improve services and scale revenue.
              </p>
            </div>

            {/* <div className={styles.perfectspacebottomleft}></div> */}
            <img
              src={titanium}
              className={styles.img}
              alt="Monetize your space"
              style={{ width: "63%", maxWidth: "460px" }}
            />
          </div>
          {/* <div className={styles.perfectbottomright}></div> */}
        </div>
      </section>

      {/* Monetize your space */}
      <section className={styles.sixth}>
        <div className={styles.startListing}>
          <h2 className={styles.head}>Are you ready?</h2>
          <p className={styles.text}>
            In 3mins, your space is ready to start earning. Let’s get your space
            to work!
          </p>
          <Link
            to={
              loggedInUser && loggedInUser?.role_type === "Host"
                ? "/space-description"
                : "/register"
            }
          >
            <button type="submit" className={styles.btn}>
              Start Listing your space Now
            </button>
          </Link>
        </div>
      </section>

      {/* Space Consultation */}
      <section className={styles.sixthx}>
        <div className={styles.texts}>
          <p className={styles.subHead}>
            <img
              src={hostIcon}
              className={styles.icon}
              alt="Space Consultation"
            />
          </p>

          <h2 className={styles.head}>Not sure what your space can do?</h2>

          <p className={styles.text}>
            Speak to our team of professionals, let us tell you about the
            possibilities that your space possess.
          </p>

          <div className={styles.more}>
            <button type="submit" className={styles.btn}>
              Contact Us
            </button>
            <button type="submit" className={styles.btnOutline}>
              Fill this Form for a quick assessment
            </button>
          </div>
        </div>
        <img
          src={hostConsulting}
          className={styles.img}
          alt="Schedule a Consultation"
        />
      </section>

      {/* <section className={styles.seventh}>
        <div>
          <h2>Sponsor an event with us</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. Eros viverra libero at
            libero eget. Orci vestibulum egestas nec auctor posuere. At id amet
            eu vitae. Quam id amet mauris fames arcu sit nec. Rhoncus ac nunc
            phasellus semper cras bibendum lectus. Commodo in et ut nibh in
            pellentesque aliquet.{" "}
          </p>

          <Button type="transparent">See all Events</Button>
        </div>
      </section> */}
    </div>
  );
};

export default Listing;
