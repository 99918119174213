// import Canvas from 'canvas';

export const getCroppedImg = (imageSrc: string, pixelCrop: any) => {
  return new Promise<string>((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject('Failed to get canvas context');
        return;
      }

      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      const base64Image = canvas.toDataURL('image/jpeg');
      resolve(base64Image);
    };

    image.onerror = reject;
  });
};
