import React, { useState, useEffect, Fragment } from "react";
import { CustomInputField } from "../../../../../components/customInput";
import { CustomButton } from "../../../../../components/customButton";
import { IActions } from "../../../../../types";
import { DownOutlined } from "@ant-design/icons";

import { Col, Row, Divider, Checkbox, Select, Dropdown, Input } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./serviceAmenities.module.scss";
import { AMENITIES, SPACE_TYPES } from "../../../../../mocks";
import { ISpaceType, IWorkSpaceForm } from "../../../../../interfaces/forms";
import { FormikErrors, FormikProps } from "formik";
import { TabsProp } from "../../useHostForm";
import { useNavigate } from "react-router-dom";
import { ADD_SPACE_DETAILS } from "../../../../../routes/routes";
import CustomSelect from "../../../../../components/customSelect";
import CustomTextArea from "../../../../../components/customTextArea";

interface IProps {
  formik: FormikProps<IWorkSpaceForm>;
}

const newServiceInitialState = {
  space_type: "",
  space_type_name: "",
  price_per_month: "",
  price_per_week: "",
  price_per_day: "",
  price_per_hour: "",
  space_type_capacity: "",
  space_type_total: "",
  space_type_available: "",
  space_type_description: "",
};

const ServicesAmenities = ({ formik }: IProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState({
    services: false,
    amenities: false,
  });
  const [amenities, setAmenities] = useState(AMENITIES);
  const [amenity, setAmenity] = useState("");
  const [newService, setNewService] = useState(newServiceInitialState);
  const disabled = Object.keys(formik.errors).length > 0;

  const handleAddServices = () => {
    setOpen({
      ...open,
      services: true,
    });
  };

  const handleSaveService = () => {
    if (newService.space_type) {
      formik.setFieldValue("spaceType", [
        ...formik.values.spaceType,
        newService,
      ]);
      setOpen({
        ...open,
        services: false,
      });
      setNewService(newServiceInitialState);
    }
  };

  const handleNewServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewService({
      ...newService,
      [e.target.name]: e.target.value,
    });
  };

  // handle space amenity change
  const onChange = (checkedValues: CheckboxValueType[]) => {
    formik.setFieldValue("spaceAmenity", checkedValues);
  };

  const handleOnChangeAmenities = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmenity(e.target.value);
  };

  // console.log(formik.values, 'erroe')

  return (
    <div className={styles.page}>
      <div className={styles.title}>
        <h1 className={styles.heading}>Services </h1>
        <p className={styles.subHeading}>
          Set pricing for the services you offer.
        </p>
      </div>

      <div className={styles.form}>
        <Divider orientation="left" className={styles.divider}></Divider>
        <>
          {formik.values.spaceType.map((service, i) => (
            <Fragment key={i}>
              {/* <p className={styles.subHeading}>{service.space_type}</p> */}
              <Row gutter={[24, 24]} align="bottom" wrap>
                {/* <p className={styles.subHeading}>Select type of space</p> */}
                <Col md={6} lg={8} xs={12}>
                  <CustomSelect
                    label="Space Type"
                    placeHolder="Select a Space type"
                    options={SPACE_TYPES}
                    value={service.space_type}
                    allowClear={false}
                    showSearch={false}
                    onChange={(value: string) => {
                      formik.setFieldValue(`spaceType[${i}].space_type`, value);
                    }}
                    onBlur={() =>
                      formik.setFieldTouched(`spaceType[${i}].space_type`)
                    }
                    error={
                      formik.touched.spaceType &&
                      formik.touched.spaceType[i] &&
                      formik.touched.spaceType[i].space_type &&
                      formik.errors.spaceType &&
                      formik.errors.spaceType[i] &&
                      (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                        .space_type
                    }
                  />
                </Col>
              </Row>

              <Row gutter={[24, 24]} align="top" wrap>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"text"}
                    secLabel="Name"
                    placeholder="Enter the name of this space"
                    {...formik.getFieldProps(`spaceType[${i}].space_type_name`)}
                    error={
                      formik.touched.spaceType &&
                      formik.touched.spaceType[i] &&
                      formik.touched.spaceType[i].space_type_name &&
                      formik.errors.spaceType &&
                      formik.errors.spaceType[i] &&
                      (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                        .space_type_name
                    }
                  />
                </Col>

                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Daily Pricing"
                    placeholder="Set Private office pricing"
                    {...formik.getFieldProps(`spaceType[${i}].price_per_day`)}
                    error={
                      formik.touched.spaceType &&
                      formik.touched.spaceType[i] &&
                      formik.touched.spaceType[i].price_per_day &&
                      formik.errors.spaceType &&
                      formik.errors.spaceType[i] &&
                      (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                        .price_per_day
                    }
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Hourly Pricing (optional)"
                    placeholder="Set Private office pricing"
                    {...formik.getFieldProps(`spaceType[${i}].price_per_hour`)}
                    error={
                      formik.touched.spaceType &&
                      formik.touched.spaceType[i] &&
                      formik.touched.spaceType[i].price_per_hour &&
                      formik.errors.spaceType &&
                      formik.errors.spaceType[i] &&
                      (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                        .price_per_hour
                    }
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Weekly Pricing"
                    placeholder="Set Private office pricing"
                    {...formik.getFieldProps(`spaceType[${i}].price_per_week`)}
                    error={
                      formik.touched.spaceType &&
                      formik.touched.spaceType[i] &&
                      formik.touched.spaceType[i].price_per_week &&
                      formik.errors.spaceType &&
                      formik.errors.spaceType[i] &&
                      (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                        .price_per_week
                    }
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Monthly Pricing"
                    placeholder="Set Private office pricing"
                    {...formik.getFieldProps(`spaceType[${i}].price_per_month`)}
                    error={
                      formik.touched.spaceType &&
                      formik.touched.spaceType[i] &&
                      formik.touched.spaceType[i].price_per_month &&
                      formik.errors.spaceType &&
                      formik.errors.spaceType[i] &&
                      (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                        .price_per_month
                    }
                  />
                </Col>

                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Seat Capacity"
                    placeholder="Set seat capacity"
                    {...formik.getFieldProps(
                      `spaceType[${i}].space_type_capacity`
                    )}
                    error={
                      formik.touched.spaceType &&
                      formik.touched.spaceType[i] &&
                      formik.touched.spaceType[i].space_type_capacity &&
                      formik.errors.spaceType &&
                      formik.errors.spaceType[i] &&
                      (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                        .space_type_capacity
                    }
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel={"Total Space"}
                    placeholder="Set total no of spaces"
                    {...formik.getFieldProps(
                      `spaceType[${i}].space_type_total`
                    )}
                    error={
                      formik.touched.spaceType &&
                      formik.touched.spaceType[i] &&
                      formik.touched.spaceType[i].space_type_total &&
                      formik.errors.spaceType &&
                      formik.errors.spaceType[i] &&
                      (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                        .space_type_total
                    }
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Available Space"
                    placeholder="set available no of spaces"
                    {...formik.getFieldProps(
                      `spaceType[${i}].space_type_available`
                    )}
                    error={
                      formik.touched.spaceType &&
                      formik.touched.spaceType[i] &&
                      formik.touched.spaceType[i].space_type_available &&
                      formik.errors.spaceType &&
                      formik.errors.spaceType[i] &&
                      (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                        .space_type_available
                    }
                  />
                </Col>
              </Row>

              <CustomTextArea
                label="Provide detailed info about this space"
                placeholder="Give users a detailed description of this space. "
                {...formik.getFieldProps(
                  `spaceType[${i}].space_type_description`
                )}
                error={
                  formik.touched.spaceType &&
                  formik.touched.spaceType[i] &&
                  formik.touched.spaceType[i].space_type_description &&
                  formik.errors.spaceType &&
                  formik.errors.spaceType[i] &&
                  (formik.errors.spaceType[i] as FormikErrors<ISpaceType>)
                    .space_type_description
                }
              />
            </Fragment>
          ))}

          {open.services && (
            <div className={styles.addNewService}>
              <Divider orientation="left"></Divider>
              <div className={styles.addHeading}>
                <p className={styles.subHeading}>Add New Service</p>
                <CloseCircleOutlined
                  onClick={() =>
                    setOpen({
                      ...open,
                      services: false,
                    })
                  }
                />
              </div>
              <Row gutter={[24, 24]} align="bottom" wrap>
                <Col md={6} lg={8} xs={12}>
                  <CustomSelect
                    label="Space Type"
                    placeHolder="Select a Space type"
                    options={SPACE_TYPES}
                    allowClear={false}
                    showSearch={false}
                    onChange={(value) => {
                      setNewService({
                        ...newService,
                        space_type: value,
                      });
                    }}
                    name={"space_type"}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} align="bottom" wrap>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"text"}
                    name={"space_type_name"}
                    secLabel="Service Name"
                    placeholder="Set Service Name"
                    onChange={handleNewServiceChange}
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Daily Pricing"
                    placeholder="Set Private office pricing"
                    name="price_per_day"
                    onChange={handleNewServiceChange}
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Hourly Pricing (optional)"
                    placeholder="Set Private office pricing"
                    name="price_per_hour"
                    onChange={handleNewServiceChange}
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Weekly Pricing"
                    placeholder="Set Private office pricing"
                    name={"price_per_week"}
                    onChange={handleNewServiceChange}
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Monthly Pricing"
                    placeholder="Set Private office pricing"
                    name={"price_per_month"}
                    onChange={handleNewServiceChange}
                  />
                </Col>

                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Seat Capacity"
                    placeholder="Set space capacity"
                    name={"space_type_capacity"}
                    onChange={handleNewServiceChange}
                  />
                </Col>
                <Col md={6} lg={8} xs={12}>
                  <CustomInputField
                    type={"text"}
                    secLabel={"Total Space"}
                    placeholder="Set total no of spaces"
                    name={"space_type_total"}
                    onChange={handleNewServiceChange}
                  />
                </Col>
                <Col md={8} xs={24}>
                  <CustomInputField
                    type={"number"}
                    secLabel="Available Space"
                    placeholder="set available no of spaces"
                    name={"space_type_available"}
                    onChange={handleNewServiceChange}
                  />
                </Col>
              </Row>
              <CustomTextArea
                label="Provide detailed info about this space"
                placeholder="Give users a detailed description of this space."
                name={"space_type_description"}
                onChange={(event) => {
                  setNewService({
                    ...newService,
                    space_type_description: event.target.value,
                  });
                }}
              />
            </div>
          )}

          <Row>
            <Col span={6}>
              <CustomButton
                children={open.services ? "Save" : "Add New Service"}
                icon={<PlusOutlined />}
                className={styles.btn}
                onClick={open.services ? handleSaveService : handleAddServices}
              />
            </Col>
          </Row>
        </>

        {/* amenities section */}

        <div className={styles.title}>
          <Divider orientation="left"></Divider>
          <h1 className={styles.heading}>Amenities</h1>
          <p className={styles.subHeading}>
            Set the amenities offered in your Co-working Space.
          </p>
        </div>
        {formik.errors.spaceAmenity && (
          <p className={styles.error}>{formik.errors.spaceAmenity}</p>
        )}
        <div className={styles.amenities}>
          <Checkbox.Group
            style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
            onChange={onChange}
            value={formik.values.spaceAmenity}
            defaultValue={["24/7 Customer Support"]}
          >
            <Row gutter={[24, 24]} wrap>
              {amenities.map((amenity: string) => {
                return (
                  <Col md={8} xs={24} key={amenity}>
                    <Checkbox value={amenity} className={styles.text}>
                      {amenity}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>

          {open.amenities && (
            <div className={styles.addMoreAmenities}>
              <Divider orientation="left"></Divider>
              <div className={styles.addMore}>
                <CloseCircleOutlined
                  onClick={() => setOpen({ ...open, amenities: false })}
                />
              </div>
              <Row gutter={14} align="bottom">
                <Col span={12}>
                  <CustomInputField
                    type={"text"}
                    name={"custom_amenity"}
                    label="Name of Amenity"
                    placeholder="Enter name of Amenity"
                    onChange={handleOnChangeAmenities}
                  />
                </Col>
              </Row>
            </div>
          )}

          {/* <Row>
                  <Col span={6}>
                     <CustomButton
                        children={open.amenities ? "Save" : "Add New Amenities"}
                        icon={<PlusOutlined />}
                        className={styles.btn}
                        onClick={open.amenities ? handleSaveAmenity : handleAddAmenity}
                     />
                  </Col>
               </Row> */}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <div
          className={styles.previous}
          onClick={() => navigate(`${ADD_SPACE_DETAILS}/operation_hours`)}
        >
          Previous
        </div>
        <CustomButton
          children={"Proceed"}
          onClick={() => navigate(`${ADD_SPACE_DETAILS}/preview`)}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default ServicesAmenities;
