import React, { useState } from "react";
import "./setupPage.scss";

import arrowDown from "@/assets/svgs/arrow-down.svg";

import cloud from "@/assets/svgs/cloud-add.svg";
import greentick from "@/assets/svgs/green-tick.svg";
import camera from "@/assets/svgs/camera.svg";
import tickCircle from "@/assets/svgs/tick-circle.svg";
import { CustomButton } from "@/components/customButton";
import { OptionButton } from "@/components/optionButton";
import CustomTextArea from "@/components/customTextArea";
import { Radio, RadioGroup, Label } from "@headlessui/react";

export const PageFour = ({
  saveContinue,
  values,
  handleChange,
  saving,
}: any) => {
  // Function to toggle the active selection
  const toggleSelection = (
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setter((prevState) => !prevState);
  };

  const [selectCancel, setSelectCancel] = useState("most_flexible");

  const cancelationData = [
    {
      value: "most_flexible",
      label: "Most Flexible",
      details: [
        "Renters can cancel until 24 hours before booking date for a full refund, and space owners won’t be paid.",
        "If renters cancel after that, space owners will be paid 60% of booking fee.",
      ],
    },
    {
      value: "average",
      label: "Average",
      details: [
        "Renters can cancel until 3 days before Booking date for a full refund, and hosts won’t be paid.",
        "If guests cancel after that, hosts will be paid 50% of booking fee.",
      ],
    },
    {
      value: "Firm",
      label: "Firm",
      details: [
        "To receive a full refund, renters must cancel at least 10 days before check-in.",
        "If renters cancel between 3 and 9 days before check-in, space owners will be paid 50% of booking fee.",
        "If renters cancel less than 3 days before booking date, space owners will be paid 100% for all nights.",
        "Guests can also receive a full refund if they cancel within 24 hours of booking, if the cancellation occurs at least 5 days before booking date",
      ],
    },
  ];

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <CustomButton
          children={"Save and Exit"}
          type={"link"}
          className="save-continue-btn"
          onClick={saveContinue}
          disabled={saving}
        />
      </div>
      {/* <OptionButton
        title="Food Policy"
        description="Let us know your policies for food."
        onClick={() => toggleSelection(setFoodPolicy)}
        optionType={foodPolicy}
        optionOne="External food allowed"
        optionTwo="Catering available at space"
        optionThree="External food not allowed"
      /> */}

      <div className="flex flex-col gap-y-6">
        <h3>Let us know your policies for food.?</h3>
        <RadioGroup
          name="foodPolicy"
          value={values.foodPolicy}
          onChange={(value) => {
            handleChange({ target: { name: "foodPolicy", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>External food allowed</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>External food not allowed</span>
          </Radio>
        </RadioGroup>
      </div>

      <div className="flex flex-col gap-y-6">
        <h3>Alcohol policy</h3>
        <RadioGroup
          name="alcoholPolicy"
          value={values.alcoholPolicy}
          onChange={(value) => {
            handleChange({ target: { name: "alcoholPolicy", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      <div className="flex flex-col gap-y-6">
        <h3>Pet policy</h3>
        <RadioGroup
          name="petPolicy"
          value={values.petPolicy}
          onChange={(value) => {
            handleChange({ target: { name: "petPolicy", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      <div className="flex flex-col gap-y-6">
        <h3>Smoking policy</h3>
        <RadioGroup
          name="smokingPolicy"
          value={values.smokingPolicy}
          onChange={(value) => {
            handleChange({ target: { name: "smokingPolicy", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>
      <div className="flex flex-col gap-y-6">
        <h3>Do you allow adult filming?</h3>
        <RadioGroup
          name="adultFilming"
          value={values.adultFilming}
          onChange={(value) => {
            handleChange({ target: { name: "adultFilming", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>
      <div className="flex flex-col gap-y-6">
        <h3>Noise Policy. Do you allows music in this space?</h3>
        <RadioGroup
          name="noisePolicy"
          value={values.noisePolicy}
          onChange={(value) => {
            handleChange({ target: { name: "noisePolicy", value } });
          }}
          className="flex justify-start px-4 gap-6"
        >
          <Radio
            value={true}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>Yes</span>
          </Radio>
          <Radio
            value={false}
            className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
          >
            <span>No</span>
          </Radio>
        </RadioGroup>
      </div>

      {values.noisePolicy && (
        <div className="flex flex-col gap-y-6">
          <h3>What access to music can renters have?</h3>
          <RadioGroup
            name="musicPolicyOne"
            value={values.musicPolicyOne}
            onChange={(value) => {
              handleChange({ target: { name: "musicPolicyOne", value } });
            }}
            className="flex justify-start px-4 gap-6"
          >
            <Radio
              value={true}
              className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
            >
              <span>Music available at Space</span>
            </Radio>
            <Radio
              value={false}
              className="group flex items-center justify-center rounded-xl bg-white data-[checked]:border-primary border-2 border-[#EFF0F6] shadow-sm  py-4 px-20"
            >
              <span>External Music</span>
            </Radio>
          </RadioGroup>
        </div>
      )}

      <div className="py-4">
        <h3 className="text-2xl font-semibold text-coSpireBlack py-3">
          Cancelation Policy
        </h3>
        <p className="text-lg leading-8 pb-5">
          Select the policies that guide the cancellation of prior bookings made
          for your space
        </p>

        <RadioGroup value={selectCancel} onChange={setSelectCancel}>
          {cancelationData.map((item) => (
            <div className="my-4">
              <div className="flex item-center gap-2 font-medium  ">
                <Radio
                  value={item.value}
                  className=" cursor-pointer rounded-xl items-center justify-center bg-alpha-blue flex size-5 data-[checked]:border-primary border-2 border-[#EFF0F6]"
                >
                  {/* <span className="w-2.5 h-2.5 bg-primary rounded-full duration-300 opacity-0 data-[checked]:opacity-100"></span> */}
                  <span className=" size-2 rounded-full bg-primary  group-data-[checked]:visible" />
                </Radio>
                <span>{item.label}</span>
              </div>

              <ul className="pl-8 mt-2 list-disc list-outside text-primaryText space-y-1 text-base">
                {item.details.map((detail) => (
                  <li>{detail}</li>
                ))}
              </ul>
            </div>
          ))}
        </RadioGroup>
      </div>
      {/* <div>
        <h3> Cancellation Policy</h3>
        <p className="sub-title">
          What policies guide the cancellation of prior bookings made for your
          space?
        </p>
        <CustomTextArea
          name="cancellationPolicy"
          label="Enter cancellation Policy"
          onChange={handleChange}
          value={values.cancellationPolicy}
        />
      </div> */}

      {/* <h2>Enter Cancellation Policy</h2>
      <div className="page-input-field">
        <label>Enter Cancellation Policy</label>
        <div
          className="page-input-filed-input-wrapper"
          style={{ height: "auto", maxHeight: "max-content", padding: 0 }}
        >
          <textarea
            placeholder="Type here"
            rows={10}
            value={cancellationPolicy}
            onChange={(e) => {
              setCancellationPolicy(e.target.value);
            }}
          />
        </div>
      </div> */}
      <button className="skip-button">Skip</button>
    </div>
  );
};
