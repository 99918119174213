export const CogIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M10.2964 4.38732C10.3868 3.84486 10.8562 3.44727 11.4061 3.44727H14.0001C14.55 3.44727 15.0193 3.84486 15.1098 4.38732L15.3233 5.66836C15.3856 6.04241 15.6356 6.35398 15.9673 6.53762C16.0415 6.57868 16.1148 6.6211 16.1873 6.66484C16.5123 6.86111 16.9076 6.92213 17.2632 6.78892L18.4801 6.33304C18.9951 6.14011 19.5741 6.34777 19.849 6.82404L21.146 9.07048C21.421 9.54674 21.3113 10.152 20.8867 10.5015L19.8824 11.3283C19.5899 11.5691 19.4449 11.941 19.4519 12.3198C19.4527 12.3622 19.4531 12.4047 19.4531 12.4473C19.4531 12.4898 19.4527 12.5323 19.4519 12.5747C19.4449 12.9535 19.5899 13.3254 19.8824 13.5662L20.8867 14.393C21.3113 14.7425 21.421 15.3478 21.146 15.824L19.849 18.0705C19.5741 18.5467 18.9951 18.7544 18.4801 18.5615L17.2632 18.1056C16.9077 17.9724 16.5123 18.0334 16.1873 18.2297C16.1149 18.2734 16.0415 18.3158 15.9673 18.3569C15.6356 18.5406 15.3856 18.8521 15.3233 19.2262L15.1098 20.5072C15.0193 21.0497 14.55 21.4473 14.0001 21.4473H11.4061C10.8562 21.4473 10.3868 21.0497 10.2964 20.5072L10.0829 19.2262C10.0206 18.8521 9.77059 18.5406 9.43882 18.3569C9.36464 18.3158 9.29132 18.2734 9.21889 18.2297C8.89386 18.0334 8.49853 17.9724 8.14296 18.1056L6.9261 18.5615C6.41111 18.7544 5.83211 18.5468 5.55714 18.0705L4.26016 15.8241C3.98519 15.3478 4.09484 14.7425 4.51942 14.393L5.52377 13.5662C5.81628 13.3254 5.96128 12.9535 5.95426 12.5747C5.95348 12.5323 5.95308 12.4898 5.95308 12.4473C5.95308 12.4047 5.95348 12.3622 5.95426 12.3198C5.96128 11.941 5.81628 11.5692 5.52377 11.3284L4.51942 10.5015C4.09484 10.152 3.98519 9.54676 4.26016 9.07049L5.55714 6.82405C5.83211 6.34779 6.4111 6.14012 6.9261 6.33306L8.14295 6.78893C8.49851 6.92214 8.89384 6.86112 9.21888 6.66484C9.29132 6.6211 9.36464 6.57869 9.43882 6.53763C9.77059 6.35398 10.0206 6.04241 10.0829 5.66836L10.2964 4.38732Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.7029 12.4472C15.7029 14.104 14.3597 15.4472 12.7029 15.4472C11.046 15.4472 9.70285 14.104 9.70285 12.4472C9.70285 10.7903 11.046 9.44719 12.7029 9.44719C14.3597 9.44719 15.7029 10.7903 15.7029 12.4472Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
