import { cn } from "@/lib/utils";
import React from "react";
import { MappedPaginatorInfo } from "@/types";

const LIST = [
  { value: 1, active: true },
  { value: 2, active: false },
  { value: 3, active: false },
  { value: 4, active: false },
];
export function Paginator({
  total,
  lastPage,
  current,
  pageSize,
  onPagination,
}: {
  total?: number;
  lastPage: number;
  current: number;
  pageSize: number;
  onPagination: (current: number) => void;
}) {
  return (
    <div className="flex justify-center mt-8">
      {Array.from({ length: lastPage }).map((_, index) => (
        <button
          className={cn(
            "border border-gray-300 rounded-md mx-1 py-4 px-8 text-lg font-normal",
            index + 1 === current && "bg-alpha-blue text-primary"
          )}
          onClick={() => onPagination(index + 1)}
        >
          {index + 1}
        </button>
      ))}

      <button className="border border-gray-300 rounded-md py-1 px-3 mx-1">
        ...
      </button>
      <button
        title="More pages"
        className="border border-gray-300 rounded-md py-1 px-3 mx-1"
      >
        <i className="fas fa-arrow-right"></i>
      </button>
    </div>
  );
}
