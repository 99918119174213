import React, { Fragment, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import "./index.module.scss";

import { SearchBar } from "@/components/a";
import workspace from "@/assets/img/workspace.png";
import arrowright from "@/assets/svgs/arrow-long-right.svg";
import arrowrightlight from "@/assets/svgs/heroicons-outline.svg";
import arrowrightblack from "@/assets/svgs/arrowblack.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import hostlisting from "@/assets/img/host.png";
import discountCoupons from "@/assets/img/discountCoupons.svg";
import magnifyingGlass from "@/assets/img/magnifyingGlass.svg";
import messageNotif from "@/assets/img/messageNotif.svg";
import cup from "@/assets/img/cup.svg";
import click from "@/assets/img/click.svg";

import titanium from "@/assets/img/lock.png";

import hostConsulting from "@/assets/img/host-two.png";
import spaceTwo from "@/assets/img/space-two.png";
import spaceOne from "@/assets/img/space-one.png";
import magicPen from "@/assets/img/magic-pen.png";
import hostIcon from "@/assets/img/host-icon.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { TabTitle } from "@/utils/titleFunction";
import { useAppSelector } from "@/redux/hook";
import {
  FeaturedCities,
  FeaturedCities1,
  FeaturedCities2,
  Property1,
  Property2,
} from "@/assets/img/";
import {
  setLocation,
  setWorkspaceFilter,
} from "@/redux/reduxSlice/hostWorkSpacesSlice";
import { useDispatch } from "react-redux";

import { Spin } from "antd";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import Venues from "./components/FindVenues/Venues";
import DiscoverSpaces from "./components/Discover/DiscoverSpaces";
import SpaceCard from "../../components/spaceCard/SpaceCard";
import { WORKSPACES } from "@/routes/routes";

const HomePage: React.FC = () => {
  interface Space {
    location: string;
    title: string;
    photos: string[];
    featured: boolean;
    workspace_id: string;
  }

  interface TopLocations {
    city: string;
    num: number;
    img: string;
  }

  const imgs = [
    FeaturedCities,
    FeaturedCities1,
    FeaturedCities2,
    Property1,
    Property2,
  ];
  const [topLocations, setTopLocations] = useState<TopLocations[]>([]);
  const [hasTopLocations, setHasTopLocations] = useState(false);

  const { workspaces } = useAppSelector((state) => state.hostWorkSpace);

  const dispatch = useDispatch();

  TabTitle("co-spire | Home");
  const navigate = useNavigate();

  const updateTopLocations = useCallback(() => {
    if (!hasTopLocations) {
      const updatedTopLocations = [...topLocations];

      if (workspaces?.data) {
        workspaces?.data.forEach((loc) => {
          const randomNumber = Math.floor(Math.random() * 5);
          const existingLocation = updatedTopLocations.find(
            (topLoc) => topLoc.city === loc.location
          );
          if (existingLocation) {
            existingLocation.num += 1;
          } else {
            updatedTopLocations.push({
              city: loc.location,
              num: 1,
              img:
                Array.isArray(loc.photos) && loc.photos.length > 0
                  ? loc.photos[0]
                  : imgs[randomNumber],
            });
          }
        });
      }
      updatedTopLocations.sort((a, b) => b.num - a.num);

      setTopLocations(updatedTopLocations);
      setHasTopLocations(true);
    }
  }, [hasTopLocations, topLocations, workspaces?.data]);

  useEffect(() => {
    if (workspaces?.data) {
      updateTopLocations();
    }
  }, [workspaces?.data, updateTopLocations]);

  const handleTopLocation = (city: string) => {
    dispatch(setLocation(city));
    dispatch(setWorkspaceFilter("location"));
    navigate("workspace");
  };

  const partners = [
    "https://www.citisquare.africa/_next/static/media/periwinkle.1a45c077.webp",
    "https://www.citisquare.africa/_next/static/media/mixta.b9d3ff64.webp",
    "https://www.citisquare.africa/_next/static/media/flutterwave.04239b52.webp",
    "https://www.citisquare.africa/_next/static/media/landwey.a70e7f22.webp",
    "https://www.citisquare.africa/_next/static/media/grenadines_homes.14b0a36d.webp",
    "https://www.citisquare.africa/_next/static/media/sparkle.757cbe09.webp",
  ];

  return (
    <div className={styles.main}>
      <Venues />
      <DiscoverSpaces />
      {/* <section className={styles.second}>
        <div>
          <h2>Nigeria’s Most Organized Marketplace For Coworking Spaces</h2>
          <p>
            You’ve got the key to the widest network of coworking spaces in
            Nigeria. Get in and Explore!
          </p>
        </div>
        <div></div>
        <div>
          <h2>Trusted by Hundreds of Organizations Like Yours</h2>
          <p>
            Everyday, new organizations like yours chose CO-SPIRE to find the
            right coworking space and amenity that meets their needs and that of
            their team and yes, they always find what they need!
          </p>
        </div>
      </section> */}
      <div>
        {/* <Splide
          aria-labelledby="location"
          options={{
            type: 'loop',
            drag: 'free',
            focus: 'center',
            perPage: 3,
            arrows: false,
            pagination: false,
            autoScroll: {
              speed: 1,
              pauseOnHover: true,
              pauseOnFocus: true,
            },
            breakpoints: {
              640: {
                perPage: 1,
              },

              1080: {
                perPage: 3,
              },

              1660: {
                perPage: 4,
              },
            },
            gap: "1.5rem",

          }}
          extensions={{ AutoScroll }}
        >
          {

            partners?.map((partner, i) => (
              <SplideSlide key={i}>
                <div className={styles.partnersList}>
                  <img src={partner} alt="" />
                </div>
              </SplideSlide>
            ))
          }
        </Splide> */}
      </div>

      {/* <section className={styles.fifth}>
        <div className={styles.text}>
          <h2>Featured Coworking Spaces</h2>
          <p>
            Take your team with you, wherever business calls. Connect with new
            opportunities. Find a workspace that works for you.
          </p>
        </div>

        <div className={styles.spaces}>
          <Fragment>
            {workspaces?.data &&
              workspaces?.data
                .filter((item) => item.featured)
                .map((item, index) => {
                  return (
                    <SpaceCard item={item} index={index} />
                  );
                })}
          </Fragment>
        </div>
      </section> */}

      {/* <section className={styles.third}>
        <div>
          <p className={styles.subhead}>CONNECT, CO-WORK, CO-SPIRE</p>
          <h2>Lets help connect you to your next opportunity!</h2>
          <p className={styles.text}>
            Opportunities are often everywhere most people aren’t looking.
            Coworking spaces at CO-SPIRE are not just a place to co-work, but to
            also connect with new opportunities. Whether you’re a small business
            looking to build a corporate structure, or expanding into new
            markets as a fast growing brand.
          </p>
          <p className={styles.text}>
            Or you just need a workspace that feels like home. Our coworking
            spaces were designed to meet and satisfy your every need.
          </p>
          <div
            className={styles.explore}
            onClick={() => navigate("/workspace")}
          >
            <span> Explore Available Workspaces</span>
            <img src={arrowright} alt="" />
          </div>
        </div>
        <img src={workspace} className={styles.img} alt="" />
      </section> */}

      {/* <section className={styles.forth}>
        <div className={styles.text}>
          <h2>Top Coworking Locations</h2>
          <p>Wherever work takes you, is where we want to be.</p>
        </div>
        <div className={styles.splides}>
          <Splide
            aria-labelledby="location"
            options={{
              updateOnMove: true,
              lazyLoad: true,
              type: "loop",
              perMove: 1,
              perPage: 4,
              breakpoints: {
                640: {
                  perPage: 1,
                },

                1080: {
                  perPage: 3,
                },
                1660: {
                  perPage: 4,
                },
              },
              gap: "1.5rem",
              classes: {
                arrows: "splide__arrows splide-arrows-nav",
                arrow: "splide__arrow splide-navigation-arrow ",
                prev: "splide__arrow--prev splide-nav-prev",
                next: "splide__arrow--next splide-nav-next",
                pagination: "splide__pagination  splide-nav-pagination",
              },
              arrowPath:
                "M18.9342 0.599936L26.4009 8.0666C26.5342 8.19993 26.6285 8.34438 26.6836 8.49994C26.7396 8.65549 26.7676 8.82216 26.7676 8.99994C26.7676 9.17771 26.7396 9.34438 26.6836 9.49994C26.6285 9.65549 26.5342 9.79993 26.4009 9.93327L18.9342 17.3999C18.6898 17.6444 18.384 17.7666 18.0169 17.7666C17.6507 17.7666 17.3342 17.6444 17.0676 17.3999C16.8009 17.1333 16.6676 16.8168 16.6676 16.4506C16.6676 16.0835 16.8009 15.7666 17.0676 15.4999L22.2342 10.3333L2.00091 10.3333C1.62313 10.3333 1.30669 10.2057 1.05158 9.9506C0.795578 9.6946 0.667578 9.37771 0.667578 8.99994C0.667578 8.62216 0.795578 8.30571 1.05158 8.0506C1.30669 7.7946 1.62313 7.6666 2.00091 7.6666L22.2342 7.6666L17.0676 2.4666C16.8231 2.22216 16.6951 1.91682 16.6836 1.5506C16.6729 1.18349 16.8009 0.866602 17.0676 0.599936C17.312 0.355492 17.6231 0.233267 18.0009 0.233267C18.3787 0.233267 18.6898 0.355492 18.9342 0.599936Z",
            }}
          >
            <Fragment>
              {topLocations?.map((item, index) => (
                <SplideSlide key={index} onClick={() => navigate(`workspace`)}>
                  <div
                    className={styles.splide}
                    onClick={() => handleTopLocation(item.city)}
                  >
                    <img className={styles.img} src={item.img} />

                    <div className={styles.box}>
                      <p className={styles.space}>{item.num}+ SPACES</p>
                      <div className={styles.city}>
                        <p>{item.city}</p>

                        <img src={arrowrightlight} />
                      </div>
                    </div>
                    <div className={styles.box_}>
                      <div className={styles.city}>
                        <p>{item.city}</p>

                        <img src={arrowrightlight} />
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              ))}
            </Fragment>
          </Splide>
        </div>
      </section> */}
      {/* Book your space */}
      <section className={styles.fourthx}>
        <h2>Book your space, It’s easy!</h2>
        <div className={styles.spaceproperties}>
          <div>
            <img src={magnifyingGlass} alt="" />
            <h4>Find your space</h4>
            <p>Search and find your space with our smart search. </p>
            <hr></hr>
          </div>
          <div>
            <img src={click} alt="" />
            <h4>Book your space</h4>
            <p>
              When you find the perfect space, go ahead and book the space with
              the day and time details
            </p>
            <hr></hr>
          </div>
          <div>
            <img src={messageNotif} alt="" />
            <h4>Receive confirmation</h4>
            <p>
              Your booking would be confirmed and payment details would be sent
              to your email address.
            </p>
            <hr></hr>
          </div>
          <div>
            <img src={cup} alt="" />
            <h4>Create, Meet and Play!</h4>
            <p>
              After making payments you are ready to use create memories, meet
              and play
            </p>
            <hr></hr>
          </div>
        </div>
        <button
          onClick={() => navigate(WORKSPACES)}
          type="submit"
          className={styles.btn}
        >
          Start Booking now
        </button>
      </section>
      {/* perfect space your space */}
      <section className={styles.fifthx}>
        <div className={styles.perfecttop}>
          <div className={styles.topText}>
            <h2>Find Perfect spaces in minutes</h2>
            <p className={styles.text}>
              Cut down planning time and find what you need fast. From a
              fully-equipped studio to a mansion or abandoned warehouse, you can
              find your perfect fit.
            </p>
          </div>

          <div className={styles.perfectspace}></div>
        </div>
        <div className={styles.perfectbottom}>
          <div className={styles.perfectbottomleft}>
            <div className={styles.bottomText}>
              <h2>Clear pricing</h2>
              <p className={styles.text}>
                With clear hourly rates and no surprise fees, you’ll know what
                you’re paying for, right from the get-go.
              </p>
            </div>

            {/* <div className={styles.perfectspacebottomleft}></div> */}
            <img
              src={discountCoupons}
              className={styles.img}
              alt="Monetize your space"
              style={{ width: "63%", maxWidth: "460px" }}
            />
          </div>
          <div className={styles.perfectbottomright}>
            <div className={styles.bottomText}>
              <h2>Trusted hosts</h2>
              <p className={styles.text}>
                We do the dirty work so you don’t have to worry, just book and
                create!.
              </p>
            </div>

            {/* <div className={styles.perfectspacebottomleft}></div> */}
            <img
              src={titanium}
              className={styles.img}
              alt="Monetize your space"
              style={{ width: "63%", maxWidth: "460px" }}
            />
          </div>
          {/* <div className={styles.perfectbottomright}></div> */}
        </div>
      </section>

      {/* Monetize your space */}
      <section className={styles.sixth}>
        <img
          src={hostlisting}
          className={styles.img}
          alt="Monetize your space"
        />
        <div className={styles.texts}>
          <p className={styles.subHead}>
            <img src={hostIcon} className={styles.icon} alt="Monetize space" />
          </p>

          <h2 className={styles.head}>Monetize your space</h2>
          <p className={styles.text}>Put your space to work.</p>
          <p className={styles.text}>
            Earn extra income by creatively repurposing your space to host
            personal and professional gatherings, shoots, etc.
          </p>

          <button
            onClick={() => navigate("/listing")}
            type="submit"
            className={styles.btn}
          >
            List your space
          </button>
        </div>
      </section>

      {/* Space Consultation */}
      <section className={styles.sixthx}>
        <div className={styles.texts}>
          <p className={styles.subHead}>
            <img
              src={hostIcon}
              className={styles.icon}
              alt="Space Consultation"
            />
          </p>

          <h2 className={styles.head}>Not sure what your space can do?</h2>

          <p className={styles.text}>
            Speak to our team of professionals, let us tell you about the
            possibilities that your space possess.
          </p>

          <div className={styles.more}>
            <button type="submit" className={styles.btn}>
              Contact Us
            </button>
            <button type="submit" className={styles.btnOutline}>
              Fill this Form for a quick assessment
            </button>
          </div>
        </div>
        <img
          src={hostConsulting}
          className={styles.img}
          alt="Schedule a Consultation"
        />
      </section>

      <section className={styles.seventhx}>
        <div className={styles.flip_card}>
          <div className={styles.card}>
            <div className={styles.front}>
              <div className={styles.front_data}>
                <p>It is not just a Resataurant by the sea</p>
              </div>
            </div>
            <div className={styles.back}>
              <div className={styles.back_data}>
                <p>It is also a fashion photoshoot location</p>
              </div>
            </div>
          </div>
          {/* <img
          src={spaceOne}
          className={`${styles.img} ${styles.img_1}`}
          alt="Cofee shop"
        /> */}
        </div>
        <div className={styles.yourspace}>
          <img src={magicPen} className={styles.penImage} alt="Cofee shop" />

          <p className={styles.header}>Reimagine your space</p>
          <p className={styles.sub_header}>Put your space to work.</p>
          <p>
            Earn extra income by creatively repurposing your space to host
            personal and professional gatherings, shoots, etc.
          </p>
          <Link to={"/listing"} type="submit" className={styles.btn}>
            List your space
          </Link>
        </div>
        <div className={styles.flip_card_two}>
          <div className={styles.card}>
            <div className={styles.front}>
              <div className={styles.front_data}>
                <p>It is not just an empty room</p>
              </div>
            </div>
            <div className={styles.back}>
              <div className={styles.back_data}>
                <p>It is also a live recording studio</p>
              </div>
            </div>
          </div>
          {/* <img src={spaceTwo} className={styles.img} alt="" /> */}
        </div>
      </section>

      {/* <section className={styles.seventh}>
        <div>
          <h2>Sponsor an event with us</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. Eros viverra libero at
            libero eget. Orci vestibulum egestas nec auctor posuere. At id amet
            eu vitae. Quam id amet mauris fames arcu sit nec. Rhoncus ac nunc
            phasellus semper cras bibendum lectus. Commodo in et ut nibh in
            pellentesque aliquet.{" "}
          </p>

          <Button type="transparent">See all Events</Button>
        </div>
      </section> */}
    </div>
  );
};

export default HomePage;
