import React, { ChangeEvent } from "react";
import styles from "./index.module.scss";
import { Input } from "antd";

interface CustomTextAreaProps {
   value?: string;
   onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
   onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
   label?: string;
   disabled?: boolean;
   secLabel?: string;
   placeholder?: string;
   maxLength?: number;
   rows?: number;
   showCount?: boolean;
   error?: string | undefined | number | boolean;
   name?: string;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
   value,
   error,
   onChange,
   name,
   disabled,
   label,
   secLabel,
   placeholder,
   maxLength,
   rows = 8,
   showCount,
   onBlur
}) => {
   return (
      <div className={styles.textAreaCtn}>
         {label && <label className={styles.label}>{label}</label>}
         {secLabel && <p className={styles.secondaryLabel}>{secLabel}</p>}
         <Input.TextArea
            showCount={showCount}
            className={styles.customTextarea}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={placeholder}
            rows={rows}
            maxLength={maxLength}
            name={name}
            value={value}
         />
         {error && typeof error === "string" && <p className={styles.error}>{error}</p>}
      </div>
   );
};

export default CustomTextArea;
