import React, { useState, useEffect } from "react";
import { useAppSelector } from "@/redux/hook";
import {
  IGetHostWorkSpaces,
  IVerifiedReviews,
  Services,
} from "@/interfaces/apiResponse";
import { useMe } from "@/data/user";

const useHostWorkspaces = () => {
  const [hostWorkSpaces, setHostWorkspaces] = useState<
    IGetHostWorkSpaces[] | undefined
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState<string | number>(4);
  const { me: loggedInUser } = useMe();
  const { workspaces, workspacesIsLoading, workspacesIsError } = useAppSelector(
    (state) => state.hostWorkSpace
  );

  const getAverageRating = (reviews: IVerifiedReviews[]) => {
    if (reviews?.length === 0) return "No rating available";
    const total_rating = reviews?.reduce((acc, curr) => {
      return acc + curr.review_rating;
    }, 0);
    const average_rating = Number(total_rating) / Number(reviews.length);
    return Math.round(average_rating) + ".0";
  };

  const getLowestPrice = (services: Services[]) => {
    const hourlyPrices = services
      .map((service) => service.hourly_price || Number.MAX_SAFE_INTEGER)
      .filter(Boolean);
    const dailyPrices = services
      .map((service) => service.daily_price)
      .filter(Boolean);
    const weeklyPrices = services
      .map((service) => service.weekly_price)
      .filter(Boolean);
    const monthlyPrices = services
      .map((service) => service.monthly_price)
      .filter(Boolean);

    const lowestHourlyPrice =
      hourlyPrices.length > 0 ? Math.min(...hourlyPrices) : null;
    const lowestDailyPrice =
      dailyPrices.length > 0 ? Math.min(...dailyPrices) : null;
    const lowestWeeklyPrice =
      weeklyPrices.length > 0 ? Math.min(...weeklyPrices) : null;
    const lowestMonthlyPrice =
      monthlyPrices.length > 0 ? Math.min(...monthlyPrices) : null;

    // Determine the lowest price and its corresponding time unit
    let lowestPrice = null;
    let timeUnit = "";

    if (
      lowestHourlyPrice !== null &&
      (!lowestPrice || lowestHourlyPrice < lowestPrice)
    ) {
      lowestPrice = lowestHourlyPrice;
      timeUnit = "hour";
    }
    if (
      lowestDailyPrice !== null &&
      (!lowestPrice || lowestDailyPrice < lowestPrice)
    ) {
      lowestPrice = lowestDailyPrice;
      timeUnit = "day";
    }
    if (
      lowestWeeklyPrice !== null &&
      (!lowestPrice || lowestWeeklyPrice < lowestPrice)
    ) {
      lowestPrice = lowestWeeklyPrice;
      timeUnit = "week";
    }
    if (
      lowestMonthlyPrice !== null &&
      (!lowestPrice || lowestMonthlyPrice < lowestPrice)
    ) {
      lowestPrice = lowestMonthlyPrice;
      timeUnit = "month";
    }

    // Return the lowest price and its time unit
    if (lowestPrice !== null) {
      return `${lowestPrice}/${timeUnit}`;
    } else {
      return "No price available";
    }
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // refetch data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (workspaces && loggedInUser?.uid) {
      if (workspaces.data) {
        // Check if workspaces.data is defined
        const spaces = workspaces.data.filter(
          (workspace: IGetHostWorkSpaces) => {
            return workspace.created_by === loggedInUser?.uid;
          }
        );
        setHostWorkspaces(spaces);
        setTotalPagesCount(workspaces.totalPages);
      }
    }
  }, [workspaces, loggedInUser]);

  return {
    hostWorkSpaces,
    workspacesIsLoading,
    workspacesIsError,
    getAverageRating,
    getLowestPrice,
    totalPagesCount,
    currentPage,
    changePage,
  };
};

export default useHostWorkspaces;
