import React, { useState } from "react";
import { Dialog } from "@/components/b/Dialog";
import styles from "./additinal.module.scss";
import camera from "@/assets/img/cafe.jpeg";
import Button from "@/components/c/Button";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  rental: any;
}

function AdditionalOffer({ isOpen, closeModal, rental }: Prop) {
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div>
      <Dialog isOpen={isOpen} close={closeModal}>
        <div className={styles.main}>
          <div className={styles.cardContainer}>
            <img src={rental.img} alt="" />
            <div className={styles.top}>
              <p className={styles.title}>{rental.title}</p>
              <p className={styles.text}>
                3-seat sofa with chaise longue, Gunnared beige
              </p>
              <div className={styles.price}>
                <p>
                  ₦{rental.price} <span>per Item</span>
                </p>
                <div>
                  <button onClick={handleDecrement}>-</button>
                  <p>{quantity}</p>
                  <button onClick={handleIncrement}>+</button>
                </div>
              </div>
            </div>
            <div className={styles.btnContainer}>
              <button className={styles.add}>Add to your booking</button>
              <button className={styles.close} onClick={() => closeModal()}>
                Close{" "}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default AdditionalOffer;
