export const EmailIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 3.58936H7C4 3.58936 2 5.08936 2 8.58936V15.5894C2 19.0894 4 20.5894 7 20.5894H17C20 20.5894 22 19.0894 22 15.5894V8.58936C22 5.08936 20 3.58936 17 3.58936ZM17.47 9.67936L14.34 12.1794C13.68 12.7094 12.84 12.9694 12 12.9694C11.16 12.9694 10.31 12.7094 9.66 12.1794L6.53 9.67936C6.21 9.41936 6.16 8.93936 6.41 8.61936C6.67 8.29936 7.14 8.23936 7.46 8.49936L10.59 10.9994C11.35 11.6094 12.64 11.6094 13.4 10.9994L16.53 8.49936C16.85 8.23936 17.33 8.28936 17.58 8.61936C17.84 8.93936 17.79 9.41936 17.47 9.67936Z"
        fill="currentColor"
      />
    </svg>
  );
};
