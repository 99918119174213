import React, { useEffect, useState } from "react";
import styles from "./operations.module.scss";
import { CustomInputField } from "../../../../../components/customInput";
import { CustomButton } from "../../../../../components/customButton";

import { Col, Row, Divider, Checkbox, DatePicker } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import SelectTime from "../../../../../components/selectTimeComponent";
import CustomDatePicker from "../../../../../components/customDatePicker";

import { IWorkSpaceForm } from "../../../../../interfaces/forms";
import { FormikProps } from "formik";
import { Timestamps } from "../../../../../mocks";
import dayjs, { Dayjs } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useNavigate } from "react-router-dom";
import { ADD_SPACE_DETAILS } from "../../../../../routes/routes";

dayjs.extend(LocalizedFormat);

interface IProps {
   formik: FormikProps<IWorkSpaceForm>;
}

interface RoleType {
   holidayName?: string;
   holidayDate?: Dayjs | null | undefined;
}

const initialState = {
   holidayName: "",
   holidayDate: null,
};

const Operations = ({ formik }: IProps) => {
   const navigate = useNavigate()
   const [holidayData, setHolidayData] = useState<RoleType>(initialState);

   const onChange = (e: CheckboxChangeEvent) => {
      formik.setFieldValue("selectDays", e.target.checked);
   };

   const handleSaveHoliday = () => {
      if (holidayData.holidayName && holidayData.holidayDate) {
         formik.setFieldValue("holidays", [...formik.values.holidays, { ...holidayData, holidayDate: dayjs(holidayData.holidayDate).format("LL") }]);
         setHolidayData(initialState);
      }
   };

   const handleDelete = (holiday: any) => {
      const newHoliday = formik.values.holidays.filter((item) => item.holidayName !== holiday.holidayName);
      formik.setFieldValue("holidays", newHoliday);
   };

   return (
      <div className={styles.page}>
         <div className={styles.title}>
            <h1 className={styles.heading}>Operation Schedule</h1>
            <p className={styles.subHeading}>Set your opening hours and days.</p>
         </div>

         <div className={styles.days}>
            {formik.values.selectDays ? (
               <div className={styles.selectDays}>
                  <Divider orientation='left' className={styles.divider}></Divider>
                  <Row>
                     <Col span={6} xs={24}>
                        <Checkbox onChange={onChange} checked={formik.values.selectDays}>
                           Select Days
                        </Checkbox>
                     </Col>
                  </Row>

                  <h1 className={styles.heading}>Monday to Friday</h1>
                  <Row gutter={[45, 24]}>
                     <Col span={12} xs={24}>
                        <SelectTime
                           rangeType='From'
                           header={"Opening Hours"}
                           options={Timestamps}
                           onChange={(value: string) => {
                              formik.setFieldValue("monday_friday_from", value);
                           }}
                           value={formik.values.monday_friday_from}
                           onBlur={() => formik.setFieldTouched("monday_friday_from")}
                           error={formik.touched.monday_friday_from && formik.errors.monday_friday_from}
                        />
                     </Col>
                     <Col span={12} xs={24}>
                        <SelectTime
                           rangeType='To'
                           onChange={(value: string) => {
                              formik.setFieldValue("monday_friday_to", value);
                           }}
                           onBlur={() => formik.setFieldTouched("monday_friday_to")}
                           value={formik.values.monday_friday_to}
                           header={"Closing Hours"}
                           options={Timestamps}
                           error={formik.touched.monday_friday_to && formik.errors.monday_friday_to}
                        />
                     </Col>
                  </Row>

                  <h1 className={styles.heading}>Saturday<span className={styles.optional}>  (optional)</span></h1>
                  <Row gutter={[45, 24]}>
                     <Col span={12} xs={24}>
                        <SelectTime
                           error={formik.touched.saturday_from && formik.errors.saturday_from}
                           rangeType='From'
                           onChange={(value: string) => {
                              formik.setFieldValue("saturday_from", value);
                           }}
                           onBlur={() => formik.setFieldTouched("saturday_from")}
                           value={formik.values.saturday_from}
                           header={"Opening Hours"}
                           options={Timestamps}
                        />
                     </Col>
                     <Col span={12} xs={24}>
                        <SelectTime
                           rangeType='To'
                           header={"Closing Hours"}
                           options={Timestamps}
                           onBlur={() => formik.setFieldTouched("saturday_to")}
                           onChange={(value: string) => {
                              formik.setFieldValue("saturday_to", value);
                           }}
                           value={formik.values.saturday_to}
                           error={formik.touched.saturday_to && formik.errors.saturday_to}
                        />
                     </Col>
                  </Row>

                  <h1 className={styles.heading}>Sunday <span className={styles.optional}>  (optional)</span></h1>
                  <Row gutter={[45, 24]}>
                     <Col span={12} xs={24}>
                        <SelectTime
                           rangeType='From'
                           onChange={(value: string) => {
                              formik.setFieldValue("sunday_from", value);
                           }}
                           onBlur={() => formik.setFieldTouched("sunday_from")}
                           value={formik.values.sunday_from}
                           header={"Opening Hours"}
                           options={Timestamps}
                           error={formik.touched.sunday_from && formik.errors.sunday_from}
                        />
                     </Col>
                     <Col span={12} xs={24}>
                        <SelectTime
                           rangeType='To'
                           onChange={(value: string) => {
                              formik.setFieldValue("sunday_to", value);
                           }}
                           onBlur={() => formik.setFieldTouched("sunday_to")}
                           value={formik.values.sunday_to}
                           header={"Closing Hours"}
                           options={Timestamps}
                           error={formik.touched.sunday_to && formik.errors.sunday_to}
                        />
                     </Col>
                  </Row>
               </div>
            ) : (
               <div className={styles.selectDays}>
                  <Divider orientation='left'></Divider>
                  <h1 className={styles.heading}>Monday to Sunday</h1>
                  <Row gutter={[45, 24]}>
                     <Col span={12} xs={24}>
                        <SelectTime
                           rangeType='From'
                           onChange={(value: string) => {
                              formik.setFieldValue("monday_sunday_from", value);
                           }}
                           value={formik.values.monday_sunday_from}
                           onBlur={() => {
                              formik.setFieldTouched("monday_sunday_from");
                           }}
                           error={formik.touched.monday_sunday_from && formik.errors.monday_sunday_from}
                           header={"Opening Hours (select time)"}
                           options={Timestamps}
                        />
                     </Col>
                     <Col span={12} xs={24}>
                        <SelectTime
                           rangeType='To'
                           onChange={(value: string) => {
                              formik.setFieldValue("monday_sunday_to", value);
                           }}
                           value={formik.values.monday_sunday_to}
                           onBlur={() => formik.setFieldTouched("monday_sunday_to")}
                           error={formik.touched.monday_sunday_to && formik.errors.monday_sunday_to}
                           header={"Closing Hours (select time)"}
                           options={Timestamps}
                        />
                     </Col>
                  </Row>

                  <Row>
                     <Col span={6} xs={24}>
                        <Checkbox onChange={onChange} checked={formik.values.selectDays}>
                           Select Days
                        </Checkbox>
                     </Col>
                  </Row>
               </div>
            )}
         </div>

         <div className={styles.holidays}>
            <div className={styles.title}>
               <Divider orientation='left'></Divider>
               <h1 className={styles.heading}>Holidays</h1>
               <Col span={18} xs={24}>
                  <p className={styles.subHeading}>
                     Holiday dates would be blocked on your calendar. Potential customers won’t be able to book on those days.
                  </p>
               </Col>
            </div>
            {(formik.touched.holidays && formik.errors.holidays) && (<p className={styles.error}>*Please add a holiday*</p>)}
            <Row gutter={[45, 24]} align='bottom' wrap>
               <Col md={12} xs={24}>
                  <CustomInputField
                     type={"text"}
                     name={"holiday_name"}
                     label='Holiday Name'
                     value={holidayData.holidayName}
                     placeholder='Enter the Holiday Name'
                     onChange={(e) => {
                        setHolidayData({
                           ...holidayData,
                           holidayName: e.target.value,
                        });
                     }}
                     onBlur={() => formik.setFieldTouched("holidays")}
                  />
               </Col>
               <Col md={12} xs={24}>
                  <CustomDatePicker
                     label='Holiday Date'
                     value={holidayData.holidayDate}
                     onChange={(date) => {
                        setHolidayData({
                           ...holidayData,
                           holidayDate: date,
                        });
                     }}
                     onBlur={() => formik.setFieldTouched("holidays")}
                  />
               </Col>
               <Col span={6}>
                  <CustomButton
                     children={holidayData.holidayName && holidayData.holidayDate ? "Save Holiday" : "Add New Holiday"}
                     icon={<PlusOutlined />}
                     className={styles.btn}
                     onClick={handleSaveHoliday}
                  />
               </Col>

               <Divider orientation='left'></Divider>
               <div className={styles.addNewHoliday}>
                  {formik.values.holidays.length > 0 && (
                     <>
                        {formik.values.holidays.map((holiday, i) => {
                           return (
                              <div className={styles.addHeading} key={i}>
                                 <p className={styles.subHeading}>{holiday?.holidayName + ", " + holiday?.holidayDate}</p>
                                 <CloseCircleOutlined onClick={() => handleDelete(holiday)} />
                              </div>
                           );
                        })}
                     </>
                  )}
               </div>
            </Row>
         </div>

         <div className={styles.buttonContainer}>
            <div className={styles.previous} onClick={() => {navigate(`${ADD_SPACE_DETAILS}/contact_details`)}}>
               Previous
            </div>
            <CustomButton
               children={"Next"}
               onClick={() => navigate(`${ADD_SPACE_DETAILS}/services_amenities`)}
               disabled={
                  Boolean(formik.errors.holidays) ||
                  Boolean(formik.errors.monday_sunday_from) ||
                  Boolean(formik.errors.monday_sunday_to) ||
                  Boolean(formik.errors.sunday_from) ||
                  Boolean(formik.errors.sunday_to) ||
                  Boolean(formik.errors.saturday_from) ||
                  Boolean(formik.errors.saturday_to) ||
                  Boolean(formik.errors.monday_friday_from) ||
                  Boolean(formik.errors.monday_friday_to)
               }
            />
         </div>
      </div>
   );
};

export default Operations;
