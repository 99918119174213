import React, { useState } from "react";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
import { Divider } from "antd";
import books from "@/assets/svgs/books.svg";
import cancel from "@/assets/svgs/cancel.svg";
import payment from "@/assets/svgs/payment.svg";
import styles from "./index.module.scss";
import { CustomButton } from "@/components/customButton";
import WithdrawCard from "@/components/cards/WithDrawCard/WithdrawCard";
import { WithdrawalAccounts } from "@/components/wallet/withdrawal-accounts";
import useWallet from "../../Host/Profile/components/Wallet/useWallet";
import CustomModal from "@/components/a/Modals/CustomModal/CustomModal";
import { WithdrawFunds } from "@/components/wallet/modals/withdraw-funds";
import { Formik, FormikHelpers, FormikProps } from "formik";
import Kycverification from "../../Host/Profile/components/Wallet/Kycverification";
import SuccessModal from "@/components/a/Modals/Success";
import tick_circle from "@/assets/svgs/success-verified.svg";
import loading_gif from "@/assets/svgs/spinner.gif";
import error_vector from "@/assets/svgs/errorVector.svg";
import { TransactionHistory } from "@/components/transactions/transactions-history";
import { cn } from "@/lib/utils";
import { AnalyticsCard } from "@/components/wallet/analytics-card";
import { formatNumberWithCommas } from "@/utils/formatNumbersWithCommas";
import { BalanceCard } from "@/components/wallet/balance-card";
import { LightningIcon } from "@/components/icons/lightning";
import { TotalEarnings } from "@/components/wallet/earnings";
import { formatMoney } from "@/utils/format-money";
import { useAddWithdrawalAccountMutation, useMyWallet } from "@/data/wallet";
import { AddWithdrawalBank } from "@/components/wallet/modals/add-bank";
import { useModalAction } from "@/components/modal-view/context";

const WalletPage = () => {
  const isMobile = useCheckIsMobileScreen();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const modalContent = [
    {
      heading: "Hold On",
      description: "We’re processing your payment",
      icon: loading_gif,
    },
    {
      description: "Congratulations withdrawal successful! ",
      icon: tick_circle,
      button: (
        <CustomButton
          children={"Done"}
          className={styles.btn}
          onClick={() => {
            setIsSuccessModalOpen(false);
          }}
        />
      ),
    },
    {
      heading: "Oops!",
      description: "Something went wrong. Please try again",
      icon: error_vector,
      button: (
        <CustomButton
          children={"Try Again"}
          className={styles.btn}
          onClick={() => {
            setIsSuccessModalOpen(false);
          }}
        />
      ),
    },
  ];

  const {
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    types,
    handleWithdrawFunds,
    progress,
    type,
  } = useWallet();

  const { wallet, isLoading } = useMyWallet();

  const { openModal } = useModalAction();

  return (
    <>
      {isSuccessModalOpen && (
        <SuccessModal
          openModal={isSuccessModalOpen}
          closeModal={() => setIsSuccessModalOpen(false)}
          setOpenModal={setIsSuccessModalOpen}
          heading={modalContent[progress].heading}
          icon={modalContent[progress].icon}
          description={modalContent[progress].description}
          button={progress && modalContent[progress].button}
        />
      )}
      <div className={styles.page}>
        <div className="grid gap-y-6 md:grid-cols-12 gap-x-6">
          <div className="md:col-span-8">
            <div className={styles.heading}>
              <div>
                <h2 className={styles.title}>Wallet</h2>
              </div>
            </div>
            <BalanceCard
              extraStyles={{ width: "100%" }}
              title="Balance"
              body={formatMoney(Number(wallet?.ledger_balance ?? 0))}
              icon={<LightningIcon className="text-amber" />}
              handleWithdraw={() => openModal("WITHDRAW_FUNDS")}
            />
            <Divider orientation="left"></Divider>
            {/* <div className={cn(styles.balances, "")}>
                    <AnalyticsCard
                      title={"Total income"}
                      body={formatNumberWithCommas(100_000)}
                      subText="This month"
                    />
                    <AnalyticsCard
                      title={"Top  Paying Activity "}
                      body="Creative"
                      icon={payment}
                      subText="Compared to last month"
                    />
                  </div>
                  <Divider orientation="left"></Divider> */}
            <TransactionHistory />
          </div>
          <div className="md:col-span-4 bg-white">
            {/* <TotalEarnings /> */}
            <div className={cn(styles.cards)}>
              {/* <BankCard
                      title={"Payment method"}
                      buttonText={"Add Payment method"}
                      onClick={() => handleOpenModal("Add payment")}
                    /> */}
              <WithdrawalAccounts
                openAddModal={() => handleOpenModal(types.add_payment)}
              />
            </div>
          </div>

          <CustomModal
            openModal={modalOpen}
            closeModal={handleCloseModal}
            className={styles.modalContainer}
            footer={null}
            childrenStyles={styles.modal}
            width={900}
            bodyStyle={{ padding: "0 24px 20px" }}
            title={
              <div className={styles.modalTitle}>
                <div className={styles.modalheading}>Add Account Details</div>
                <p className={styles.modalSubHeading}>
                  Tell us where you want to receive payments
                </p>
              </div>
            }
          >
            {type === types.add_payment && <AddWithdrawalBank />}
          </CustomModal>
        </div>
      </div>
    </>
  );
};

export default WalletPage;
