import React, { Fragment, useRef, useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Dialog } from "@/components/b/Dialog";
import Close from "@/assets/svgs/Close.svg";
import chevronDown from "@/assets/svgs/chevron-down.svg";
import banknotes from "@/assets/svgs/banknotes.svg";
import Bolt from "@/assets/svgs/Bolt.svg";
import ChekBadge from "@/assets/svgs/check-badge.svg";
import CustomSelect from "@/components/customSelect";
import { CustomInputField } from "../../../customInput";
import { CustomButton } from "@/components/customButton";
import { Form, Input, Select, Button, DatePicker, Divider } from "antd";
import { ALL_COUNTRIES, countries } from "@/mocks";
import dayjs, { Dayjs } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useNavigate, useParams } from "react-router-dom";
import Workspace from "@/pages/Workspace";
import { parse } from "path";
import { toast } from "react-toastify";
import { createBooking } from "@/services/networkRequests/bookingService";
import PhoneInput from "@/components/phoneNumberInput/CustomPhoneNoInput";
import { set } from "lodash";
import PayButton from "./PayButton";
import { calculatePrice } from "@/utils/helpers";
import { formatNumberWithCommas } from "@/utils/formatNumbersWithCommas";
import { useMe } from "@/data/user";

dayjs.extend(LocalizedFormat);
const minDate = dayjs();
interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  services: any;
  reviews: any;
}
interface FormValues {
  numberOfPeople: string;
  serviceType: string;
  service_type_id: any;
  duration: string;
  startDate: any;
  firstName: string;
  lastName: string;
  otherReq?: string;
  phoneNumber: string;
  companyName: string;
  billingOption: string;
}
interface RoleType {
  startDate?: Dayjs | null | undefined;
  serviceType?: string;
  numberOfPeople?: string;
  billingOption: string;
  duration: string;
}
const initialState: RoleType = {
  startDate: dayjs(),
  serviceType: "",
  numberOfPeople: "",
  billingOption: "Daily",
  duration: "1",
};
interface Service {
  name: string;
  available_space: number;
  hourly_price: number;
  weekly_price: number;
  daily_price: number;
  monthly_price: number;
  // Add other properties if necessary
}
console.log(initialState);
const { Option } = Select;
// const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// 	console.log(`checked = ${e.target.checked}`);
// };
const Getquote = ({ isOpen, closeModal, services, reviews }: Prop) => {
  const [form] = Form.useForm();
  const { me: loggedInUser, isLoggedIn } = useMe();
  const params = useParams();
  const id = params?.id;
  const [startData, setStartData] = useState<RoleType>(initialState);
  const [disabledFields, setDisabledFields] = useState(true); // Initially, all fields below are disabled
  const [serviceTypeSelected, setserviceTypeSelected] = useState<string>("");
  const [numberOfPeopleSelected, setNumberOfPeopleSelected] = useState<
    number | 0
  >(0);
  const [available_space, setAvailableSpace] = useState<number | 0>(0);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    // Set disabledFields to true when the modal is opened
    setDisabledFields(true);
  }, [isOpen]);

  useEffect(() => {
    if (serviceTypeSelected) {
      const availableSpace =
        services.find(
          (service: Service) => service.name === serviceTypeSelected
        )?.available_space || 0;
      setAvailableSpace(availableSpace);
    }
  }, [serviceTypeSelected]);

  const [showBillMe, setShowBillMe] = useState(true);

  const handleOk = () => {
    closeModal();
  };
  console.log("serviceTypeSelected:", serviceTypeSelected);
  console.log(
    "services",
    services.find((service: Service) => service.name === serviceTypeSelected)
  );
  const disabledDate = (current: Dayjs | null) => {
    if (!current || !minDate) {
      return false;
    }
    return current.isBefore(minDate, "day");
  };
  const onFinish = (values: FormValues) => {
    console.log("Form valuessss:", values);
    handleOk();
    handleBooking(values);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Form errors:", errorInfo);
  };
  const numberOfPeople = [
    { label: "1 Person", value: "1" },
    { label: "5 People", value: "5" },
    { label: "10 - 20 People", value: "10-20" },
    { label: "20 - 50 People", value: "20-50" },
    { label: "100 and above", value: "100+" },
  ];
  const serviceType = services.map((service: any) => ({
    label: service.name,
    value: service.name,
    id: service.id,
  }));

  //   const serviceType = [
  //     { label: "Shared Desk", value: "Shared Desk" },
  //     {
  //       label: "Dedicated Desk/Private Desk",
  //       value: "Dedicated Desk/Private Desk",
  //     },
  //     { label: "Private office", value: "Private office" },
  //     { label: "Meeting Room", value: "Meeting Room" },
  //     { label: "Event/Conference Rooms", value: "Event/Conference Rooms" },
  //   ];
  // const duration = [
  // 	{ label: "1 Month", value: "1" },
  // 	{ label: "2 Months", value: "2" },
  // 	{ label: "6 Months", value: "6" },
  // 	{ label: "12 Months", value: "12" },
  // ];
  const handleDurationChange = (value: string) => {
    // Check if "Private office" is selected to show the "Bill me" field
    if (value === "") {
      setShowBillMe(false);
    } else {
      setShowBillMe(true);
    }
  };
  const [durationOptions, setDurationOptions] = useState<string>("days");
  const handleBillingOptionChange = (value: string) => {
    setBillingOption(value);
    // Update duration options based on the selected billing option
    if (value === "daily") {
      setDurationOptions("days");
    } else if (value === "hourly") {
      setDurationOptions("hours");
    } else if (value === "weekly") {
      setDurationOptions("weeks");
    } else if (value === "monthly") {
      setDurationOptions("months");
    }
  };
  const handleNumberOfPeopleChange = (value: string) => {
    if (value === "") {
      setNumberOfPeopleSelected(0); // or set it to any default value you prefer
    } else {
      setNumberOfPeopleSelected(parseInt(value));
    }
    console.log("value", value);
  };

  useEffect(() => {
    const handleNumberOfPeopleChange = (numberOfPeopleSelected: string) => {
      if (numberOfPeopleSelected) {
        const P = numberOfPeopleSelected === "";
        const T = serviceTypeSelected === "";
        const A = parseInt(numberOfPeopleSelected) <= available_space;
        if (!P && A && !T) {
          return setDisabledFields(false);
        } else {
          return setDisabledFields(true);
        }
      } else {
        setDisabledFields(true);
      }
    };
    setNumberOfPeopleSelected(numberOfPeopleSelected);
    handleNumberOfPeopleChange(numberOfPeopleSelected.toString());
  }, [numberOfPeopleSelected, serviceTypeSelected, available_space]);

  useEffect(() => {
    const handleserviceTypeChange = (serviceTypeSelected: string) => {
      setserviceTypeSelected(serviceTypeSelected);
      if (serviceTypeSelected) {
        const P = numberOfPeopleSelected === 0;
        const T = serviceTypeSelected === "";
        const A = numberOfPeopleSelected <= available_space;
        if (!P && A && !T) {
          return setDisabledFields(false);
        } else {
          return setDisabledFields(true);
        }
      } else {
        setDisabledFields(true);
      }
    };
    handleserviceTypeChange(serviceTypeSelected.toString());
  }, [serviceTypeSelected, numberOfPeopleSelected, available_space]);
  const handleserviceTypeChange = (value: string) => {
    setserviceTypeSelected(value);
    setDisabledFields(
      value === "" ||
        (startData.serviceType === "" && parseInt(value) > available_space)
    );
  };

  const [duration, setDuration] = useState<number | 1>(1);
  const handleDuration = (value: string) => {
    setDuration(parseInt(value));
    // setShowBillMe(false);
  };
  const costSummary = () => {
    let price = 0;
    if (billingOption === "daily") {
      price = serviceTypeSelected
        ? services.find(
            (service: Service) => service.name === serviceTypeSelected
          )?.daily_price * duration
        : 0;
    } else if (billingOption === "hourly") {
      price = serviceTypeSelected
        ? services.find(
            (service: Service) => service.name === serviceTypeSelected
          )?.hourly_price * duration
        : 0;
    } else if (billingOption === "weekly") {
      price = serviceTypeSelected
        ? services.find(
            (service: Service) => service.name === serviceTypeSelected
          )?.weekly_price * duration
        : 0;
    } else if (billingOption === "monthly") {
      price = serviceTypeSelected
        ? services.find(
            (service: Service) => service.name === serviceTypeSelected
          )?.monthly_price * duration
        : 0;
    }

    return serviceTypeSelected === "Private office" ||
      "Meeting space" ||
      "Event Space" ||
      "Conference Rooms"
      ? price
      : price * numberOfPeopleSelected;
  };

  const generateBillingOptions = (services: any) => {
    const billingOptions = [
      { label: "Hourly", value: "hourly" },
      { label: "Daily", value: "daily" },
      { label: "Weekly", value: "weekly" },
      { label: "Monthly", value: "monthly" },
    ];

    // Check if there are services with the corresponding prices
    const hasHourlyPrice = services.some(
      (service: Service) => service.hourly_price
    );
    const hasDailyPrice = services.some(
      (service: Service) => service.daily_price
    );
    const hasWeeklyPrice = services.some(
      (service: Service) => service.weekly_price
    );
    const hasMonthlyPrice = services.some(
      (service: Service) => service.monthly_price
    );

    // Filter the billing options based on the presence of prices
    const filteredBillingOptions = billingOptions.filter((option) => {
      if (option.value === "hourly" && !hasHourlyPrice) return false;
      if (option.value === "daily" && !hasDailyPrice) return false;
      if (option.value === "weekly" && !hasWeeklyPrice) return false;
      if (option.value === "monthly" && !hasMonthlyPrice) return false;
      return true;
    });

    return filteredBillingOptions;
  };

  const billingOptions = generateBillingOptions(services);
  const [billingOption, setBillingOption] = useState(billingOptions[0].value); // Default to daily

  const handleBooking = async (values: FormValues) => {
    const workspace_id = id;
    const user_id = loggedInUser?.uid;

    if (user_id && workspace_id) {
      const formattedStartDate = dayjs(values.startDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      const endDate = values.startDate.add(duration, durationOptions);
      const formattedEndDate: string = endDate.format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      const selectedserviceType = serviceType.find(
        (space: any) => space.value === values.serviceType
      );

      const payload = {
        booking_details: {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          seats: parseInt(values.numberOfPeople),
          fullname: `${values.firstName} ${values.lastName}`,
          company_info: values.companyName,
          message: values.otherReq,
          booking_price: `${costSummary()}`,
          booking_duration: values.duration,
          service_type_id: selectedserviceType?.id,
          service_type: values.serviceType.toUpperCase(),
          status: "pending check",
          booking_type: billingOption.toLowerCase(),
          phone_number: values.phoneNumber,
        },
      };

      try {
        await createBooking(payload, workspace_id, user_id);
        form.resetFields();
        toast.success("Booking submitted successfully!");
        closeModal();
      } catch (error) {
        toast.error("An error occurred, please try again");
      }
    } else {
      toast.error(
        "User ID or Workspace ID is missing. Please log in or provide a workspace ID."
      );
    }
  };

  return (
    <Fragment>
      <Dialog isOpen={isOpen} close={closeModal}>
        <div className={styles.main}>
          <div className={styles.top}>
            <div>
              <p className={styles.title}>Booking Information</p>
              <p className={styles.subtitle}>
                Thank you for your interest in our space, please fill in your
                information.
              </p>
            </div>
            <div className={styles.close} onClick={closeModal}>
              <img src={Close} />
            </div>
          </div>
          <div className={styles.formContainer}>
            <Form<FormValues>
              name="getQuoteForm"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className={styles.form}
            >
              <div className={styles.formRow}>
                {/* type of space */}
                <Form.Item
                  name="serviceType"
                  label={
                    <span className={styles.label}>
                      What type of space do you want?
                    </span>
                  }
                  className={styles.formItem}
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter the type of space that you want...",
                    },
                  ]}
                >
                  <CustomSelect
                    allowClear={false}
                    options={serviceType}
                    onChange={handleserviceTypeChange}
                    placeHolder="Select an Option"
                  />
                </Form.Item>
                {/* how many people */}
                <Form.Item
                  name="numberOfPeople"
                  label={
                    <span className={styles.label}>
                      How many people is this space for?
                    </span>
                  }
                  className={styles.formItem}
                  rules={[
                    {
                      required: true,
                      message: `Sorry, only ${
                        available_space || 0
                      } seats are available currently.`,
                      validator: (_, value) => {
                        if (value <= available_space) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          `Sorry, only ${
                            available_space || 0
                          } seats are available currently.`
                        );
                      },
                    },
                  ]}
                >
                  {/* <CustomSelect
									options={numberOfPeople}
									placeHolder='Select an Option'
								/> */}
                  <CustomInputField
                    type="number"
                    onChange={(e) => {
                      handleNumberOfPeopleChange(e.target.value);
                    }}
                  />
                </Form.Item>
                {/* duration */}
                {/* Bill me field */}
                {showBillMe && (
                  <Form.Item
                    name="billingOption"
                    label={<span className={styles.label}>Booking type</span>}
                    className={styles.formItem}
                    initialValue={startData.billingOption}
                    rules={[
                      {
                        required: true,
                        message: "Please select a billing option...",
                      },
                    ]}
                  >
                    <CustomSelect
                      options={generateBillingOptions(services)}
                      disabled={disabledFields}
                      onChange={handleBillingOptionChange}
                      placeHolder="Select an Option"
                    />
                  </Form.Item>
                )}
                {/* Duration */}
                <Form.Item
                  name="duration"
                  label={
                    <span className={styles.label}>
                      Duration in {durationOptions || "days"}
                    </span>
                  }
                  className={styles.formItem}
                  initialValue={startData.duration}
                  rules={[
                    {
                      required: true,
                      message: `Please state your duration in ${
                        durationOptions || "days"
                      }`,
                    },
                  ]}
                >
                  <CustomInputField
                    type="number"
                    disabled={disabledFields}
                    onChange={(e) => handleDuration(e.target.value)}
                    // placeHolder="Select an Option"
                  />
                </Form.Item>
                {/* </div> */}
                {/* start date */}
                {/* <Form.Item
								name='startDate'
								label={<span className={styles.label}>Start Date?</span>}
								className={styles.formItem}
								rules={[
									{
										required: true,
										message:
											"Please enter the date you want to start using the space...",
										type: "date",
										// format: "DD/MM/YYYY",
									},
								]}>
								{" "}
								<DatePicker
									placeholder='Set a start date'
									className={styles.input}
								/>{" "}
							</Form.Item> */}
                <Form.Item
                  name="startDate"
                  label={<span className={styles.label}>Start Date?</span>}
                  className={styles.formItem}
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter the date you want to start using the space...",
                    },
                  ]}
                >
                  <DatePicker
                    format={"YYYY/MM/DD"}
                    disabled={disabledFields}
                    className={styles.input}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                {/* first name */}
                <Form.Item
                  name="firstName"
                  className={styles.formItem}
                  label={<span className={styles.label}>First Name</span>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name",
                    },
                  ]}
                >
                  <CustomInputField disabled={disabledFields} type="text" />
                </Form.Item>
                {/* last name */}
                <Form.Item
                  className={styles.formItem}
                  label={<span className={styles.label}>Last Name</span>}
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your last name...",
                    },
                  ]}
                >
                  <CustomInputField disabled={disabledFields} type="text" />
                </Form.Item>
                {/* company name */}
                <Form.Item
                  className={styles.formItem}
                  label={
                    <span className={styles.label}>
                      What is your Company Name (Optional)
                    </span>
                  }
                  name="companyName"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <CustomInputField disabled={disabledFields} type="text" />
                </Form.Item>
                <Form.Item
                  className={styles.formItem}
                  name="phoneNumber"
                  label={<span className={styles.label}>Phone Number</span>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                  ]}
                >
                  <PhoneInput
                    value={form.getFieldValue("phoneNumber")}
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    onChange={(value) =>
                      form.setFieldsValue({ phoneNumber: value })
                    }
                    disabled={disabledFields}
                    onBlur={() =>
                      form.setFieldsValue({
                        phoneNumber: form.getFieldValue("phoneNumber").trim(),
                      })
                    }
                  />
                </Form.Item>
                {/* Promo Code */}
                {/* <Form.Item
									className={styles.formItem}
									label={<span className={styles.label}>Promo Code</span>}
									name='companyName'
									rules={[
										{
											required: false,
										},
									]}>
									<CustomInputField
										type='text'
										disabled={disabledFields}
										placeholder='Enter your Promo code'
									/>
								</Form.Item> */}
              </div>
              <div className={styles.formTextItems}>
                <Form.Item
                  name="otherReq"
                  className={styles.formItem}
                  label={
                    <span className={styles.label}>
                      Additional Information (optional)
                    </span>
                  }
                  //   rules={[
                  //     {
                  //       required: true,
                  //       message: "Please input your country",
                  //     },
                  //   ]}
                >
                  <Input.TextArea
                    className={styles.text}
                    disabled={disabledFields}
                    placeholder=""
                  />
                </Form.Item>
              </div>
              <Divider />
              <div className={styles.bottom}>
                {/* <div className={styles.upper}>
									<div className={styles.couponCont}>
										<div className={styles.coupon}>
											<img className={styles.banknote} src={banknotes} alt='' />
											<input
												type='text'
												placeholder='Enter Co-spire Credit here'
											/>
										</div>
										<p>Apply</p>
									</div>
									<p>Available Co-spire Credit: 10000</p>
								</div> */}
                <div className={styles.lower}>
                  <div className={styles.left}>
                    <p className={styles.total}>Total</p>
                    <p className={styles.price}>
                      ₦{formatNumberWithCommas(calculatePrice(services) || 0)}
                    </p>
                  </div>
                  <div className={styles.right}>
                    {/* <img className={styles.bolt} src={Bolt} alt='' /> */}
                    <div className={styles.rev}>
                      <img src={ChekBadge} />
                      <p className={styles.review}>
                        {reviews.length} Verified Reviews
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <PayButton
                email={loggedInUser?.email}
                amount={calculatePrice(services)}
                name={loggedInUser?.displayName}
                phone={"any"}
                workspace_id={id}
                disabled={costSummary() <= 0}
              />
              {/* <CustomButton
                children={"Book Now"}
                htmlType="submit"
                extraStyles={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              /> */}
            </Form>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};
export default Getquote;
