import React from "react";
import styles from "./index.module.scss";
import { Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useMe } from "@/data/user";

interface ILogIn {
  menuIcon?: any;
  name?: string | null;
  icon?: any;
  isMobile?: boolean;
  toggleMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const LoggedInMenu = ({
  icon,
  name,
  menuIcon,
  isMobile,
  toggleMenu,
}: ILogIn) => {
  const { me: loggedInUser } = useMe();

  return (
    <div className={styles.page} onClick={toggleMenu}>
      {loggedInUser?.photoURL ? (
        <Avatar
          size={isMobile ? "small" : "default"}
          src={loggedInUser.photoURL}
        />
      ) : (
        <Avatar className="bg-primary" size={isMobile ? "small" : "default"}>
          {name?.substring(0, 1).toUpperCase()}
        </Avatar>
      )}
      {menuIcon && <div className={styles.menuIcon}>{menuIcon}</div>}
    </div>
  );
};

export default LoggedInMenu;
