import React, { useState } from "react";
import styles from "./index.module.scss";
import { CustomButton } from "../../../../../components/customButton";
import VerifyBadge from "../../../../../assets/img/verify_meduim_orange.png";
import { featured_verify, success } from "@/assets/img";
import { Divider, Row, Col } from "antd";
import { CustomInputField } from "../../../../../components/customInput";
import ImageUploader from "../../../../../components/customImageUploader";
import CustomSelect from "../../../../../components/customSelect";
import SuccessModal from "../../../../../components/a/Modals/Success";

const VerifyHostWorkspace = () => {
  const [showForm, setShowForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {showForm ? (
        <div className={styles.page}>
          <h2 className={styles.title}>Verify your Workspace</h2>
          <p className={styles.subTitle}>
            Give your workspace more visibility by requesting Workspace
            Verification.
          </p>
          <Divider orientation="left" className={styles.divider}></Divider>
          <div className={styles.form}>
            <Row gutter={[45, 24]} align="bottom">
              <Col span={12} xs={24}>
                <CustomSelect
                  options={[]}
                  onSelect={() => {}}
                  label={"Name of Workspace to be verified"}
                  placeHolder="Choose the of Workspace to be verified"
                  size="large"
                />
              </Col>
              <Col span={12} xs={24}>
                <CustomInputField
                  type={"email"}
                  name={"email"}
                  label="Email Address"
                  placeholder="Enter your Email address"
                  onChange={() => {}}
                />
              </Col>
            </Row>
            <div className={styles.imageUploadContainer}>
              <ImageUploader thumbnails={[]} />
            </div>
            <Row>
              <Col flex="auto">
                <CustomButton
                  children={"Send"}
                  className={styles.btn}
                  onClick={() => setOpenModal(true)}
                />
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className={styles.page}>
          <div className={styles.verify}>
            <div className={styles.img}>
              <img src={featured_verify} alt="featured" />
            </div>
            <div className={styles.content}>
              <img src={VerifyBadge} alt="verify" />
              <p className={styles.title}>Verify Workspace</p>
              <p className={styles.description}>
                Give your workspace more visibility by requesting Workspace
                Verification.
              </p>
              <CustomButton
                children={"Request Workspace verification"}
                className={styles.button}
                onClick={() => setShowForm(true)}
              />
            </div>
          </div>
        </div>
      )}

      {openModal && (
        <SuccessModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          heading={"Verification Sent"}
          icon={success}
          description={
            "Lorem ipsum dolor sit amet consectetur. Massa diam varius adipiscing mauris. Rutrum luctus amet nisl eget nulla blandit bibendum."
          }
        />
      )}
    </>
  );
};

export default VerifyHostWorkspace;
