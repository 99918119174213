export const CloseCircleIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.95312 10.1973L14.4531 14.6973M14.4531 10.1973L9.95312 14.6973M21.2031 12.4473C21.2031 17.4178 17.1737 21.4473 12.2031 21.4473C7.23256 21.4473 3.20312 17.4178 3.20312 12.4473C3.20312 7.4767 7.23256 3.44727 12.2031 3.44727C17.1737 3.44727 21.2031 7.4767 21.2031 12.4473Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
