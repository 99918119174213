import React, { useState } from "react";
import styles from "./bookForTeams.module.scss";
import {
  community1,
  about_us,
  community2,
  location,
  offices,
} from "@/assets/img";
import arrowright from "@/assets/svgs/arrow-long-right.svg";
// import { Button } from "antd";
import Button from "@/components/c/Button";
import users from "../../assets/svgs/users.svg";
import check from "../../assets/svgs/check.svg";
import { useNavigate } from "react-router-dom";
import { TeamsBooking } from "@/components/a/Modals";
import { TabTitle } from "@/utils/titleFunction";

const BookForTeams = () => {
  TabTitle("co-spire | book-for-team ");

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleGetAccess = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();
  return (
    <div className={styles.page}>
      {isOpen && <TeamsBooking isOpen={isOpen} closeModal={closeModal} />}
      <div className={styles.banner}>
        <div className={styles.bannerContent}>
          <h1 className={styles.title}>Booking for Teams</h1>
          <p className={styles.description}>
            Need to book coworking spaces for your large team? We created this
            for you!
          </p>
          <Button
            onClick={handleGetAccess}
            type="filled"
            children="Book Now!"
          />
        </div>
      </div>
      {isOpen && <TeamsBooking isOpen={isOpen} closeModal={closeModal} />}

      <section className={styles.aboutInfo}>
        <div className={styles.aboutInfoContent}>
          <div className={styles.img1}>
            <img src={about_us} alt="aboutus" />
          </div>

          <div className={styles.aboutInfoText}>
            <p className={styles.subtitle}>CO-SPIRE FOR TEAMS</p>
            <h1 className={styles.title}>How it works</h1>
            <div className={styles.how}>
              <div className={styles.step}>
                <p className={styles.number}>1</p>
                <p className={styles.details}>
                  View and select a suitable plan for your team
                </p>
              </div>

              <div className={styles.step}>
                <p className={styles.number}>2</p>
                <p className={styles.details}>
                  Supply the required information to complete registration for
                  your team.
                </p>
              </div>

              <div className={styles.step}>
                <p className={styles.number}>3</p>
                <p className={styles.details}>
                  Find as many coworking spaces as you want that meet your needs
                  and book slots ahead for your team right away.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.insights}>
        <p className={styles.title}>Designed to accommodate your team</p>
        <div className={styles.statsContainer}>
          <div className={styles.statContainer}>
            <div className={styles.stats}>
              <img src={users} alt="user_group" />
              <span className={styles.subtitle}>Small Group</span>
              <p className={styles.description}>
                Team sizes typically less than or exactly 20 are well
                accommodated in our plans.
              </p>
            </div>
          </div>

          <div className={styles.statContainer}>
            <div className={styles.stats}>
              <img src={users} alt="user_group" />
              <span className={styles.subtitle}>Large Group</span>
              <p className={styles.description}>
                From team sizes of 20 and above, we have the doors flung open to
                our wide network of coworking spaces waiting for you and your
                team.
              </p>
            </div>
          </div>

          <div className={styles.statContainer}>
            <div className={styles.stats}>
              <img src={offices} alt="office" />
              <span className={styles.subtitle}>Corporate Organisation</span>
              <p className={styles.description}>
                How quickly will you like to expand your business or
                organization's reach? With CO-SPIRE Book for Teams, you can
                spread as wide and as far as you want to.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className={styles.bookForTeams}  >

				<div className={styles.top} >
					<p className={styles.subtitle}>Pricing</p>
					<h1 className={styles.title}>Flexible plans to suit you</h1>
				</div>

				<div className={styles.body} >

					{
						pricingPackages.map((pricingPackage) => {

							if (pricingPackage.name === "Business Plan") return (
								<div className={`${styles.businessCard} `}>
									{pricingPackage.name === "Business Plan" && <div className={styles.businessTop} > Most popular plan</div>}
									<div className={`${styles.card} ${styles.cardTopBorder}`}  >
										<div className={styles.cardTop} >
											<h1 className={styles.price} >${pricingPackage.price}/year</h1>
											<p className={styles.plan} >{pricingPackage.name}</p>
											<p className={styles.billing} >{pricingPackage.billed}</p>
										</div>
										<ul className={styles.properties} >
											{
												pricingPackage.properties?.map((property) => (
													<div className={styles.property} >
														<img className={styles.icon} src={check} alt="" />
														<li  >{property}</li>
													</div>

												))
											}
										</ul>
										<div className={styles.btnContainer}>
											<Button type="transparent" onClick={handleGetAccess} className={styles.button} children="Get Access" />
										</div>
									</div>
								</div>
							)


							return (
								<div>
									<div className={`${styles.card} ${styles.rounded}`} >
										<div className={styles.cardTop} >
											<h1 className={styles.price} >${pricingPackage.price}{pricingPackage.name == "Free" ? "" : "/ltd"} </h1>
											<p className={styles.plan} >{pricingPackage.name}</p>
											<p className={styles.billing} >{pricingPackage.billed}</p>
										</div>
										<ul className={styles.properties} >
											{
												pricingPackage.properties?.map((property) => (
													<div className={styles.property} >
														<img className={styles.icon} src={check} alt="" />
														<li  >{property}</li>
													</div>

												))
											}
										</ul>
										<div className={styles.btnContainer}>
											<Button onClick={handleGetAccess} type="transparent" className={styles.button} children="Get Access" />
										</div>
									</div>
								</div>

							)

						})}
				</div>


				


			</section> */}

      <section className={styles.contactSales}>
        <div className={styles.contact}>
          <h1>NEED TO TALK TO SOMEONE?</h1>
          <p>
            A dedicated professional awaits at the other end to kick start a
            conversation with you anytime and any day. Just tell us a few things
            about your company, and we’ll reach out within 24 business hours.
          </p>
          <Button type="transparent" children="Contact Sale" />
        </div>
      </section>
    </div>
  );
};

export default BookForTeams;

const pricingPackages = [
  {
    name: "Free",
    price: 0,
    billed: "Auto Renew",
    properties: [
      "A super simple icon list element.",
      "It has several list items you can easily edit.",
      "Delete rows you don't need.",
      "Duplicate existing rows to add more.",
      "Change the icons per row.",
    ],
  },
  {
    name: "Business Plan",
    price: 99,
    billed: "Billed Annually",
    properties: [
      "A super simple icon list element.",
      "It has several list items you can easily edit.",
      "Delete rows you don't need.",
      "Duplicate existing rows to add more.",
      "Change the icons per row.",
    ],
  },
  {
    name: "Enterprise Plan",
    price: 129,
    billed: "Billed Once",
    properties: [
      "A super simple icon list element.",
      "It has several list items you can easily edit.",
      "Delete rows you don't need.",
      "Duplicate existing rows to add more.",
      "Change the icons per row.",
    ],
  },
];
