import { RequestResponse } from "../../types";
import {
  CREATE_WORKSPACE,
  LIST_WORKSPACE,
  LIST_WORKSPACES,
  CREATE_REVIEW,
  UPDATE_WORKSPACE,
  LIST_REVIEW,
  CANCELLATION_POLICY,
  LIST_SPACE_REVIEWS,
} from "../apiEndpoints";
import { axiosPrivate } from "../axiosPrivate";

const createWorkSpace = async (payload: any) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL + CREATE_WORKSPACE}`;
  const response: RequestResponse = await axiosPrivate.post(endpoint, payload);
  return response;
};

const allWorkSpaces = async () => {
  const endpoint = `${process.env.REACT_APP_BASE_URL + LIST_WORKSPACES}`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response;
};

const cancelPolicy = async (ccp_id: string) => {
  const endpoint = `${
    process.env.REACT_APP_BASE_URL + CANCELLATION_POLICY + ccp_id
  }`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response;
};

const listWorkSpaces = async (
  currentPage?: number,
  PageSize?: number,
  PageAction?: any
) => {
  const sendAction = PageAction ? `&pageAction=${PageAction}` : "";
  const endpoint = `${
    process.env.REACT_APP_BASE_URL + LIST_WORKSPACES
  }/?page=${currentPage}&pageSize=${PageSize}${sendAction}`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response.data;
};

const listUserWorkSpaces = async (currentPage?: number, PageSize?: number) => {
  const endpoint = `${
    process.env.REACT_APP_BASE_URL + LIST_WORKSPACES
  }/?page=${currentPage}&pageSize=${PageSize}`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response.data;
};

const getWorkSpace = async (workspace_id: string) => {
  const endpoint = `${
    process.env.REACT_APP_BASE_URL + LIST_WORKSPACE + workspace_id
  }`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response;
};

const updateWorkSpace = async (payload: any, workspace_id: string) => {
  const endpoint = `${
    process.env.REACT_APP_BASE_URL + UPDATE_WORKSPACE + workspace_id
  }`;
  const response: RequestResponse = await axiosPrivate.patch(endpoint, payload);
  return response;
};

const createReview = async (payload: any) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL + CREATE_REVIEW}`;
  const response: RequestResponse = await axiosPrivate.post(endpoint, payload);
  return response;
};

const listReview = async (workspace_id: string) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL + LIST_REVIEW + workspace_id}`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response;
};

const listAllReview = async (workspace_id: string) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL + LIST_SPACE_REVIEWS + workspace_id + "/spaces"}`;
  const response: RequestResponse = await axiosPrivate.get(endpoint);
  return response;
};

export {
  createWorkSpace,
  getWorkSpace,
  listWorkSpaces,
  listUserWorkSpaces,
  createReview,
  updateWorkSpace,
  listReview,
  listAllReview,
  allWorkSpaces,
  cancelPolicy,
};
