export const MarkerIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.35989 15.1246C6.38254 15.1377 6.40035 15.1479 6.41296 15.155L6.4339 15.1666C6.60105 15.2584 6.8084 15.2577 6.97571 15.1669L6.9972 15.155C7.00981 15.1479 7.02761 15.1377 7.05027 15.1246C7.09557 15.0984 7.16031 15.0601 7.2417 15.01C7.40443 14.9098 7.63414 14.762 7.90854 14.5682C8.45639 14.1811 9.18739 13.6069 9.91999 12.8568C11.3781 11.3639 12.8926 9.12095 12.8926 6.23633C12.8926 2.81907 10.1223 0.0488281 6.70508 0.0488281C3.28782 0.0488281 0.517578 2.81907 0.517578 6.23633C0.517578 9.12095 2.03207 11.3639 3.49017 12.8568C4.22277 13.6069 4.95376 14.1811 5.50162 14.5682C5.77602 14.762 6.00573 14.9098 6.16845 15.01C6.24985 15.0601 6.31458 15.0984 6.35989 15.1246ZM6.70508 8.48633C7.94772 8.48633 8.95508 7.47897 8.95508 6.23633C8.95508 4.99369 7.94772 3.98633 6.70508 3.98633C5.46244 3.98633 4.45508 4.99369 4.45508 6.23633C4.45508 7.47897 5.46244 8.48633 6.70508 8.48633Z"
        fill="currentColor"
      />
    </svg>
  );
};
