import { useQuery } from "react-query";
import { API_ENDPOINTS } from "./client/api-endpoints";
import { useAuth } from "@/components/auth/use-auth";
import { User } from "@/types/user";
import client from "./client";

export function useMe() {
  const { userLoggedIn } = useAuth();
  const { data, isLoading, error } = useQuery<{ data: User }, Error>(
    [API_ENDPOINTS.USERS_ME],
    client.users.me,
    {
      enabled: userLoggedIn,
    }
  );
  return {
    me: data?.data ?? null,
    isLoading,
    error,
    isLoggedIn: userLoggedIn,
  };
}
