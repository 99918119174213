import { PaymentMethod, Subscription } from "@/types/subscription";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "./client/api-endpoints";
import client from "./client";

export function usePaymentMethodsQuery() {
  const { data, isLoading, error } = useQuery<{ data: PaymentMethod[] }, Error>(
    [API_ENDPOINTS.SUBSCRIPTIONS.PAYMENT_METHODS],
    client.subscriptions.payment_methods
  );
  return {
    methods: data?.data ?? null,
    isLoading,
    error,
  };
}

export const useCreateSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.subscriptions.create, {
    // Always refetch after error or success:
    // onSettled: () => {
    //   queryClient.invalidateQueries(
    //     API_ENDPOINTS.SUBSCRIPTIONS.PAYMENT_METHODS
    //   );
    // },
  });
};

export function useActiveSubscription() {
  const { data, isLoading, error } = useQuery<{ data: Subscription }, Error>(
    [API_ENDPOINTS.SUBSCRIPTIONS.ME],
    client.subscriptions.me
  );
  return {
    subscription: data?.data ?? null,
    isLoading,
    error,
  };
}
