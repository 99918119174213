import React, { useRef, Fragment, useState } from "react";
import styles from "./index.module.scss";
import { Dialog } from "@/components/b/Dialog";
import Close from "@/assets/svgs/Close.svg";
import { Form, Input, Select, Checkbox, DatePicker, Button } from "antd";
import { CustomInputField } from "@/components/customInput";
import CustomSelect from "@/components/customSelect";
import { ALL_COUNTRIES } from "../../../../mocks";
import { CustomButton } from "@/components/customButton";
import CustomDatePicker from "@/components/customDatePicker";
import dayjs, { Dayjs } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { toast } from "react-toastify";
// import { createBooking } from "@/Services/networkRequests/bookingService";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SuccessModal from "@/components/a/Modals/Success";
import { success } from "@/assets/img";
import { useMe } from "@/data/user";

dayjs.extend(LocalizedFormat);

const minDate = dayjs();

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
}

interface FormValues {
  businessEmail: string;
  typeOfSpace: string;
  duration: string;
  numberOfPeople: number;
  otherReq: string;
  startDate: Date;
  firstName: string;
  lastName: string;
  companyName?: string;
}

const typeOfSpace = [
  { label: "Office Desk", value: "office-desk" },
  { label: "Private office", value: "private-office" },
  { label: "Meeting Room", value: "meeting-room" },
  { label: "Conference Rooms", value: "conference-room" },
];

const duration = [
  { label: "1 Month", value: "1" },
  { label: "2 Months", value: "2" },
  { label: "6 Months", value: "6" },
  { label: "12 Months", value: "12" },
];

interface RoleType {
  startDate?: Dayjs | null | undefined;
}

const initialState: RoleType = {
  startDate: dayjs(),
};

console.log(initialState);

const TeamsBooking = ({ isOpen, closeModal }: Prop) => {
  const [form] = Form.useForm();
  const { me: loggedInUser, isLoggedIn } = useMe();
  const params = useParams();
  const id = params?.id;
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOk = () => {
    // closeModal();
    setIsSuccessModalOpen(true);
  };

  const onFinish = (values: FormValues) => {
    console.log("Form values:", values);
    handleOk();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Form errors:", errorInfo);
  };
  const [startData, setStartData] = useState<RoleType>(initialState);
  const disabledDate = (current: Dayjs | null) => {
    if (!current || !minDate) {
      return false;
    }
    return current.isBefore(minDate, "day");
  };

  const handleBooking = async (values: FormValues) => {
    // const fullName = form.getFieldValue("fullName");
    const teamsBooking = form.getFieldValue("teamsBooking");
    const payload = {
      user_id: loggedInUser?.uid,
      // teams_booking_content: teamsBooking,
      ...values,
      workspace_id: id,

      // fullName: fullName,
    };

    //   try {
    //     await createBooking(payload);

    //     // Clear the form fields after successful submission
    //     form.resetFields();

    //     // Show success toast message
    //     toast.success("Review submitted successfully!");

    //     // Close the modal
    //     closeModal();
    //   } catch (error) {
    //     toast.error("An error occured, please try again");
    //   }
  };

  return (
    <Fragment>
      <SuccessModal
        openModal={isSuccessModalOpen}
        setOpenModal={setIsSuccessModalOpen}
        heading={"Enquiry Received!"}
        closeModal={() => {
          setIsSuccessModalOpen(false);
          closeModal();
          // navigate('/book-for-teams/');
        }}
        icon={success}
        description={
          "Lorem ipsum dolor sit amet consectetur. Amet vitae odio elit a pharetra. Vulputate aliquam purus orci turpis odio in morbi netus. "
        }
      />
      <Dialog isOpen={isOpen} close={closeModal}>
        <div className={styles.main}>
          <div className={styles.top}>
            <h2>Team Enquiry</h2>
            <div className={styles.close} onClick={closeModal}>
              <img src={Close} />
            </div>
          </div>
          <p>
            Thank you for your interest in our space, please fill in your
            information.
          </p>

          <Form<FormValues>
            name="teamsBookingForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            size={"large"}
            autoComplete="off"
            className={styles.form}
          >
            <div className={styles.formItems}>
              <Form.Item
                name="businessEmail"
                className={styles.formItem}
                label={
                  <span className={styles.label}>Business Email Address</span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please input your business email address.",
                  },
                ]}
              >
                <Input type="text" className={styles.input} placeholder="" />
              </Form.Item>
            </div>

            <div className={styles.formItems}>
              <Form.Item
                name="firstName"
                className={styles.formItem}
                label={<span className={styles.label}>First Name</span>}
                rules={[
                  {
                    required: true,
                    message: "Please input your first name",
                  },
                ]}
              >
                <Input type="text" className={styles.input} placeholder="" />
              </Form.Item>

              <Form.Item
                name="lastName"
                className={styles.formItem}
                label={<span className={styles.label}>Last Name</span>}
                rules={[
                  {
                    required: true,
                    message: "Please input your last name",
                  },
                ]}
              >
                <Input type="text" className={styles.input} placeholder="" />
              </Form.Item>
            </div>

            {/* company's name locatio  */}
            <div className={styles.formItems}>
              <Form.Item
                name="companyName"
                className={styles.formItem}
                label={
                  <span className={styles.label}>
                    What is your Company Name
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please input conmpanys' name",
                  },
                ]}
              >
                <Input type="text" className={styles.input} placeholder="" />
              </Form.Item>

              <Form.Item
                name="location"
                label={<span className={styles.label}>Location</span>}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: "Please enter your preferred location...",
                  },
                ]}
              >
                <CustomSelect
                  options={ALL_COUNTRIES}
                  placeHolder="Select your preferred Location"
                />
              </Form.Item>
            </div>

            <div className={styles.formItems}>
              <Form.Item
                name="numberOfPeople"
                label={
                  <span className={styles.label}>
                    How many people is this space for?
                  </span>
                }
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of people",
                  },
                ]}
              >
                <CustomInputField type="number" />
              </Form.Item>

              <Form.Item
                name="typeOfSpace"
                label={
                  <span className={styles.label}>
                    What type of space do you want?
                  </span>
                }
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: "Please enter the type of space that you want...",
                  },
                ]}
              >
                <CustomSelect
                  options={typeOfSpace}
                  placeHolder="Select an Option"
                />
              </Form.Item>
            </div>

            <div className={styles.formItems}>
              <Form.Item
                name="duration"
                label={
                  <span className={styles.label}>
                    How long do you want to book the space for ?
                  </span>
                }
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: "Please state how long you want to book for...",
                  },
                ]}
              >
                <CustomSelect
                  options={duration}
                  placeHolder="Select an Option"
                />
              </Form.Item>

              <Form.Item
                name="startDate"
                label={<span className={styles.label}>Start Date?</span>}
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter the date you want to start using the space...",
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY/MM/DD"}
                  className={styles.datepicker}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </div>

            <div className={styles.formTextItems}>
              <Form.Item
                name="otherReq"
                className={styles.formItem}
                label={<span className={styles.label}>Other Requirements</span>}
                //   rules={[
                //     {
                //       required: true,
                //       message: "Please input your country",
                //     },
                //   ]}
              >
                <Input.TextArea className={styles.text} placeholder="" />
              </Form.Item>
            </div>

            <CustomButton
              children={"Submit Enquiry"}
              htmlType="submit"
              type="primary"
            />
          </Form>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default TeamsBooking;
