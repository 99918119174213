import React, { useState, useRef, useEffect } from "react";
import styles from "./NotLoggedInHeader.module.scss";
import { useNavigate } from "react-router-dom";
import Logo from "../../../../assets/svgs/logo-large.svg";

import Button from "@/components/c/Button";
import { LOGIN, WORKSPACES } from "../../../../routes/routes";
import useCheckIsMobileScreen from "../../../../hooks/useCheckIsMobileScreen";
import Menu from "../../../c/Menu";
import { useAppDispatch } from "@/redux/hook";
import { Drawer } from "antd";
import { useModalAction } from "@/components/modal-view/context";

interface IHeader {
  openModal: (type: string) => void;
}

const NotLoggedInHeader = ({}: IHeader) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useCheckIsMobileScreen();
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  const { openModal } = useModalAction();

  const showLoading = () => {
    setOpen(true);
    setLoading(true);

    // Simple loading mock. You should add cleanup logic in real world.
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [showMenu]);

  if (isMobile) {
    return (
      <div className={styles.navbar}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <img src={Logo} alt="logo" onClick={() => navigate("/")} />
          </div>
          <div onClick={showLoading}>
            <Menu />
          </div>
        </div>

        <Drawer
          closable
          destroyOnClose
          placement="right"
          open={open}
          onClose={() => setOpen(false)}
          className={styles.custumDrawer}
        >
          <div className={styles.navright}>
            <p
              onClick={() => {
                navigate(WORKSPACES);
                setOpen(false);
              }}
              className={styles.exp}
            >
              Explore Spaces
            </p>

            <div className={styles.button}>
              <Button
                type="nav"
                onClick={() => {
                  navigate("/listing");
                  setOpen(false);
                }}
              >
                List Your Space +
              </Button>
            </div>
            <div className={styles.links}>
              <p
                className={styles.text}
                onClick={() => {
                  navigate(LOGIN);
                  setOpen(false);
                }}
              >
                Login
              </p>
              <div className={styles.line}></div>
              <p
                className={styles.text}
                onClick={() => {
                  openModal("REGISTER");
                  setOpen(false);
                }}
              >
                Sign Up
              </p>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }

  return (
    <div className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={Logo} alt="logo" onClick={() => navigate("/")} />
          {/* <p onClick={() => navigate("/book-for-teams")} className={styles.text}>Book for teams</p> */}
        </div>
        <div className={styles.buttons}>
          <div className={styles.links}>
            <p onClick={() => navigate(WORKSPACES)}>Explore Spaces</p>
            <p
              onClick={() => {
                console.log("login");

                openModal("LOGIN_VIEW");
              }}
            >
              Login
            </p>
            <p
              onClick={() => {
                console.log("register");

                openModal("REGISTER");
              }}
            >
              Sign Up
            </p>
          </div>
          <div className={styles.button} onClick={() => navigate("/listing")}>
            <Button type="nav">List Your Space +</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotLoggedInHeader;
