import React from "react";
import styles from "./footer.module.scss";
import { footerLinks } from "../../../mocks";
import { Link } from "react-router-dom";
import logo_white from "@/assets/svgs/logo-cospire-svg-footer.svg";
import useCheckIsMobileScreen from "../../../hooks/useCheckIsMobileScreen";
// import Image from 'react-image';

const Footer = () => {
  const isMobile = useCheckIsMobileScreen();
  const privacyLink =
    "https://drive.google.com/file/d/1BbY8lTb3EwGlsi98fBq4-jtYaxO83SZT/view?usp=sharing";

  return (
    <div className={styles.page}>
      {isMobile ? (
        <>
          <div className={styles.container}>
            <div className={styles.linksCtn}>
              {footerLinks.map((item, index) => {
                return (
                  <div className={styles.links} key={index}>
                    <h1 className={styles.title}>{item.title}</h1>
                    <ul className={styles.list}>
                      {item.links.map((link, index) => {
                        return (
                          <Link
                            className={styles.link}
                            key={index}
                            to={link.path}
                          >
                            {link.name}
                          </Link>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>

            <div className={styles.subscribe}>
              <div className={styles.subscribeText}>
                <h1 className={styles.title}>Join over 10K+ Subscribers</h1>
                <p className={styles.text}>
                  Be the first to hear about a new opportunity. Subscribe to
                  join the Co-spire tribe now!
                </p>
              </div>
              <div className={styles.subscribeAction}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className={styles.input}
                />
                <button type="submit" className={styles.btn}>
                  Subscribe
                </button>
              </div>
            </div>

            <div className={styles.copyright}>
              <div className={styles.logo}>
                <img src={logo_white} alt="logo" />
              </div>
              <div className={styles.privacy}>
                <p className={styles.text}>
                  © 2023 Co-spire. All right reserved.
                </p>
                <p>
                  {/* <Link className={styles.text} to={privacyLink}>
										Privacy Policy
									</Link>
									<Link className={styles.text} to={privacyLink}>
										Terms of service
									</Link> */}
                  <a href={privacyLink} className={styles.text}>
                    Privacy Policy
                  </a>
                  <a href={privacyLink} className={styles.text}>
                    Terms of service
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.container}>
          <div className={styles.linksCtn}>
            {footerLinks.map((item, index) => {
              return (
                <div className={styles.links} key={index}>
                  <h1 className={styles.title}>{item.title}</h1>
                  <ul className={styles.list}>
                    {item.links.map((link, index) => {
                      return (
                        <Link
                          className={styles.link}
                          key={index}
                          to={link.path}
                        >
                          {link.name}
                        </Link>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className={styles.subscribe}>
            <div className={styles.subscribeText}>
              <h1 className={styles.title}>Join over 10K+ Subscribers</h1>
              <p className={styles.text}>
                Be the first to hear about a new opportunity. Subscribe to join
                the Co-spire tribe now!
              </p>
            </div>
            <div className={styles.subscribeAction}>
              <input
                type="email"
                placeholder="Enter your email"
                className={styles.input}
              />
              <button type="submit" className={styles.btn}>
                Subscribe
              </button>
            </div>
          </div>
          <div className={styles.copyright}>
            <div className={styles.logo}>
              <img src={logo_white} alt="logo" />
              <p className={styles.text}>
                © 2023 Co-spire. All right reserved.
              </p>
            </div>
            <div className={styles.privacy}>
              {/* <Link className={styles.text} to={"#"}>
								Privacy Policy
							</Link>
							<Link className={styles.text} to={"#"}>
								Terms of service
							</Link> */}
              <a href={privacyLink} className={styles.text}>
                Privacy Policy
              </a>
              <a href={privacyLink} className={styles.text}>
                Terms of service
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
