import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "@/utils/cropUtils"; // Your cropping utility function
import styles from "./index.module.scss";
import { Dialog } from "@/components/b/Dialog";
import Close from "@/assets/svgs/Close.svg";

interface CropModalProps {
  isOpen: boolean;
  closeModal: () => void;
  imageToCrop: string | null;
  onCropComplete: (croppedImage: string) => void;
}

const CropModal: React.FC<CropModalProps> = ({
  isOpen,
  closeModal,
  imageToCrop,
  onCropComplete,
}) => {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const handleCropComplete = async (
    croppedArea: any,
    croppedAreaPixels: any
  ) => {
    const croppedImage = await getCroppedImg(
      imageToCrop as string,
      croppedAreaPixels
    );
    setCroppedImage(croppedImage);
  };

  const handleSave = () => {
    if (croppedImage) {
      onCropComplete(croppedImage);
      closeModal();
    }
  };

  return (
    <Dialog isOpen={isOpen} close={closeModal}>
      <div className={styles.main}>
        <div className={styles.top}>
          <h2>Crop Image</h2>
          <div className={styles.close} onClick={closeModal}>
            <img src={Close} alt="Close" />
          </div>
        </div>
        <div className={styles.content}>
          {imageToCrop && (
            <div className={styles.cropperContainer}>
              <Cropper
                image={imageToCrop}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            </div>
          )}
        </div>
        <div className={styles.controls}>
          <button onClick={handleSave}>Save</button>
          <button onClick={closeModal}>Cancel</button>
        </div>
      </div>
    </Dialog>
  );
};

export default CropModal;
