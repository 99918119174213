import { ReactComponent as BackIcon } from '../../assets/svgs/backIcon.svg'
import { ReactComponent as Star } from '../../assets/svgs/ratingStar.svg'
import { ReactComponent as VerifiedIcon } from '../../assets/svgs/verifiedIcon.svg'
import { ReactComponent as CircumscribedCloseIcon } from '../../assets/svgs/circumscribedCloseIcon.svg'
import { ReactComponent as BankNotesIcon } from '../../assets/svgs/bankNotesIcons.svg'
import { ReactComponent as FlashIcon } from '../../assets/svgs/flashIcon.svg'
import {ReactComponent as DeleteIcon} from '../../assets/svgs/Trash.svg'
export type iconTypes = 
    | 'backIcon'
    | 'star'
    | 'verifiedIcon'
    | 'circumscribedCloseIcon'
    | 'bankNotesIcon'
    | 'flashIcon'
    | 'deleteIcon'
 

interface IconInterface {
    name: iconTypes;
    svgProps?: React.SVGProps<SVGSVGElement>;
}

const CustomIcons = ({name, svgProps} : IconInterface) => {
    const icons: Record<iconTypes, JSX.Element> = {
        backIcon : <BackIcon {...svgProps} />,
        star: <Star {...svgProps} />,
        verifiedIcon:<VerifiedIcon {...svgProps}/>,
        circumscribedCloseIcon: <CircumscribedCloseIcon {...svgProps}/>,
        bankNotesIcon: <BankNotesIcon {...svgProps} />,
        flashIcon: <FlashIcon {...svgProps} />,
        deleteIcon: <DeleteIcon {...svgProps} />,
      
    }

    return icons[name]
}

export default CustomIcons