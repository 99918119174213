import { useQuery } from "react-query";
import {
  ActivitiesPaginator,
  ActivitiesQueryOptions,
} from "@/types/activities";
import { API_ENDPOINTS } from "./client/api-endpoints";
import client from "./client";
import { CategoriesPaginator, CategoriesQueryOptions } from "@/types/category";

export const useCategoriesQuery = (
  options?: Partial<CategoriesQueryOptions>
) => {
  const { data, isLoading, error } = useQuery<CategoriesPaginator, Error>(
    [API_ENDPOINTS.CATEGORIES, options],
    ({ queryKey, pageParam }) =>
      client.categories.paginated(Object.assign({}, queryKey[1], pageParam)),
    {
      keepPreviousData: true,
    }
  );

  return {
    categories: data?.data ?? [],
    loading: isLoading,
    error,
  };
};
