import React from "react";
import styles from "./index.module.scss";
import Location from "@/assets/svgs/map-pin-black";
import Star from "@/assets/svgs/Vector.svg";
import Close from "@/assets/svgs/Close-white.svg";
import { Splide, SplideSlide, SplideProps } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useNavigate } from "react-router-dom";

type WorkspacePointProps = {
  onClick: () => void;
  name: string;
};

export function WorkspacePoint(props: WorkspacePointProps) {
  return (
    <div className={styles.point} onClick={props.onClick}>
      <Location color="#0047AB" />
    </div>
  );
}

type WorkspaceCardProps = {
  id: number;
  name?: string;
  handleClose?: () => void;
  imgs?: any;
  amount?: string;
  rating?: number;
};

export function WorkspaceCard(props: WorkspaceCardProps) {
  const navigate = useNavigate();
  return (
    <div className={styles.popup}>
      <div className={styles.slideImg}>
        <img src={Close} className={styles.close} onClick={props.handleClose} />
        <div className={styles.imgs}>
          <Splide
            aria-labelledby="listing"
            options={{
              rewind: true,
              perPage: 1,
              gap: "1.5rem",
              classes: {
                arrows: "splide__arrows",
                arrow: "splide__arrow",
                prev: "splide__arrow--prev splide-list-prev",
                next: "splide__arrow--next splide-list-next",
                pagination: "splide__pagination splide-list-pagination",
              },
            }}
          >
            {props?.imgs?.map((img: any, idx: number) => (
              <SplideSlide key={idx}>
                <img
                  src={img}
                  className={styles.img}
                  onClick={() => navigate(`/workspace/${props.id}`)}
                />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.top}>
          <h2 onClick={() => navigate(`/workspace/${props.id}`)}>
            {props.name}
          </h2>
          {props.rating !== 0 && (
            <div>
              <img src={Star} />
              <p>{props.rating}</p>
            </div>
          )}
        </div>
        <div className={styles.bottom}>
          <p>{`₦${props.amount}`}</p> <p> | Cospire Space</p>
        </div>
      </div>
    </div>
  );
}
