import AddOnTemplate from "@/components/Subscriptions/AddOnTemplate";

interface AddOnProps {
  onAddonSelect: (plan: any) => void;
}
const AddOns = ({ onAddonSelect }: AddOnProps) => {
  const addOnServices = [
    {
      title: "iwalkTroo™️",
      about:
        "Activate virtual live tours for your listings and allow renters have seamless booking experience.",
      amount: "10,000",
      period: "per month",
      ctaText: "See plan options below",
      offerings: [
        "WalkTru Basic - 2 tours/month",
        "walkTru Standard - 5 tours/month",
        "walkTru Premium - unlimited tours",
      ],
      ctaButtonText: "Buy Add-on",
      note: "Note: This Add-on can be bought alone",
      bgColor: "bg-[#FFE9B8]",
      type: "radio",
    },
    {
      title: "CO-SPIRE Verified",
      about: "Verify your listings and get more bookings",
      amount: "2,500",
      period: "per month",
      ctaText: "Fast and easy verification",
      offerings: [
        "Higher visibility in search results",
        "Trust and credibility ",
        "Verified badge on listings",
      ],
      ctaButtonText: "Buy Add-on",
      note: "Note: This Add-on can be bought alone",
      bgColor: "bg-lightBlue",
      type: "list",
    },
  ];

  const handleSubscriptionPlan = (plan: any) => {
    onAddonSelect(plan);
  };
  return (
    <div className="grid place-items-center gap-8">
      <div className="grid gap-y-4 place-items-center">
        <p className="text-2xl leading-8">Add-on services</p>
        <p className="leading-4">
          Special features you can add to your booking with or without a
          subscription
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {addOnServices.map((item, index) => (
          <AddOnTemplate
            item={item}
            handleSubscriptionPlan={handleSubscriptionPlan}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default AddOns;
