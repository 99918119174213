import React, { useRef } from "react";
import { Splide, SplideSlide, SplideProps } from "@splidejs/react-splide";
import styles from "./splide.module.scss"

interface ISplide {
   thumbnails?: string[];
   space?: any;
   onClick?: () => void
   className?: string
}

const Splider = ({ thumbnails, space, onClick, className }: ISplide) => {
   const sliderRef = useRef(null);
   return (
      <div className={className}>
         <Splide
            aria-labelledby='listing'
            onMove={() => {}}
            ref={sliderRef}
            options={{
               rewind: true,
               perPage: 1,
               gap: "1.5rem",
               classes: {
                  arrows: "splide__arrows splide__arrows-list",
                  arrow: "splide__arrow",
                  prev: "splide__arrow--prev splide-list-prev",
                  next: "splide__arrow--next splide-list-next",
                  pagination: "splide__pagination splide-list-pagination",
               },
               arrowPath:
                  "M18.9342 0.599936L26.4009 8.0666C26.5342 8.19993 26.6285 8.34438 26.6836 8.49994C26.7396 8.65549 26.7676 8.82216 26.7676 8.99994C26.7676 9.17771 26.7396 9.34438 26.6836 9.49994C26.6285 9.65549 26.5342 9.79993 26.4009 9.93327L18.9342 17.3999C18.6898 17.6444 18.384 17.7666 18.0169 17.7666C17.6507 17.7666 17.3342 17.6444 17.0676 17.3999C16.8009 17.1333 16.6676 16.8168 16.6676 16.4506C16.6676 16.0835 16.8009 15.7666 17.0676 15.4999L22.2342 10.3333L2.00091 10.3333C1.62313 10.3333 1.30669 10.2057 1.05158 9.9506C0.795578 9.6946 0.667578 9.37771 0.667578 8.99994C0.667578 8.62216 0.795578 8.30571 1.05158 8.0506C1.30669 7.7946 1.62313 7.6666 2.00091 7.6666L22.2342 7.6666L17.0676 2.4666C16.8231 2.22216 16.6951 1.91682 16.6836 1.5506C16.6729 1.18349 16.8009 0.866602 17.0676 0.599936C17.312 0.355492 17.6231 0.233267 18.0009 0.233267C18.3787 0.233267 18.6898 0.355492 18.9342 0.599936Z",
            }}
         >
            {thumbnails?.map((item: string, idx: number) => (
               <SplideSlide key={idx}>
                  <img
                     src={item}
                     alt={space.title}
                     onClick={onClick}
                  />
               </SplideSlide>
            ))}
         </Splide>
      </div>
   );
};

export default Splider;
