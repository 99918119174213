import React, { useState } from "react";
import styles from "./index.module.scss";
import { Select } from "antd";
import type { SelectProps } from 'antd';

interface Option {
   // value?: string;
   // label?: string;
   [key: string]: string;
}

interface CustomSelectProps {
   key?: any;
   options?: SelectProps['options'];
   onSelect?: (value: string) => void;
   error?: string | undefined | number | boolean;
   defaultValue?: string | null | undefined
   label?: string;
   name?: string
   value?: string
   secLabel?: string;
   size?: "large" | "middle" | "small";
   extraStyles?: React.CSSProperties;
   className?: string;
   placeHolder?: string;
   optionFilterProp?: string;
   showSearch?: boolean;
   disabled?: boolean; 
   onSearch?: (value: string) => void;
   onChange?: (value: string) => void;
   onBlur?: () => void;
   filterOption?: boolean;
   allowClear?: boolean;
   children?: React.ReactNode
}


const CustomSelect: React.FC<CustomSelectProps> = ({
   key,
   options,
   onSelect,
   value,
   label,
   secLabel,
   size,
   extraStyles,
   className,
   placeHolder,
   optionFilterProp,
   onChange,
   onSearch,
   showSearch = true,
   disabled = false,
   filterOption = true,
   error,
   onBlur,
   defaultValue,
   allowClear = true,
   children
}) => {
   return (
      <div className={styles.selectContainer}>
         {label && <label className={styles.label}>{label}</label>}
         {secLabel && <p className={styles.secondaryLabel}>{secLabel}</p>}
         <Select
            key={key}
            showSearch={showSearch}
            filterOption={filterOption}
            virtual={false}
            defaultValue={defaultValue}
            value={value}
            allowClear={allowClear}
            size={size}
            bordered={false}
            style={extraStyles}
            className={className ? className : styles.select}
            placeholder={placeHolder}
            optionFilterProp={optionFilterProp}
            onChange={onChange}
            onSelect={onSelect}
            onBlur={onBlur}
            onSearch={onSearch}
            disabled={disabled}
            options={options}
            dropdownStyle={{ overflow: 'hidden' }}
         >
           {children}
         </Select>
         {error && typeof error === "string" && (
            <p className={styles.error}>{error}</p>
         )}
      </div>
   );
};

export default CustomSelect;
