import React, { useState } from "react";
import { Dialog } from "@/components/b/Dialog";
import Close from "@/assets/svgs/Close.svg";
import styles from "./servicesWeb.module.scss";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  services: any;
}

function ServicesWeb({ isOpen, closeModal, services }: Prop) {
  return (
    <Dialog isOpen={isOpen} close={closeModal}>
      {/* {services.map((item:any)=>( */}

      <div className={styles.main}>
        <div className={styles.top}>
          <h2>{services.name}</h2>
          <div className={styles.close} onClick={closeModal}>
            <img src={Close} />
          </div>
        </div>

        <div className={styles.content}>
          {services.service_description || "no decscription yet"}
        </div>
      </div>
      {/* ))} */}
    </Dialog>
  );
}

export default ServicesWeb;
