import LoginForm from "@/components/a/Forms/LoginForm";
import { MODAL_VIEWS, useModalAction, useModalState } from "./context";
// import Modal from "@/components/modal-views/modal";
import { Dialog as Modal } from "@/components/b/Dialog";
import ForgotPassword from "@/components/a/Forms/ForgotPassword";
import RegisterUserForm from "@/components/a/Forms/RegisterUserForm";
import { WithdrawalMethods } from "@/components/wallet/modals/withdrawal-methods";
import { ConfirmRemoveBank } from "@/components/wallet/modals/withdrawal-methods/confirm-remove";
import { WithdrawFunds } from "@/components/wallet/modals/withdraw-funds";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function renderModalContent(view: MODAL_VIEWS) {
  switch (view) {
    case "REGISTER":
      return <RegisterUserForm />;
    case "LOGIN_VIEW":
      return <LoginForm />;
    case "FORGOT_PASSWORD_VIEW":
      return <ForgotPassword />;
    case "WITHDRAWAL_METHODS":
      return <WithdrawalMethods />;
    case "CONFIRM_REMOVE_BANK":
      return <ConfirmRemoveBank />;
    case "WITHDRAW_FUNDS":
      return <WithdrawFunds />;
    default:
      return null;
  }
}

export default function ModalsContainer() {
  const { view, isOpen } = useModalState();
  const { closeModal } = useModalAction();

  const location = useLocation();

  useEffect(() => {
    closeModal();
  }, [location.pathname]); // triggered on route change

  return (
    <Modal hasInput isOpen={isOpen} close={closeModal}>
      {view && renderModalContent(view)}
    </Modal>
  );
}
