import { useParams } from "react-router-dom";
import { useMyWorkspaceQuery } from "@/data/workspace";
import { UpdateSpaceForm } from "@/components/listing-space/forms/update-space";

export default function UpdateSpacePage() {
  const params = useParams<{ id: string }>();

  const {
    workspace,
    isLoading: loading,
    error,
  } = useMyWorkspaceQuery(params.id!, {
    enabled: !!params.id,
  });

  if (error) {
    return <div>An error occurred</div>;
  }
  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <UpdateSpaceForm workspace={workspace!} />
      )}
    </>
  );
}
