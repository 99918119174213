import React, { useState } from "react";
import styles from "./previewWorkspace.module.scss";

import {
  chair,
  laptop,
  office_icon,
  swimming,
  community,
  brunch,
  time_blue,
} from "@/assets/img";
import { Col, Row, Divider, Checkbox, Space } from "antd";
import { CustomButton } from "../../../../../components/customButton";
import useCheckIsMobileScreen from "../../../../../hooks/useCheckIsMobileScreen";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";
import usePreviewWorkspace from "./usePreviewWorkspace";
import { useNavigate } from "react-router-dom";
import { ADD_SPACE_DETAILS } from "../../../../../routes/routes";
import Splider from "../../../../../components/splide/splide";
import { RightOutlined } from "@ant-design/icons";
import Spinner from "../../../../../components/spinner/Spinner";
import { setEditModeOn } from "../../../../../redux/rootReducer";
import { TabTitle } from "@/utils/titleFunction";
import { useMe } from "@/data/user";

const PreviewWorkspace = () => {
  TabTitle("co-spire | work spaces");
  const isMobile = useCheckIsMobileScreen();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { me: loggedInUser } = useMe();
  const { workspace, workspaceIsLoading, workspaceIsError } =
    usePreviewWorkspace();

  return (
    <div className={styles.page}>
      {workspaceIsLoading && <Spinner size={"large"} />}

      {!workspaceIsLoading && !workspaceIsError && (
        <section className={styles.container}>
          <div className={styles.breadcrumbs}>
            {" "}
            <span className={styles.first} onClick={() => navigate(-1)}>
              Settings
            </span>{" "}
            <RightOutlined className={styles.arrow} />{" "}
            <span className={styles.label}>My Workspace</span>{" "}
          </div>
          <div className={styles.banner}></div>

          <div className={styles.content}>
            {isMobile ? (
              <>
                <div className={styles.slider}>
                  <Splider
                    thumbnails={workspace?.photos}
                    space={workspace?.title}
                    className={styles.img}
                  />
                </div>
                <div className={styles.header}>
                  <Row>
                    <Col className={styles.heading}>
                      <span>WORKSPACE DETAILS</span>
                    </Col>
                  </Row>
                  <Row wrap>
                    <Col span={24} className={styles.text}>
                      <p className={styles.text}>{workspace?.info}</p>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <>
                <section className={styles.gridImages}>
                  {workspace?.photos.map((thumbnail, i) => {
                    return (
                      <div
                        className={styles[`items${i}`]}
                        style={{
                          background: `url(${thumbnail}) no-repeat center`,
                          backgroundSize: "cover",
                        }}
                        key={`items${i}`}
                      ></div>
                    );
                  })}
                </section>
                <div className={styles.header}>
                  <Row justify="space-between">
                    <Col className={styles.heading}>
                      <span>WORKSPACE DETAILS</span>
                    </Col>
                    <Col>
                      <CustomButton
                        children={"Edit  Details"}
                        className={styles.btnWhite}
                        onClick={() => {
                          dispatch(setEditModeOn(true));
                          navigate(`${ADD_SPACE_DETAILS}/description`);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row wrap>
                    <Col span={24} className={styles.text}>
                      <p className={styles.text}>{workspace?.info}</p>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            <div className={styles.contentDetails}>
              <div className={styles.sections}>
                <Row gutter={[24, 24]} wrap>
                  <Col md={5} xs={12} flex={"auto"}>
                    <div className={styles.textContainer}>
                      <h1 className={styles.subHeading}>Workspace Name</h1>
                      <p className={styles.text}>{workspace?.title}</p>
                    </div>
                  </Col>
                  <Col md={5} xs={12}>
                    <div className={styles.textContainer}>
                      <h1 className={styles.subHeading}>Email Address</h1>
                      <p className={styles.text}>
                        {workspace?.space_contact?.space_email}
                      </p>
                    </div>
                  </Col>
                  <Col md={5} xs={12}>
                    <div className={styles.textContainer}>
                      <h1 className={styles.subHeading}>Location</h1>
                      <p className={styles.text}>{workspace?.location}</p>
                    </div>
                  </Col>
                  <Col md={5} xs={12}>
                    <div className={styles.textContainer}>
                      <h1 className={styles.subHeading}>Owner’s Full Name</h1>
                      <p className={styles.text}>{loggedInUser?.displayName}</p>
                    </div>
                  </Col>
                  <Col md={5} xs={12} flex={"auto"}>
                    <div className={styles.textContainer}>
                      <h1 className={styles.subHeading}>Website</h1>
                      <p className={styles.linkText}>
                        {workspace?.space_contact?.website_url}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Divider orientation="left"></Divider>
              </div>

              {/* socials */}
              <div className={styles.sections}>
                <Row wrap>
                  <Col className={styles.heading}>
                    <span>SOCIAL MEDIA INFORMATION</span>
                  </Col>
                </Row>
                <Row gutter={[24, 24]} wrap>
                  <Col md={6} xs={12}>
                    <div className={styles.textContainer}>
                      <h1 className={styles.subHeading}>FACEBOOK</h1>
                      <a
                        href={workspace?.space_contact?.social_links?.facebook}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.linkText}
                      >
                        {workspace?.space_contact?.social_links?.facebook}
                      </a>
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div className={styles.textContainer}>
                      <h1 className={styles.subHeading}>TWITTER</h1>
                      <a
                        href={workspace?.space_contact?.social_links?.twitter}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.linkText}
                      >
                        {workspace?.space_contact?.social_links?.twitter}
                      </a>
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div className={styles.textContainer}>
                      <h1 className={styles.subHeading}>INSTAGRAM</h1>
                      <a
                        href={workspace?.space_contact?.social_links?.instagram}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.linkText}
                      >
                        {workspace?.space_contact?.social_links?.instagram}
                      </a>
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div className={styles.textContainer}>
                      <h1 className={styles.subHeading}>LINKEDIN</h1>
                      <a
                        href={workspace?.space_contact?.social_links?.linkedin}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.linkText}
                      >
                        {workspace?.space_contact?.social_links?.linkedin}
                      </a>
                    </div>
                  </Col>
                </Row>
                <Divider orientation="left"></Divider>
              </div>

              {/* services */}
              <div className={styles.sections}>
                <Row>
                  <Col className={styles.heading}>
                    <span>SERVICES OFFERED</span>
                  </Col>
                </Row>
                <Row gutter={[24, 48]}>
                  {workspace?.services &&
                    workspace?.services?.length > 0 &&
                    workspace?.services?.map((space) => {
                      return (
                        <Col md={6} xs={24} key={space.name}>
                          <div className={styles.iconText}>
                            <img
                              className={styles.icon}
                              src={office_icon}
                              alt="laptop"
                            />
                            <p className={styles.text}>{space.name}</p>
                          </div>
                        </Col>
                      );
                    })}
                  {workspace?.services && workspace?.services?.length === 0 && (
                    <Col flex="auto" className={styles.iconText}>
                      <p className={styles.text}>N/A</p>
                    </Col>
                  )}
                </Row>
                <Divider orientation="left"></Divider>
              </div>
              {/* Amenities */}
              <div className={styles.sections}>
                <Row>
                  <Col className={styles.heading}>
                    <span>AMENITIES</span>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  {workspace?.service_amenities &&
                    workspace?.service_amenities.length > 0 &&
                    workspace?.service_amenities.map((amenity, i) => {
                      return (
                        <Col
                          md={6}
                          xs={24}
                          flex="auto"
                          className={styles.iconText}
                          key={i}
                        >
                          <img
                            className={styles.icon}
                            src={time_blue}
                            alt="icon"
                          />
                          <p className={styles.text}>{amenity.name}</p>
                        </Col>
                      );
                    })}
                </Row>
                <Divider orientation="left"></Divider>
              </div>

              {/* OPENING HOURS */}
              <div className={styles.sections}>
                <Row>
                  <Col className={styles.heading}>
                    <span>OPENING HOURS</span>
                  </Col>
                </Row>
                <div className={styles.time}>
                  <Col className={styles.tag}>
                    <span className={styles.span}>Monday</span>
                    <span className={styles.time}>
                      {workspace?.working_hours?.monday}
                    </span>
                  </Col>
                  <Col className={styles.tag}>
                    <span className={styles.span}>Tuesday</span>
                    <span className={styles.time}>
                      {workspace?.working_hours?.tuesday}
                    </span>
                  </Col>
                  <Col className={styles.tag}>
                    <span className={styles.span}>Wednesday</span>
                    <span className={styles.time}>
                      {workspace?.working_hours?.wednesday}
                    </span>
                  </Col>
                  <Col className={styles.tag}>
                    <span className={styles.span}>Thursday</span>
                    <span className={styles.time}>
                      {workspace?.working_hours?.thursday}
                    </span>
                  </Col>
                  <Col className={styles.tag}>
                    <span className={styles.span}>Friday</span>
                    <span className={styles.time}>
                      {workspace?.working_hours?.friday}
                    </span>
                  </Col>
                  <Col className={styles.tag}>
                    <span className={styles.span}>Saturday</span>
                    <span className={styles.time}>
                      {workspace?.working_hours?.saturday}
                    </span>
                  </Col>
                  <Col className={styles.tag}>
                    <span className={styles.span}>Sunday</span>
                    <span className={styles.time}>
                      {workspace?.working_hours?.sunday}
                    </span>
                  </Col>
                </div>
                <Divider orientation="left"></Divider>
              </div>

              {/* HOLIDAYS */}
              <div className={styles.sections}>
                <Row gutter={24}>
                  <Col className={styles.heading}>
                    <span>HOLIDAYS</span>
                  </Col>
                </Row>
                <div className={styles.time}>
                  {workspace?.holiday &&
                    workspace?.holiday.length > 0 &&
                    workspace?.holiday.map((holiday, i) => {
                      return (
                        <Col className={styles.tag} key={i}>
                          <span className={styles.span}>
                            {holiday.holidayName}
                          </span>
                          <span className={styles.time}>
                            {holiday.holidayDate}
                          </span>
                        </Col>
                      );
                    })}
                </div>
                <Divider orientation="left"></Divider>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default PreviewWorkspace;
