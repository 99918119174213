import { useQuery } from "react-query";
import {
  ActivitiesPaginator,
  ActivitiesQueryOptions,
} from "@/types/activities";
import { API_ENDPOINTS } from "./client/api-endpoints";
import client from "./client";

export const useActivitiesQuery = (
  options?: Partial<ActivitiesQueryOptions>
) => {
  const { data, isLoading, error } = useQuery<ActivitiesPaginator, Error>(
    [API_ENDPOINTS.ACTIVITIES.BASE, options],
    ({ queryKey, pageParam }) =>
      client.activities.paginated(Object.assign({}, queryKey[1], pageParam)),
    {
      keepPreviousData: true,
    }
  );

  return {
    activities: data?.data ?? [],
    loading: isLoading,
    error,
  };
};
