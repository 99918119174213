import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/hook";
import Link from "antd/es/typography/Link";
import { CUSTOMER_MENU, PROFILE_TABS_HOST } from "../../mocks";
import { CUSTOMER_PROFILE, HOST_PROFILE } from "../../routes/routes";
import { useMe } from "@/data/user";

const breadCrumbs: { [key: string]: string } = {
  host_dashboard: "Dashboard",
  workspaces: "My Workspaces",
  verify_workspace: "Verify Workspace",
  bookings: "Bookings",
  transactions_payments: "Transactions",
  wallet: "Wallet",
  saved_spaces: "Saved Spaces",
  booking_history: "Booking History",
  account_settings: "Account Settings",
};

enum tabs {
  // host_dashboard = "Dashboard",
  workspaces = "My Workspaces",
  verify_workspace = "Verify Workspace",
  bookings = "Bookings",
  transactions_payments = "Transactions",
  wallet = "Wallet",
  saved_spaces = "Saved Spaces",
  booking_history = "Booking History",
  account_settings = "Account Settings",
  customer_dashboard = "customer_dashboard",
}

export type TabProps = typeof tabs;

export interface ITabs {
  label: string;
  icon: string;
  slug: string;
  path: string;
  id: number;
  subNav?: ITabs[];
}

export const useDashBoard = () => {
  const location = useLocation();
  const { isLoggedIn, me: loggedInUser } = useMe();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { tab } = useParams();
  // console.log("tab", tab)
  const { pathname } = useLocation();
  const roleType = loggedInUser?.role_type;
  const [activeTab, setActiveTab] = useState<string>(
    roleType === "Host" ? "host_dashboard" : "booking_history"
  );

  const tablist: ITabs[] =
    roleType === "Host" ? PROFILE_TABS_HOST : CUSTOMER_MENU;
  const path = roleType === "Host" ? HOST_PROFILE : CUSTOMER_PROFILE;

  // Handle undefined tab
  const currentTab = breadCrumbs[location.pathname.split("/")[2]];

  // console.log(pathname)

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  return {
    isLoggedIn,
    loggedInUser,
    activeTab,
    setActiveTab,
    tabs,
    navigate,
    tablist,
    currentTab,
    path,
    pathname,
  };
};
