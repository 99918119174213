import React, { useRef, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { CUSTOMER_MENU, HOST_TABS, PROFILE_TABS_HOST } from "../../../mocks";
import { Avatar } from "antd";
import useHostWorkspaces from "../../../pages/Host/Profile/components/MyWorkspaces/useHostWorkspaces";
import { signOut } from "../../../firebase/utils";
import { logout } from "../../../assets/img";
import { office_icon_bw } from "../../../assets/img";
import RevenueCard from "@/pages/Host/Profile/components/RevenueCard/RevenueCard";
import { useNavigate } from "react-router-dom";
import { CloseCircleOutlined, UserOutlined } from "@ant-design/icons";
import Button from "@/components/c/Button";
import useCheckIsMobileScreen from "@/hooks/useCheckIsMobileScreen";
import { cn } from "@/lib/utils";
import { EARNINGS_PAYMENT, SUBSCRIPTIONS } from "../../../routes/routes";
import { useMe } from "@/data/user";

interface IMenuDropDown {
  role: string;
  user?: any;
  onSelect?: () => void;
  showMenu: (state: boolean) => void;
}

const MenuDropDown = ({ role, showMenu }: IMenuDropDown) => {
  const { hostWorkSpaces } = useHostWorkspaces();
  const navigate = useNavigate();
  const isMobile = useCheckIsMobileScreen();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { me: loggedInUser } = useMe();
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const currentHour = new Date().getHours();
    let newGreeting = "Good evening"; // Default

    if (currentHour < 12) {
      newGreeting = "Good morning";
    } else if (currentHour < 18) {
      newGreeting = "Good afternoon";
    }

    setGreeting(newGreeting);
  }, []);

  let TABS;
  switch (role) {
    case "Host":
      TABS = hostWorkSpaces
        ? hostWorkSpaces?.length > 0
          ? PROFILE_TABS_HOST
          : HOST_TABS
        : "";
      break;
    case "Customer":
      TABS = CUSTOMER_MENU;
      break;
    default:
      TABS = [];
      break;
  }

  const handleIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    showMenu(false);
  };

  const handleLogout = () => {
    navigate("/");
    showMenu(false);
    signOut();
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      showMenu(false);
    }
  };

  // useEffect(() => {
  //    if (showMenu(false)) {
  //       document.addEventListener("click", handleDocumentClick);
  //    } else {
  //       document.removeEventListener("click", handleDocumentClick);
  //    }

  //    return () => {
  //       document.removeEventListener("click", handleDocumentClick);
  //    };
  // }, [showMenu(false)]);

  if (isMobile) {
    return (
      <>
        {role === "Host" ? (
          <div className={styles.menu} ref={dropdownRef}>
            {/* <div className={styles.closeBar} onClick={handleIconClick}>
                        <CloseCircleOutlined className={styles.close} />
                     </div> */}
            <div className={styles.navright}>
              <div className={styles.customerCorner}>
                {loggedInUser?.photoURL ? (
                  <Avatar size={59} src={loggedInUser?.photoURL} />
                ) : (
                  <Avatar size={59} icon={<UserOutlined />} />
                )}
                <div className={styles.customerText}>
                  <p>{greeting}</p>
                  <p className={styles.nameStyle}>
                    {loggedInUser?.displayName!.substring(0, 12)}
                  </p>
                  <p style={{ fontSize: "14px" }}>{loggedInUser?.email}</p>
                </div>
              </div>
              <div className={styles.button}>
                <Button
                  type="nav"
                  onClick={() => {
                    navigate("space-description");
                    showMenu(false);
                  }}
                >
                  List Your Space +
                </Button>
              </div>
              <div className={styles.line}></div>
              <div className={styles.links}>
                {/* <p
                              className={styles.text}
                              style={{ cursor: "default", fontWeight: 500 }}
                           >
                              Menu
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 showMenu(false);
                              }}
                           >
                              Dashboard
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 navigate("/listing");
                                 showMenu(false);
                              }}
                           >
                              Listings
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 showMenu(false);
                              }}
                           >
                              Bookings
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 showMenu(false);
                              }}
                           >
                              Calendar
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 navigate("/messages");
                                 showMenu(false);
                              }}
                           >
                              Messages
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 showMenu(false);
                              }}
                           >
                              Add-Ons
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 navigate("/workspace");
                                 showMenu(false);
                              }}
                           >
                              Explore Spaces
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 showMenu(false);
                              }}
                           >
                              Notifications
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 showMenu(false);
                              }}
                           >
                              Ratings & Review
                           </p> */}
                <p className={styles.text} onClick={() => showMenu(false)}>
                  Subscriptions
                </p>
                <p className={styles.text} onClick={() => showMenu(false)}>
                  Earnings and payments
                </p>
              </div>
              <div className={styles.line}></div>
              <div className={styles.links}>
                <p
                  className={styles.text}
                  style={{ cursor: "default", fontWeight: 500 }}
                >
                  Account
                </p>
                <p
                  className={styles.text}
                  onClick={() => {
                    navigate(
                      role === "Host"
                        ? "/host-profile/account_settings/profile_settings"
                        : "/customer-profile/account_settings/profile_settings"
                    );
                    showMenu(false);
                  }}
                >
                  Profile
                </p>
                <p
                  className={styles.text}
                  onClick={() => {
                    showMenu(false);
                  }}
                >
                  Switch to renter
                </p>
                <p
                  className={styles.text}
                  onClick={() => {
                    navigate(
                      role === "Host"
                        ? "/host-profile/account_settings"
                        : "/customer-profile/account_settings"
                    );
                    showMenu(false);
                  }}
                >
                  Account settings
                </p>
              </div>
              <div className={styles.line}></div>
              <div className={styles.links}>
                <p
                  className={styles.text}
                  style={{ cursor: "default", fontWeight: 500 }}
                >
                  Help & Support
                </p>
                <p
                  className={styles.text}
                  onClick={() => {
                    showMenu(false);
                  }}
                >
                  Contact CO-SPIRE
                </p>
                <p
                  className={styles.text}
                  onClick={handleLogout}
                  style={{ color: "#FF4B55", paddingTop: "20px" }}
                >
                  Logout
                </p>
                <p
                  className={styles.text}
                  onClick={() => {
                    showMenu(false);
                  }}
                  style={{ color: "#FF4B55" }}
                >
                  Logout
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.menu} ref={dropdownRef}>
            <div className={styles.closeBar} onClick={handleIconClick}>
              <CloseCircleOutlined className={styles.close} />
            </div>
            <div className={styles.navright}>
              <div className={styles.customerCorner}>
                {loggedInUser?.photoURL ? (
                  <Avatar size={59} src={loggedInUser?.photoURL} />
                ) : (
                  <Avatar size={59} icon={<UserOutlined />} />
                )}
                <div className={styles.customerText}>
                  <p>{greeting}</p>
                  <p className={styles.nameStyle}>
                    {loggedInUser?.displayName!.substring(0, 12)}
                  </p>
                  <p style={{ fontSize: "14px" }}>{loggedInUser?.email}</p>
                </div>
              </div>
              <div className={styles.button}>
                <Button
                  type="nav"
                  onClick={() => {
                    navigate("space-description");
                    showMenu(false);
                  }}
                >
                  List Your Space +
                </Button>
              </div>
              <div className={styles.line}></div>
              {/* <div className={styles.links}>
                           <p
                              className={styles.text}
                              style={{ cursor: "default", fontWeight: 500 }}
                           >
                              Menu
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 navigate("/workspace");
                                 showMenu(false);
                              }}
                           >
                              Explore Spaces
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 showMenu(false);
                              }}
                           >
                              Saved spaces
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 showMenu(false);
                              }}
                           >
                              Bookings
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 navigate("/messages");
                                 showMenu(false);
                              }}
                           >
                              Messages
                           </p>
                           <p
                              className={styles.text}
                              onClick={() => {
                                 showMenu(false);
                              }}
                           >
                              Notifications
                           </p>
                        </div> */}
              {/* <div className={styles.line}></div> */}
              <div className={styles.links}>
                <p
                  className={styles.text}
                  style={{ cursor: "default", fontWeight: 500 }}
                >
                  Account
                </p>
                <p
                  className={styles.text}
                  onClick={() => {
                    navigate(
                      role === "Host"
                        ? "/host-profile/account_settings/profile_settings"
                        : "/customer-profile/account_settings/profile_settings"
                    );
                    showMenu(false);
                  }}
                >
                  Profile
                </p>
                <p
                  className={styles.text}
                  onClick={() => {
                    showMenu(false);
                  }}
                >
                  Become a space owner
                </p>
                <p
                  className={styles.text}
                  onClick={() => {
                    navigate(
                      role === "Host"
                        ? "/host-profile/account_settings"
                        : "/customer-profile/account_settings"
                    );
                    showMenu(false);
                  }}
                >
                  Account settings
                </p>
              </div>
              <div className={styles.line}></div>
              <div className={styles.links}>
                <p
                  className={styles.text}
                  style={{ cursor: "default", fontWeight: 500 }}
                >
                  Help & Support
                </p>
                <p
                  className={styles.text}
                  onClick={() => {
                    showMenu(false);
                  }}
                >
                  Contact CO-SPIRE
                </p>
                <p
                  className={styles.text}
                  onClick={handleLogout}
                  style={{ color: "#FF4B55", paddingTop: "20px" }}
                >
                  Logout
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {role === "Host" ? (
        <div className={styles.menu} ref={dropdownRef}>
          <div className={styles.navright}>
            <div className={styles.customerCorner}>
              {loggedInUser?.photoURL ? (
                <Avatar size={59} src={loggedInUser?.photoURL} />
              ) : (
                <Avatar size={59} icon={<UserOutlined />} />
              )}
              <div className={styles.customerText}>
                <p>{greeting}</p>
                <p className={styles.nameStyle}>
                  {loggedInUser?.displayName!.substring(0, 12)}
                </p>
                <p className="text-sm">{loggedInUser?.email}</p>
              </div>
            </div>
            <div className={styles.button}>
              <Button
                type="nav"
                onClick={() => {
                  navigate("space-description");
                  showMenu(false);
                }}
              >
                List Your Space +
              </Button>
            </div>
            <div className={styles.line}></div>
            <div className={styles.links}>
              <p className={cn(styles.text, "cursor-default font-medium")}>
                Menu
              </p>
              {/* <p className={styles.text} onClick={() => showMenu(false)}>
                Dashboard
              </p>
              <p className={styles.text} onClick={() => showMenu(false)}>
                Notifications
              </p>
              <p className={styles.text} onClick={() => showMenu(false)}>
                Ratings & Review
              </p> */}
              <p
                className={styles.text}
                onClick={() => {
                  navigate(SUBSCRIPTIONS);
                  showMenu(false);
                }}
              >
                Subscriptions
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  navigate(EARNINGS_PAYMENT);
                  showMenu(false);
                }}
              >
                Earnings and payments
              </p>
            </div>
            <div className={styles.line}></div>
            <div className={styles.links}>
              <p
                className={styles.text}
                style={{ cursor: "default", fontWeight: 500 }}
              >
                Account
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  navigate(
                    role === "Host"
                      ? "/host-profile/account_settings/profile_settings"
                      : "/customer-profile/account_settings/profile_settings"
                  );
                  showMenu(false);
                }}
              >
                Profile
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  showMenu(false);
                }}
              >
                Switch to renter
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  navigate(
                    role === "Host"
                      ? "/host-profile/account_settings"
                      : "/customer-profile/account_settings"
                  );
                  showMenu(false);
                }}
              >
                Account settings
              </p>
            </div>
            <div className={styles.line}></div>
            <div className={styles.links}>
              <p
                className={styles.text}
                style={{ cursor: "default", fontWeight: 500 }}
              >
                Help & Support
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  showMenu(false);
                }}
              >
                Contact CO-SPIRE
              </p>
              <p
                className={styles.text}
                onClick={handleLogout}
                style={{ color: "#FF4B55", paddingTop: "20px" }}
              >
                Logout
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.menu} ref={dropdownRef}>
          {/* <div className={styles.closeBar} onClick={handleIconClick}>
                     <CloseCircleOutlined className={styles.close} />
                  </div> */}
          <div className={styles.navright}>
            <div className={styles.customerCorner}>
              {loggedInUser?.photoURL ? (
                <Avatar size={59} src={loggedInUser?.photoURL} />
              ) : (
                <Avatar size={59} icon={<UserOutlined />} />
              )}
              <div className={styles.customerText}>
                <p>{greeting}</p>
                <p className={styles.nameStyle}>
                  {loggedInUser?.displayName!.substring(0, 12)}
                </p>
                <p style={{ fontSize: "14px" }}>{loggedInUser?.email}</p>
              </div>
            </div>
            <div className={styles.button}>
              <Button
                type="nav"
                onClick={() => {
                  navigate("space-description");
                  showMenu(false);
                }}
              >
                List Your Space +
              </Button>
            </div>
            <div className={styles.line}></div>
            {/* <div className={styles.links}>
                        <p
                           className={styles.text}
                           style={{ cursor: "default", fontWeight: 500 }}
                        >
                           Menu
                        </p>
                        <p
                           className={styles.text}
                           onClick={() => {
                              showMenu(false);
                           }}
                        >
                           Saved spaces
                        </p>
                        <p
                           className={styles.text}
                           onClick={() => {
                              showMenu(false);
                           }}
                        >
                           Notifications
                        </p>
                     </div> */}
            {/* <div className={styles.line}></div> */}
            <div className={styles.links}>
              <p
                className={styles.text}
                style={{ cursor: "default", fontWeight: 500 }}
              >
                Account
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  navigate(
                    role === "Host"
                      ? "/host-profile/account_settings/profile_settings"
                      : "/customer-profile/account_settings/profile_settings"
                  );
                  showMenu(false);
                }}
              >
                Profile
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  showMenu(false);
                }}
              >
                Become a space owner
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  navigate(
                    role === "Host"
                      ? "/host-profile/account_settings"
                      : "/customer-profile/account_settings"
                  );
                  showMenu(false);
                }}
              >
                Account settings
              </p>
            </div>
            <div className={styles.line}></div>
            <div className={styles.links}>
              <p
                className={styles.text}
                style={{ cursor: "default", fontWeight: 500 }}
              >
                Help & Support
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  showMenu(false);
                }}
              >
                Contact CO-SPIRE
              </p>
              <p
                className={styles.text}
                onClick={() => {
                  showMenu(false);
                  signOut();
                }}
                style={{ color: "#FF4B55", paddingTop: "20px" }}
              >
                Logout
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuDropDown;
