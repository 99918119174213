import React, { useEffect, useState } from "react";
import { getAllBookings } from "@/services/networkRequests/bookingService";
import useHostWorkspaces from "../MyWorkspaces/useHostWorkspaces";
import { toast } from "react-toastify";
import { useMe } from "@/data/user";

interface DataType {
  key?: string;
  customerInformation: {
    name: string;
    createdDate: Date;
  };
  bookingStatus: string;
  amount: number;
  paymentStatus: string;
}

const data: DataType[] = [
  {
    key: "1",
    customerInformation: {
      name: "Customer Name",
      createdDate: new Date("2023-09-06"),
    },
    bookingStatus: "Ongoing",
    amount: 5000,
    paymentStatus: "Approved",
  },
  {
    key: "2",
    customerInformation: {
      name: "Customer Name",
      createdDate: new Date("2023-09-06"),
    },
    bookingStatus: "Cancelled",
    amount: 5000,
    paymentStatus: "Pending",
  },
];

const useHost = () => {
  const [bookings, setBookings] = useState<any>([]);
  const [bookTest, setBookTest] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(false);
  const isProPlan: boolean = true;
  const { me: loggedInUser } = useMe();

  useEffect(() => {
    async function getData() {
      const id = loggedInUser?.uid;
      if (id) {
        try {
          const res = await getAllBookings(id);
          // setBookTest(res.data)
          // let filterBookings = res.data.filter(
          //   (item: any) => item.created_by == loggedInUser?.uid
          // );

          // filterBookings.forEach((element: any) => {
          //   element.customerInfo = {
          //     fullname: element.fullname,
          //     start_date: element.start_date,
          //   };
          // });

          setBookings(res.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error("User not found");
      }
      getData();
    }
  }, [loggedInUser]);

  const { hostWorkSpaces } = useHostWorkspaces();

  const getAllBookingForHost = () => {
    const workspaceIds = hostWorkSpaces?.map(
      (workspace: any) => workspace.workspace_id
    );

    let hostBookings = bookings.filter((booking: any) =>
      workspaceIds?.some(
        (workspaceId: any) => workspaceId === booking.workspace_id
      )
    );

    hostBookings.forEach((element: any) => {
      element.customerInfo = {
        fullname: element.fullname,
        start_date: element.start_date,
      };
    });

    return hostBookings.slice(0, 3);
    console.log(hostBookings, "chekingh");
  };

  const getColor = (text: string) => {
    switch (text) {
      case "Ongoing":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "Cancelled":
        return {
          color: "red",
          backgroundColor: "rgba(255, 204, 204, 1)",
        };
      case "Approved":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "Pending" || "pending check":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 229, 204, 1)",
        };
      case "pending check":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 229, 204, 1)",
        };
      default:
        return {
          color: "black",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
    }
  };

  const handleOpenModal = (type: string) => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return {
    data,
    getColor,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    isProPlan,
    bookings,
    isLoading,
    getAllBookingForHost,
  };
};

export default useHost;
