import React, { useState } from "react";
import { Modal } from "antd";
import styles from './index.module.scss'
import { CloseCircleOutlined } from "@ant-design/icons";

export interface ISuccessModal {
   openModal?: boolean;
   setOpenModal: (value: boolean) => void;
   icon?: string;
   heading?: string;
   title?: string;
   description?: string;
   button?: React.ReactNode;
   closeModal?: () => void
}

const SuccessModal = ({ openModal, setOpenModal, closeModal, icon, heading, title,  description, button }: ISuccessModal) => {
   return (
      <Modal
         title={title}
         // centered
         open={openModal}
         onCancel={closeModal}
         footer={null}
         closeIcon={<CloseCircleOutlined />}
         style={{ top: '25%' }}
         // bodyStyle={styles.contents}
      >
         <div className={styles.contents}>
            {icon ? <img src={icon} alt='icon' /> : null}
            <h1 className={styles.title}>{heading}</h1>
            <p className={styles.text}>{description}</p>
            {button && button}
         </div>
      </Modal>
   );
};

export default SuccessModal;
