import { useEffect, useState } from "react";
import styles from "./add-bank.module.scss";
import { Col, Row, Checkbox } from "antd";

import { CustomInputField } from "../../customInput";
import CustomSelect from "../../customSelect";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CustomButton } from "../../customButton";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { IConfirmAccount, IGetBanks } from "../../../interfaces/apiResponse";
import { confirmBankAccount } from "../../../services/networkRequests/walletService";
import { BANK_ACCOUNT_SCHEMA } from "@/validationSchemas/schema";
import { useAddWithdrawalAccountMutation } from "@/data/wallet";
import useWallet from "@/pages/Host/Profile/components/Wallet/useWallet";
import { handleError } from "@/services/networkRequests";

interface FormValues {
  bank_name: string;
  account_name: string;
  account_number: string;
  type: string;
  bank_code: string;
  bank_id: number;
  currency: string;
  privacy_policy: boolean;
}

function Form({
  formik,
  banks,
}: {
  formik: FormikProps<FormValues>;
  banks: IGetBanks[];
}) {
  const [isVerifying, setIsVerifying] = useState(false);
  const disabled = Object.keys(formik.errors).length > 0;
  const onChange = (e: CheckboxChangeEvent) => {
    formik.setFieldValue("privacy_policy", e.target.checked);
  };

  const handleSelectChange = async (
    value: string | number,
    formik: FormikProps<FormValues>
  ) => {
    const bank = banks.find((bank: IGetBanks) => bank.id === value);
    if (bank) {
      formik.setValues({
        ...formik.values,
        bank_name: bank?.name,
        bank_code: bank?.code,
        bank_id: bank?.id,
        currency: bank?.currency,
        type: bank?.type,
      });
    }
  };

  useEffect(() => {
    const verifyAccount = async (): Promise<void> => {
      if (formik.values.account_number && formik.values.bank_code) {
        setIsVerifying(true);
        const response: IConfirmAccount = await confirmBankAccount(
          formik.values.account_number,
          formik.values.bank_code
        );
        if (response?.account_name) {
          formik.setFieldValue("account_name", response?.account_name);
        } else {
          formik.setFieldError("account_name", "error verifying account");
        }
        setIsVerifying(false);
      }
    };
    verifyAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.bank_code, formik.values.account_number]);
  return (
    <>
      <div className={styles.form}>
        <Row gutter={[45, 24]}>
          <Col md={12} xs={24}>
            <CustomInputField
              type="text"
              label="Account Number"
              placeholder="Enter Account Number"
              maxLength={10}
              inputMode="numeric"
              pattern="[0-9]*"
              {...formik.getFieldProps("account_number")}
              error={
                formik.touched.account_number && formik.errors.account_number
              }
            />
          </Col>
          <Col md={12} xs={24}>
            <CustomSelect
              value={formik.values.bank_name}
              showSearch
              onChange={(value) => handleSelectChange(value, formik)}
              onBlur={() => formik.setFieldTouched("bank_name")}
              label={"Bank Name"}
              placeHolder="Select your Bank"
              size="large"
              error={formik.touched.bank_name && formik.errors.bank_name}
              options={banks.map((bank: IGetBanks) => ({
                label: bank.name,
                value: bank.id,
              }))}
            ></CustomSelect>
          </Col>
          <Col md={12} xs={24}>
            <CustomInputField
              type={"text"}
              label="Account Name"
              placeholder=""
              disabled
              value={formik.values?.account_name}
              loading={isVerifying}
              error={formik.errors?.account_name}
            />
          </Col>
        </Row>
      </div>

      <Row>
        <Col span={6} xs={24}>
          <Checkbox onChange={onChange} checked={formik.values.privacy_policy}>
            <span className={styles.terms}>
              I confirm that I have read and agree to Co-Spire’s Privacy Policy
              and consent to sharing my information.
            </span>
          </Checkbox>
        </Col>
      </Row>
      <div className={styles.btn}>
        <CustomButton
          children={"Save Changes"}
          onClick={formik.handleSubmit}
          disabled={
            disabled || formik.isSubmitting || !formik.values.privacy_policy
          }
          loading={formik.isSubmitting && isVerifying}
        />
      </div>
    </>
  );
}

export function AddWithdrawalBank() {
  const initialValues: FormValues = {
    account_number: "",
    bank_name: "",
    bank_code: "",
    bank_id: 0,
    currency: "",
    type: "",
    account_name: "",
    privacy_policy: false,
  };

  const { mutateAsync: addWithdrawalBank } = useAddWithdrawalAccountMutation();

  const { handleCloseModal, banks } = useWallet();

  return (
    <div className={styles.page}>
      <Formik
        initialValues={initialValues}
        validationSchema={BANK_ACCOUNT_SCHEMA}
        onSubmit={(
          values: FormValues,
          { setSubmitting, resetForm }: FormikHelpers<FormValues>
        ) => {
          addWithdrawalBank(values, {
            onSuccess: () => {
              resetForm();
              handleCloseModal();
            },
            onError: handleError,
          });
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {(formik) => {
          return <Form banks={banks} formik={formik} />;
        }}
      </Formik>
    </div>
  );
}
