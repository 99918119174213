export const LightningIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.2059 2.94727L4.29937 13.6351C3.95056 14.0537 3.77616 14.263 3.77349 14.4397C3.77118 14.5934 3.83965 14.7396 3.95917 14.8362C4.09666 14.9473 4.36909 14.9473 4.91395 14.9473H12.2059L11.2059 22.9473L20.1125 12.2594C20.4613 11.8408 20.6357 11.6316 20.6383 11.4548C20.6407 11.3011 20.5722 11.155 20.4527 11.0584C20.3152 10.9473 20.0427 10.9473 19.4979 10.9473H12.2059L13.2059 2.94727Z"
        fill="currentColor"
      />
    </svg>
  );
};
