import React, { useState } from "react";
import styles from "./index.module.scss";
import verified from "@/assets/svgs/verified.svg";
import { useNavigate } from "react-router-dom";
import { useMe } from "@/data/user";

const Verified = () => {
  const { me: loggedInUser } = useMe();
  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <h2 className={styles.title}>
        <span
          onClick={() =>
            navigate(
              loggedInUser?.role_type === "Host"
                ? "/host-profile/account_settings"
                : "/customer-profile/account_settings"
            )
          }
        >
          Account settings &gt;{" "}
        </span>
        BVN & NIN Verification
      </h2>
      <p className={styles.subheading}>
        Verify your account with either of the verification numbers.{" "}
      </p>
      <div className={styles.verifiedBox}>
        <img src={verified} alt="Verified" />
        <div className={styles.congratulations}>
          <h2>Congratulations your identity has been verified!</h2>
        </div>
        <div className={styles.idNumberBox}>
          <div className={styles.idContent}>
            <p className={styles.idType}>BVN Number</p>
            <p className={styles.idNumber}>0*******39</p>
            <p className={styles.copy}>Copy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verified;
