import React, { Fragment, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { CustomButton } from "@/components/customButton";
import { Breadcrumb, Divider } from "antd";
import { pin, removeIcon, featured_space, Revenue } from "@/assets/img";
import verified from "@/assets/img/verified_orange.png";
import trash from "@/assets/svgs/Trash.svg";
import img1 from "@/assets/img/myListingBanner1.png";
import img2 from "@/assets/img/myListingBanner2.png";
import img3 from "@/assets/img/myListingBanner3.png";
import { StarFilled } from "@ant-design/icons";
import useCheckIsMobileScreen from "@/hooks/useCheckIsMobileScreen";
import useHostWorkspaces from "./useHostWorkspaces";
import { Link, useNavigate } from "react-router-dom";
import NoDataPage from "@/components/noDataPage/noDataPage";
import Splider from "@/components/splide/splide";
import Button from "@/components/c/Button";
import { Search } from "../../../components/Search/Search";
import { useMyWorkspacesQuery } from "@/data/workspace";
import { cn } from "@/lib/utils";
import placeholder from "../../../assets/placeholders/workspace.svg";

const ViewSpaces = () => {
  const isMobile = useCheckIsMobileScreen();
  const navigate = useNavigate();
  const { getAverageRating, getLowestPrice } = useHostWorkspaces();

  const { workspaces, loading: isLoading, error } = useMyWorkspacesQuery({});

  const links = [
    {
      title: <Link to={"/"}>Home</Link>,
    },
    {
      title: "My Listings",
      link: "",
    },
  ];

  const imgShow = [
    {
      src: img1,
      name: "img1",
    },
    {
      src: img2,
      name: "img2",
    },
    {
      src: img3,
      name: "img3",
    },
  ];

  const [searchValue, setSearchValue] = useState<string>("");

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filteredWorkspaces = useMemo(() => {
    return workspaces.filter((space) =>
      space.title.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, workspaces]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Breadcrumb
          separator=">"
          items={links}
          className={styles.breadcrumbs}
        />
      </div>
      <div className={styles.listingTop}>
        <img src={img1} alt="advert1" />
        <img src={img2} alt="advert2" />
        <img src={img3} alt="advert3" />
      </div>
      <div className={styles.listingMobile}>
        <img src={Revenue} alt="advert" />
      </div>
      <div className={styles.searchFilter}>
        <Search
          onChange={onSearch}
          placeholder={"Search Listings"}
          className={styles.searchInput}
        />
      </div>
      <div className={styles.heading}>
        <h2 className={styles.title}>My Listings</h2>
        <div className={styles.listButton}>
          <Button
            type="nav"
            onClick={() => {
              navigate("/listing");
            }}
          >
            List Your Space +
          </Button>
        </div>
      </div>
      <div>
        <Fragment>
          {filteredWorkspaces?.length > 0 ? (
            filteredWorkspaces?.map((space) => {
              return (
                <Fragment key={space.workspace_id}>
                  <div className={styles.featured}>
                    <div className={styles.featuredItem}>
                      <div className={styles.left}>
                        <Splider
                          thumbnails={
                            space?.photos?.length > 0
                              ? space.photos
                              : [placeholder]
                          }
                          space={space.title}
                          className={styles.img}
                        />
                      </div>
                      <div className={styles.workspace}>
                        {isMobile ? (
                          <div className={cn("sm:hidden", styles.rightTop)}>
                            <div className={styles.content}>
                              <div className={styles.con}>
                                <h1 className={styles.title}>{space.title}</h1>
                                <h1
                                  className={styles.title}
                                  style={{ color: "#0047AB" }}
                                >
                                  Starting from &#8358;
                                  {space?.services &&
                                    getLowestPrice(space?.services)}
                                </h1>
                                <div className={styles.icon}>
                                  <StarFilled className={styles.iconColor} />
                                  <p className={styles.subtitle}>
                                    {space?.verified_reviews &&
                                      getAverageRating(space?.verified_reviews)}
                                  </p>
                                </div>
                              </div>
                              <div className={styles.con}>
                                {space?.verified_space && (
                                  <div className={styles.icon}>
                                    <img src={verified} alt="verified" />
                                    <p className={styles.subtitle}>
                                      Verified Workspace
                                    </p>
                                  </div>
                                )}
                                <p className={styles.subtitle}>
                                  {space?.verified_reviews?.length} Verified
                                  Reviews
                                </p>
                              </div>
                              <div className={styles.con}>
                                <div className={styles.location}>
                                  <div className={styles.icon}>
                                    <img src={pin} alt="pin" />
                                    <p className={styles.subtitle}>
                                      <span>{space.formatted_address}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="hidden sm:block">
                            <div className={styles.rightTop}>
                              <div className={styles.content}>
                                <h1 className="text-xl font-semibold text-coSpireBlack">
                                  {space?.title}
                                </h1>
                                <h1
                                  className={cn("text-primary", styles.title)}
                                >
                                  ₦
                                  {space?.services &&
                                    getLowestPrice(space?.services)}
                                </h1>
                                <div className={styles.icon}>
                                  <StarFilled className={styles.iconColor} />
                                  <p className={styles.subtitle}>
                                    {space?.verified_reviews &&
                                      getAverageRating(space?.verified_reviews)}
                                  </p>
                                </div>
                                {space?.verified_space && (
                                  <div className={styles.icon}>
                                    <img src={verified} alt="verified" />
                                    <p className={styles.subtitle}>
                                      Verified Workspace
                                    </p>
                                  </div>
                                )}
                                <p className={styles.subtitle}>
                                  {space?.verified_reviews?.length} Verified
                                  Reviews
                                </p>
                              </div>
                            </div>
                            <div className={styles.price}>
                              <div className={styles.location}>
                                <div className={styles.icon}>
                                  <img src={pin} alt="pin" />
                                  <p className={styles.subtitle}>
                                    <span>{space.formatted_address}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.ctaCorner}>
                      <div className={styles.ctaBoxes}>
                        <div className={cn(styles.cta, "flex items-center")}>
                          {space.approved_space ? (
                            <button className={styles.btnBlue}>
                              View as a renter
                            </button>
                          ) : (
                            <Link
                              to={"/my-listing/preview/" + space.workspace_id}
                              className="text-lg font-medium text-primary flex-1"
                            >
                              Continue editing
                            </Link>
                          )}

                          <CustomButton
                            children={"Preview"}
                            className={styles.btnWhite}
                            onClick={() =>
                              navigate(
                                "/my-listing/preview/" + space.workspace_id
                              )
                            }
                          />
                        </div>
                        <div className={styles.recyclyeBin}>
                          <img src={trash} alt="bin" />
                          <p>Remove</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider orientation="left"></Divider>
                </Fragment>
              );
            })
          ) : (
            <NoDataPage
              title={"No workspaces available"}
              subtitle="All your workspaces will be displayed here"
            />
          )}
        </Fragment>
      </div>
    </div>
  );
};

export default ViewSpaces;
