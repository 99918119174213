import React, { useEffect, useRef, useState } from "react";
import styles from "./Venues.module.scss";
import searchIcon from "@/assets/svgs/searchIconBlue.svg";
import venueImageOne from "@/assets/img/2_2_Frame_25.png";
import venueImageTwo from "@/assets/img/1_5_Frame_36.png";
import venueImageThree from "@/assets/img/2_4_Frame_26.png";
import venueImageFour from "@/assets/img/2_5_Frame_32.png";
import useCheckIsMobileScreen from "@/hooks/useCheckIsMobileScreen";
import star from "@/assets/svgs/star-filled.svg";
import userOne from "@/assets/img/userOne.png";
import userTwo from "@/assets/img/userTwo.png";
import userThree from "@/assets/img/userThree.png";
import userFour from "@/assets/img/userFour.png";
import { locations } from "@/mocks/index";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/redux/hook";

import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Divider, Tooltip } from "antd";
import AddressAutocomplete from "@/components/address-autocomplete";
import { WORKSPACES } from "@/routes/routes";
import { useWorkspacesQuery } from "@/data/workspace";
// import { setLocation, setSearchValue } from "@/redux/reduxSlice/userSlice";

const data = [
  "Content Creation",
  "Podcast studio",
  "Party space",
  "Filming venues",
  "Outdoor wedding",
  "Video shooting venues",
  "Work meetings",
  "Podcast Studio",
];

interface Space {
  thumbNail: string;
}
const LIMIT = 100;

const Venues = () => {
  const isMobile = useCheckIsMobileScreen();
  const [filterSpaces, setFilterSpaces] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [searchtitle, setSearchtitle] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const { workspaces, loading } = useWorkspacesQuery({
    limit: LIMIT,
  });  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const handleSearch = () => {
    if (!searchtitle.trim() && !searchLocation.trim()) {
      setFilterSpaces(workspaces || []);
      return;
    }

    const titleSearchTerms = searchtitle.trim().toLowerCase().split(" ");
    const locationSearchTerms = searchLocation.trim().toLowerCase().split(" ");

    const filtered = workspaces.filter((item: any) => {
      // const address = item.address?.street_address.toLowerCase() || "";
      const title = item?.title.toLowerCase() || "";
      const spaceCategory = item.space_category?.toLowerCase() || "";
      const activities = item.activity
        ? item.activity.map((act: string) => act.toLowerCase())
        : [];
      const streetAddress = item.address?.street_address?.toLowerCase() || "";

      const titleMatch = titleSearchTerms.every(
        (term) =>
          // address.includes(term) ||
          title.includes(term) ||
          spaceCategory.includes(term) ||
          activities.some((activity: string) => activity.includes(term))
      );
      const locationMatch = locationSearchTerms.every((term) =>
        streetAddress.includes(term)
      );

      return titleMatch && locationMatch;
    });

    setFilterSpaces(filtered);
  };

  const handleTittleChange = (text: any) => {
    setSearchtitle(text);
    // dispatch(setSearchValue(text));
  };

  const handleLocationChange = (text: any) => {
    setSearchLocation(text);
    // dispatch(setLocation(text));
  };
  const handleAddressChange = (address: string) => {
    setSearchLocation(address);
  };

  useEffect(() => {
    handleSearch();
  }, [searchtitle, searchLocation]);

  const splitSpacesIntoArrays = (spaces: Space[]): string[][] => {
    // Sort the spaces based on the length of the thumbNail property
    const sortedSpaces = [...spaces].sort(
      (a, b) => a.thumbNail.length - b.thumbNail.length
    );

    // Calculate the length of each subarray
    const totalLength = sortedSpaces.length;
    const subarrayLength = Math.floor(totalLength / 4);
    const remainder = totalLength % 4;

    const arrays = [];

    let startIndex = 0;
    for (let i = 0; i < 4; i++) {
      const endIndex = startIndex + subarrayLength + (i < remainder ? 1 : 0);
      arrays.push(
        sortedSpaces.slice(startIndex, endIndex).map((space) => space.thumbNail)
      );
      startIndex = endIndex;
    }

    return arrays;
  };

  const spaces: Space[] = [
    { thumbNail: venueImageOne },
    { thumbNail: venueImageTwo },
    { thumbNail: venueImageThree },
    { thumbNail: venueImageFour },
  ];

  const avatarData = [
    {
      img: userOne,
    },
    {
      img: userTwo,
    },
    {
      img: userThree,
    },
    {
      img: userFour,
    },
  ];
  const spaceArrays = splitSpacesIntoArrays(spaces);

  // console.log(spaceArrays, "space");
  // console.log(locations, "loc");

  return (
    <div className={styles.page}>
      {isMobile ? (
        <>
          <div className={styles.mobile_container}>
            <div className={styles.data_container}>
              <p className={styles.header_text}>
                Find the perfect venue for your next event
              </p>

              <div className={styles.inbox_container}>
                <div className={styles.search_inbox1}>
                  <input
                    type="search"
                    placeholder="What are you planning?"
                    value={searchtitle}
                    onChange={(e) => handleTittleChange(e.target.value)}
                    onFocus={() => {
                      setIsFocused(true);
                    }}
                    onBlur={() => {
                      setIsFocused(false);
                    }}
                  />
                </div>

                <div className={styles.search_inbox}>
                  <input
                    type="search"
                    value={searchLocation}
                    onChange={(e) => handleLocationChange(e.target.value)}
                    placeholder="Where?"
                    onFocus={() => {
                      setIsFocused(true);
                    }}
                    onBlur={() => {
                      setIsFocused(false);
                    }}
                  />
                </div>
              </div>
              <button
                onClick={() => {
                  handleSearch();
                  navigate(WORKSPACES, {
                    state: { searchtitle, searchLocation },
                  });
                }}
              >
                Search
              </button>

              <div className={styles.userAvatar_moibile}>
                <Avatar.Group>
                  {avatarData.slice(0, 4).map((item, i) => (
                    <Avatar src={item.img} />
                  ))}
                </Avatar.Group>

                <div>
                  {avatarData.length <= 100 ? (
                    <p>{avatarData.length + 196}+ users</p>
                  ) : (
                    <p>{avatarData.length}+ users</p>
                  )}

                  <div className={styles.stars}>
                    <img src={star} width={20} alt="imf" />
                    <img src={star} width={20} alt="imf" />
                    <img src={star} width={20} alt="imf" />
                    <img src={star} width={20} alt="imf" />
                    <img src={star} width={20} alt="imf" />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.image_con}>
              <div className={styles.imageCon}>
                <div className={styles.imageSlide}>
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageThree} alt="" />
                </div>
                <div className={styles.imageSlide}>
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageFour} alt="" />
                </div>
                <div className={styles.imageSlide}>
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                </div>
                <div className={styles.imageSlide}>
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageThree} alt="" />
                </div>
                {/* <div className={styles.imageSlide}>
                <img src={venueImageOne} alt="" />
                <img src={venueImageOne} alt="" />
                <img src={venueImageOne} alt="" />
                <img src={venueImageOne} alt="" />
                <img src={venueImageOne} alt="" />
                <img src={venueImageOne} alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.userAvatar}>
              <Avatar.Group>
                {avatarData.slice(0, 4).map((item, i) => (
                  <Avatar src={item.img} />
                ))}
              </Avatar.Group>

              <div>
                {avatarData.length <= 100 ? (
                  <p>{avatarData.length + 196}+ users</p>
                ) : (
                  <p>{avatarData.length}+ users</p>
                )}

                <div className={styles.stars}>
                  <img src={star} width={20} alt="imf" />
                  <img src={star} width={20} alt="imf" />
                  <img src={star} width={20} alt="imf" />
                  <img src={star} width={20} alt="imf" />
                  <img src={star} width={20} alt="imf" />
                </div>
              </div>
            </div>

            <div className={styles.contentLeft}>
              <div className={styles.content}>
                <div className={styles.header}>
                  <h2 className={styles.headerText}>
                    Find the perfect venue for your next event
                  </h2>
                  <p className={styles.subText}>
                    Choose from our network of spaces , for your events, shoots
                    and special meetings.
                  </p>
                </div>
                <div className={styles.searchContainer}>
                  <div className={styles.search}>
                    <div className={styles.inputBox1}>
                      <input
                        type="search"
                        placeholder="What are you planning?"
                        value={searchtitle}
                        onChange={(e) => handleTittleChange(e.target.value)}
                        onFocus={() => {
                          setIsFocused(true);
                        }}
                        onBlur={() => {
                          setIsFocused(false);
                        }}
                      />{" "}
                    </div>
                    <div className={styles.inputBox2}>
                      <input
                        type="search"
                        value={searchLocation}
                        onChange={(e) => handleLocationChange(e.target.value)}
                        placeholder="Where?"
                        onFocus={() => {
                          setIsFocused(true);
                        }}
                        onBlur={() => {
                          setIsFocused(false);
                        }}
                      />
                      {/* <AddressAutocomplete
                        address={searchLocation}
                        setAddress={handleAddressChange}
                        placeholder="Where?"
                      />{" "} */}
                      <button
                        className={styles.searchIcon}
                        onClick={() => {
                          handleSearch();
                          navigate(WORKSPACES, {
                            state: { searchtitle, searchLocation },
                          });
                        }}
                      >
                        <img src={searchIcon} alt="search_icon" />
                      </button>
                    </div>
                  </div>

                  {/* {isFocused && !searchtitle && !searchLocation && (
                    <div className={`${styles.suggest} ${styles.searchResult}`}>
                      <p className={styles.title}>Suggested</p>
                      <p className={styles.body}>Pop Up</p>
                      <p className={styles.body}>Podcast studio</p>
                      <p className={styles.body}>Production</p>
                      <p className={styles.body}>Photo Shoot </p>
                    </div>
                  )} */}

                  {isFocused && (
                    <div className={styles.searchResult}>
                      {filterSpaces.length > 0 && searchtitle ? (
                        filterSpaces.map((item: any) => {
                          const matchingActivities = item.activity.filter((act: string) =>
                            act.toLowerCase().includes(searchtitle.toLowerCase())
                          );

                          const matchingSpaceCategory = item.space_category
                            .toLowerCase()
                            .includes(searchtitle.toLowerCase())
                            ? item.space_category
                            : null;

                          const matchingTitle = item.title
                            .toLowerCase()
                            .includes(searchtitle.toLowerCase())
                            ? item.title
                            : null;

                          return (
                            <div
                              className={styles.userSearch}
                              style={{ cursor: "pointer" }}
                              onClick={() => navigate(`/workspace/${item.workspace_id}`)}
                            >
                              {matchingActivities.length > 0 ? (
                                matchingActivities.map((act: string, index: number) => (
                                  <p className={styles.location} key={index}>{act}</p>
                                ))
                              ) : (
                                <>
                                  {matchingSpaceCategory && (
                                    <p className={styles.location}>{matchingSpaceCategory}</p>
                                  )}
                                  {matchingTitle && (
                                    <p className={styles.location}>{matchingTitle}</p>
                                  )}
                                </>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <div className={styles.userSearch}>
                          <p className={styles.location}>No data</p>
                        </div>
                      )}
                    </div>
                  )}

                  {(searchLocation) &&
                    (
                      <div className={styles.searchResult}>
                        {filterSpaces.map((item: any, i: any) => (
                          filterSpaces.length > 0 ? <div
                            className={styles.userSearch}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/workspace/${item.workspace_id}`)
                            }
                          >
                            <p className={styles.location}>
                              {item?.address?.city} {", "} {item?.address?.country}
                            </p>
                          </div> :
                            <div
                              className={styles.userSearch}
                            >
                              <p className={styles.location}>
                                No data
                              </p>
                            </div>
                        ))}
                      </div>
                    )}
                </div>
                <div className={styles.tagsContainer}>
                  <span className={styles.span}>Popular searches</span>
                  <div className={styles.tags}>
                    {data.map((tag) => {
                      return (
                        <span
                          className={styles.tag}
                          onClick={() => setSearchtitle(tag)}
                        >
                          {tag}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.contentRight}>
              <div className={styles.imageCon}>
                <div className={styles.imageSlide}>
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageThree} alt="" />
                </div>
                <div className={styles.imageSlide}>
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageFour} alt="" />
                </div>
                <div className={styles.imageSlide}>
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageFour} alt="" />
                  <img src={venueImageThree} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageTwo} alt="" />
                </div>

                {/* <div className={styles.imageSlide}>
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageOne} alt="" />
                  <img src={venueImageOne} alt="" />
                  </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Venues;
