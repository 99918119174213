import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hook";

const usePreviewWorkspace = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { workspace, workspaceIsLoading, workspaceIsError } = useAppSelector(
    (state) => state.hostWorkSpace
  );

  return {
    workspace,
    workspaceIsLoading,
    workspaceIsError,
  };
};

export default usePreviewWorkspace;
