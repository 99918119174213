import { FieldProps } from "formik";
import Select from "react-select";

interface SearchableSelectProps extends FieldProps {
  options: any[];
  placeholder?: string; // Custom placeholder prop
}

const SearchableSelect = ({ options, field, form, placeholder }: SearchableSelectProps) => {
  const handleChange = (option: any) => {
    form.setFieldValue(field.name, option ? option.value : "");
  };

  const selectedOption = options.find(option => option.value === field.value);

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleChange}
      isSearchable={true}
      placeholder={placeholder} // Use the custom placeholder
      classNamePrefix="react-select"
      styles={{
        control: (provided) => ({
          ...provided,
          width: "100%",
          height: "100%",
          border: "none",
          boxShadow: "none",
          minHeight: "auto",
        }),
        container: (provided) => ({
          ...provided,
          width: "100%",
          height: "100%",
        }),
        menu: (provided) => ({
          ...provided,
          width: "100%",
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: "100%",
          padding: "0 8px",
          display: "flex",
          alignItems: "center",
        }),
        input: (provided) => ({
          ...provided,
          margin: 0,
          padding: 0,
        }),
      }}
    />
  );
};

export default SearchableSelect;
