import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBanks } from '../actions/walletActions';
import { IGetBanks } from '../../interfaces/apiResponse';

interface WalletState {
    banks: IGetBanks[];
    banksIsLoading: boolean;
    banksIsError: boolean;
}

const initialState: WalletState = {
    banks: [],
    banksIsLoading: false,
    banksIsError: false,

};


export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBanks.pending, (state) => {
                state.banksIsLoading = true;
                state.banksIsError = false;
            })
            .addCase(fetchBanks.fulfilled, (state, action) => {
                state.banks = action.payload;
                state.banksIsLoading = false;
                state.banksIsError = false;
            })
            .addCase(fetchBanks.rejected, (state, action) => {
                state.banksIsLoading = false;
                state.banksIsError = true;
            });
    },
});

export default walletSlice.reducer;
