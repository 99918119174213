import React from "react";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "@/pages";
import { HOME } from "./routes";

import Layout from "@/layout/Layout";
import { OtherRoutes } from "./other-route";
import CompleteBooking from "@/pages/CompleteBooking";

const Router: React.FC = () => {
  return (
    <Routes>
      {/* Home Route */}
      <Route path={HOME} element={<Layout />}>
        <Route path={HOME} element={<HomePage />} />
      </Route>

      <Route path={"/complete-booking"} element={<Layout />}>
        <Route
          path="/complete-booking/:booking_id"
          element={<CompleteBooking />}
        />
      </Route>

      {/* Remaining Routes Under Layout */}
      <Route path="/*" element={<OtherRoutes />} />
    </Routes>
  );
};

export default Router;
