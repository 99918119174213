import React, {useEffect}  from "react";
import styles from "./index.module.scss";
import { CustomInputField } from "../../../../../components/customInput";
import CustomTextArea from "../../../../../components/customTextArea";
import ImageUploader from "../../../../../components/customImageUploader";
import { CustomButton } from "../../../../../components/customButton";
import CustomSelect from "../../../../../components/customSelect";
import { ALL_COUNTRIES } from "../../../../../mocks";
import { IWorkSpaceForm } from "../../../../../interfaces/forms";
import { FormikProps } from "formik";
import { GeoTagInput } from "../../../../../components/geoTagAddressInput";
import { ADD_SPACE_DETAILS } from "../../../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import useHostForm from "../../useHostForm";

interface IProps {
   formik: FormikProps<IWorkSpaceForm>;
}

const Description = ({ formik }: IProps) => {
   const navigate = useNavigate();
   const {debounceSearch, nameError} = useHostForm()

   useEffect(() => {
      if(formik.values.spaceName){
         debounceSearch(formik.values.spaceName)
      }   
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [formik.values.spaceName])
   

   return (
      <div className={styles.page}>
         <div className={styles.title}>
            <h1 className={styles.heading}>Workspace Description</h1>
            <p className={styles.subHeading}>CO-SPIRE users are searching for Coworking Spaces like yours.</p>
         </div>

         <div className={styles.form}>
            <CustomInputField
               type={"text"}
               {...formik.getFieldProps("spaceName")}
               error={(formik.touched.spaceName && formik.errors.spaceName) || nameError}
               label='Name of Coworking Space'
               placeholder='Enter Workspace Name'
            />
            <Row gutter={[24, 24]}>
               <Col md={12} xs={24}>
                  <CustomSelect
                     options={ALL_COUNTRIES}
                     value={formik.values.spaceLocation}
                     showSearch
                     onChange={(value: string) => {
                        formik.setFieldValue("spaceLocation", value);
                     }}
                     onBlur={() => formik.setFieldTouched("spaceLocation")}
                     label={"Location"}
                     placeHolder='Select Workspace Location'
                     size='large'
                     error={formik.touched.spaceLocation && formik.errors.spaceLocation}
                  />
               </Col>

               <Col md={12} xs={24}>
                  <GeoTagInput
                     type={"text"}
                     value={formik.values.address}
                     onChange={(value: string) => {
                        formik.setFieldValue("address", value);
                     }}
                     error={formik.touched.address && formik.errors.address}
                     onBlur={() => formik.setFieldTouched("address")}
                     label='Address'
                     placeholder='Enter Workspace address'
                     formik={formik}
                  />
               </Col>
            </Row>

            <CustomTextArea
               label='Provide detailed info about your workspace'
               placeholder='Give users a detailed description of your space. '
               {...formik.getFieldProps("spaceDescription")}
               error={formik.touched.spaceDescription && formik.errors.spaceDescription}
            />
         </div>

         <div className={styles.imageUpload}>
            <h1 className={styles.heading}>Workspace photos</h1>
            <p className={styles.subHeading}>Show off your space in pictures(Add a maximum of 4).</p>

            <div className={styles.imageUploadContainer}>
               <ImageUploader formik={formik} error={formik.touched.fileList && formik.errors.fileList} thumbnails={formik.values.fileList} />
            </div>
         </div>

         <div className={styles.buttonContainer}>
            <CustomButton
               children={"Next"}
               onClick={() => {
                  navigate(`${ADD_SPACE_DETAILS}/contact_details`);
               }}
               disabled={
                  Boolean(formik.errors.spaceName) ||
                  Boolean(formik.errors.spaceLocation) ||
                  Boolean(formik.errors.spaceDescription) ||
                  Boolean(formik.errors.spaceThumbnail)
               }
               extraStyles={{ maxWidth: "150px" }}
            />
         </div>
      </div>
   );
};

export default Description;
