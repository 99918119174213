import { useEffect, useRef, useState } from "react";
import "./add-space.scss";
import {
  StepFour,
  PageFour,
  ConfirmPricing,
  ContactDetails,
  PageSix,
  PageThree,
  Address,
  PricingStep,
} from "@/components/listing-space/steps";
import { useLocation, useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import { StepperComponent } from "@/components/stepper/_StepperComponent";
import { Form, Formik, FormikValues } from "formik";
import { formatAddress } from "@/lib/format-address";
import { AddSpaceVales, formSchema } from "./schema";
import { useCreateSpaceMutation } from "@/data/workspace";
import { toast } from "react-toastify";
import { handleError } from "@/services/networkRequests";
import { ILoginResponse } from "@/interfaces/apiResponse";
import { CreateSpacePayload } from "@/types/workspace";
import { useMe } from "@/data/user";

type AddSpaceFormProps = {};

export function AddSpaceForm() {
  const navigate = useNavigate();

  const { me: user } = useMe();

  const { state } = useLocation();

  const { mutateAsync: addSpace, isLoading } = useCreateSpaceMutation();

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(formSchema[0]);
  const [initValues] = useState<AddSpaceVales>({
    countryRegion: "",
    state: "",
    city: "",
    streetAddress: "",
    zipCode: "",
    map: {
      lat: 0,
      lng: 0,
    },
    overnightStay: null,
    parkingSpaces: null,
    parkingOption: "free",
    parkingSlots: 0,
    nearbyParkingSpaces: null,
    securityCameras: null,
    restRooms: null,
    twentyFourSeven: null,
    foodPolicy: null,
    adultFilming: null,
    alcoholPolicy: null,
    noisePolicy: null,
    musicPolicyOne: null,
    petPolicy: null,
    smokingPolicy: null,
    spaceTitle: "",
    about: "",
    numberOfPeoples: 0,
    spaceRules: "",
    hasAgeRestriction: false,
    agesAllowed: [],
    facilities: [],
    minHoursOption: "",
    currency: "NGN",
    daysOpen: [],
    pricing: {
      hourly: 0,
      daily: 0,
      weekly: 0,
      monthly: 0,
    },
    securityDepositPer: 0,
    spacePictures: [],
    availableDays: [],
    openingTime: "00:00",
    closingTime: "00:00",
    contactImage: null,
    displayName: user?.displayName ?? "",
    emailAddress: user?.email ?? "",
    lorem: "",
  });

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(formSchema[stepper.current.currentStepIndex - 1]);
    window.scrollTo(0, 0);
  };

  function handleAddSpace(payload: CreateSpacePayload) {
    toast.promise(
      addSpace(payload, {
        onSuccess: ({ data }) => {
          toast.success("Space added successfully");
          navigate("/my-listing/preview/" + data?.workspace_id);
        },
        onError: (err) => handleError(err),
      }),
      {
        pending: "Adding space...",
      }
    );
  }

  const submitStep = async (values: AddSpaceVales, actions: FormikValues) => {
    console.log({ values, actions });

    if (!stepper.current) {
      return;
    }

    setCurrentSchema(formSchema[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
      window.scrollTo(0, 0);
    } else {
      const { openingTime, closingTime, availableDays } = values;

      const payload: CreateSpacePayload = {
        is_park_space_available: values.parkingSpaces!,
        is_park_space_free: values.parkingOption === "free",
        is_external_food_allowed: values.foodPolicy!,
        is_catering_available: values.foodPolicy!,
        title: values.spaceTitle,
        about: values.about,
        is_alcohol_allowed: values.alcoholPolicy!,
        display_name: values.displayName,
        restroom: values.restRooms!,
        is_overnight_allowed: values.overnightStay!,
        security_cameras: values.securityCameras!,
        space_email: values.emailAddress,
        space_phone_number: "",
        whatsapp_phone_number: "",
        website_url: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        space_amenity_obj: values.facilities.length
          ? (values.facilities as string[])
          : [],
        pricing: {
          hourly: values.pricing.hourly,
          daily: values.pricing.daily,
          weekly: values.pricing.weekly ?? 0,
          monthly: values.pricing.monthly ?? 0,
        },
        holidays: [],
        cancellation_policy_ids: ["qIXXAAtNPEvDoZAdGliV"],
        number_of_parking_space: Number(values.parkingSlots),
        nearby_parking_lot: [],
        round_the_clock_service: values.twentyFourSeven!,
        food_policy_description: "",
        percentage_of_security_deposit: values.securityDepositPer,
        electricity_usage: "use-electricity",
        rules: values.spaceRules,
        images: values.spacePictures?.map((img: any) => img.src)!,
        formatted_address:
          formatAddress({
            country: values.countryRegion,
            state: values.state,
            city: values.city,
            street_address: "",
            zip: values.zipCode,
          }) ?? "",
        address: {
          country: values.countryRegion,
          state: values.state,
          city: values.city,
          street_address: values.streetAddress,
          zip_code: values.zipCode,
        },
        operation_hours: {
          monday: {
            open_time: availableDays?.includes("monday")
              ? openingTime
              : "closed",
            close_time: availableDays?.includes("monday")
              ? closingTime
              : "closed",
          },
          tuesday: {
            open_time: availableDays?.includes("tuesday")
              ? openingTime
              : "closed",
            close_time: availableDays?.includes("tuesday")
              ? closingTime
              : "closed",
          },
          wednesday: {
            open_time: availableDays?.includes("wednesday")
              ? openingTime
              : "closed",
            close_time: availableDays?.includes("wednesday")
              ? closingTime
              : "closed",
          },
          thursday: {
            open_time: availableDays?.includes("thursday")
              ? openingTime
              : "closed",
            close_time: availableDays?.includes("thursday")
              ? closingTime
              : "closed",
          },
          friday: {
            open_time: availableDays?.includes("friday")
              ? openingTime
              : "closed",
            close_time: availableDays?.includes("friday")
              ? closingTime
              : "closed",
          },
          saturday: {
            open_time: availableDays?.includes("saturday")
              ? openingTime
              : "closed",
            close_time: availableDays?.includes("saturday")
              ? closingTime
              : "closed",
          },
          sunday: {
            open_time: availableDays?.includes("sunday")
              ? openingTime
              : "closed",
            close_time: availableDays?.includes("sunday")
              ? closingTime
              : "closed",
          },
        },
        space_activities: state.activities,
        space_type: state.type,
        space_size: values.numberOfPeoples,
        capacity: values.numberOfPeoples,
        minimum_hours: Number(values.minHoursOption),
        map: {
          latitude: 0,
          longitude: 0,
        },
        age_restriction: values.hasAgeRestriction
          ? values.agesAllowed!.join(", ")
          : "nil",
        is_adult_filming_allowed: values.adultFilming!,
        is_music_allowed: values.musicPolicyOne!,
        is_pet_allowed: values.petPolicy!,
        is_smoking_allowed: values.smokingPolicy!,
        currency: values.currency,
      };
      await handleAddSpace(payload);
    }
  };

  useEffect(() => {
    if (!stepperRef.current) return;
    loadStepper();
  }, [stepperRef]);

  const [wifiService, setWifiService] = useState(false);
  const [staffSupport, setStaffSupport] = useState(false);

  const progress = ((stepper.current?.currentStepIndex ?? 0) / 9) * 100;

  async function handleSave(values: AddSpaceVales) {
    const { openingTime, closingTime, availableDays } = values;

    const payload: CreateSpacePayload = {
      is_park_space_available: values.parkingSpaces!,
      is_park_space_free: values.parkingOption === "free",
      is_external_food_allowed: values.foodPolicy!,
      is_catering_available: values.foodPolicy!,
      title: values.spaceTitle,
      about: values.about,
      is_alcohol_allowed: values.alcoholPolicy!,
      display_name: values.displayName,
      restroom: values.restRooms!,
      is_overnight_allowed: values.overnightStay!,
      security_cameras: values.securityCameras!,
      space_email: values.emailAddress,
      space_phone_number: "",
      whatsapp_phone_number: "",
      website_url: "",
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      space_amenity_obj: values.facilities.length
        ? (values.facilities as string[])
        : [],
      pricing: {
        hourly: values.pricing.hourly,
        daily: values.pricing.daily,
        weekly: values.pricing.weekly ?? 0,
        monthly: values.pricing.monthly ?? 0,
      },
      holidays: [],
      cancellation_policy_ids: ["qIXXAAtNPEvDoZAdGliV"],
      number_of_parking_space: Number(values.parkingSlots),
      nearby_parking_lot: [],
      round_the_clock_service: values.twentyFourSeven!,
      food_policy_description: "",
      percentage_of_security_deposit: values.securityDepositPer,
      electricity_usage: "use-electricity",
      rules: values.spaceRules,
      images: values.spacePictures?.map((img: any) => img.src)!,
      formatted_address:
        formatAddress({
          country: values.countryRegion,
          state: values.state,
          city: values.city,
          street_address: "",
          zip: values.zipCode,
        }) ?? "",
      address: {
        country: values.countryRegion,
        state: values.state,
        city: values.city,
        street_address: values.streetAddress,
        zip_code: values.zipCode,
      },
      operation_hours: {
        monday: {
          open_time: availableDays?.includes("monday") ? openingTime : "closed",
          close_time: availableDays?.includes("monday")
            ? closingTime
            : "closed",
        },
        tuesday: {
          open_time: availableDays?.includes("tuesday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("tuesday")
            ? closingTime
            : "closed",
        },
        wednesday: {
          open_time: availableDays?.includes("wednesday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("wednesday")
            ? closingTime
            : "closed",
        },
        thursday: {
          open_time: availableDays?.includes("thursday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("thursday")
            ? closingTime
            : "closed",
        },
        friday: {
          open_time: availableDays?.includes("friday") ? openingTime : "closed",
          close_time: availableDays?.includes("friday")
            ? closingTime
            : "closed",
        },
        saturday: {
          open_time: availableDays?.includes("saturday")
            ? openingTime
            : "closed",
          close_time: availableDays?.includes("saturday")
            ? closingTime
            : "closed",
        },
        sunday: {
          open_time: availableDays?.includes("sunday") ? openingTime : "closed",
          close_time: availableDays?.includes("sunday")
            ? closingTime
            : "closed",
        },
      },
      space_activities: state.activities,
      space_type: state.type,
      space_size: values.numberOfPeoples,
      capacity: values.numberOfPeoples,
      minimum_hours: Number(values.minHoursOption),
      map: {
        latitude: 0,
        longitude: 0,
      },
      age_restriction: values.hasAgeRestriction
        ? values.agesAllowed!.join(", ")
        : "nil",
      is_adult_filming_allowed: values.adultFilming!,
      is_music_allowed: values.musicPolicyOne!,
      is_pet_allowed: values.petPolicy!,
      is_smoking_allowed: values.smokingPolicy!,
      currency: values.currency,
    };
    await handleAddSpace(payload);
  }

  return (
    <div ref={stepperRef} className="stepper" id="kt_add_space_stepper">
      <div className="w-full bg-[#f0f0f0]">
        <div style={{ width: `${progress}%` }} className="h-2.5 bg-[#007bff]" />
      </div>
      <div className="numbered-progress space-x-4">
        {Array.from({ length: formSchema.length }).map((_, index) => {
          return (
            <div
              data-kt-stepper-element="nav"
              className={cn(
                "flex items-center gap-x-3",
                index === 0 ? "current" : ""
              )}
            >
              <p className={cn("progress-indication-number")}>{index + 1}</p>
              <div className="progress-indication-bar !w-10">
                <div className="blue-bar"></div>
              </div>
            </div>
          );
        })}
      </div>
      <Formik
        validationSchema={currentSchema}
        initialValues={initValues}
        onSubmit={submitStep}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, values, ...rest }) => {
          return (
            <Form id="kt_add_space_form">
              <div className="workspace-setup-page-content">
                <div className="pages-parent">
                  <div className="current" data-kt-stepper-element="content">
                    <Address values={values} />
                  </div>
                  <div data-kt-stepper-element="content">
                    <PageThree
                      values={values}
                      handleChange={rest.handleChange}
                      wifiService={wifiService}
                      setWifiService={setWifiService}
                      staffSupport={staffSupport}
                      setStaffSupport={setStaffSupport}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <PageFour
                      values={values}
                      handleChange={rest.handleChange}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <StepFour
                      values={values}
                      handleChange={rest.handleChange}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <PricingStep
                      values={values}
                      handleChange={rest.handleChange}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <ConfirmPricing
                      values={values}
                      saving={isLoading}
                      saveContinue={() => handleSave(values)}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <PageSix
                      values={values}
                      setValue={rest.setFieldValue}
                      saveContinue={() => handleSave(values)}
                      saving={isLoading}
                    />
                  </div>
                  <div data-kt-stepper-element="content">
                    <ContactDetails
                      values={values}
                      setValue={rest.setFieldValue}
                    />
                  </div>
                </div>

                <p
                  className={cn(
                    "error-in-listing",
                    "text-red-600 px-4 py-6",
                    Object.keys(errors).length ? "visible" : "hidden"
                  )}
                >
                  Please complete all the required fields
                </p>

                <div className="add-workspace-button-wrapper">
                  <button
                    onClick={prevStep}
                    type="button"
                    data-kt-stepper-action="previous"
                    className="btn button-outline"
                  >
                    Back
                  </button>

                  <button disabled={isLoading} type="submit" className="btn">
                    {!isLoading ? <>Continue</> : "Please wait..."}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
