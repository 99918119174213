import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Col, Divider, Row } from "antd";
import CustomBox from "@/components/customBox";
import note from "../../../../../assets/svgs/banknotesHero.svg";
import arrowUp from "../../../../../assets/svgs/Line 103.svg";
import arrowRight from "../../../../../assets/svgs/arrow-long-right-blue.svg";
import viewMore from "../../../../../assets/svgs/more.svg";
import CustomTable from "@/components/tableComponent/Table/Table";
import useHost from "./Host";
import CustomTag from "@/components/customTag";
import { Link } from "react-router-dom";
import { Switch } from "antd";
import { TabTitle } from "@/utils/titleFunction";
import { CustomButton } from "@/components/customButton";
import CustomModal from "@/components/a/Modals/CustomModal/CustomModal";
import BillingPlans from "@/components/a/Modals/BillingModal/BillingPlans";
import Preview from "@/components/Preview/Preview";
import bookingPreview from "../../../../../assets/svgs/bookinPreview.svg";
import bankNotes from "../../../../../assets/svgs/banknotesPreview.svg";
import { default as dayjs } from "dayjs";

// import { listBooking } from "@/Services/networkRequests/bookingService";
import axios from "axios";
import { getAllBookings } from "@/services/networkRequests/bookingService";
import { useMe } from "@/data/user";

const HomeHost = () => {
  TabTitle("co-spire | Dashboard");
  const { me: loggedInUser } = useMe();

  const {
    data,
    getColor,
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    isProPlan,
    bookings,
    isLoading,
    getAllBookingForHost,
  } = useHost();

  const handleViewMore = () => {
    console.log("view more");
  };
  const recentHostTransaction = getAllBookingForHost();

  const booking = [
    {
      title: (
        <span style={{ textTransform: "uppercase", color: "#131416" }}>
          Customer Informations
        </span>
      ),
      dataIndex: "customerInfo",
      key: "customerInfo",
      render: (customerInfo: { fullname: string; start_date: any }) => (
        <div className={`${styles.workspaceInfo}`}>
          <h1 className={`${styles.name}`}>{customerInfo.fullname}</h1>
          <p>
            Booked on :{dayjs(customerInfo.start_date).format("YYYY/MM/DD")}
          </p>
        </div>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Booking Status
        </span>
      ),
      dataIndex: "status",
      key: "bookingStatus",
      render: (text: string) => (
        <CustomTag color={getColor(text)} text={text} />
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => (
        <span className={`${styles.amount}`}>{`${amount}`}</span>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Payment Status
        </span>
      ),
      dataIndex: "status",
      key: "paymentStatus",
      render: (text: string) => (
        <CustomTag color={getColor(text)} text={text} />
      ),
    },
  ];
  const recentTransaction = [
    {
      title: (
        <span style={{ textTransform: "uppercase", color: "#131416" }}>
          Customer Information
        </span>
      ),
      dataIndex: "customerInfo",
      key: "customerInfo",
      render: (customerInfo: any) => (
        <div className={`${styles.workspaceInfo}`}>
          {/* @ts-ignore */}
          <h1 className={`${styles.name}`}>{customerInfo?.fullname}</h1>
          <p>Booked on : {customerInfo?.start_date} </p>
        </div>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Payment Status
        </span>
      ),
      dataIndex: "status",
      key: "status",
      render: (text: string) => (
        <CustomTag color={getColor(text)} text={text} />
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => (
        <span className={`${styles.amount}`}>{`₦${amount || 0}`}</span>
      ),
    },
    {
      title: (
        <span style={{ color: "#131416", textTransform: "uppercase" }}>
          Payment Status
        </span>
      ),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text: string) => (
        <CustomTag color={getColor(text)} text={text} />
      ),
    },
  ];

  return (
    <div>
      <div className={styles.page}>
        {/* <div className={styles.heading}>
          <h2 className={styles.title}>Home</h2>
          <p>Lorem ipsum dolor</p>
        </div>
        <Divider orientation="left"></Divider> */}
        <p className={styles.hostName}>Hi {loggedInUser?.displayName},</p>

        {/* <div className={styles.content}> */}
        {/* <Row
            gutter={[24, 24]}
            align="stretch"
            className={styles.header_wrapper}
          >
            {/* <div className={styles.box_wrapper}> */}
        {/* <Col
              lg={5}
              span={12}
              xs={24}
              className={styles.total_balance_box_wrapper}
            >
              <div className={styles.total_balance_box}>
                <div className={styles.note_container}>
                  <p>
                    <img src={note} alt="" className={styles.note} />
                  </p>
                  <p className={styles.para_1}>Total Balance </p>
                </div>
                <p className={styles.para_2}>
                  00{" "}
                  <span>
                    +0.0% <img src={arrowUp} width={"9px"} alt="" />
                  </span>
                </p>
                <p className={styles.para_3}>Compared to last month</p>

                <Link to={"/host-profile/wallet"}>
                  <CustomButton
                    children={"Withdraw Funds"}
                    className={styles.para_4}
                  />
                </Link>
              </div>
            </Col> */}
        {/* <CustomBox className={styles.customBox}>
              {isProPlan ? (
                <>
                  <div className={styles.isPro}>
                    <p className={styles.para}>Active Plan</p>
                    <p className={styles.para_1}>Pro Plan name</p>
                    <p className={styles.para_2}>Expires 20th Aug, 2028</p>
                    <p className={styles.para_3}>
                      Auto Renew plan <Switch defaultChecked />{" "}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <p className={styles.para_1}>
                    Upgrade to Co-Spire's Pro plan
                  </p>
                  <p className={styles.para_2}>
                    Best deals, at amazing rates, suitable just for your company
                  </p>
                  <p
                    className={styles.para_3}
                    onClick={() => handleOpenModal("upgrade plan")}
                  >
                    Upgrade to Pro <img src={arrowRight} alt="" />
                  </p>
                </>
              )}
            </CustomBox> */}

        {/*           
            <CustomModal
              width={"965px"}
              footer={false}
              openModal={modalOpen}
              childrenStyles={styles.modal}
              closeModal={handleCloseModal}
              title={
                <div className={styles.modalTitle}>
                  <div className={styles.heading}> Co-Spire Billing Plans</div>
                  <div className={styles.subHeading}>
                    {" "}
                    Lorem ipsum dolor sit amet consectetur.{" "}
                  </div>
                  <div></div>
                </div>
              }
            >
              <BillingPlans />
            </CustomModal> */}

        {/* </Row>  */}
        <Divider orientation="left"></Divider>
        <p className={styles.book}>
          Recent Booking{" "}
          <Link to={"/host-profile/bookings"} className={styles.book_seeAll}>
            {" "}
            See all
          </Link>{" "}
        </p>
        <Divider orientation="left"></Divider>

        {isLoading ? (
          <Preview
            image={bookingPreview}
            bookingText="No bookings yet!"
            labelText="Your recent bookings will be displayed here."
          />
        ) : (
          <div className={styles.table_container}>
            <CustomTable columns={booking} data={recentHostTransaction} />
          </div>
        )}
      </div>

      {/* <div>
          <Divider orientation="left"></Divider>
          <p className={styles.book}>
            Recent Transactions{" "}
            <Link
              to={"/host-profile/transactions_payments"}
              className={styles.book_seeAll}
            >
              {" "}
              See all
            </Link>{" "}
          </p>
          <Divider orientation="left"></Divider>

          {isLoading ? (
            <Preview
              image={bankNotes}
              bookingText="No Active transaction"
              labelText="Your recent transactions will be displayed here."
            />
          ) : (
            <div className={styles.table_container}>
              <CustomTable columns={recentTransaction} data={bookings} />
            </div>
          )}
        </div> */}
    </div>
    // </div>
  );
};

export default HomeHost;
