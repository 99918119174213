import React from "react";
import { LockIcon, CheckIcon } from "@/components/SVGs";
import { FeatureItem } from "@/types/subscription";

interface FeatureListProps {
  features: FeatureItem[];
}

const FeatureList: React.FC<FeatureListProps> = ({ features }) => {
  return (
    <ul className="list-none grid gap-2">
      {features.map((item, index) => (
        <li key={index} className="flex gap-1 items-center">
          {item.isLocked ? <LockIcon /> : <CheckIcon />}
          <span
            className={item.isLocked ? "text-textGrey" : "text-coSpireBlack"}
          >
            {item.name}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default FeatureList;
