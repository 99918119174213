import React, { useRef, useState } from "react";
import styles from "./index.module.scss";
import { Dialog } from "@/components/b/Dialog";
import Close from "@/assets/svgs/Close.svg";
import Location from "@/assets/svgs/map-pin-black";
import ChekBadge from "@/assets/svgs/check-badge.svg";
import Star from "@/assets/svgs/Vector.svg";
import chekbadge from "@/assets/svgs/check-badge.svg";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Divider,
  Rate,
  Select,
  message,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { createReview } from "@/services/networkRequests/workspaceService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { CustomButton } from "@/components/customButton";
import { useMe } from "@/data/user";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  services: any | [];
}

// const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// 	console.log(`checked = ${e.target.checked}`);
// };

const desc = [
  "Good Service",
  "I can live with It",
  "Great Service",
  "Excellent Service",
  "Absolutely Fantastic",
];

const MessageHost = ({ isOpen, closeModal, services }: Prop) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [rating, setRating] = useState(1);
  const { me: loggedInUser, isLoggedIn } = useMe();
  const params = useParams();
  const id = params?.id || "uIwwbh0fqb0QvXTKh6n4";
  console.log(id);

  const onChange = (value: number) => {
    setRating(value);
  };

  const handleMessage = async (values: any) => {
    // const fullName = form.getFieldValue("fullName");
    const message = form.getFieldValue("message");
    const fullName = form.getFieldValue("fullName");
    const email = form.getFieldValue("email");
    const payload = {
      user_id: loggedInUser?.uid,
      message: message,
      workspace_id: id,
      email: loggedInUser?.email || email,
      reviewers_name: loggedInUser?.displayName || fullName,

      // fullName: fullName,
    };
    console.log(payload);
    try {
      await createReview(payload);
      console.log("Successfully Submitted");

      // Clear the form fields after successful submission
      form.resetFields();

      // Show success toast message
      toast.success("Message submitted successfully!");

      // Close the modal
      closeModal();
      // fetchWorkspace();
    } catch (error) {
      toast.error("An error occured, please try again");
    }

    console.log("values", form.getFieldsValue());
  };

  return (
    <Dialog isOpen={isOpen} close={closeModal}>
      <div className={styles.main}>
        <div className={styles.top}>
          <div>
            <div className={styles.location1}>
              <h2>Contact John River</h2>
            </div>
            <div className={styles.location}>
              <p>
                Thank you for your interest in our space, please leave us a
                message, we are always available to respond.
              </p>
            </div>
          </div>
          <div className={styles.close} onClick={closeModal}>
            <img src={Close} />
          </div>
        </div>
        <div className={styles.review}>
          <Form
            layout="vertical"
            size={"large"}
            autoComplete="off"
            form={form}
            onFinish={async () => {
              console.log(form.getFieldsValue());

              await handleMessage(form.getFieldsValue());
              console.log("Done");
            }}
            className={styles.form}
          >
            <Form.Item
              name="fullName"
              className={styles.formItem}
              label={<span className={styles.label}>Full name</span>}
              rules={[
                { required: false, message: "Please input your full name!" },
              ]}
            >
              <Input
                type="text"
                className={styles.input}
                placeholder="Enter your Fullname"
              />
            </Form.Item>

            <Form.Item
              name="email"
              className={styles.formItem}
              label={<span className={styles.label}>Email Address</span>}
              rules={[
                {
                  required: false,
                  message: "Please input your email address!",
                },
              ]}
            >
              <Input
                type="text"
                className={styles.input}
                placeholder="Enter your Email Address"
              />
            </Form.Item>

            <Form.Item
              name="message"
              className={styles.formItem}
              label={<span className={styles.label}>Your Message</span>}
              rules={[
                {
                  required: false,
                  message: "Type in your Message about the space",
                },
              ]}
            >
              <TextArea className={styles.input} rows={4} />
            </Form.Item>

            <Form.Item className={styles.formItem} name="checkbox">
              <Checkbox className={styles.checkbox}>
                I confirm that I have read and agree to Co-Spire’s Privacy
                Policy and consent to sharing my information.
              </Checkbox>
            </Form.Item>
            <CustomButton
              disabled={
                !form.isFieldsTouched(true) ||
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0
              }
              type="primary"
              htmlType="submit"
              className={styles.button}
            >
              Submit Message
            </CustomButton>
          </Form>
        </div>
      </div>
    </Dialog>
  );
};

export default MessageHost;
