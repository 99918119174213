import React, { ReactNode } from 'react';

interface ModalWrapperProps {
	isOpen: boolean;
	children: ReactNode;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({ isOpen, children }) => {
	if (!isOpen) return null;
	return (
		<div className='fixed bottom-0 top-0 right-0 left-0 bg-transparent z-10'>
			<div className='flex flex-col items-center justify-center h-full'>{children}</div>
		</div>
	);
};

export default ModalWrapper;
