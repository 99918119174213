import React,{useState,useEffect} from "react";

interface DataType {
  key?: string;
  customerInformation: {
    name: string;
    createdDate: Date;
  };
  bookingStatus: string;
  amount: number;
  paymentStatus: string;
 
}

const data: DataType[] = [
  {
    key: "1",
    customerInformation: {
      name: "Customer Name",
      createdDate: new Date("2023-09-06"),
    },
    bookingStatus: "Ongoing",
    amount: 5000,
    paymentStatus: "Approved",
 
  },
  {
    key: "2",
    customerInformation: {
      name: "Customer Name",
      createdDate: new Date("2023-09-06"),
    },
    bookingStatus: "Pending",
    amount: 5000,
    paymentStatus: "Pending",
 
  },
  {
    key: "3",
    customerInformation: {
      name: "Customer Name",
      createdDate: new Date("2023-09-06"),
    },
    bookingStatus: "Cancelled",
    amount: 5000,
    paymentStatus: "Pending",
  
  },
];
const useCustomer = () => {
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useState(() => {
    setTimeout(() => {
      setIsFetching(false);
    }, 4000);
  });
  const spaces=[
    {
      locationName: 'Kalsie WorkSpace',
      rating: 5 ,
      isVerified: true,
      verifyReview: 200,
      location: 'Lagos, Nigeria'
    },
    {
      locationName: 'Kalsie WorkSpace',
      rating: 4 ,
      isVerified: true,
      verifyReview: 200,
      location: 'Lagos, Nigeria'
    },
    {
      locationName: 'Kalsie WorkSpace',
      rating: 4 ,
      isVerified: false,
      verifyReview: 200,
      location: 'Lagos, Nigeria'
    },
  ]

  const getColor = (text: string) => {
    if (text === "Ongoing") {
      return {
        color: "green",
        backgroundColor: "rgba(191, 248, 200, 1)",
      };
    } else if (text === "Cancelled") {
      return {
        color: "red",
        backgroundColor: "rgba(255, 204, 204, 1)",
      };
    } else if (text === "Approved") {
      return {
        color: "green",
        backgroundColor: "rgba(191, 248, 200, 1)",
      };
    } else if (text === "Pending") {
      return {
        color: "orange",
        backgroundColor: "rgba(255, 229, 204, 1)",
      };
    } else {
      return {
        color: "black",
        backgroundColor: "rgba(255, 255, 255, 1)",
      };
    }
  };

  

  return {
    data,
    getColor,
    spaces,
    isFetching
  };
};

export default useCustomer;
