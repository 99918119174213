import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  searchValue: string;
  searchLocation: string;
}

const initialState: UserState = {
  searchValue: "",
  searchLocation: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setLocation: (state, action: PayloadAction<string>) => {
      state.searchLocation = action.payload;
    },
  },
});

export const { setSearchValue, setLocation } = userSlice.actions;
export default userSlice.reducer;
