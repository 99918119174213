import React from 'react';
import styles from "./Table.module.scss";
import { Space, Table, Tag } from 'antd';
import type { ColumnType } from 'antd/es/table';
   
  interface ITable<T> {
    columns: ColumnType<T>[]
    data: T[]
    rowClassName?: (record: T) => string;
  }


const CustomTable = ({data, columns, rowClassName} : ITable<any>) => {
  return (
    <div className={styles.pages}>
      <Table 
        dataSource={data} 
        columns={columns} 
        rowClassName={rowClassName} 
        pagination={{ position: ['bottomCenter'] }} 
      />
    </div>
  )
}

export default CustomTable