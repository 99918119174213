export const subscription_data = {
  starter: {
    id: "starter",
    name: "Starter",
    description: "Best for individuals",
    plan_code: "PLN_3kjxil13jv4ie9p",
    features: [
      { name: "Booking management ", isLocked: false },
      { name: "List up to 6 spaces", isLocked: false },
      { name: "Inbound marketing", isLocked: false },
      { name: "Sponsored listing", isLocked: true },
      { name: "Basic Insights and Analytics", isLocked: true },
      { name: "Advanced Insights and Analytics", isLocked: true },
      { name: "Monthly performance report", isLocked: true },
      { name: "Outbound marketing (Ads)", isLocked: true },
      { name: "Credit-card payments", isLocked: false },
      { name: "Managed via Slack and Trello", isLocked: false },
      { name: "Pause or cancel anytime", isLocked: false },
      { name: "Enhanced profile customization", isLocked: true },
      { name: "Customer testimonials", isLocked: true },
    ],
    price: 22500,
    level: 1,
  },
  basic: {
    id: "basic",
    name: "Basic",
    description: "Best for small businesses",
    plan_code: "PLN_3kjxil13jv4ie9p",
    features: [
      { name: "Booking management ", isLocked: false },
      { name: "List up to 30 spaces", isLocked: false },
      { name: "Inbound marketing", isLocked: false },
      { name: "Sponsored listing-2 times a week", isLocked: false },
      { name: "Basic Insights and Analytics", isLocked: false },
      { name: "Advanced Insights and Analytics", isLocked: true },
      { name: "Monthly performance report", isLocked: true },
      { name: "Outbound marketing (Ads)", isLocked: true },
      { name: "Access to renter’s KYC", isLocked: false },
      { name: "Dedicated account manger", isLocked: false },
      { name: "Customer testimonials", isLocked: false },
      { name: "Enhanced profile customization", isLocked: false },
      { name: "Loyalty Program and Rewards", isLocked: false },
    ],
    price: 30500,
    level: 2,
  },
  premium: {
    id: "premium",
    name: "Premium",
    description: "Best for large businesses",
    plan_code: "PLN_0kujvbn6l8lsxqu",
    features: [
      { name: "Booking management ", isLocked: false },
      { name: "List up to 30 spaces", isLocked: false },
      { name: "Inbound marketing", isLocked: false },
      { name: "Sponsored listing-2 times a week", isLocked: false },
      { name: "Basic Insights and Analytics", isLocked: false },
      { name: "Advanced Insights and Analytics", isLocked: false },
      { name: "Monthly performance report", isLocked: false },
      { name: "Outbound marketing (Ads)", isLocked: false },
      { name: "Access to renter’s KYC", isLocked: false },
      { name: "Dedicated account manger", isLocked: false },
      { name: "Customer testimonials", isLocked: false },
      { name: "Enhanced profile customization", isLocked: false },
      { name: "Loyalty Program and Rewards", isLocked: false },
    ],
    price: 40500,
    level: 3,
  },
};
