import { SearchOutlined } from "@ant-design/icons";
import { CustomInputField } from "../customInput";
import styles from "./Search.module.scss";

type Isearch = {
   placeholder?: string;
   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
   disabled?: boolean;
   extraStyles?: Record<string, string>;
   value?: string;
   className?: string
};

export const Search = ({ disabled, extraStyles, placeholder, value, onChange, className }: Isearch) => {
   return (
      <>
         <CustomInputField
            type={"text"}
            suffix={<SearchOutlined />}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            extraStyles={extraStyles}
            className={className}
         />
      </>
   );
};
