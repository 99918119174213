import React, { useState } from "react";
import { Radio } from "antd";

interface Plan {
  title: string;
  amount: number | string;
  period: string;
  ctaText: string;
  offerings: string[];
  ctaButtonText: string;
  note: string;
  bgColor: string;
  type?: string;
}

const AddOnTemplate: React.FC<{
  item: Plan;
  handleSubscriptionPlan: (title: string) => void;
}> = ({ item, handleSubscriptionPlan }) => {
    const [selectedOffering, setSelectedOffering] = useState<string | undefined>(undefined);

  const handleOfferingChange = (e: any) => {
    setSelectedOffering(e.target.value);
  };
  return (
    <div
      className={`grid gap-4 place-items-center rounded-2xl p-6 ${item.bgColor}`}
    >
      <p className="leading-9 text-3xl text-center">{item.title}</p>
      <p className="leading-9 text-3xl">
        ₦{item.amount}
        <sub className="text-[9px] font-normal">{item.period}</sub>
      </p>
      <p className="text-textGrey">{item.ctaText}</p>
      {item.type === "radio" ? (
        <Radio.Group onChange={handleOfferingChange} value={selectedOffering} className="grid">
          {item.offerings.map((offering, index) => (
            <Radio key={index} value={offering}>
              {offering}
            </Radio>
          ))}
        </Radio.Group>
      ) : (
        <ol>
          {item.offerings.map((subItem, subIndex) => (
            <li key={subIndex} className="list-disc">
              {subItem}
            </li>
          ))}
        </ol>
      )}

      <div className="grid">
        <button
          className="ring-1 px-6 py-2 rounded-2xl w-full hover:bg-darkBlue hover:text-white"
          onClick={() => handleSubscriptionPlan(item.title)}
        >
          {item.ctaButtonText}
        </button>
      </div>
      <p>{item.note}</p>
    </div>
  );
};

export default AddOnTemplate;
