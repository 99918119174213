import React, { useState } from "react";
import ReactSlider from "react-slider";
import "./exploreFilterModal.scss";
import { Workspace } from "@/types/workspace";
import { WorkspaceNew } from "@/types";

type Props = {
  toggleFilterModal: any;
  filteredData: Workspace[];
  onApplyFilter: (filteredSpaces: Workspace[]) => void;
};
export default function ExploreFilterModal({
  toggleFilterModal,
  filteredData,
  onApplyFilter,
}: Props) {
  const [priceValues, setPriceValues] = useState([5, 50000]);
  const [hoursValue, setHoursValue] = useState([0, 24]);
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [spaceTypes, setSpaceTypes] = useState<string[]>([]);
  const [allowed, setAllowed] = useState<string[]>([]);
  const [showMorePopularTypes, setShowMorePopularTypes] = useState(false);

  function addCommas(number: number) {
    let numStr = String(number);
    let startIndex = numStr.length % 3;
    if (startIndex === 0) {
      startIndex = 3;
    }
    let result = numStr.slice(0, startIndex);
    for (let i = startIndex; i < numStr.length; i += 3) {
      result += "," + numStr.slice(i, i + 3);
    }
    return result;
  }

  function convertToAMPM(hours: number) {
    if (hours === 0) {
      return "12 AM";
    } else if (hours < 12) {
      return hours + " AM";
    } else if (hours === 12) {
      return "12 PM";
    } else {
      return hours - 12 + " PM";
    }
  }

  const handleToggleSpaceType = (type: string) => {
    if (spaceTypes.includes(type)) {
      setSpaceTypes(spaceTypes.filter((item) => item !== type));
    } else {
      setSpaceTypes([...spaceTypes, type]);
    }
  };

  const handleToggleAllowed = (type: string) => {
    if (allowed.includes(type)) {
      setAllowed(allowed.filter((item) => item !== type));
    } else {
      setAllowed([...allowed, type]);
    }
  };

  const filterSpaces = () => {
    return filteredData.filter((space) => {
      const meetsPriceCriteria =
        Math.min(
          space?.pricing?.hourly,
          space?.pricing?.daily,
          space?.pricing?.weekly,
          space?.pricing?.monthly
        ) >= priceValues[0] &&
        Math.min(
          space?.pricing?.hourly,
          space?.pricing?.daily,
          space?.pricing?.weekly,
          space?.pricing?.monthly
        ) <= priceValues[1];

      const meetsHoursCriteria =
        Number(space?.minimum_hours) <= hoursValue[1] - hoursValue[0];

      const meetsPeopleCriteria = numberOfPeople
        ? Number(space?.space_size) >=
            (parseInt(numberOfPeople.split("-")[0]) || 0) &&
          Number(space?.space_size) <=
            (parseInt(numberOfPeople.split("-")[1]) || Infinity)
        : true;

      const meetsSpaceTypeCriteria =
        spaceTypes.length > 0
          ? spaceTypes.some(
              (type) =>
                space.space_category.toLowerCase() === type.toLowerCase().trim()
            )
          : true;

      const meetsAllowedCriteria =
        allowed.length > 0
          ? allowed.every((a) => {
              switch (a) {
                case "Adult Filming":
                  return space.other_policy.is_adult_filming_allowed;
                case "Outdoor Catering":
                  return space.food_policy.is_external_food_allowed;
                case "Alcohol":
                  return space.other_policy.is_alcohol_allowed;
                case "Smoking":
                  return space.other_policy.is_smoking_allowed;
                case "Electricity Usage":
                  return space.service_amenities.some(
                    (s) => s.name === "Electricity"
                  );
                case "Cooking":
                  return space.service_amenities.some(
                    (s) => s.name === "Cooking Facilities"
                  );
                case "Loud Music":
                  return space.other_policy.is_music_allowed;
                case "Pets":
                  return space.other_policy.is_pet_allowed;
                default:
                  return false;
              }
            })
          : true;

      return (
        meetsPriceCriteria &&
        meetsHoursCriteria &&
        meetsPeopleCriteria &&
        meetsSpaceTypeCriteria &&
        meetsAllowedCriteria
      );
    });
  };

  const filteredSpaces = filterSpaces();

  const handleShowListingsClick = () => {
    // Assuming `filteredSpaces` is the updated filtered data
    onApplyFilter(filteredSpaces);
  };

  return (
    <div className="explore-filter-modal-parent" onClick={toggleFilterModal}>
      <div
        className="explore-filter-modal"
        onClick={(event) => event.stopPropagation()}
      >
        <h2 className="title-h2">Filters</h2>
        <div className="mobile-filter-heading">
          <h2>Filters</h2>
          <p onClick={toggleFilterModal} className="close filter">
            &#10006;
          </p>
        </div>
        <div className="price-range-div filter-category">
          <p>Price Range</p>
          <div className="price-range-content">
            <div className="price-range-div-option">
              <span>From</span>
              <p>₦{addCommas(priceValues[0])}</p>
            </div>
            <div className="price-range-div-option">
              <span>To</span>
              <p>₦{addCommas(priceValues[1])}</p>
            </div>
          </div>
          <div className="slider-wrapper">
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              defaultValue={priceValues}
              ariaLabel={["Lower thumb", "Upper thumb"]}
              ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
              renderThumb={(props, state) => <div {...props}></div>}
              pearling
              min={5}
              max={50000}
              minDistance={100}
              onChange={(newValues) => setPriceValues(newValues)}
            />
          </div>
        </div>
        <div className="attendees-div filter-category">
          <p>Attendees</p>
          <div className="check-box-wrapper">
            {["1-5", "6-15", "16-30", "31-45", "46-50", "60+"].map((range) => (
              <div className="filter-form-select-div" key={range}>
                <input
                  type="checkbox"
                  style={{ WebkitAppearance: "checkbox" }}
                  checked={numberOfPeople === range}
                  onChange={() => {
                    setNumberOfPeople(numberOfPeople === range ? "" : range);
                  }}
                />
                <span>{range.replace("+", " +")} People</span>
              </div>
            ))}
          </div>
        </div>
        <div className="price-range-div filter-category">
          <p>Minimum Hours</p>
          <div className="price-range-content">
            <div className="price-range-div-option">
              <span>From</span>
              <p>{convertToAMPM(hoursValue[0])}</p>
            </div>
            <div className="price-range-div-option">
              <span>To</span>
              <p>{convertToAMPM(hoursValue[1])}</p>
            </div>
          </div>
          <div className="slider-wrapper">
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              defaultValue={hoursValue}
              ariaLabel={["Lower thumb", "Upper thumb"]}
              ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
              pearling
              min={0}
              max={24}
              minDistance={1}
              onChange={(newValues) => setHoursValue(newValues)}
            />
          </div>
        </div>
        <div className="popular-types-div filter-category">
          <p>Popular Types</p>
          <div className="check-box-wrapper">
            {[
              "Content Creation",
              "Music Studio",
              "Working Spaces",
              "Outdoor Weddings",
              "Art Galleries",
              "Content Creation",
              "Podcast studio",
              "Party space",
              "Event Spaces",
              "Filming venues",
              "Outdoor wedding",
              "Video shooting venues",
              "Work meetings",
            ]
              .slice(0, showMorePopularTypes ? undefined : 5)
              .map((type) => (
                <div className="filter-form-select-div" key={type}>
                  <input
                    type="checkbox"
                    style={{ WebkitAppearance: "checkbox" }}
                    checked={spaceTypes.includes(type)}
                    onChange={() => handleToggleSpaceType(type)}
                  />
                  <span>{type}</span>
                </div>
              ))}
          </div>
          <p
            className="show-all"
            onClick={() => setShowMorePopularTypes(!showMorePopularTypes)}
          >
            {showMorePopularTypes ? "Show less" : "Show all (12)"}
          </p>
        </div>
        <div className="allowed filter-category">
          <p>Location Allows</p>
          <div className="check-box-wrapper">
            {[
              "Adult Filming",
              "Outdoor Catering",
              "Alcohol",
              "Smoking",
              "Electricity Usage",
              "Cooking",
              "Loud Music",
              "Pets",
            ].map((type) => (
              <div className="filter-form-select-div" key={type}>
                <input
                  type="checkbox"
                  style={{ WebkitAppearance: "checkbox" }}
                  checked={allowed.includes(type)}
                  onChange={() => handleToggleAllowed(type)}
                />
                <span>{type}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="last-div filter-category">
          <p onClick={toggleFilterModal}>Clear All</p>
          <button onClick={handleShowListingsClick}>
            Show {filteredSpaces.length}+ Listing
          </button>
        </div>
      </div>
    </div>
  );
}
