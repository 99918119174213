import { Field, useFormikContext } from "formik";
import "./setupPage.scss";
import arrowDown from "@/assets/svgs/arrow-down.svg";
import countryData from "@/assets/data/countries+states+cities.json";
import { CustomButton } from "@/components/customButton";
import AddressAutocomplete from "@/components/address-autocomplete";
import { AutoComplete } from "antd";
import SearchableSelect from "@/components/searchableSelect";
import { cn } from "@/lib/utils";

const convertedCountryData = Array.prototype.slice.call(countryData);

export const Address = ({ saveContinue, values }: any) => {
  const { setFieldValue } = useFormikContext();

  const handleAddressChange = (address: string) => {
    setFieldValue("streetAddress", address);
  };

  const countryOptions = convertedCountryData.map(
    (country: any, index: number) => ({
      value: index,
      label: country.name,
    })
  );

  const stateOptions =
    values?.countryRegion !== ""
      ? convertedCountryData[values?.countryRegion].states.map(
          (state: any, index: number) => ({
            value: index,
            label: state.name,
          })
        )
      : [];

  const cityOptions =
    values?.countryRegion !== "" && values?.state !== ""
      ? convertedCountryData[values?.countryRegion].states[
          values?.state
        ].cities.map((city: any, index: number) => ({
          value: index,
          label: city.name,
        }))
      : [];

  return (
    <div className="page-one page-two">
      <div className={cn("heading-div", "flex !justify-between")}>
        <div>
          <h2>Tell us your location</h2>
          <p className="sub-title">Let us know where your space is situated</p>
        </div>
        <div className=""></div>
      </div>
      <div className="page-input-field">
        <label>Country / Region</label>
        <div className="page-input-filed-input-wrapper">
          <Field
            name="countryRegion"
            component={SearchableSelect}
            options={countryOptions}
            placeholder="Select a country"
          />
        </div>
      </div>
      <div className="page-input-field-double">
        <div className="page-input-field">
          <label>State</label>
          <div className="page-input-filed-input-wrapper">
            <Field
              name="state"
              component={SearchableSelect}
              options={stateOptions}
              placeholder="Select a  state"
            />
          </div>
        </div>
        <div className="page-input-field">
          <label>City</label>
          <div className="page-input-filed-input-wrapper">
            <Field
              name="city"
              component={SearchableSelect}
              options={cityOptions}
              placeholder="Select a city"
            />
          </div>
        </div>
      </div>
      <div className="page-input-field-double">
        <div className="page-input-field">
          <label>Street Address</label>
          <div className="page-input-filed-input-wrapper">
            <AddressAutocomplete
              address={values.streetAddress}
              setAddress={handleAddressChange}
            />
          </div>
        </div>
        <div className="page-input-field">
          <label>Zip Code</label>
          <div className="page-input-filed-input-wrapper">
            <Field
              as="input"
              type="text"
              placeholder="Enter Zip Code"
              id="zipCode"
              name="zipCode"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
