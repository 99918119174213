import { useState } from "react";
import { CancelIcon } from "@/components/SVGs";
import ModalWrapper from "@/components/Subscriptions/ModalWrapper";
import AddOnTemplate from "@/components/Subscriptions/AddOnTemplate";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  onPlanSelect: any;
}
const AddonPopup: React.FC<Prop> = ({ isOpen, closeModal, onPlanSelect }) => {
  const userSelection = {
    title: "iwalkTroo™️",
    about:
      "Activate virtual live tours for your listings and allow renters have seamless booking experience.",
    amount: "10,000",
    period: "per month",
    ctaText: "See plan options below",
    offerings: [
      "WalkTru Basic - 2 tours/month",
      "walkTru Standard - 5 tours/month",
      "walkTru Premium - unlimited tours",
    ],
    ctaButtonText: "Buy Add-on",
    note: "Note: This Add-on can be bought alone",
    bgColor: "bg-white",
    type: "radio",
  };

  const handleSubscriptionPlan = (plan: any) => {
    onPlanSelect(plan);
  };

  return (
    <ModalWrapper isOpen={isOpen}>
      <div className="grid bg-white shadow-xl py-6 px-8 rounded-2xl">
        <button
          title="Cancel"
          className="flex justify-end"
          onClick={closeModal}
        >
          <CancelIcon />
        </button>

        <AddOnTemplate
          item={userSelection}
          handleSubscriptionPlan={handleSubscriptionPlan}
        />
      </div>
    </ModalWrapper>
  );
};

export default AddonPopup;
