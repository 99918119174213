import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import { getAllBookings } from "@/services/networkRequests/bookingService";
import * as dayjs from "dayjs";
import useHostWorkspaces from "@/pages/Host/Profile/components/MyWorkspaces/useHostWorkspaces";
import { toast } from "react-toastify";
import { log } from "console";
import { message1, message2, message3, message4 } from "@/assets/img";
import { useMe } from "@/data/user";
// import { dayjs } from 'dayjs';
enum tabs {
  all_messages = "All",
  unread_messages = "Unread Messages",
  starred_messages = "Starred Messages",
  // cancelled = "Unread Messages",
}

const messageStatus = [
  { label: "Unread", value: "unread_messages" },
  { label: "Starred", value: "Starred" },
];

interface DataType {
  key: string;
  workspaceInformation: {
    name: string;
    message: string;
    avatar: string;
    createdDate: Date;
    chatMessages: any;
  };
  messagesStatus: string;
  amount: number;
  messageStatus: string;
  more: string;
}

const chatMessages = [
  { type: "sender", text: "Hello I need your help", time: "12:30 PM" },
  { type: "recipient", text: "Hello, how may I help you", time: "12:31 PM" },
  {
    type: "sender",
    text: "I need to confirm if I can book for long",
    time: "12:32 PM",
  },
  { type: "sender", text: "Like the entire month", time: "12:33 PM" },
  { type: "recipient", text: "Yea sure! Hold on for a min.", time: "12:34 PM" },
  { type: "sender", text: "Alright, I'm waiting", time: "12:35 PM" },
];

const data: DataType[] = [
  {
    key: "1",
    workspaceInformation: {
      name: "Borderless Production Studio",
      message: "Thank you for booking our space.",
      avatar: message1,
      createdDate: new Date("2023-07-25"),
      chatMessages: chatMessages,
    },
    messagesStatus: "Starred Messages",
    amount: 5000,
    messageStatus: "Starred",
    more: "",
  },
  {
    key: "2",
    workspaceInformation: {
      name: "Eva’s Event",
      message: "Thank you for booking our space.",
      avatar: message2,
      createdDate: new Date("2023-07-25"),
      chatMessages: chatMessages,
    },
    messagesStatus: "Starred Messages",
    amount: 5000,
    messageStatus: "Starred",
    more: "",
  },
  {
    key: "4",
    workspaceInformation: {
      name: "Fad Art Gallery",
      message: "Thank you for booking our space.",
      avatar: message3,
      createdDate: new Date("2023-07-25"),
      chatMessages: chatMessages,
    },
    messagesStatus: "Unread Messages",
    amount: 5000,
    messageStatus: "Starred",
    more: "",
  },
  {
    key: "5",
    workspaceInformation: {
      name: "Borderless workspace",
      message: "Thank you for booking our space.",
      avatar: message4,
      createdDate: new Date("2023-07-25"),
      chatMessages: chatMessages,
    },
    messagesStatus: "Unread Messages",
    amount: 5000,
    messageStatus: "Starred",
    more: "",
  },
  {
    key: "6",
    workspaceInformation: {
      name: "Borderless workspace",
      message: "Thank you for booking our space.",
      avatar: message4,
      createdDate: new Date("2023-07-25"),
      chatMessages: chatMessages,
    },
    messagesStatus: "Unread Messages",
    amount: 5000,
    messageStatus: "Unread",
    more: "",
  },
];

const useMessage = () => {
  const [currentTab, setcurrentTab] = useState<string>("All");
  const [selectedMessageStatus, setSelectedMessageStatus] = useState<
    string | null
  >(null);
  const [searchValue, setSearchValue] = useState<any>(null);
  const tabLists = [
    tabs.all_messages,
    tabs.unread_messages,
    tabs.starred_messages,
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState<any>([]);
  const [messageTest, setMessageTest] = useState<any>([]);
  const [customerMessages, setCustomerMessages] = useState<any>([]);
  const { me: loggedInUser } = useMe();

  useEffect(() => {
    async function getData() {
      const id = loggedInUser?.uid;
      if (id) {
        try {
          // const { data } = await getAllBookings(id);
          setMessageTest(data);

          let filterBookings = data;
          // let filterBookings = data.filter(
          //    (item: any) => item.created_by === loggedInUser?.uid
          // );

          filterBookings.forEach((element: any) => {
            element.companyInfo = {
              companyName: element.name,
              start_date: element.createdDate,
            };
          });
          setCustomerMessages(filterBookings);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error("Please login to continue");
      }
    }
    getData();
  }, [loggedInUser?.uid, data]);

  useEffect(() => {
    async function getData() {
      const id = loggedInUser?.uid;
      if (id) {
        try {
          // const { data } = await getAllBookings(id);
          setMessageTest(data);

          let filterBookings = data;
          // let filterBookings = data.filter(
          //   (item: any) => item.created_by == loggedInUser?.uid
          // );

          filterBookings.forEach((element: any) => {
            element.companyInfo = {
              companyName: element.name,
              start_date: element.createdDate,
            };
          });
          setMessages(data);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error("Please login to continue");
      }
    }
    getData();
  }, [loggedInUser?.uid, data]);

  const customerMessagesData = customerMessages;
  console.log(customerMessagesData, "customerMessagesData");

  const { hostWorkSpaces } = useHostWorkspaces();

  function getAllMessageForHost(hostWorkSpaces: any, messages: any) {
    const workspaceIds = hostWorkSpaces?.map(
      (workspace: any) => workspace.workspace_id
    );

    let hostBookings = messages.filter((booking: any) =>
      workspaceIds?.some(
        (workspaceId: any) => workspaceId === booking.workspace_id
      )
    );

    hostBookings.forEach((element: any) => {
      element.companyInfo = {
        companyName: element.name,
        start_date: element.createdDate,
      };
      console.log(hostBookings);
    });
    return hostBookings;
  }

  const onTabClick = (tab: string) => {
    setcurrentTab(tab);
  };

  const onSelectPaymentStatus = (status: string) => {
    setSelectedMessageStatus(status);
  };

  const getColor = (text: string): { [key: string]: string } => {
    switch (text) {
      case "Starred Messages":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "Unread Messages":
        return {
          color: "red",
          backgroundColor: "rgba(255, 204, 204, 1)",
        };
      case "Starred":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "Unread":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 229, 204, 1)",
        };
      case "unread_messages":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 229, 204, 1)",
        };
      case "Unread Messages":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 229, 204, 1)",
        };
      case "Unread Messages":
        return {
          color: "white",
          backgroundColor: "rgba(194, 201, 209, 1)",
        };
      case "Starred Messages":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "inprogress":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      default:
        return {
          color: "black",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
    }
  };

  const filterData = () => {
    //  let filteredData = messages;
    let filteredData;

    if (loggedInUser?.role_type === "Host") {
      filteredData = messages;
    } else {
      filteredData = customerMessagesData;
    }

    if (selectedMessageStatus) {
      filteredData = filteredData.filter(
        (item: any) => item.messagesStatus.trim() === selectedMessageStatus
      );
      // filteredData = filteredData.filter((item:any) => item.messageStatus === selectedMessageStatus);
    }

    if (currentTab !== tabs.all_messages) {
      filteredData = filteredData.filter(
        (item: any) => item.messagesStatus === currentTab
      );
      // filteredData = filteredData.filter((item:any) => item.messagesStatus === currentTab);
    }

    if (searchValue) {
      filteredData = filteredData?.filter((item: any) =>
        item?.workspaceInformation?.name
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase())
      );
    }

    return filteredData;
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return {
    tabLists,
    currentTab,
    onTabClick,
    messageStatus,
    onSelectPaymentStatus,
    onSearch,
    getColor,
    data: filterData(),
    isLoading,
    messages: filterData(),
    getAllMessageForHost,
    customerMessagesData: filterData(),
    messageTest,
    hostWorkSpaces,
  };
};
export default useMessage;
