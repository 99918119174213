import React, { useRef, useState } from "react";
import styles from "./index.module.scss";
import { Dialog } from "@/components/b/Dialog";
import Close from "@/assets/svgs/Close.svg";
import CustomModal from "../CustomModal/CustomModal";

interface Prop {
  isOpen: boolean;
  closeModal: () => void;
  services: any;
  otherService: any;
}

const Services = ({ isOpen, closeModal, services, otherService }: Prop) => {
  return (
    <>
      <Dialog isOpen={isOpen} close={closeModal}>
        <div className={styles.main}>
          <div className={styles.top}>
            <h2>Services Offered</h2>
            <div className={styles.close} onClick={closeModal}>
              <img src={Close} />
            </div>
          </div>

          <div className={styles.content}>
            {services?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <img src={item.icon} />
                  <p>{item.name}</p>
                </div>
              );
            })}
          </div>
          <div>
            <h3>Other Services</h3>
            <div className={styles.content}>
              {otherService?.map((item: any, index: number) => {
                return (
                  <div key={index}>
                    <img src={item.icon} />
                    <p>{item.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* <CustomModal
        openModal={openModal}
        closeModal={handleCloseModal}
        width={"50%"}
        footer={false}
        
        title={
          <h2 >check</h2>
        }

      /> */}
      </Dialog>
    </>
  );
};

export default Services;
