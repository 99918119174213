import { Footer, Header } from "../components/a";
import { Spacer } from "../components/Spacer";
import styles from "./Layout.module.scss";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className={styles.layout}>
      <Header />
      <Spacer height={100} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
