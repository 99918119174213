import React, { useState } from "react";
import styles from "./index.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import logo from "@/assets/svgs/logo-cospire-svg.svg";
import google from "@/assets/svgs/google.svg";
import Frame from "@/assets/svgs/Frame.svg";
import Close from "@/assets/svgs/Close.svg";
import { createUser } from "@/redux/actions/authActions";

import { useAppDispatch } from "@/redux/hook";
import { IRegisterForm } from "@/interfaces/apiResponse";
import { handleError } from "@/services/networkRequests/error";
import { HOME_URL, LOGIN, VERIFY_EMAIL } from "@/routes/routes";
import { Checkbox } from "antd";
import { handleGoogleSignIn } from "../../../../firebase/functions";
import { sendUserVerifyEmail } from "../../../../services/networkRequests/userService";
import { useModalAction } from "@/components/modal-view/context";
import { useMe } from "@/data/user";

const RegisterUserForm = () => {
  const privacyLink =
    "https://drive.google.com/file/d/1BbY8lTb3EwGlsi98fBq4-jtYaxO83SZT/view?usp=sharing";
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { isLoading } = useMe();

  const { openModal, closeModal } = useModalAction();

  const handleFormSubmit = async (values: IRegisterForm) => {
    setLoading(true);
    const payload = {
      user_email: values.email,
      user_name_first: values.firstName,
      user_name_last: values.lastName,
      password: values.password,
      user_job_title: values.jobRole,
      role_type: "Customer",
    };

    try {
      const response = await dispatch(createUser(payload));
      if (response?.meta?.requestStatus === "fulfilled") {
        closeModal();
        sendEmail(payload.user_email);
      }
    } catch (error) {
      setLoading(false);
      handleError(error);
    } finally {
      setLoading(false);
      form.resetFields();
    }
  };

  const sendEmail = async (email: string) => {
    const payload = { userEmail: email, callbackUrl: HOME_URL + LOGIN };
    await sendUserVerifyEmail(payload);
    navigate(VERIFY_EMAIL, { replace: true });
  };

  const handleGoogleSignInwithGoogle = async () => {
    await handleGoogleSignIn();
    closeModal();
  };

  console.log({ agree });

  return (
    <div className={styles.main}>
      <div className={styles.close} onClick={closeModal}>
        <img src={Close} alt="close modal" />
      </div>
      <div className={styles.box}>
        <img alt="co-spire" src={logo} />
        <h1>Welcome to Co-spire:</h1>
        <p>Connect…Cowork…Co-spire!</p>
        <Form
          layout="vertical"
          size={"large"}
          autoComplete="off"
          form={form}
          disabled={loading}
          onFinish={async () => await handleFormSubmit(form.getFieldsValue())}
          className={styles.form}
        >
          <div className={styles.fullName}>
            <Form.Item
              name="firstName"
              className={styles.formItem}
              label={<span className={styles.label}>FirstName</span>}
              rules={[
                { required: true, message: "Please input your firstname!" },
              ]}
            >
              <Input
                type="text"
                className={styles.input}
                placeholder="Enter your Fullname"
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              className={styles.formItem}
              label={<span className={styles.label}>Lastname</span>}
              rules={[
                { required: true, message: "Please input your lastname!" },
              ]}
            >
              <Input
                type="text"
                className={styles.input}
                placeholder="Enter your Fullname"
              />
            </Form.Item>
          </div>
          <Form.Item
            name="job"
            className={styles.formItem}
            label={<span className={styles.label}>Occupation/Job Title</span>}
            rules={[
              { required: true, message: "Please input your job title!" },
            ]}
          >
            <Input
              type="text"
              className={styles.input}
              placeholder="What is your Job Title"
            />
          </Form.Item>

          <Form.Item
            name="email"
            className={styles.formItem}
            label={<span className={styles.label}>Email Address</span>}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              type="email"
              className={styles.input}
              placeholder="Enter your Email Address"
            />
          </Form.Item>

          <Form.Item
            name="password"
            className={styles.formItem}
            label={<span className={styles.label}>Password</span>}
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              className={styles.input}
              placeholder="Set a strong Password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            className={styles.formItem}
            label={<span className={styles.label}>Confirm Password</span>}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className={styles.input}
              placeholder="Confirm your Password"
            />
          </Form.Item>

          <Form.Item
            name="agree"
            valuePropName="checked"
            className={styles.formItem}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("agree") || value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "You must agree to the policy before submitting"
                  );
                },
              }),
            ]}
          >
            <Checkbox
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
            >
              By submitting your details you agree to our{" "}
              <Link to={privacyLink} style={{ color: "lightBlue" }}>
                Terms of use
              </Link>{" "}
              and
              <Link to={privacyLink} style={{ color: "lightBlue" }}>
                {" "}
                privacy policy
              </Link>
            </Checkbox>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className={styles.button}
            loading={loading}
            disabled={
              !agree || !Object.values(form.getFieldsValue()).every(Boolean)
            }
          >
            Signup
          </Button>
          <div className={styles.or}>
            <span></span>
            <p>or</p> <span></span>
          </div>
          <Button
            onClick={handleGoogleSignInwithGoogle}
            disabled={true}
            className={styles.goggle}
          >
            <img alt="Google logo" src={google} />{" "}
            <span>Login with Google</span>
          </Button>
          <p className={styles.register}>
            Already on Co-Spire?{" "}
            <button
              title="Login"
              type="button"
              onClick={() => openModal("LOGIN_VIEW")}
            >
              Login
            </button>{" "}
          </p>
        </Form>
      </div>
      <div className={styles.frame}>
        <img src={Frame} alt="..." />
      </div>
    </div>
  );
};

export default RegisterUserForm;
