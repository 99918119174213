export const LinkIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.0715 14.3293C18.7815 14.6193 18.3215 14.6193 18.0415 14.3293C17.7515 14.0393 17.7515 13.5793 18.0415 13.2993C20.0415 11.2993 20.0415 8.04933 18.0415 6.05933C16.0415 4.06933 12.7915 4.05933 10.8015 6.05933C8.81149 8.05933 8.80149 11.3093 10.8015 13.2993C11.0915 13.5893 11.0915 14.0493 10.8015 14.3293C10.5115 14.6193 10.0515 14.6193 9.77149 14.3293C7.20149 11.7593 7.20149 7.57933 9.77149 5.01933C12.3415 2.45933 16.5215 2.44933 19.0815 5.01933C21.6415 7.58933 21.6415 11.7593 19.0715 14.3293Z"
        fill="currentColor"
      />
      <path
        d="M4.93144 9.84934C5.22144 9.55934 5.68144 9.55934 5.96144 9.84934C6.25144 10.1393 6.25144 10.5993 5.96144 10.8793C3.96144 12.8793 3.96144 16.1293 5.96144 18.1193C7.96144 20.1093 11.2114 20.1193 13.2014 18.1193C15.1914 16.1193 15.2014 12.8693 13.2014 10.8793C12.9114 10.5893 12.9114 10.1293 13.2014 9.84934C13.4914 9.55934 13.9514 9.55934 14.2314 9.84934C16.8014 12.4193 16.8014 16.5993 14.2314 19.1593C11.6614 21.7193 7.48144 21.7293 4.92144 19.1593C2.36144 16.5893 2.36144 12.4193 4.93144 9.84934Z"
        fill="currentColor"
      />
    </svg>
  );
};
