import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ISpaceType, IWorkSpaceForm } from "../../../interfaces/forms";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
  createWorkSpace,
  updateWorkSpace,
} from "../../../services/networkRequests/workspaceService";
import { toast } from "react-toastify";
import { HOST_TABS, SERVICES } from "../../../mocks";
import { setEditModeOn } from "../../../redux/rootReducer";
import { IFile } from "../../../components/customImageUploader";
import { debounce } from "lodash";
import { useMe } from "@/data/user";

enum tabs {
  description = "Description",
  contact_details = "Contact",
  operation_hours = "Operation Schedule",
  services_amenities = "Services & Amenities",
  preview = "Preview",
}

export type TabsProp = typeof tabs;

type Crumbs = {
  [key: string]: string;
};

const breadCrumbTab: Crumbs = {
  description: "Description",
  contact_details: "Contact",
  operation_hours: "Operation Schedule",
  services_amenities: "Services & Amenities",
  preview: "Preview",
};

const initialValues: IWorkSpaceForm = {
  spaceName: "",
  spaceLocation: "",
  spaceDescription: "",
  spaceThumbnail: [],
  spaceEmailAddress: "",
  spacePhoneNumber: "",
  spaceWhatsappPhoneNumber: "",
  spaceWebsite: "",
  facebook: "",
  instagram: "",
  twitter: "",
  linkedin: "",
  selectDays: false,
  fileList: [],
  monday_friday_from: "",
  monday_friday_to: "",
  saturday_from: "",
  saturday_to: "",
  sunday_from: "",
  sunday_to: "",
  monday_sunday_from: "",
  monday_sunday_to: "",
  spaceType: SERVICES,
  spaceAmenity: ["24/7 Customer Support"],
  holidays: [],
  address: "",
  map: {
    lat: "",
    lng: "",
  },
};

const useHostForm = () => {
  const [initialState, setInitialState] = useState(initialValues);
  const [activeTab, setActiveTab] = useState<string>(tabs.description);
  const [openModal, setOpenModal] = useState(false);
  const [openSelectDays, setOpenSelectDays] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nameError, setNameError] = useState("");
  const { me: loggedInUser, isLoggedIn } = useMe();
  const { workspace, workspaceIsLoading, workspaceIsError, isEditing } =
    useAppSelector((state) => state.hostWorkSpace);
  const { workspaces } = useAppSelector((state) => state.hostWorkSpace);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { id, tab } = useParams();
  const currentTab = breadCrumbTab[activeTab];

  const handleFormSubmit = async (values: IWorkSpaceForm) => {
    setIsSubmitting(true);
    const selectedDays = {
      monday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      tuesday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      wednesday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      thursday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      friday: `${values.monday_friday_from} - ${values.monday_friday_to}`,
      saturday: `${values.saturday_from} - ${values.saturday_to}`,
      sunday: `${values.sunday_from} - ${values.sunday_to}`,
    };

    const allDays = {
      monday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      tuesday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      wednesday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      thursday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      friday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      saturday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
      sunday: `${values.monday_sunday_from} - ${values.monday_sunday_to}`,
    };

    const operationHours = values.selectDays ? selectedDays : allDays;

    const payload = {
      user_id: loggedInUser?.uid,
      title: values.spaceName,
      about: values.spaceDescription,
      space_email: values.spaceEmailAddress,
      space_phone_number: values.spacePhoneNumber,
      location: values.spaceLocation,
      spaceThumbnail: values.spaceThumbnail,
      whatsapp_phone_number: values.spaceWhatsappPhoneNumber,
      website_url: values.spaceWebsite,
      facebook: values.facebook,
      twitter: values.twitter,
      instagram: values.instagram,
      linkedin: values.linkedin,
      space_amenity_obj: values.spaceAmenity,
      space_type_obj: values.spaceType,
      holiday: values.holidays,
      address: values.address,
      map: values.map,
      operationHours: operationHours,
    };
    try {
      if (isEditing && workspace?.workspace_id) {
        await updateWorkSpace(payload, workspace.workspace_id);
        setIsSubmitting(false);
        setOpenModal(true);
      } else {
        await createWorkSpace(payload);
        setIsSubmitting(false);
        setOpenModal(true);
      }
    } catch (error) {
      setIsSubmitting(false);
      toast.error("An error occured, please try again");
    }
  };

  const checkIfNameExists = (name: string) => {
    if (workspaces && workspaces.data) {
      const found = workspaces.data.some(
        (item) => item.title.trim().toLowerCase() === name.trim().toLowerCase()
      );
      if (found) {
        !isEditing && setNameError("Name already exists");
      } else {
        setNameError("");
      }
    } else return false;
  };

  const debounceSearch = useMemo(
    () =>
      debounce((value: string) => {
        checkIfNameExists(value);
      }, 1200),
    []
  );

  useEffect(() => {
    if (isEditing && workspace) {
      const amenities = workspace.service_amenities.map((amenity) => {
        return amenity.name;
      });
      const services = workspace?.services.map((service) => {
        return {
          space_type_name: service.name,
          price_per_month: service.monthly_price,
          price_per_week: service.weekly_price,
          price_per_day: service.daily_price,
          price_per_hour: service.hourly_price ? service.hourly_price : "",
          space_type_capacity: service.seat_capacity,
          space_type_total: service.total_space,
          space_type_available: service.available_space,
          id: service.id,
        };
      });
      const thumbnails: IFile[] = workspace.photos.map((url, index) => ({
        uid: `-${index}`,
        name: url?.split(",")[0],
        status: "done",
        url: url,
        thumbUrl: url,
      }));

      const [monday_from, monday_to] =
        workspace?.working_hours?.monday?.split(" - ");
      const [saturday_from, saturday_to] =
        workspace?.working_hours?.saturday?.split(" - ");
      const [sunday_from, sunday_to] =
        workspace?.working_hours?.sunday?.split(" - ");

      let operationHours = workspace?.selectDays
        ? {
            monday_friday_from: monday_from,
            monday_friday_to: monday_to,
            saturday_from: saturday_from,
            saturday_to: saturday_to,
            sunday_from: sunday_from,
            sunday_to: sunday_to,
          }
        : {
            monday_sunday_from: monday_from,
            monday_sunday_to: monday_to,
          };

      const data = {
        ...initialState,
        spaceName: workspace?.title,
        spaceDescription: workspace?.info,
        spaceEmailAddress: workspace?.space_contact?.space_email,
        spacePhoneNumber: workspace?.space_contact?.space_phone_number,
        spaceLocation: workspace?.location,
        fileList: thumbnails,
        spaceWhatsappPhoneNumber:
          workspace?.space_contact?.whatsapp_phone_number,
        spaceWebsite: workspace?.space_contact?.website_url,
        facebook: workspace?.space_contact?.social_links?.facebook,
        twitter: workspace?.space_contact?.social_links?.twitter,
        instagram: workspace?.space_contact?.social_links?.instagram,
        linkedin: workspace?.space_contact?.social_links?.linkedin,
        spaceAmenity: amenities,
        spaceType: services,
        holidays: workspace?.holiday,
        address: workspace?.address,
        map: workspace?.map,
        ...operationHours,
      };
      setInitialState(data);
    }
    // console.log(workspace, 'test')
  }, [isEditing, workspace]);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  return {
    initialState,
    activeTab,
    setActiveTab,
    openModal,
    handleFormSubmit,
    currentTab,
    isEditing,
    navigate,
    tabs,
    openSelectDays,
    setOpenSelectDays,
    isSubmitting,
    workspace,
    workspaceIsLoading,
    workspaceIsError,
    setOpenModal,
    loggedInUser,
    isLoggedIn,
    debounceSearch,
    checkIfNameExists,
    nameError,
  };
};

export default useHostForm;
