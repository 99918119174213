import React from "react";

const Menu: React.FC<{ type?: string; className?: string }> = ({ type = "#0047AB", className }) => {
   return (
      <svg className={className} width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <rect width='30' height='30' rx='7' fill='#DDEBFF' />
         <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.60156 9.00078C6.60156 8.68252 6.72799 8.3773 6.95303 8.15225C7.17808 7.92721 7.4833 7.80078 7.80156 7.80078H22.2016C22.5198 7.80078 22.825 7.92721 23.0501 8.15225C23.2751 8.3773 23.4016 8.68252 23.4016 9.00078C23.4016 9.31904 23.2751 9.62427 23.0501 9.84931C22.825 10.0744 22.5198 10.2008 22.2016 10.2008H7.80156C7.4833 10.2008 7.17808 10.0744 6.95303 9.84931C6.72799 9.62427 6.60156 9.31904 6.60156 9.00078ZM6.60156 15.0008C6.60156 14.6825 6.72799 14.3773 6.95303 14.1523C7.17808 13.9272 7.4833 13.8008 7.80156 13.8008H15.0016C15.3198 13.8008 15.625 13.9272 15.8501 14.1523C16.0751 14.3773 16.2016 14.6825 16.2016 15.0008C16.2016 15.319 16.0751 15.6243 15.8501 15.8493C15.625 16.0744 15.3198 16.2008 15.0016 16.2008H7.80156C7.4833 16.2008 7.17808 16.0744 6.95303 15.8493C6.72799 15.6243 6.60156 15.319 6.60156 15.0008ZM6.60156 21.0008C6.60156 20.6825 6.72799 20.3773 6.95303 20.1523C7.17808 19.9272 7.4833 19.8008 7.80156 19.8008H22.2016C22.5198 19.8008 22.825 19.9272 23.0501 20.1523C23.2751 20.3773 23.4016 20.6825 23.4016 21.0008C23.4016 21.319 23.2751 21.6243 23.0501 21.8493C22.825 22.0744 22.5198 22.2008 22.2016 22.2008H7.80156C7.4833 22.2008 7.17808 22.0744 6.95303 21.8493C6.72799 21.6243 6.60156 21.319 6.60156 21.0008Z'
            fill={type}
         />
      </svg>
   );
};

export default Menu;
