import React from "react";
import styles from "./index.module.scss";
import useWallet from "../../../pages/Host/Profile/components/Wallet/useWallet";
import { CogIcon } from "@/components/icons/cog-icon";
import { Switch } from "antd";
import AccountCard from "../account-card";
import { useModalAction } from "@/components/modal-view/context";
import { useMyWallet } from "@/data/wallet";

interface WithdrawalAccountsProps {
  openAddModal?: () => void;
}

export const WithdrawalAccounts = ({
  openAddModal,
}: WithdrawalAccountsProps) => {
  const { wallet } = useMyWallet();

  const { openModal } = useModalAction();

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div className={styles.content}>
          <h2 className={styles.title}>Withdrawal accounts</h2>
        </div>
        <button
          onClick={() => openModal("WITHDRAWAL_METHODS")}
          title="Manage withdrawal account"
          type="button"
        >
          <CogIcon className="w-6 h-6 text-primaryText" />
        </button>
      </div>

      {wallet?.withdrawal_banks?.map((account) => (
        <AccountCard
          account={account}
          title={"Bank Account"}
          preferred={account.preferred}
        />
      ))}

      <div>
        <div className="flex justify-between mb-3">
          <p className="text-lg">Automatic withdrawal</p>
          <Switch defaultChecked={false} disabled onChange={() => {}} />
        </div>
        <p className="text-xs text-primaryText">
          When on, funds would automatically be withdrawn into your preferred
          account.
        </p>
      </div>

      <div className="mt-3">
        <button
          type="button"
          onClick={openAddModal}
          className="text-lg text-primary font-medium"
        >
          Add withdrawal account
        </button>
      </div>
    </div>
  );
};
