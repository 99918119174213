import React, { ChangeEvent } from "react";
import arrowDown from "@/assets/svgs/arrow-down.svg";

interface Option {
  name: string;
  value: string;
  disabled?: boolean;
}

interface SelectInputProps {
  label: string;
  options: Option[];
  value: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  name?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  options,
  value,
  onChange,
  name,
}) => {
  return (
    <div className="page-input-field">
      <label>{label}</label>
      <div className="page-input-filed-input-wrapper">
        <select title={name} name={name} value={value} onChange={onChange}>
          <option value="">Select {label}</option>
          {options.map((item, index) => (
            <option disabled={item?.disabled} key={index} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
        <img src={arrowDown} alt="arrow down" className="hover clicker" />
      </div>
    </div>
  );
};

export default SelectInput;
