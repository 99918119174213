import React, { useState } from "react";
import styles from "./addYourWorkspace.module.scss";
import {
  community1,
  about_us,
  community2,
  location,
  offices,
} from "@/assets/img";
import arrowright from "@/assets/svgs/arrow-long-right.svg";
import users from "../../assets/svgs/users.svg";
import boostSales from "../../assets/svgs/boostSales.svg";
import moneyReceive from "../../assets/svgs/moneyReceive.svg";
import qualityData from "../../assets/svgs/qualityData.svg";
import { useNavigate } from "react-router-dom";
import Button from "@/components/c/Button";
import check from "../../assets/svgs/check.svg";
import { TeamsBooking } from "@/components/a/Modals";
import { TabTitle } from "@/utils/titleFunction";
import { Divider } from "antd";

const AddYourWorkspace = () => {
  // const [isOpen, setIsOpen] = useState<boolean>(false);

  // const handleGetAccess = () => {
  // 	setIsOpen(true)
  // }
  // const closeModal = () => {
  // 	setIsOpen(false);
  // };

  const stepsData = [
    {
      step: 1,
      detail: `Click on the ‘Add your Space’ button to get started`,
    },
    {
      step: 2,
      detail: `Supply all required information to complete registration for your coworking space`,
    },
    {
      step: 3,
      detail: `We will verify the information supplied and notify you when your request has been approved.`,
    },
  ];

  const whyUsData = [
    {
      img: boostSales,
      title: `Boost Sales`,
      text: ` Access to a ready and active audience, 
	  as well as our search optimization and strategic
	   marketing puts coworking spaces on our platform ahead
	    of their competitors.`,
    },
    {
      img: moneyReceive,
      title: `Easy Recieve and Monitor Payments`,
      text: ` Effectively track your customer data and 
	  their booking history for each of your workspace 
	  and amenities, accept payments and generate invoices
	   at the snap of a finger and enjoy effective relationships
	    with your customers totally hassle-free!.
	  `,
    },
    {
      img: qualityData,
      title: `Access Quality Data`,
      text: `Find out which of your space amenities generate the
	   most revenue and which generates the least revenue, 
	   access other quality data that helps you make better
	    informed decisions, improve your services and scale revenue.`,
    },
  ];

  const navigate = useNavigate();
  return (
    <div className={styles.page}>
      <div className={styles.banner}>
        <div className={styles.bannerContent}>
          <h1 className={styles.title}>
            Boost Sales For Your Coworking Space!
          </h1>
          <p className={styles.description}>
            Leverage our targeted and quality audience to increase visibility
            for your coworking space, rapidly boost sales and repeated
            patronage.
          </p>
          <Button
            onClick={() => {
              navigate("/add-workspace");
            }}
            type="filled"
            children="Get started"
          />
        </div>
      </div>

      <section className={styles.aboutInfo}>
        <div className={styles.aboutInfoContent}>
          <div className={styles.img1}>
            <img src={about_us} alt="aboutus" />
          </div>

          <div className={styles.aboutInfoText}>
            <p className={styles.subtitle}>CO-SPIRE FOR TEAMS</p>
            <h1 className={styles.title}>How it works</h1>
            <div className={styles.how}>
              {stepsData.map((item, i) => (
                <div className={styles.step}>
                  <p className={styles.number}>{item.step}</p>
                  <p className={styles.details}>{item.detail}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* why cospire */}
      <section className={styles.insights}>
        <p className={styles.title}>
          WHY COWORKING SPACES LIKE YOURS CHOOSE CO-SPIRE
        </p>
        <div className={styles.statsContainer}>
          {whyUsData.map((item, i) => (
            <div className={styles.statContainer}>
              <div className={styles.stats}>
                <img
                  src={item.img}
                  alt="user_group"
                  style={{ fill: "white" }}
                />
                <span className={styles.subtitle}>{item.title}</span>
                <p className={styles.description}>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <div style={{margin:'20px'}}></div>
      <section className={styles.insights}>
        <p className={styles.title}>Designed to accommodate your team</p>
        <div className={styles.statsContainer}>
          <div className={styles.statContainer}>
            <div className={styles.stats}>
              <img src={users} alt="user_group" />
              <span className={styles.subtitle}>Small Group</span>
              <p className={styles.description}>
                Team sizes typically less than or exactly 20 are well
                accommodated in our plans.
              </p>
            </div>
          </div>

          <div className={styles.statContainer}>
            <div className={styles.stats}>
              <img src={users} alt="user_group" />
              <span className={styles.subtitle}>Large Group</span>
              <p className={styles.description}>
                From team sizes of 20 and above, we have the doors flung open to
                our wide network of coworking spaces waiting for you and your
                team.
              </p>
            </div>
          </div>

          <div className={styles.statContainer}>
            <div className={styles.stats}>
              <img src={offices} alt="office" />
              <span className={styles.subtitle}>Corporate Organisation</span>
              <p className={styles.description}>
                How quickly will you like to expand your business or
                organization's reach? With CO-SPIRE Book for Teams, you can
                spread as wide and as far as you want to.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className={styles.bookForTeams}  >

				<div className={styles.top} >
					<p className={styles.subtitle}>Pricing</p>
					<h1 className={styles.title}>Flexible plans to suit you</h1>
				</div>

				<div className={styles.body} >

					{
						pricingPackages.map((pricingPackage) => {

							if (pricingPackage.name === "Business Plan") return (
								<div className={`${styles.businessCard} `}>
									{pricingPackage.name === "Business Plan" && <div className={styles.businessTop} > Most popular plan</div>}
									<div className={`${styles.card} ${styles.cardTopBorder}`}  >
										<div className={styles.cardTop} >
											<h1 className={styles.price} >${pricingPackage.price}/year</h1>
											<p className={styles.plan} >{pricingPackage.name}</p>
											<p className={styles.billing} >{pricingPackage.billed}</p>
										</div>
										<ul className={styles.properties} >
											{
												pricingPackage.properties?.map((property) => (
													<div className={styles.property} >
														<img className={styles.icon} src={check} alt="" />
														<li  >{property}</li>
													</div>

												))
											}
										</ul>
										<div className={styles.btnContainer}>
											<Button type="transparent" onClick={handleGetAccess} className={styles.button} children="Get Access" />
										</div>
									</div>
								</div>
							)


							return (
								<div>
									<div className={`${styles.card} ${styles.rounded}`} >
										<div className={styles.cardTop} >
											<h1 className={styles.price} >${pricingPackage.price}{pricingPackage.name == "Free" ? "" : "/ltd"} </h1>
											<p className={styles.plan} >{pricingPackage.name}</p>
											<p className={styles.billing} >{pricingPackage.billed}</p>
										</div>
										<ul className={styles.properties} >
											{
												pricingPackage.properties?.map((property) => (
													<div className={styles.property} >
														<img className={styles.icon} src={check} alt="" />
														<li  >{property}</li>
													</div>

												))
											}
										</ul>
										<div className={styles.btnContainer}>
											<Button onClick={handleGetAccess} type="transparent" className={styles.button} children="Get Access" />
										</div>
									</div>
								</div>

							)

						})}
				</div>


				{
					isOpen && (<TeamsBooking isOpen={isOpen} closeModal={closeModal} />)
				}


			</section> */}

      <section className={styles.contactSales}>
        <div className={styles.contact}>
          <h1>NEED TO TALK TO SOMEONE?</h1>
          <p>
            A dedicated professional awaits at the other end to kick start a
            conversation with you anytime and any day. Just tell us a few things
            about your company, and we’ll reach out within 24 business hours.
          </p>
          <Button type="transparent" children="Contact Sale" />
        </div>
      </section>
    </div>
  );
};

export default AddYourWorkspace;

const pricingPackages = [
  {
    name: "Free",
    price: 0,
    billed: "Auto Renew",
    properties: [
      "A super simple icon list element.",
      "It has several list items you can easily edit.",
      "Delete rows you don't need.",
      "Duplicate existing rows to add more.",
      "Change the icons per row.",
    ],
  },
  {
    name: "Business Plan",
    price: 99,
    billed: "Billed Annually",
    properties: [
      "A super simple icon list element.",
      "It has several list items you can easily edit.",
      "Delete rows you don't need.",
      "Duplicate existing rows to add more.",
      "Change the icons per row.",
    ],
  },
  {
    name: "Enterprise Plan",
    price: 129,
    billed: "Billed Once",
    properties: [
      "A super simple icon list element.",
      "It has several list items you can easily edit.",
      "Delete rows you don't need.",
      "Duplicate existing rows to add more.",
      "Change the icons per row.",
    ],
  },
];
