import { useQuery } from "react-query";
import {
  TransactionPaginator,
  TransactionQueryOptions,
} from "@/types/transaction";
import { API_ENDPOINTS } from "./client/api-endpoints";
import client from "./client";
import { mapPaginatorData } from "@/utils/data-mappers";

export const useTransactionsQuery = (
  params: Partial<TransactionQueryOptions>,
  options = {}
) => {
  const { data, error, isLoading } = useQuery<TransactionPaginator, Error>(
    [API_ENDPOINTS.TRANSACTIONS, params],
    ({ queryKey, pageParam }) =>
      client.transactions.paginated(Object.assign({}, queryKey[1], pageParam)),
    {
      keepPreviousData: true,
      ...options,
    }
  );

  return {
    transactions: data?.data ?? [],
    paginatorInfo: mapPaginatorData(data),
    error,
    loading: isLoading,
  };
};
