import * as React from "react";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { WorkspaceCard, WorkspacePoint } from "./workspaceCard";
import { Services } from "@/interfaces/apiResponse";
import { minimumPrice } from "@/utils/helpers";

// base function for creating DOM div node
function createOverlayElement() {
  const el = document.createElement("div");
  el.style.position = "absolute";
  el.style.display = "inline-block";
  el.style.width = "9999px";
  return el;
}

// Our OverlayComponent will recieve map, postion and children props - position is coords, map is google.map object and children is a component that will be render in overlay
export type Props = {
  map: google.maps.Map | null;
  position: { lat: number; lng: number };
  children?: React.ReactNode;
};

const OverlayContainer = (props: Props) => {
  const overlay = React.useRef<google.maps.OverlayView | null>(null);
  const el = React.useRef<Element | null>(null);

  // modified OverlayView from google.maps [https://developers.google.com/maps/documentation/javascript/reference/3.44/overlay-view?hl=en]
  class OverlayView extends window.google.maps.OverlayView {
    position: google.maps.LatLng | null = null;
    content: any = null;

    constructor(props: any) {
      super();
      props.position && (this.position = props.position);
      props.content && (this.content = props.content);
    }

    onAdd = () => {
      if (this.content) this.getPanes()?.floatPane.appendChild(this.content);
    };

    onRemove = () => {
      if (this.content?.parentElement) {
        this.content.parentElement.removeChild(this.content);
      }
    };

    draw = () => {
      if (this.position) {
        const divPosition: any = this.getProjection().fromLatLngToDivPixel(
          this.position
        );
        this.content.style.left = divPosition.x + "px";
        this.content.style.top = divPosition.y + "px";
      }
    };
  }

  React.useEffect(() => {
    return () => {
      if (overlay.current) overlay.current.setMap(null);
    };
  }, []);

  if (props.map) {
    el.current = el.current || createOverlayElement();

    overlay.current =
      overlay.current ||
      new OverlayView({
        position: new google.maps.LatLng(
          props.position.lat,
          props.position.lng
        ),
        content: el.current,
      });
    overlay.current.setMap(props.map);
    return ReactDOM.createPortal(props.children, el.current);
  }
  return null;
};

type MapPointProps = {
  id: number;
  name: string;
  imgs: any;
  amount: string;
  rating: number;
  position: any;
};

const MapPoint = (props: MapPointProps) => {
  const [opened, setIsOpened] = useState<boolean>(false);
  const handleOnOpen = () => setIsOpened(true);
  const handleOnClose = () => setIsOpened(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Hook for handle outside click
  useEffect(() => {
    function handleClickOutside(this: Document, event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpened(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <div ref={containerRef}>
      {opened ? (
        <WorkspaceCard
          id={props.id}
          handleClose={handleOnClose}
          name={props.name}
          imgs={props.imgs}
          amount={props.amount}
          rating={props.rating}
        />
      ) : (
        <WorkspacePoint onClick={handleOnOpen} name={props.name} />
      )}
    </div>
  );
};

// Our component will receive center coords and zoom size in props
type MapProps = {
  workspace: any;
  center: google.maps.LatLngLiteral;
  zoom: number;
};

function Map({ workspace, center, zoom }: MapProps) {
  const ref = useRef(null);
  const [map, setMap] = useState<any>(null);

  useEffect(() => {
    // we need to save google-map object for adding markers and routes in future
    if (ref.current) {
      // here will connect map frame to div element in DOM by using ref hook
      let createdMap = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        disableDefaultUI: true,
        clickableIcons: false,
      });
      setMap(createdMap);
    }
  }, [center, zoom]);

  // const getLowestPrice = (services: Services[]) => {

  //   const hourlyPrices = services.map((service) => service.hourly_price || Number.MAX_SAFE_INTEGER).filter(Boolean);
  //   const dailyPrices = services.map((service) => service.daily_price).filter(Boolean);
  //   const weeklyPrices = services.map((service) => service.weekly_price).filter(Boolean);
  //   const monthlyPrices = services.map((service) => service.monthly_price).filter(Boolean);

  //   const lowestHourlyPrice = hourlyPrices.length > 0 ? Math.min(...hourlyPrices) : null;
  //   const lowestDailyPrice = dailyPrices.length > 0 ? Math.min(...dailyPrices) : null;
  //   const lowestWeeklyPrice = weeklyPrices.length > 0 ? Math.min(...weeklyPrices) : null;
  //   const lowestMonthlyPrice = monthlyPrices.length > 0 ? Math.min(...monthlyPrices) : null;

  //   // Determine the lowest price and its corresponding time unit
  //   let lowestPrice = null;
  //   let timeUnit = '';

  //   if (lowestHourlyPrice !== null && (!lowestPrice || lowestHourlyPrice < lowestPrice)) {
  //     lowestPrice = lowestHourlyPrice;
  //     timeUnit = 'hour';
  //   }
  //   if (lowestDailyPrice !== null && (!lowestPrice || lowestDailyPrice < lowestPrice)) {
  //     lowestPrice = lowestDailyPrice;
  //     timeUnit = 'day';
  //   }
  //   if (lowestWeeklyPrice !== null && (!lowestPrice || lowestWeeklyPrice < lowestPrice)) {
  //     lowestPrice = lowestWeeklyPrice;
  //     timeUnit = 'week';
  //   }
  //   if (lowestMonthlyPrice !== null && (!lowestPrice || lowestMonthlyPrice < lowestPrice)) {
  //     lowestPrice = lowestMonthlyPrice;
  //     timeUnit = 'month';
  //   }

  //   // Return the lowest price and its time unit
  //   if (lowestPrice !== null) {
  //     return `${lowestPrice}/${timeUnit}`;
  //   } else {
  //     return 'No price available';
  //   }
  // };

  // function arrayMin(array: number[]) {
  //   return Math.min(...array);
  // }

  function calculateAverage(numbers: number[]) {
    if (numbers.length === 0) {
      return 0;
    }

    const sum = numbers.reduce((total, num) => total + num, 0);
    const average = Math.floor(sum / numbers.length);

    return average;
  }

  // map will be connect to this div block
  console.log(workspace, "ghvfdfjgdgfdfgdjfdgf");

  return (
    <div ref={ref} id="map" style={{ height: "100%" }}>
      {workspace?.length > 0 &&
        workspace?.map((space: any, index: number) => (
          <OverlayContainer
            map={map}
            position={{
              lat: Number(space?.map?.lat || 6.465422),
              lng: Number(space?.map?.lng || 3.406448),
            }}
            key={index}
          >
            <MapPoint
              id={space.workspace_id}
              name={space.title}
              imgs={space.photos}
              amount={minimumPrice(space.pricing)}
              rating={
                0
                //   calculateAverage(
                //   space?.verified_reviews?.map((item: any) => {
                //     return item.review_rating;
                //   })
                // )
              }
              position={{
                lat: Number(space?.map?.lat || 6.465422),
                lng: Number(space?.map?.lng || 3.406448),
              }}
            />
          </OverlayContainer>
        ))}
    </div>
  );
}

export default Map;
