import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { image, success } from "@/assets/img";
import securitySafe from "@/assets/svgs/security-safe.svg";
import starIcon from "@/assets/svgs/starIcon.svg";
import chevrondown from "@/assets/svgs/chevron-down.svg";
import blue_guard from "@/assets/svgs/blue_guard.svg";
import { TabTitle } from "@/utils/titleFunction";
import Dropdown from "@/assets/svgs/chevron-down";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Spin } from "antd";
import { CustomInputField } from "@/components/customInput";
import PhoneInput from "@/components/phoneNumberInput/CustomPhoneNoInput";
import { Checkbox } from "antd";
import { IBookSpaceForm } from "@/interfaces/forms";
import { Form, FormikProps } from "formik";
import { handleError } from "@/services/networkRequests/error";
import { reset } from "@/redux/rootReducer";
import { BOOK_SCHEMA } from "@/validationSchemas/schema";
import CustomSelect from "@/components/customSelect";
import CustomTextArea from "@/components/customTextArea";
import Splider from "@/components/splide/splide";
import CustomIcons from "@/components/icons";

import { calculatePrice, newCalculatePrice } from "@/utils/helpers";
import CustomDatePicker from "@/components/customDatePicker";
import TimePickerComponent from "@/components/customTimePicker";
import dayjs, { Dayjs } from "dayjs";
import PayButton from "@/components/a/Modals/GetQuote/PayButton";
import { CustomButton } from "@/components/customButton";
import { HOME_URL, WORKSPACES } from "@/routes/routes";
import SuccessModal from "@/components/a/Modals/Success";
import CustomerDatePicker from "@/components/confirmDatePicker/customerDatePicker";
import { DateObject } from "react-multi-date-picker";
import tick_circle from "@/assets/svgs/success-verified.svg";
import loading_gif from "@/assets/svgs/spinner.gif";
import error_vector from "@/assets/svgs/errorVector.svg";
import { RentersRegistration } from "@/components/auth/renters-registration";
import { ChevronDownIcon } from "@/components/icons/chevron-down";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { cn } from "@/lib/utils";
import { useActivitiesQuery } from "@/data/activities";
import { useCreateBookingMutation } from "@/data/booking";
import { CreateBookingPayload } from "@/types/booking";
import { toast } from "react-toastify";
import { Workspace } from "@/types/workspace";
import { useMe } from "@/data/user";

interface FormValues {
  order_for: boolean;
  renter?: {
    first_name: string;
    last_name: string;
    email: string;
  };
  activity: string;
  project_name: string;
  company_name?: string;
  phone_number: string;
  description: string;
  num_of_people: number;
  recurring?: boolean;
  start_date: Date;
  end_date: Date;
  dates: DateObject[];
  start_time: string;
  end_time: string;
}

const validationSchema = Yup.object().shape({
  order_for: Yup.boolean().default(false),
  renter: Yup.object().when("order_for", {
    is: true,
    then: Yup.object({
      first_name: Yup.string()
        .min(2, "Name must be minimum 2")
        .max(100, "Name must not be more than 100 characters")
        .required("Name is required"),
      last_name: Yup.string()
        .min(2, "Name must be minimum 2")
        .max(100, "Name must not be more than 100 characters")
        .required("Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
    }),
  }),
  activity: Yup.string()
    .min(2, "Field must be minimum 2")
    .max(100, "Field must not be more than 100 characters")
    .required(),
  project_name: Yup.string()
    .min(2, "Field must be minimum 2")
    .max(100, "Field must not be more than 100 characters")
    .required(),
  company_name: Yup.string(),
  phone_number: Yup.string()
    .min(2, "Field must be minimum 2")
    .required("Field is required"),
  description: Yup.string()
    .max(100, "Field must not be more than 100 characters")
    .optional(),
  num_of_people: Yup.number().required("Number of people is required"),
  recurring: Yup.boolean().optional().default(false),
  start_date: Yup.date().required(),
  end_date: Yup.date().required(),
  dates: Yup.array().of(Yup.date()).default([]),
  start_time: Yup.string().min(2, "Field must be minimum 2").required(),
  end_time: Yup.string()
    .max(100, "Field must not be more than 100 characters")
    .required(),
});

const { RangePicker } = DatePicker;

const dayMap: { [x: number]: string } = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};

function getDayStartAndCloseTime(
  day: number,
  timing: Workspace["working_hours"]
) {
  const dayMap: { [x: number]: string } = {
    0: "sunday",
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
  };

  const openTime = timing[dayMap[day]].open_time;
  const closeTime = timing[dayMap[day]].close_time;

  return {
    open_time: openTime,
    close_time: closeTime,
  };
}

function getCloseHoursArray(day: number, timing: Workspace["working_hours"]) {
  const openTime = timing[dayMap[day]].open_time;
  const closeTime = timing[dayMap[day]].close_time;

  if (openTime === "closed" && closeTime === "closed") {
    return Array.from({ length: 24 }, (_, i) => i + 1);
  }
  return Array.from({ length: 24 }, (_, i) => i + 1).filter(
    (num) => num < Number(openTime.split(":")[0]) || num > 7
  );
}

function getDurationInHours(
  dates: DateObject[],
  startTime: string,
  endTime: string
): number {
  if (dates.length > 1) {
    // Ensure dates are sorted in ascending order
    dates.sort((a, b) => a.toDate().getTime() - b.toDate().getTime());

    // Get the first and last dates
    const startDate = dates[0];
    const endDate = dates[dates.length - 1];

    // Helper function to convert a date and time string to milliseconds
    const toMilliseconds = (date: Date, time: string): number => {
      const [hours, minutes] = time.split(":").map(Number);
      date.setHours(hours, minutes, 0, 0);
      return date.getTime();
    };

    // Convert start and end times to milliseconds
    const startMs = toMilliseconds(new Date(startDate.toDate()), startTime);
    let endMs = toMilliseconds(new Date(endDate.toDate()), endTime);

    // Handle overlap if end time is earlier than start time
    if (endMs < startMs) {
      endMs += 24 * 60 * 60 * 1000; // Add one day in milliseconds
    }

    // Calculate duration in milliseconds and convert to hours
    const durationMs = endMs - startMs;
    return durationMs / (1000 * 60 * 60);
  }
  return 0;
}

function pickDates(dates: DateObject[]) {
  if (dates.length > 1) {
    dates.sort((a, b) => a.toDate().getTime() - b.toDate().getTime());

    // Get the first and last dates
    const startDate = dates[0];
    const endDate = dates[dates.length - 1];

    return {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    };
  }
  return {
    startDate: dates.length > 1 ? dates[0].toDate() : new Date(),
    endDate: dates.length > 1 ? dates[0].toDate() : new Date(),
  };
}

export function BookingForm({ workspace }: { workspace: Workspace }) {
  const { state } = useLocation();
  const initialValues: FormValues = {
    order_for: false,
    activity: "",
    project_name: "",
    company_name: "",
    phone_number: "",
    description: "",
    num_of_people: state?.numberOfPeople ?? 1,
    start_date: state?.dated.length ? state.dated[0] : new Date(),
    end_date: state?.dated.length ? state.dated[1] : new Date(),
    dates: state?.dates ?? [],
    start_time:
      state?.startTime ??
      getDayStartAndCloseTime(new Date().getDay(), workspace?.working_hours!)
        .open_time!,
    end_time:
      state?.endTime ??
      getDayStartAndCloseTime(new Date().getDay(), workspace?.working_hours!)
        .close_time!,
  };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setTimeout(() => {
      handleBooking(values);
      setSubmitting(false);
    }, 500);
  };

  const { isLoggedIn, me: user } = useMe();

  const navigate = useNavigate();
  const { id } = useParams();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(true);
  const [progress, setProgress] = useState(0);

  const toggleEventDetails = () => setIsEventDetailsOpen((prev) => !prev);

  const modalContent = [
    {
      heading: "Hold On",
      description: "While we redirect you to the payment gateway",
      icon: loading_gif,
    },
    {
      heading: "Thank you! Your payment was successful",
      description:
        "Your reservation has been sent to the host, details of your booking would be communicated via email.",
      icon: tick_circle,
      button: (
        <CustomButton
          children={"Explore more Spaces"}
          className={styles.btn}
          onClick={() => {
            setIsSuccessModalOpen(false);
            navigate(WORKSPACES);
          }}
        />
      ),
    },
    {
      heading: "Oops!",
      description: "Something went wrong. Please try again",
      icon: error_vector,
      button: (
        <CustomButton
          children={"Try Again"}
          className={styles.btn}
          onClick={() => {
            setIsSuccessModalOpen(false);
          }}
        />
      ),
    },
  ];

  const { mutateAsync: book, isLoading } = useCreateBookingMutation();

  const handleBooking = async (values: FormValues) => {
    console.log("values", values);

    const startDate = values.recurring
      ? dayjs(pickDates(values.dates).startDate)
      : dayjs(values.start_date);
    const endDate = values.recurring
      ? dayjs(pickDates(values.dates).endDate)
      : dayjs(values.end_date);

    const startTime = dayjs(values.start_time, "HH:mm");
    const endTime = dayjs(values.end_time, "HH:mm");

    let newStartDate = startDate
      .add(startTime.hour(), "hour")
      .add(startTime.minute(), "minute");

    let newEndDate = endDate
      .add(endTime.hour(), "hour")
      .add(endTime.minute(), "minute");

    const price = newCalculatePrice(
      pricing,
      values.recurring ?? false,
      values.dates.map((date) => date?.toDate().toISOString()),
      getDurationInHours(values.dates, values.start_time, values.end_time),
      newStartDate?.toISOString(),
      newEndDate?.toISOString()
    );
    console.log(price);
    const amount = price[0] || 1000;

    const payload: CreateBookingPayload = {
      workspace_id: id!,
      activity: values.activity,
      type: "instant",
      company_name: values.company_name || "",
      dates: [],
      duration: 0,
      full_name:
        isLoggedIn && values.order_for
          ? values.renter?.first_name + " " + values.renter?.last_name
          : user?.displayName || "",
      phone_number: isLoggedIn && values.order_for ? values.phone_number : "",
      booking_type: "hourly",
      description: values?.description || "",
      start_date: startDate?.toISOString(),
      end_date: endDate?.toISOString(),
      start_time: values.start_time,
      end_time: values.end_time,
      num_of_people: values.num_of_people,
      order_for: values.order_for,
      project_name: values.project_name,
      recurring: values.recurring || false,
      amount: amount,
    };

    setIsSuccessModalOpen(true);

    book(payload, {
      onSuccess: ({ data }) => {
        setProgress(1);
        toast.success("Booking submitted successfully!");
        window.location.href = data.payment_link;
      },
      onError: (error) => {
        setProgress(2);
        handleError(error);
      },
      onSettled(data, error, variables, context) {
        console.log({ data, error, variables, context });
        setIsSuccessModalOpen(true);
      },
    });

    // const workspace_id = id;
    // const user_id = loggedInUser?.uid;
    // const registerUserId = user?.uid;
    // const fullName =
    //   loggedInUser && !isOrderFor
    //     ? loggedInUser.displayName
    //     : `${values.renter?.first_name} ${values.renter?.last_name}`;
    // const callbackUrl = `${
    //   window.location.hostname.includes("localhost")
    //     ? "http://localhost:3000"
    //     : (process.env.REACT_APP_URL as string)
    // }`;
    // const startTime = dayjs(values.start_time, "HH:mm");
    // const endTime = dayjs(values.end_time, "HH:mm");
    // const durationInHours = endTime && endTime.diff(startTime, "hour");
    // const formattedStartTime = startTime.format("HH:mm:ss");
    // const startDate = dayjs(
    //   `${values.start_date.toISOString().split("T")[0]}T${formattedStartTime}`
    // ).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    // const formattedEndTime = endTime.format("HH:mm:ss");
    // const endDate = dayjs(
    //   `${values.end_date.toISOString().split("T")[0]}T${formattedEndTime}`
    // ).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    // const payload = {
    //   time_slot: values.dates,
    //   start_date: startDate,
    //   end_date: endDate,
    //   booking_duration: durationInHours,
    //   message: values.description,
    //   company_info: values.company_name ?? "",
    //   booking_price: amount,
    //   fullname: fullName,
    //   seats: values.num_of_people,
    //   status: "pending check",
    //   // service_type_id: services?.id,
    //   // service_type: services.service_type,
    //   service_type_id: "id",
    //   service_type: "service_type",
    //   booking_type: "online",
    //   activity: values.activity,
    //   phone_number: values.phone_number,
    // };
    // const metadata = {
    //   booking_id: "booking_id",
    //   user_id: user_id,
    //   amount: amount,
    //   type: "booking",
    //   workspace_id: workspace_id,
    //   booking_details: payload,
    // };
    // if (user_id && workspace_id) {
    //   try {
    //     // console.log("payload", payload);
    //     setIsSuccessModalOpen(true);
    //     await createTransaction(
    //       { amount: amount, callback_url: callbackUrl, metadata: metadata },
    //       workspace_id,
    //       user_id
    //     );
    //     reset();
    //     toast.success("Booking submitted successfully!");
    //     setProgress(1);
    //   } catch (error) {
    //     // console.log("res ", createTransaction({amount: amount, callback_url: callbackUrl, metadata: metadata}, workspace_id, user_id));
    //     toast.error("An error occurred, please try again");
    //     setProgress(2);
    //   }
    // } else if (registerUserId && workspace_id) {
    //   try {
    //     // console.log("payload", payload);
    //     setIsSuccessModalOpen(true);
    //     await createTransaction(
    //       { amount: amount, callback_url: callbackUrl, metadata: metadata },
    //       workspace_id,
    //       registerUserId
    //     );
    //     reset();
    //     toast.success("Booking submitted successfully!");
    //     setProgress(1);
    //   } catch (error) {
    //     toast.error("An error occurred, please try again");
    //     setProgress(2);
    //   }
    // } else {
    //   toast.error(
    //     "User ID or Workspace ID is missing. Please log in or provide a workspace ID."
    //   );
    // }
  };

  // const services = workspace?.services[0];
  const pricing = {
    hourly_price: workspace?.pricing?.hourly || 0,
    daily_price: workspace?.pricing?.daily || 0,
    weekly_price: workspace?.pricing?.weekly || 0,
    monthly_price: workspace?.pricing?.monthly || 0,
  };

  const { activities } = useActivitiesQuery({});

  const [isActivitiesOpen, setIsActivitiesOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");

  const openModal = (type: string) => {
    setIsActivitiesOpen(true);
    setModalType(type);
  };

  return (
    <>
      {isSuccessModalOpen && (
        <SuccessModal
          openModal={isSuccessModalOpen}
          closeModal={() => setIsSuccessModalOpen(false)}
          setOpenModal={setIsSuccessModalOpen}
          heading={modalContent[progress].heading}
          icon={modalContent[progress].icon}
          description={modalContent[progress].description}
          button={progress && modalContent[progress].button}
        />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, handleSubmit, touched, ...formik }) => {
          const { values } = formik;

          console.log({ values });

          const startDate = values.recurring
            ? dayjs(pickDates(values.dates).startDate)
            : dayjs(formik.values.start_date);
          const endDate = values.recurring
            ? dayjs(pickDates(values.dates).endDate)
            : dayjs(formik.values.end_date);

          const startTime = dayjs(values.start_time, "HH:mm");
          const endTime = dayjs(values.end_time, "HH:mm");

          let newStartDate = startDate
            .add(startTime.hour(), "hour")
            .add(startTime.minute(), "minute");

          let newEndDate = endDate
            .add(endTime.hour(), "hour")
            .add(endTime.minute(), "minute");

          const duration = dayjs(endDate).diff(dayjs(startDate), "hour");

          const isRecurring = formik.values.recurring ?? false;

          console.log({
            duration,
            pricing,
            startDate: newStartDate?.toISOString(),
            endDate: newEndDate?.toISOString(),
          });

          const price = newCalculatePrice(
            pricing,
            isRecurring,
            values.dates.map((date) => date?.toDate().toISOString()),
            getDurationInHours(
              values.dates,
              values.start_time,
              values.end_time
            ),
            newStartDate?.toISOString(),
            newEndDate?.toISOString()
          );
          console.log(price);
          const amount = price[0] || 1000;
          const priceForDuration = price[1];
          const processingFee = price[1] * 0.1;

          console.log({ errors });

          const getSelectedPrice = () => {
            switch (price[2]) {
              case "hr":
                return pricing?.hourly_price;
              case "day":
                return pricing?.daily_price;
              case "week":
                return pricing?.weekly_price;
              case "month":
                return pricing?.monthly_price;
              default:
                return "Invalid price type";
            }
          };
          return (
            <Form onSubmit={handleSubmit} className={styles.page}>
              <div className={styles.form}>
                {isLoggedIn && (
                  <Checkbox
                    name="order_for"
                    onChange={(e) => {
                      formik.setFieldValue("order_for", e.target.checked);
                    }}
                    checked={formik.values.order_for}
                  >
                    <p>Book for someone else</p>
                  </Checkbox>
                )}
                {!isLoggedIn ? (
                  <RentersRegistration />
                ) : (
                  <>
                    {formik.values.order_for && (
                      <div className={styles.renters}>
                        <div className={styles.headerRow}>
                          <h2>Renter’s Details </h2>
                          <ChevronDownIcon className="text-primaryText cursor-pointer" />
                        </div>
                        <>
                          <div className={styles.inputContainer}>
                            <div className={styles.formRow}>
                              <CustomInputField
                                type={"text"}
                                label={"FirstName"}
                                placeholder="First name"
                                name={"renter.first_name"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // error={
                                //   touched?.renter.first_name &&
                                //   errors.renter.first_name
                                // }
                              />
                              <CustomInputField
                                type={"text"}
                                label={"LastName"}
                                placeholder="Last name"
                                name={"renter.last_name"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // error={
                                //   touched.renter.last_name &&
                                //   errors.renter.last_name
                                // }
                              />
                            </div>
                            <div className={styles.formRow}>
                              <CustomInputField
                                type={"email"}
                                label={"Email Address"}
                                placeholder="Enter your Email Address"
                                name={"renter.email"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // error={
                                //   touched.renter.email && errors.renter.email
                                // }
                              />
                            </div>
                          </div>
                        </>
                      </div>
                    )}
                  </>
                )}

                <div
                  className={cn(
                    styles.event,
                    isLoggedIn ? styles.eventLoggedIn : ""
                  )}
                >
                  <div className={styles.headerRow}>
                    <h2>Event Details </h2>
                    <ChevronDownIcon
                      onClick={toggleEventDetails}
                      className="text-primaryText cursor-pointer"
                    />
                  </div>
                  {isEventDetailsOpen && (
                    <>
                      <div className={styles.inputContainer}>
                        <div className={styles.formRow}>
                          <CustomSelect
                            options={activities.map((activity) => ({
                              label: activity.name,
                              value: activity.id,
                            }))}
                            label={"Activity (Be as specific as possible)"}
                            placeHolder="Select an Activity"
                            name={"activity"}
                            disabled={!isLoggedIn}
                            value={formik.values.activity}
                            onChange={(value) =>
                              formik.setFieldValue("activity", value)
                            }
                            onBlur={() =>
                              formik.setFieldTouched("activity", true)
                            }
                            error={touched.activity && errors.activity}
                          />
                          <CustomInputField
                            type={"text"}
                            label={"Name of Project"}
                            disabled={!isLoggedIn}
                            placeholder="Elsa’s baby shower"
                            name={"project_name"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={touched.project_name && errors.project_name}
                          />
                        </div>
                        <div className={styles.formRow}>
                          <CustomInputField
                            type={"text"}
                            label={"Company Name (Optional)"}
                            disabled={!isLoggedIn}
                            placeholder="Company"
                            name={"company_name"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={touched.company_name && errors.company_name}
                          />
                          <PhoneInput
                            label="Phone Number"
                            placeholder="Enter Phone Number"
                            disabled={!isLoggedIn}
                            value={formik.values.phone_number}
                            onChange={(value: string) =>
                              formik.setFieldValue("phone_number", value)
                            }
                            onBlur={() =>
                              formik.setFieldTouched("phone_number")
                            }
                            error={touched.phone_number && errors.phone_number}
                          />
                        </div>
                      </div>
                      <div className={styles.inputContainer}>
                        <div className={styles.textArea}>
                          <CustomTextArea
                            label={"About your Project"}
                            disabled={!isLoggedIn}
                            placeholder="Tell us about what you are planning ..."
                            name={"description"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      {Object.keys(errors).length > 0 && (
                        <div>
                          <h3>Invalid Fields:</h3>
                          <ul>
                            {Object.keys(errors).map((fieldName) => (
                              <li key={fieldName}>
                                {fieldName}: {[fieldName]}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={styles.summaryCard}>
                <div className={styles.shadowedCard}>
                  <div className={styles.spaceDetails}>
                    <div className={styles.BookingMainDetails}>
                      <Splider
                        thumbnails={workspace?.photos.map((photo) => photo)}
                        space={{ title: "slides" }}
                        className={styles.spliderContainer}
                      />
                      <div className={styles.spaceInfo}>
                        <div className={styles.spaceInfoSub}>
                          <div>
                            <h2 className={styles.spaceName}>
                              {workspace?.title}
                            </h2>
                            <span className={styles.rating}>
                              <div>
                                <img src={starIcon} alt="..." /> <p>5.0</p>
                              </div>
                            </span>
                            {workspace?.verified_space && (
                              <span className={styles.verified}>
                                <img src={securitySafe} alt="" />
                                <p>Verified Spaces</p>
                              </span>
                            )}
                            <div className={styles.verified}>
                              <div>
                                <p>200 Verified Reviews</p>
                              </div>
                            </div>
                          </div>
                          <p style={{ color: "blue" }}>
                            ₦{getSelectedPrice() || " Computing"}/{price[2]}
                          </p>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className={styles.styledForm}>
                      <CustomInputField
                        label="How many people?"
                        placeholder="1"
                        name="num_of_people"
                        value={formik.values.num_of_people}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={touched.num_of_people && errors.num_of_people}
                        type="number"
                      />
                      {workspace?.pricing?.hourly ? (
                        <div>
                          <Checkbox
                            name="recurring"
                            checked={formik.values.recurring}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "recurring",
                                e.target.checked
                              );
                            }}
                          >
                            Book a recurring booking
                          </Checkbox>
                        </div>
                      ) : null}
                      <div className={styles.formRowDate}>
                        <label>Date and Time</label>
                        {!formik.values.recurring ? (
                          <RangePicker
                            disabledDate={() => {
                              return (
                                workspace?.working_hours[
                                  dayMap[new Date().getDay()]
                                ].open_time === "closed"
                              );
                            }}
                            format={"YYYY/MM/DD"}
                            suffixIcon={null}
                            className={styles.paymentBtn}
                            name="date"
                            onChange={(value) => {
                              formik.setFieldValue("start_date", value?.[0]);
                              formik.setFieldValue("end_date", value?.[1]);
                            }}
                            value={[
                              dayjs(formik.values.start_date),
                              dayjs(formik.values.end_date),
                            ]}
                          />
                        ) : (
                          <CustomerDatePicker
                            name="dates"
                            value={formik.values.dates}
                            onChange={(
                              dates: DateObject | DateObject[] | null
                            ) => {
                              console.log("############", dates);

                              formik.setFieldValue("dates", dates);
                            }}
                            className={styles.paymentBtn}
                          />
                        )}
                      </div>
                      <div className={styles.formRow}>
                        <TimePickerComponent
                          placeholder="10:00"
                          selectedTime={dayjs(
                            formik.values.start_time,
                            "HH:mm"
                          )}
                          onTimeChange={(instance) => {
                            const formattedTime =
                              instance && instance.format("HH:mm");
                            formik.setFieldValue("start_time", formattedTime);
                          }}
                          name="startTime"
                          className={styles.paymentBtn}
                          disableHours={getCloseHoursArray(
                            new Date().getDay(),
                            workspace?.working_hours!
                          )}
                        />
                        <TimePickerComponent
                          placeholder="16:00"
                          // defaultOpenValue={}
                          selectedTime={dayjs(formik.values.end_time, "HH:mm")}
                          onTimeChange={(instance) => {
                            const formattedTime =
                              instance && instance.format("HH:mm");
                            formik.setFieldValue("end_time", formattedTime);
                          }}
                          name="endTime"
                          className={styles.paymentBtn}
                          disableHours={getCloseHoursArray(
                            new Date().getDay(),
                            workspace?.working_hours!
                          )}
                        />
                      </div>
                    </div>
                    <div className={styles.price_section}>
                      <div className={styles.section_header}>
                        <div className={styles.price_title}>Price</div>
                      </div>
                      {/* <div className={styles.card}>
                    <div className={styles.card_content}>
                      <div className={styles.card_item}>
                        <div className={styles.item_label}>
                          Space Add-ons
                        </div>
                        <button
                          onClick={() => openModal("view-addons")}
                          className={styles.btn}
                          disabled={true}
                          style={{ color: "grey" }}
                        >
                          View Add-ons
                        </button>
                      </div>
                      <div
                        className={`${styles.item_amount} ${styles.crossedOut}`}
                      >
                        ₦{processingFee || " Computing"}
                      </div>
                    </div>
                  </div> */}
                      <div className={styles.card}>
                        <div className={styles.card_content}>
                          <div className={styles.card_item}>
                            {priceForDuration ? (
                              <div className={styles.item_label}>
                                {getSelectedPrice() || 0} x {price[3]}
                              </div>
                            ) : (
                              <div className={styles.item_label}>
                                For interval
                              </div>
                            )}
                          </div>
                          <div className={styles.item_amount}>{`₦${
                            priceForDuration || " Computing"
                          }`}</div>
                        </div>
                      </div>
                      <div className={styles.card}>
                        <div className={styles.card_content}>
                          <div className={styles.card_item}>
                            <div className={styles.item_label}>
                              Processing fee
                            </div>
                          </div>
                          <div className={styles.item_amount}>{`₦${
                            Math.ceil((processingFee * 100) / 100) ||
                            " Computing"
                          }`}</div>
                        </div>
                      </div>
                      <div className={styles.total_card}>
                        <div className={styles.total_card_content}>
                          <div className={styles.card_item}>
                            <div className={styles.total_item_label}>Total</div>
                          </div>
                          <div className={styles.total_item_amount}>
                            ₦{amount || " Computing"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttonBox}>
                    {/* <PayButton
                                        email={loggedInUser?.email}
                                        amount={amount  || " Computing"}
                                        disabled={disabled}
                                        name={loggedInUser?.displayName}
                                        phone={"any"}
                                        workspace_id={id}
                                        className={styles.btn}
                                    /> */}
                    <CustomButton
                      children={"Make Payment"}
                      htmlType="submit"
                      // disabled={disabled}
                      loading={isSubmitting}
                      extraStyles={{
                        width: "100%",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    />
                  </div>
                  <div className={styles.cardFoot}>
                    <img src={blue_guard} alt="..." />
                    <p>
                      To protect your payment, never transfer money or
                      communicate outside of the Co-spire website.
                    </p>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
