import React, { useState, useEffect, useRef } from "react";
import "./addworkspace.scss";
import search from "@/assets/svgs/search-normal.svg";
import cloud from "@/assets/svgs/cloud-add.svg";
import greentick from "@/assets/svgs/green-tick.svg";
import camera from "@/assets/svgs/camera.svg";
import arrowDown from "@/assets/svgs/arrow-down.svg";
import tickCircle from "@/assets/svgs/tick-circle.svg";
import countryData from "@/assets/data/countries+states+cities.json";
import { createWorkSpace } from "@/services/networkRequests/workspaceService";
const convertedCountryData = Array.prototype.slice.call(countryData);

type Props = {};

export default function AddWorkSpace({}: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // page one states
  const [filter, setFilter] = useState<string>("");

  // page two states
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
  const [selectedState, setSelectedState] = useState<any | null>(null);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);
  const [address, setAddress] = useState<any | null>(null);
  const [zipcode, setZipCode] = useState<any | null>(null);

  // page three states

  const [overnightStay, setOvernightStay] = useState(false);
  const [parkingSpaces, setParkingSpaces] = useState(false);
  const [securityCameras, setSecurityCameras] = useState(false);
  const [restroomsAvailable, setRestroomsAvailable] = useState(false);

  // page four states

  const [foodPolicy, setFoodPolicy] = useState(false);
  const [alcoholPolicy, setAlcoholPolicy] = useState(false);
  const [musicPolicyOne, setMusicPolicyOne] = useState(false);
  const [musicPolicyTwo, setMusicPolicyTwo] = useState(false);
  const [securityDeposit, setSecurityDeposit] = useState(0);
  const [cancellationPolicy, setCancellationPolicy] = useState("");

  // page five states

  const [spaceTitle, setSpaceTitle] = useState("");
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [spaceRules, setSpaceRules] = useState("");
  const [ageRestriction, setAgeRestriction] = useState(false);
  const [allowedAges, setAllowedAges] = useState<string[]>([]);

  // page six states

  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [pricingType, setPricingType] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [otherActivities, setOtherActivities] = useState("");
  const [uploadedImages, setUploadedImages] = useState<
    { src: string; title: string; size: string }[]
  >([]);

  // page seven states

  const [image, setImage] = useState<string | null>("");
  const [displayName, setDisplayName] = useState<string | null>("");
  const [emailAddress, setEmailAddress] = useState<string | null>("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const nextPage = () => {
    if (currentPage < 8) {
      setCurrentPage(currentPage + 1);
      setErrorMessage("");
      setShowError(false);
    } else {
    }
  };

  const handleIncompletePage = (error: string) => {
    setShowError(true);
    setErrorMessage(`Incomplete: ${error}`);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
    }
  };
  const progress = (currentPage / 8) * 100;

  const postData = {
    filter,
    selectedCountry,
    selectedState,
    selectedCity,
    address,
    zipcode,
    overnightStay,
    parkingSpaces,
    securityCameras,
    restroomsAvailable,
    foodPolicy,
    alcoholPolicy,
    musicPolicyOne,
    musicPolicyTwo,
    securityDeposit,
    spaceTitle,
    numberOfPeople,
    spaceRules,
    ageRestriction,
    allowedAges,
    selectedActivities,
    selectedDates,
    openTime,
    closeTime,
    pricingType,
    basePrice,
    otherActivities,
    uploadedImages,
    image,
    displayName,
    emailAddress,
  };

  const handleSubmit = async () => {
    // Set loading state to true when starting the request
    setLoading(true);
    await createWorkSpace(postData);

    // Create an object representing all the data to send

    // Make the Axios POST request
    // axios
    //   .post(API_URL, postData)
    //   .then((response) => {
    //     console.log("Data sent successfully:", response.data);
    //     // Handle success
    //     setSuccess(true);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.error("Error sending data:", error);
    //     // Handle error
    //     setError(error.message);
    //     setLoading(false);
    //   });
  };
  const handleProceed = () => {
    switch (currentPage) {
      case 1:
        if (filter === "") {
          handleIncompletePage("select a category");
        } else {
          nextPage();
        }
        break;

      case 2:
        const missingFields = [];
        if (selectedCountry === null) missingFields.push("Country");
        if (selectedState === null) missingFields.push("State");
        if (selectedCity === null) missingFields.push("City");
        if (address === null) missingFields.push("Address");
        if (zipcode === null) missingFields.push("Zip Code");

        if (missingFields.length > 0) {
          handleIncompletePage(`provide ${missingFields.join(", ")}`);
        } else {
          nextPage();
        }
        break;

      case 5:
        const missingFieldsPage6 = [];
        if (spaceTitle === "") missingFieldsPage6.push("Space Title");
        if (numberOfPeople === "")
          missingFieldsPage6.push("Number of guests that can be accommodated");
        if (spaceRules === "") missingFieldsPage6.push("Space Rules");
        if (ageRestriction && allowedAges.length === 0)
          missingFieldsPage6.push("Accepted Ages");

        if (missingFieldsPage6.length > 0) {
          handleIncompletePage(`provide ${missingFieldsPage6.join(", ")}`);
        } else {
          nextPage();
        }
        break;

      case 6:
        const missingFieldsPage7 = [];
        if (uploadedImages.length < 4)
          missingFieldsPage7.push("Upload up to four images");
        if (selectedActivities.length === 0)
          missingFieldsPage7.push("Select at least one activity");
        if (selectedDates.length === 0)
          missingFieldsPage7.push("Select at least one date");
        if (openTime === "") missingFieldsPage7.push("Enter Opening Time");
        if (closeTime === "") missingFieldsPage7.push("Enter Closing Time");
        if (pricingType === "") missingFieldsPage7.push("Select Pricing Type");
        if (basePrice === "") missingFieldsPage7.push("Enter Base Price");
        if (selectedActivities.includes("Other") && otherActivities === "")
          missingFieldsPage7.push("Enter Other Activities");

        if (missingFieldsPage7.length > 0) {
          handleIncompletePage(`provide ${missingFieldsPage7.join(", ")}`);
        } else {
          nextPage();
        }
        break;

      case 7:
        const missingFieldsPage8 = [];
        if (image === "") missingFieldsPage8.push("Upload an image");
        if (displayName === "") missingFieldsPage8.push("Enter Owner's name");
        if (emailAddress === "") missingFieldsPage8.push("Enter Email Address");

        if (missingFieldsPage8.length > 0) {
          handleIncompletePage(`provide ${missingFieldsPage8.join(", ")}`);
        } else {
          nextPage();
        }
        break;
      case 8:
        handleSubmit();
        break;

      default:
        nextPage();
    }
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#f0f0f0",
        }}
      >
        <div
          style={{
            backgroundColor: "#007bff",
            width: `${progress}%`,

            height: "10px",
          }}
        ></div>
      </div>
      <div className="numbered-progress">
        <p
          className={`progress-indication-number ${
            currentPage === 1 || currentPage === 2 ? "current-page" : ""
          }`}
        >
          1
        </p>
        <div
          className={`progress-indication-bar ${
            currentPage === 1 || currentPage === 2 ? "current-progress" : ""
          }`}
        >
          <div className="blue-bar"></div>
        </div>
        <p
          className={`progress-indication-number ${
            currentPage === 3 ? "current-page" : ""
          }`}
        >
          2
        </p>
        <div
          className={`progress-indication-bar ${
            currentPage === 3 ? "current-progress" : ""
          }`}
        >
          <div className="blue-bar"></div>
        </div>
        <p
          className={`progress-indication-number ${
            currentPage === 4 ? "current-page" : ""
          }`}
        >
          3
        </p>
        <div
          className={`progress-indication-bar ${
            currentPage === 4 ? "current-progress" : ""
          }`}
        >
          <div className="blue-bar"></div>
        </div>
        <p
          className={`progress-indication-number ${
            currentPage === 5 ? "current-page" : ""
          }`}
        >
          4
        </p>
        <div
          className={`progress-indication-bar ${
            currentPage === 5 ? "current-progress" : ""
          }`}
        >
          <div className="blue-bar"></div>
        </div>
        <p
          className={`progress-indication-number ${
            currentPage === 6 ? "current-page" : ""
          }`}
        >
          5
        </p>
        <div
          className={`progress-indication-bar ${
            currentPage === 6 ? "current-progress" : ""
          }`}
        >
          <div className="blue-bar"></div>
        </div>
        <p
          className={`progress-indication-number ${
            currentPage === 7 ? "current-page" : ""
          }`}
        >
          6
        </p>
      </div>
      <div className="workspace-setup-page-content">
        <div className="pages-parent">
          {currentPage === 1 && (
            <PageOne filter={filter} setFilter={setFilter} />
          )}
          {currentPage === 2 && (
            <PageTwo
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
              zipcode={zipcode}
              setZipCode={setZipCode}
              address={address}
              setAddress={setAddress}
            />
          )}
          {currentPage === 3 && (
            <PageThree
              nextPage={nextPage}
              overnightStay={overnightStay}
              setOvernightStay={setOvernightStay}
              parkingSpaces={parkingSpaces}
              setParkingSpaces={setParkingSpaces}
              securityCameras={securityCameras}
              setSecurityCameras={setSecurityCameras}
              restroomsAvailable={restroomsAvailable}
              setRestroomsAvailable={setRestroomsAvailable}
            />
          )}
          {currentPage === 4 && (
            <PageFour
              nextPage={nextPage}
              foodPolicy={foodPolicy}
              setFoodPolicy={setFoodPolicy}
              alcoholPolicy={alcoholPolicy}
              setAlcoholPolicy={setAlcoholPolicy}
              musicPolicyOne={musicPolicyOne}
              setMusicPolicyOne={setMusicPolicyOne}
              musicPolicyTwo={musicPolicyTwo}
              setMusicPolicyTwo={setMusicPolicyTwo}
              securityDeposit={securityDeposit}
              setSecurityDeposit={setSecurityDeposit}
              cancellationPolicy={cancellationPolicy}
              setCancellationPolicy={setCancellationPolicy}
            />
          )}

          {currentPage === 5 && (
            <PageFive
              spaceTitle={spaceTitle}
              setSpaceTitle={setSpaceTitle}
              numberOfPeople={numberOfPeople}
              setNumberOfPeople={setNumberOfPeople}
              spaceRules={spaceRules}
              setSpaceRules={setSpaceRules}
              ageRestriction={ageRestriction}
              setAgeRestriction={setAgeRestriction}
              allowedAges={allowedAges}
              setAllowedAges={setAllowedAges}
            />
          )}
          {currentPage === 6 && (
            <PageSix
              selectedActivities={selectedActivities}
              setSelectedActivities={setSelectedActivities}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              openTime={openTime}
              setOpenTime={setOpenTime}
              closeTime={closeTime}
              setCloseTime={setCloseTime}
              pricingType={pricingType}
              setPricingType={setPricingType}
              basePrice={basePrice}
              setBasePrice={setBasePrice}
              otherActivities={otherActivities}
              setOtherActivities={setOtherActivities}
              uploadedImages={uploadedImages}
              setUploadedImages={setUploadedImages}
            />
          )}
          {currentPage === 7 && (
            <PageSeven
              image={image}
              setImage={setImage}
              displayName={displayName}
              emailAddress={emailAddress}
              setDisplayName={setDisplayName}
              setEmailAddress={setEmailAddress}
            />
          )}
          {currentPage === 9 && <p>dsd</p>}
        </div>

        <p
          style={{
            color: "red",
            visibility: showError ? "visible" : "hidden",
            padding: "1.5% 10%",
          }}
          className="error-in-listing"
        >
          Error: {errorMessage}
        </p>

        <div className="add-workspace-button-wrapper">
          <button className="btn button-outline" onClick={prevPage}>
            Back
          </button>
          {/* <button className="cancel">Cancel</button> */}
          <button className="btn " onClick={handleProceed}>
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

const PageOne = ({ filter, setFilter }: any) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const filterFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    setDropdownVisible(true); // Show dropdown when typing in the input field
  };

  const handleItemClick = (item: string) => {
    setFilter(item);
    setDropdownVisible(false); // Close dropdown after selecting an item
  };

  const predefinedArray = [
    "Production Spaces",
    "Co-working Spaces",
    "Outdoor Spaces",
    "Event Spaces",
  ];

  const filteredItems = predefinedArray.filter((item) =>
    item.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="page-one">
      <h2>Select Your Category</h2>
      <p className="sub-title">
        Add a category to get more visibility to your space.
      </p>
      <div className="search-bar">
        <input
          type="text"
          value={filter}
          onChange={filterFunction}
          placeholder="Search for your category here..."
        />
        <img src={search} alt="search" className="hover" />
      </div>

      <div
        className="dropdown-content-parent"
        style={{
          visibility:
            dropdownVisible && filteredItems.length > 0 ? "visible" : "hidden",
        }}
      >
        {filteredItems.length >= 1 ? (
          <div className="dropdown-content">
            {filteredItems.map((item) => (
              <p key={item} onClick={() => handleItemClick(item)}>
                {item}
              </p>
            ))}
          </div>
        ) : (
          <div className="dropdown-content">
            <p>Nothing found</p>
          </div>
        )}
      </div>

      <div className="space-categories first">
        <p>Production Spaces</p>
        <p>Co-working Spaces</p>
        <p>Outdoor Spaces</p>
        <p>Event Spaces</p>
      </div>
      <div className="space-categories">
        <p>Production Spaces</p>
        <p>Co-working Spaces</p>
        <p>Outdoor Spaces</p>
        <p>Event Spaces</p>
      </div>
    </div>
  );
};

const PageTwo = ({
  selectedCountry,
  setSelectedCountry,
  selectedState,
  setSelectedState,
  selectedCity,
  zipcode,
  setZipCode,
  address,
  setAddress,
  setSelectedCity,
}: any) => {
  // var convertedCountryData = Array.prototype.slice.call(countryData);

  const handleCountrySelect = (countryId: number) => {
    setSelectedCountry(countryId);
    setSelectedState(null);
  };

  const handleStateSelect = (state: string) => {
    setSelectedState(state);
    setSelectedCity(null);
  };
  const handleSelectCity = (city: string) => {
    setSelectedCity(city);
  };

  return (
    <div className="page-one page-two">
      <h2>Tell us your location</h2>
      <p className="sub-title">
        Add your address to get more visibility to your space.
      </p>
      <div className="page-input-field">
        <label>Country / Region</label>
        <div className="page-input-filed-input-wrapper">
          <select
            id="country"
            onChange={(e) => handleCountrySelect(parseInt(e.target.value))}
          >
            <option value="">Select a country</option>
            {convertedCountryData.map((country: any, index) => (
              <option key={index} value={index}>
                {country.name}
              </option>
            ))}
          </select>

          <img src={arrowDown} alt="search" className="hover clicker " />
        </div>
      </div>
      <div className="page-input-field-double">
        <div className="page-input-field">
          <label>State</label>
          <div className="page-input-filed-input-wrapper">
            <select
              value={selectedState}
              onChange={(e) => handleStateSelect(e.target.value)}
            >
              <option value="">Select a state</option>
              {selectedCountry !== null &&
                convertedCountryData[selectedCountry].states.map(
                  (item: any, index: any) => (
                    <option key={index} value={index}>
                      {item.name}
                    </option>
                  )
                )}
            </select>
            <img src={arrowDown} alt="search" className="hover clicker " />
          </div>
        </div>
        <div className="page-input-field">
          <label>City</label>
          <div className="page-input-filed-input-wrapper">
            <select
              value={selectedCity}
              onChange={(e) => handleSelectCity(e.target.value)}
            >
              <option value="">Select a city</option>

              {selectedCountry &&
                selectedState !== null &&
                convertedCountryData[selectedCountry].states[
                  selectedState
                ].cities.map((item: any, index: any) => (
                  <option key={index} value={index}>
                    {item.name}
                  </option>
                ))}
            </select>
            <img src={arrowDown} alt="search" className="hover clicker " />
          </div>
        </div>
      </div>
      <div className="page-input-field-double">
        <div className="page-input-field">
          <label>Street Address</label>
          <div className="page-input-filed-input-wrapper">
            <input
              type="text"
              value={address}
              placeholder="Enter Address"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="page-input-field">
          <label>Zip Code</label>
          <div className="page-input-filed-input-wrapper">
            <input
              type="text"
              value={zipcode}
              placeholder="Enter Zip Code"
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PageThree = ({
  nextPage,
  overnightStay,
  setOvernightStay,
  parkingSpaces,
  setParkingSpaces,
  securityCameras,
  setSecurityCameras,
  restroomsAvailable,
  setRestroomsAvailable,
}: any) => {
  // Function to toggle the active selection
  const toggleSelection = (
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setter((prevState) => !prevState);
  };

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <h2>Do you offer overnight stay at this place?</h2>
        <button className="cancel" onClick={nextPage}>
          Skip
        </button>
      </div>
      <p className="sub-title">Tell us if your space is open 24/7</p>
      <div className="option-wrapper">
        <div
          className={`single-option ${overnightStay ? "active" : ""}`}
          onClick={() => toggleSelection(setOvernightStay)}
        >
          <p>Yes, we do</p>
        </div>
        <div
          className={`single-option ${!overnightStay ? "active" : ""}`}
          onClick={() => toggleSelection(setOvernightStay)}
        >
          <p>No, we don’t</p>
        </div>
      </div>

      <h2>Are there parking spaces?</h2>
      <p className="sub-title">Tell us about ....</p>
      <div className="option-wrapper">
        <div
          className={`single-option ${parkingSpaces ? "active" : ""}`}
          onClick={() => toggleSelection(setParkingSpaces)}
        >
          <p>Yes, we do</p>
        </div>
        <div
          className={`single-option ${!parkingSpaces ? "active" : ""}`}
          onClick={() => toggleSelection(setParkingSpaces)}
        >
          <p>No, we don’t</p>
        </div>
      </div>

      <h2>Are there security cameras?</h2>
      <p className="sub-title">Tell us about ...</p>
      <div className="option-wrapper">
        <div
          className={`single-option ${securityCameras ? "active" : ""}`}
          onClick={() => toggleSelection(setSecurityCameras)}
        >
          <p>Yes, we do</p>
        </div>
        <div
          className={`single-option ${!securityCameras ? "active" : ""}`}
          onClick={() => toggleSelection(setSecurityCameras)}
        >
          <p>No, we don’t</p>
        </div>
      </div>

      <h2>Are there restrooms available?</h2>
      <p className="sub-title">Tell us about ...</p>
      <div className="option-wrapper">
        <div
          className={`single-option ${restroomsAvailable ? "active" : ""}`}
          onClick={() => toggleSelection(setRestroomsAvailable)}
        >
          <p>Yes, we do</p>
        </div>
        <div
          className={`single-option ${!restroomsAvailable ? "active" : ""}`}
          onClick={() => toggleSelection(setRestroomsAvailable)}
        >
          <p>No, we don’t</p>
        </div>
      </div>
      <button className="skip-button">Skip</button>
    </div>
  );
};

const PageFour = ({
  nextPage,
  foodPolicy,
  setFoodPolicy,
  alcoholPolicy,
  setAlcoholPolicy,
  musicPolicyOne,
  setMusicPolicyOne,
  musicPolicyTwo,
  setMusicPolicyTwo,
  securityDeposit,
  setSecurityDeposit,
  cancellationPolicy,
  setCancellationPolicy,
}: any) => {
  // Function to toggle the active selection
  const toggleSelection = (
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setter((prevState) => !prevState);
  };

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <h2>Food policy</h2>
        <button className="cancel" onClick={nextPage}>
          Skip
        </button>
      </div>
      <p className="sub-title">Tell us if your space is open 24/7</p>
      <div className="option-wrapper food-option-wrapper">
        <div
          className={`single-option ${
            foodPolicy === "External food allowed" ? "active" : ""
          }`}
          onClick={() => setFoodPolicy("External food allowed")}
        >
          <p>External food allowed</p>
        </div>
        <div
          className={`single-option ${
            foodPolicy === "Catering available at space" ? "active" : ""
          }`}
          onClick={() => setFoodPolicy("Catering available at space")}
        >
          <p>Catering available at space</p>
        </div>
        <div
          className={`single-option ${
            foodPolicy === "External food not allowed" ? "active" : ""
          }`}
          onClick={() => setFoodPolicy("External food not allowed")}
        >
          <p>External food not allowed</p>
        </div>
      </div>

      <h2>Alcohol policy</h2>
      <p className="sub-title">Tell us about ....</p>
      <div className="option-wrapper">
        <div
          className={`single-option ${alcoholPolicy ? "active" : ""}`}
          onClick={() => toggleSelection(setAlcoholPolicy)}
        >
          <p>Yes, we do</p>
        </div>
        <div
          className={`single-option ${!alcoholPolicy ? "active" : ""}`}
          onClick={() => toggleSelection(setAlcoholPolicy)}
        >
          <p>No, we don’t</p>
        </div>
      </div>

      <h2>Music policy</h2>
      <p className="sub-title">Tell us about ...</p>
      <div className="option-wrapper">
        <div
          className={`single-option ${musicPolicyOne ? "active" : ""}`}
          onClick={() => toggleSelection(setMusicPolicyOne)}
        >
          <p>Yes, we do</p>
        </div>
        <div
          className={`single-option ${!musicPolicyOne ? "active" : ""}`}
          onClick={() => toggleSelection(setMusicPolicyOne)}
        >
          <p>No, we don’t</p>
        </div>
      </div>
      <p className="sub-title">Music available at?</p>

      <div className="option-wrapper">
        <div
          className={`single-option ${musicPolicyTwo ? "active" : ""}`}
          onClick={() => toggleSelection(setMusicPolicyTwo)}
        >
          <p>Space</p>
        </div>
        <div
          className={`single-option ${!musicPolicyTwo ? "active" : ""}`}
          onClick={() => toggleSelection(setMusicPolicyTwo)}
        >
          <p>External Music</p>
        </div>
      </div>

      <h2>Security Deposit</h2>
      <div className="page-input-field">
        <label>Select a percentage from </label>
        <div className="page-input-filed-input-wrapper">
          <select
            value={securityDeposit}
            onChange={(e) => {
              setSecurityDeposit(parseInt(e.target.value));
            }}
          >
            <option value="">Select a percentage</option>

            <option value={0.05}>5%</option>
            <option value={0.1}>10%</option>
            <option value={0.15}>15%</option>
            <option value={0.2}>20%</option>
          </select>
          <img src={arrowDown} alt="search" className="hover clicker " />
        </div>
      </div>
      <h2>Enter Cancellation Policy</h2>
      <div className="page-input-field">
        <label>Enter Cancellation Policy</label>
        <div
          className="page-input-filed-input-wrapper"
          style={{ height: "auto", maxHeight: "max-content", padding: 0 }}
        >
          <textarea
            placeholder="Type here"
            rows={10}
            value={cancellationPolicy}
            onChange={(e) => {
              setCancellationPolicy(e.target.value);
            }}
          />
        </div>
      </div>
      <button className="skip-button">Skip</button>
    </div>
  );
};
const PageFive = ({
  spaceTitle,
  setSpaceTitle,
  numberOfPeople,
  setNumberOfPeople,
  spaceRules,
  setSpaceRules,
  ageRestriction,
  setAgeRestriction,
  allowedAges,
  setAllowedAges,
}: any) => {
  // State variables to track the active selections

  const handleSelectActivity = (index: number) => {
    const selectedActivity = ageRange[index];
    const isSelected = allowedAges.includes(selectedActivity);

    if (isSelected) {
      // If activity is already selected, remove it from the array
      setAllowedAges(
        allowedAges.filter((age: any) => age !== selectedActivity)
      );
    } else {
      // Otherwise, add it to the array
      setAllowedAges([...allowedAges, selectedActivity]);
    }
  };

  const ageRange = ["0-12", "13-18", "19-30", "31-50", "51+"];

  // Function to toggle the active selection
  const toggleSelection = (
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setter((prevState) => !prevState);
  };

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <h2>Choose unique space titles</h2>
      </div>
      <p className="sub-title">Tell us if your space is open 24/7</p>
      <ul>
        <li>Name should be company name</li>
        <li>Ensure your images are horizontal</li>
        <li>Screenshots are not allowed</li>
      </ul>
      <div className="page-input-field">
        <label>Space Title</label>
        <div className="page-input-filed-input-wrapper">
          <input
            type="text"
            value={spaceTitle}
            placeholder="Enter Space Title"
            onChange={(e) => {
              setSpaceTitle(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="page-input-field">
        <label>How many guests can this space accomodate</label>
        <div className="page-input-filed-input-wrapper">
          <input
            type="text"
            placeholder="E.g 5"
            value={numberOfPeople}
            onChange={(e) => {
              setNumberOfPeople(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="page-input-field">
        <label>Enter space rules</label>
        <div
          className="page-input-filed-input-wrapper"
          style={{ height: "auto", maxHeight: "max-content", padding: 0 }}
        >
          <textarea
            placeholder="Type here"
            rows={10}
            value={spaceRules}
            onChange={(e) => {
              setSpaceRules(e.target.value);
            }}
          />
        </div>
      </div>

      <h2>Are there age restrictions for this space?</h2>
      <p className="sub-title">Tell us about ...</p>

      <div className="option-wrapper">
        <div
          className={`single-option ${ageRestriction ? "active" : ""}`}
          onClick={() => toggleSelection(setAgeRestriction)}
        >
          <p>Yes, we do</p>
        </div>
        <div
          className={`single-option ${!ageRestriction ? "active" : ""}`}
          onClick={() => toggleSelection(setAgeRestriction)}
        >
          <p>No, we don’t</p>
        </div>
      </div>
      <div
        className="page-input-field"
        style={{ visibility: ageRestriction ? "visible" : "hidden" }}
      >
        <label>What age is allowed ?</label>
        <div className="space-activity-parent">
          {ageRange.map((spaceActivity, index) => {
            const isActive = allowedAges.includes(spaceActivity);
            const className = isActive
              ? "space-activity active"
              : "space-activity";

            return (
              <p
                className={className}
                key={index}
                onClick={() => handleSelectActivity(index)}
              >
                {spaceActivity}
              </p>
            );
          })}
        </div>
      </div>
      <div className="steps-remaining">
        <img src={tickCircle} alt="tickCircle" className="hover" />
        <p>Only 2 steps left!</p>
      </div>
    </div>
  );
};
const PageSix = ({
  selectedActivities,
  setSelectedActivities,
  selectedDates,
  setSelectedDates,
  openTime,
  setOpenTime,
  closeTime,
  setCloseTime,
  pricingType,
  setPricingType,
  basePrice,
  setBasePrice,
  otherActivities,
  setOtherActivities,
  uploadedImages,
  setUploadedImages,
}: any) => {
  // Define function to handle selection of space activity
  const handleSelectActivity = (index: number) => {
    const selectedActivity = spaceActivities[index];
    const isSelected = selectedActivities.includes(selectedActivity);

    if (isSelected) {
      // If activity is already selected, remove it from the array
      setSelectedActivities(
        selectedActivities.filter(
          (activity: any) => activity !== selectedActivity
        )
      );
    } else {
      // Otherwise, add it to the array
      setSelectedActivities([...selectedActivities, selectedActivity]);
    }
  };

  const handleDateChange = (date: string) => {
    if (selectedDates.includes(date)) {
      // If the date is already selected, remove it from the array
      setSelectedDates(selectedDates.filter((d: any) => d !== date));
    } else {
      // If the date is not selected, add it to the array
      setSelectedDates([...selectedDates, date]);
    }
  };

  const spaceActivities = [
    "Content Creation",
    "Podcast studio",
    "Party space",
    "Event Spaces",
    "Filming venues",
    "Outdoor wedding",
    "Video shooting venues",
    "Work meetings",
    "Other",
  ];

  return (
    <div className="page-one page-three">
      <div className="heading-div">
        <h2>Upload your space picture</h2>
      </div>
      <p className="sub-title">
        Add images of your workspace. For the best results, follow the
        instructions below;
      </p>

      <div className="double-list">
        <ul>
          <li>Upload a minimum of 4 images</li>
          <li>Ensure your images are horizontal</li>
          <li>Screenshots are not allowed</li>
        </ul>
        <ul>
          <li>We recommend uploading up to 10 images</li>
          <li>Ensure you upload coloured photos, not Black and white</li>
          <li>Ensure yor images are of high quality</li>
        </ul>
      </div>
      <ImageUploader
        uploadedImages={uploadedImages}
        setUploadedImages={setUploadedImages}
      />
      <hr />
      <h2>Add space operating hours</h2>
      <div className="page-input-field">
        <label>Choose your open days and hours for your space.</label>
        <div className="date-input-field">
          <div className="page-input-field-double ">
            <div className="page-input-field">
              <label>Open At</label>
              <div className="page-input-filed-input-wrapper">
                <input
                  placeholder="Enter Time"
                  type="time"
                  value={openTime}
                  onChange={(e) => {
                    setOpenTime(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="page-input-field">
              <label>Close At</label>
              <div className="page-input-filed-input-wrapper">
                <input
                  type="time"
                  placeholder="Enter Time"
                  value={closeTime}
                  onChange={(e) => {
                    setCloseTime(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="date-input-wrapper">
            {["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"].map(
              (item: string, index: number) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    checked={selectedDates.includes(item)}
                    onChange={() => handleDateChange(item)}
                    style={{
                      WebkitAppearance: "checkbox",
                    }}
                  />
                  <span>{item}</span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <h2>Choose pricing type</h2>
      <div className="page-input-field">
        <label>Tell us about ...</label>
        <div className="page-input-filed-input-wrapper">
          <select
            value={pricingType}
            onChange={(e) => {
              setPricingType(e.target.value);
            }}
          >
            <option value="">Select Pricing Type</option>

            <option value={"Hourly"}>Hourly</option>
            <option value={"Daily"}>Daily</option>
          </select>
          <img src={arrowDown} alt="search" className="hover clicker " />
        </div>
      </div>

      <h2>Add base pricing</h2>
      <div className="page-input-field">
        <label>Tell us about ...</label>

        <div className="page-input-filed-input-wrapper">
          <input
            type="number"
            placeholder="e.g 2000"
            value={basePrice}
            onChange={(e) => {
              setBasePrice(e.target.value);
            }}
          />
        </div>
      </div>
      <h2>Choose activities that you’d like to host in the space</h2>
      <div className="page-input-field">
        <label>Tell us about ...</label>
        <div className="space-activity-parent">
          {spaceActivities.map((spaceActivity, index) => {
            const isActive = selectedActivities.includes(spaceActivity);
            const className = isActive
              ? "space-activity active"
              : "space-activity";

            return (
              <p
                className={className}
                key={index}
                onClick={() => handleSelectActivity(index)}
              >
                {spaceActivity}
              </p>
            );
          })}
        </div>

        <div
          className="page-input-filed-input-wrapper"
          style={{
            width: "70%",
            visibility: selectedActivities.includes("Other")
              ? "visible"
              : "hidden",
          }}
        >
          <input
            type="text"
            placeholder="Other"
            value={otherActivities}
            onChange={(e: any) => {
              setOtherActivities(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
const PageSeven = ({
  image,
  setImage,
  displayName,
  emailAddress,
  setDisplayName,
  setEmailAddress,
}: any) => {
  return (
    <div className="page-one page-three page-eight">
      <div className="heading-div">
        <h2>Add your Contact details</h2>
      </div>
      <ProfileImageUpload image={image} setImage={setImage} />

      <div className="page-input-field">
        <label>Display Name</label>
        <div className="page-input-filed-input-wrapper">
          <input
            type="text"
            placeholder="Enter owner’s name"
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="page-input-field">
        <label>Email Address</label>
        <div className="page-input-filed-input-wrapper">
          <input
            type="email"
            placeholder="Enter email address"
            value={emailAddress}
            onChange={(e) => {
              setEmailAddress(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ImageUploader = ({ uploadedImages, setUploadedImages }: any) => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleImageUpload = (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const imagesArray: { src: string; title: string; size: string }[] = [];
    const uploadPromises: Promise<void>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      const uploadPromise = new Promise<void>((resolve, reject) => {
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target!.result as string;

          img.onload = () => {
            if (img.width > img.height) {
              // Image is horizontal, proceed with upload
              imagesArray.push({
                src: img.src,
                title: file.name,
                size: getFileSize(file.size),
              });
              resolve();
            } else {
              // Image is not horizontal, display error message
              reject("Please upload horizontal images only.");
            }
          };
        };

        reader.readAsDataURL(file);
      });

      uploadPromises.push(uploadPromise);
    }

    Promise.all(uploadPromises)
      .then(() => {
        setUploadedImages((prevImages: any) => [...prevImages, ...imagesArray]);
        setErrorMessage("");
        console.log("All images uploaded successfully");
        // Perform additional upload logic here if needed
      })
      .catch((error) => {
        setErrorMessage(error as string);
      });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleImageUpload(files);
  };

  const handleBrowseClick = () => {
    document.getElementById("fileInput")?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const acceptedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!acceptedTypes.includes(file.type)) {
          setErrorMessage("Please upload PNG, JPEG, or JPG files only.");
          return;
        }
        if (file.size > maxSize) {
          setErrorMessage(`Maximum file size exceeded (${maxSize} bytes).`);
          return;
        }
      }
      setErrorMessage("");
      handleImageUpload(files);
    }
  };

  const handleDeleteImage = (index: number) => {
    const newImages = [...uploadedImages];
    newImages.splice(index, 1);
    setUploadedImages(newImages);
  };

  const getFileSize = (bytes: number) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  };

  return (
    <div className="image-uploader-parent">
      <div
        className="image-uploader"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <img src={cloud} alt="upload" className="cloud-svg" />
        <p className="bold-p">Choose a file or drag & drop it here</p>
        <p className="faint-p">PNG, JPEG, or JPG files up to {100}</p>
        <button onClick={handleBrowseClick} className="cancel browseFiles">
          Browse Files
        </button>
        <input
          id="fileInput"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept=".png,.jpg,.jpeg" // Limit accepted file types
          multiple // Allow multiple file selection
        />
      </div>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <div className="uploaded-images-parent">
        {uploadedImages.map((imageData: any, index: number) => (
          <div className="uploaded-image-div" key={index} style={{}}>
            <button
              onClick={() => handleDeleteImage(index)}
              style={{
                position: "absolute",
                top: "15px",
                right: "30px",
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "red",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <img src={imageData.src} alt={`Uploaded ${index}`} />
            <div className="uploaded-image-data">
              <p className="title">{imageData.title}</p>
              <div className="bottom">
                <p>
                  {imageData.size} of {imageData.size}.
                </p>
                <div className="status">
                  <img src={greentick} alt="" />
                  <p>Completed</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ProfileImageUpload = ({ image, setImage }: any) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploading(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        const uploadedImage = reader.result as string;
        setImage(uploadedImage);
        setUploading(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="circular-upload-image">
      <div className="image-container">
        {image && <img src={image} alt="Profile" className="profile-image" />}
        {!image && <div className="profile-placeholder"></div>}

        {uploading && <div className="overlay">Uploading...</div>}
        <div className="upload-button" onClick={handleButtonClick}>
          <img src={camera} alt="camera" />
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        className="upload-input"
        disabled={uploading}
        style={{ display: "none" }}
      />
    </div>
  );
};
