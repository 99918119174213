import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import { getAllBookings } from "@/services/networkRequests/bookingService";
import * as dayjs from "dayjs";
import useHostWorkspaces from "@/pages/Host/Profile/components/MyWorkspaces/useHostWorkspaces";
import { toast } from "react-toastify";
import { log } from "console";
import { useMe } from "@/data/user";
// import { dayjs } from 'dayjs';
enum tabs {
  all_booking = "All Booking",
  pending = "Cancelled",
  ongoing = "Ongoing/Accepted",
  cancelled = "Cancelled",
}

const paymentStatus = [
  { label: "Date", value: "date" },
  { label: "Amount", value: "amount" },
];

interface DataType {
  key: string;
  workspaceInformation: {
    name: string;
    createdDate: Date;
  };
  bookingStatus: string;
  amount: number;
  paymentStatus: string;
  more: string;
}

const data: DataType[] = [
  {
    key: "1",
    workspaceInformation: {
      name: "Perfect Office",
      createdDate: new Date("2023-07-25"),
    },
    bookingStatus: "Ongoing",
    amount: 5000,
    paymentStatus: "Approved",
    more: "",
  },
  {
    key: "2",
    workspaceInformation: {
      name: "BlackBox Office space",
      createdDate: new Date("2023-07-25"),
    },
    bookingStatus: "In progress",
    amount: 5000,
    paymentStatus: "Approved",
    more: "",
  },
  {
    key: "4",
    workspaceInformation: {
      name: "Flex Office Support",
      createdDate: new Date("2023-07-25"),
    },
    bookingStatus: "pending check",
    amount: 5000,
    paymentStatus: "Approved",
    more: "",
  },
  {
    key: "5",
    workspaceInformation: {
      name: "Neutral Territory",
      createdDate: new Date("2023-07-25"),
    },
    bookingStatus: "Reserved",
    amount: 5000,
    paymentStatus: "Approved",
    more: "",
  },
  {
    key: "6",
    workspaceInformation: {
      name: "Skillnetic",
      createdDate: new Date("2023-07-25"),
    },
    bookingStatus: "Cancelled",
    amount: 5000,
    paymentStatus: "Pending",
    more: "",
  },
];

const useBooking = () => {
  const [currentTab, setcurrentTab] = useState<string>("Ongoing/Accepted");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<
    string | null
  >(null);
  const [searchValue, setSearchValue] = useState<any>(null);
  const tabLists = [tabs.ongoing, tabs.pending, tabs.all_booking];
  const [isLoading, setIsLoading] = useState(true);
  const [bookings, setBookings] = useState<any>([]);
  const [bookTest, setBookTest] = useState<any>([]);
  const [customerBookings, setCustomerBookings] = useState<any>([]);
  const { me: loggedInUser } = useMe();

  //  useEffect(() => {
  //     async function getData() {
  //        const id = loggedInUser?.uid;
  //        if (id) {
  //           try {
  //              const { data } = await getAllBookings(id);
  //              // setBookTest(data);

  //              let filterBookings = data.filter((item: any) => item.created_by == loggedInUser?.uid);

  //              filterBookings.forEach((element: any) => {
  //                 element.companyInfo = {
  //                    companyName: element.company_info,
  //                    start_date: element.start_date,
  //                 };
  //              });
  //              setCustomerBookings(filterBookings);
  //           } catch (err) {
  //              console.log(err);
  //           } finally {
  //              setIsLoading(false);
  //           }
  //        } else {
  //           toast.error("Please login to continue");
  //        }
  //     }
  //     getData();
  //  }, []);

  //  useEffect(() => {
  //     async function getData() {
  //        const id = loggedInUser?.uid;
  //        if (id) {
  //           try {
  //              const { data } = await getAllBookings(id);
  //              setBookTest(data);

  //              // let filterBookings = data.filter(
  //              //   (item: any) => item.created_by == loggedInUser?.uid
  //              // );

  //              // filterBookings.forEach((element: any) => {
  //              //   element.companyInfo = {
  //              //     companyName: element.company_info,
  //              //     start_date: element.start_date,
  //              //   };
  //              // });
  //              setBookings(data);
  //           } catch (err) {
  //              console.log(err);
  //           } finally {
  //              setIsLoading(false);
  //           }
  //        } else {
  //           toast.error("Please login to continue");
  //        }
  //     }
  //     getData();
  //  }, []);

  useEffect(() => {
    async function getData() {
      const id = loggedInUser?.uid;
      if (id) {
        try {
          const { data } = await getAllBookings(id);
          setBookTest(data);

          let filterBookings = data.filter(
            (item: any) => item.created_by === loggedInUser?.uid
          );

          filterBookings.forEach((element: any) => {
            element.companyInfo = {
              companyName: element.company_info,
              start_date: element.start_date,
            };
          });
          setCustomerBookings(filterBookings);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error("Please login to continue");
      }
    }
    getData();
  }, [loggedInUser?.uid]);

  useEffect(() => {
    async function getData() {
      const id = loggedInUser?.uid;
      if (id) {
        try {
          const { data } = await getAllBookings(id);
          // setBookTest(data);

          // let filterBookings = data.filter(
          //   (item: any) => item.created_by == loggedInUser?.uid
          // );

          // filterBookings.forEach((element: any) => {
          //   element.companyInfo = {
          //     companyName: element.company_info,
          //     start_date: element.start_date,
          //   };
          // });
          setBookings(data);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error("Please login to continue");
      }
    }
    getData();
  }, [loggedInUser?.uid]);

  const customerBookingsData = customerBookings;
  console.log(customerBookingsData, "customerBookingsData");

  const { hostWorkSpaces } = useHostWorkspaces();

  function getAllBookingForHost(hostWorkSpaces: any, bookings: any) {
    const workspaceIds = hostWorkSpaces?.map(
      (workspace: any) => workspace.workspace_id
    );

    let hostBookings = bookings.filter((booking: any) =>
      workspaceIds?.some(
        (workspaceId: any) => workspaceId === booking.workspace_id
      )
    );

    hostBookings.forEach((element: any) => {
      element.companyInfo = {
        companyName: element.company_info,
        start_date: element.start_date,
      };
      console.log(hostBookings);
    });
    return hostBookings;
  }

  const onTabClick = (tab: string) => {
    setcurrentTab(tab);
  };

  const onSelectPaymentStatus = (status: string) => {
    setSelectedPaymentStatus(status);
  };

  const getColor = (text: string): { [key: string]: string } => {
    switch (text) {
      case "Ongoing":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "Cancelled":
        return {
          color: "red",
          backgroundColor: "rgba(255, 204, 204, 1)",
        };
      case "Approved":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "Pending":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 229, 204, 1)",
        };
      case "pending":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 229, 204, 1)",
        };
      case "pending check":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 229, 204, 1)",
        };
      case "Reserved":
        return {
          color: "white",
          backgroundColor: "rgba(194, 201, 209, 1)",
        };
      case "In progress":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      case "inprogress":
        return {
          color: "green",
          backgroundColor: "rgba(191, 248, 200, 1)",
        };
      default:
        return {
          color: "black",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
    }
  };

  const filterData = () => {
    //  let filteredData = bookings;
    let filteredData;

    if (loggedInUser?.role_type === "Host") {
      filteredData = bookings;
    } else {
      filteredData = customerBookingsData;
    }

    // if (selectedPaymentStatus) {
    //   filteredData = filteredData.filter(
    //     (item: any) => item.payment_status.trim() === selectedPaymentStatus
    //   );
    //   // filteredData = filteredData.filter((item:any) => item.paymentStatus === selectedPaymentStatus);
    // }

    if (selectedPaymentStatus === "date") {
      filteredData = filteredData.sort(
        (a: any, b: any) =>
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
      );
    } else if (selectedPaymentStatus === "amount") {
      filteredData = filteredData.sort((a: any, b: any) => a.amount - b.amount);
    }

    if (currentTab === tabs.cancelled) {
      filteredData = filteredData.filter(
        (item: any) => item.status === currentTab
      );
      // filteredData = filteredData.filter((item:any) => item.bookingStatus === currentTab);
    } else if (currentTab === tabs.ongoing) {
      filteredData = filteredData.filter(
        (item: any) => item.status === "Ongoing" || "Accepted"
      );
    }

    if (searchValue) {
      filteredData = filteredData?.filter((item: any) =>
        item?.companyInfo?.companyName
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase())
      );
    }

    return filteredData;
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return {
    tabLists,
    currentTab,
    onTabClick,
    paymentStatus,
    onSelectPaymentStatus,
    onSearch,
    getColor,
    data: filterData(),
    isLoading,
    bookings: filterData(),
    getAllBookingForHost,
    customerBookingsData: filterData(),
    bookTest,
    hostWorkSpaces,
  };
};
export default useBooking;
