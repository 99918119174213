import React, { useState } from "react";
import styles from "./index.module.scss";
import { Button, Form, Input } from "antd";
import logo from "@/assets/svgs/logo-cospire-svg.svg";
import Frame from "@/assets/svgs/Frame.svg";
import Close from "@/assets/svgs/Close.svg";
import { forgotPassword } from "../../../../services/networkRequests/authService";
import { useModalAction } from "@/components/modal-view/context";

const ForgotPassword: React.FC<{}> = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { openModal, closeModal } = useModalAction();

  const handleSubmit = async () => {
    if (email) {
      setLoading(true);
      try {
        await forgotPassword({ user_email: email });
        form.resetFields();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.close} onClick={closeModal}>
        <img alt="..." src={Close} />
      </div>

      <div className={styles.box}>
        <img src={logo} alt="co-spire logo" />
        <h1>Change Your Password?</h1>
        <p>We’ve got your back! Enter your new password to proceed!</p>
        <Form
          layout="vertical"
          name="forgotPassword"
          size={"large"}
          autoComplete="off"
          className={styles.form}
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name="email"
            className={styles.formItem}
            label={<span className={styles.label}>Email Address</span>}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              type="email"
              className={styles.input}
              placeholder="Enter your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className={styles.button}
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
                loading={loading}
              >
                Reset Password
              </Button>
            )}
          </Form.Item>
          <p className={styles.register}>
            Remember your Password?
            <button
              title="Login"
              type="button"
              onClick={() => openModal("LOGIN_VIEW")}
            >
              Login
            </button>
          </p>
        </Form>
      </div>
      <div className={styles.frame}>
        <img src={Frame} alt="..." />
      </div>
    </div>
  );
};

export default ForgotPassword;
