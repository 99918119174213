import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Recommended,
  Workspace,
  WorkspacePaginator,
  WorkspaceQueryOptions,
} from "@/types/workspace";
import { API_ENDPOINTS } from "./client/api-endpoints";
import { useLocale } from "@/hooks/use-locale";
import client from "./client";
import { mapPaginatorData } from "@/utils/data-mappers";

export function useWorkspaceQuery(workspaceId: string, options?: {}) {
  const { language } = useLocale();
  const { data, isLoading, error } = useQuery<{ data: Workspace }, Error>(
    [API_ENDPOINTS.WORKSPACE.GET, { workspaceId }],
    () => client.workspace.get({ id: workspaceId }),
    options
  );

  return {
    workspace: data?.data,
    isLoading,
    error,
  };
}

export function useMyWorkspaceQuery(workspaceId: string, options?: {}) {
  const { language } = useLocale();
  const { data, isLoading, error } = useQuery<{ data: Workspace }, Error>(
    [API_ENDPOINTS.WORKSPACE.MY, { workspaceId }],
    () => client.workspace.my({ id: workspaceId }),
    options
  );

  return {
    workspace: data?.data,
    isLoading,
    error,
  };
}

export function useRecommendedQuery(workspaceId: string, options?: {}) {
  const { language } = useLocale();
  const { data, isLoading, error } = useQuery<{ data: { data: Recommended[] } }, Error>(
    [API_ENDPOINTS.RECOMMENDED.GET, { workspaceId }],
    () => client.recommended.get({ id: workspaceId }),
    options
  );

  return {
    recommended: data?.data.data ?? [],
    isLoading,
    error,
  };
}

export const useWorkspacesQuery = (
  params: Partial<WorkspaceQueryOptions>,
  options = {}
) => {
  const { language } = useLocale();

  const { data, error, isLoading } = useQuery<WorkspacePaginator, Error>(
    [API_ENDPOINTS.WORKSPACE.ALL, { ...params, language }],
    ({ queryKey, pageParam }) =>
      client.workspace.paginated(Object.assign({}, queryKey[1], pageParam)),
    {
      keepPreviousData: true,
      ...options,
    }
  );

  return {
    workspaces: data?.data ?? [],
    paginatorInfo: mapPaginatorData(data),
    error,
    loading: isLoading,
  };
};

export const useMyWorkspacesQuery = (
  params: Partial<WorkspaceQueryOptions>,
  options: any = {}
) => {
  const { data, error, isLoading } = useQuery<WorkspacePaginator, Error>(
    [API_ENDPOINTS.WORKSPACE.ME, params],
    ({ queryKey, pageParam }) =>
      client.workspace.me(Object.assign({}, queryKey[1], pageParam)),
    {
      keepPreviousData: true,
      ...options,
    }
  );

  return {
    workspaces: data?.data ?? [],
    paginatorInfo: mapPaginatorData(data),
    error,
    loading: isLoading,
  };
};

export const useUpdateWorkspaceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.workspace.update, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(API_ENDPOINTS.WORKSPACE.MY);
    },
  });
};

export const useCreateSpaceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.workspace.create, {
    // Always refetch after error or success:
    onSettled: () => {},
  });
};
