import React from "react";
import styles from "./index.module.scss";
import ChevronRight from "../../../assets/svgs/ChevronRight.svg";
import { usePagination, DOTS } from "./usePagination";

const Pagination = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    pageAction
  } = props;

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
    pageAction("NEXT")
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
    pageAction("PREVIOUS")

  };

  const lastPage =
    paginationRange && paginationRange[paginationRange?.length - 1];

  return (
    <ul className={styles.lists}>
      {currentPage > 1 && (
      <li
        onClick={onPrevious}
        className={
          currentPage === 1
            ? [styles.arrow, styles.current].join(" ")
            : [styles.arrow].join(" ")
        }
      >
        <img src={ChevronRight} style={{ transform: "rotate(180deg)" }} />
      </li>
      )}
      {paginationRange?.map((pageNumber: any) => {
        if (pageNumber === DOTS) {
          return <li className={styles.dots}>&#8230;</li>;
        }

        return (
          <li
            onClick={() => onPageChange(pageNumber)}
            className={
              pageNumber === currentPage
                ? [styles.list, styles.activelist].join(" ")
                : [styles.list].join(" ")
            }
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        onClick={onNext}
        className={
          currentPage === lastPage
            ? [styles.arrow, styles.current].join(" ")
            : [styles.arrow].join(" ")
        }
      >
        <img src={ChevronRight} />
      </li>
    </ul>
  );
};

export default Pagination;
