import React from "react";
import styles from "./DiscoverSpace.module.scss";
import "./overide.css";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import arrowrightblack from "@/assets/svgs/arrowblack.svg";
import venueImage1 from "@/assets/img/discover1.png";
import venueImage2 from "@/assets/img/discover2.png";
import venueImage3 from "@/assets/img/discover3.png";
import venueImage4 from "@/assets/img/discover4.png";
import event1 from "@/assets/img/confrence.png";
import event2 from "@/assets/img/recepton.png";
import event3 from "@/assets/img/livePerformance.png";
import event4 from "@/assets/img/banquet.jpg";
import event5 from "@/assets/img/club_lounge.jpeg";
import event6 from "@/assets/img/babyShower.png";
import coSpace1 from "@/assets/img/co_pricateOffice.png";
import coSpace2 from "@/assets/img/co_coworkingDesk.png";
import coSpace3 from "@/assets/img/co_brainStorm.png";
import coSpace4 from "@/assets/img/co_vertualOffice.png";
import coSpace5 from "@/assets/img/co_openSeating.png";
import photStudio1 from "@/assets/img/photStudio1.png";
import photStudio2 from "@/assets/img/photStudio2.png";
import photStudio3 from "@/assets/img/photStudio3.png";
import photStudio4 from "@/assets/img/photStudio4.png";
import photStudio5 from "@/assets/img/photStudio5.png";

// import discover2 from "@/assets/img/";
import { useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { WORKSPACES } from "@/routes/routes";
// import { Splide, SplideSlide } from "@splidejs/react-splide";

const photoData = [
  { title: "Content Creation", img: photStudio1 },
  { title: "Painting Studio", img: photStudio2 },
  { title: "Video production studio", img: photStudio3 },
  { title: "Podcast Studio ", img: photStudio4 },
  { title: "Design Studio ", img: photStudio5 },
];
const eventData = [
  { title: "Conference Halls", img: event1 },
  { title: "Reception Halls", img: event2 },
  { title: "Live performance", img: event3 },
  { title: "  Banquets Halls", img: event4 },
  { title: "Club Lounge ", img: event5 },
  { title: " Birthday Party Venues", img: event6 },
  { title: "Baby showers ", img: event6 },
];
const coWorkingData = [
  { title: "Private Office", img: coSpace1 },
  { title: "Co-working desks", img: coSpace2 },
  { title: "Brainstorming Rooms", img: coSpace3 },
  { title: "Virtual Offices ", img: coSpace4 },
  { title: " Open Seating Areas", img: coSpace5 },
];
// const photoData = [
//   {title: "Photography Studio", img: venueImage4},
//   {title: "", img: venueImage3},
//   {title: "", img: venueImage2},
//   {title: " ", img: venueImage1},
// ]
const dummyData = [
  { title: "Production Studions", img: venueImage4 },
  { title: "Art Exhibition Venues", img: venueImage3 },
  { title: "Book Launches", img: venueImage2 },
  { title: "Concert Venues ", img: venueImage1 },
];

interface ICard {
  title: string;
  img: string;
}

const Card = ({ title, img }: ICard) => {
  const navigate = useNavigate();
  const searchtitle = title;
  return (
    <div className="">
      <div
        onClick={() => navigate(WORKSPACES, {state: {searchtitle}})} // uncomment when explore spaces is live
        className={styles.cardContainer}
      >
        <div className={styles.cardContent}>
          <div className={styles.img}>
            <img src={img} alt="" />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <div className={styles.arrow}>
                <span className={styles.title}>{title}</span>
                <img
                  src={arrowrightblack}
                  className={styles.light}
                  alt="arrowrightlight"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TabContent = ({ workspace }: any) => {
  return (
    <div className={styles.tabsRow}>
      {workspace.map((item: any, index: any) => (
        <Card title={item.title} img={item.img} key={index} />
      ))}
    </div>
  );
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: <span className={styles.tab}>Creative Spaces</span>,
    children: <TabContent workspace={photoData} />,
  },
  {
    key: "2",
    label: <span className={styles.tab}>Co-Working Spaces </span>,
    children: <TabContent workspace={coWorkingData} />,
  },
  {
    key: "3",
    label: <span className={styles.tab}>Outdoor Spaces</span>,
    children: <TabContent workspace={dummyData} />,
  },
  {
    key: "4",
    label: <span className={styles.tab}>Event spaces</span>,
    children: <TabContent workspace={eventData} />,
  },
];

const DiscoverSpaces = () => {
  const handleTabChange = (key: string) => {
    console.log(key, "key");
  };
  return (
    <div className={styles.page}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <h2 className={styles.title}>Discover Spaces</h2>
          <p className={styles.description}>
            Discover Unique Spaces for Any Occasion, From Intimate Gatherings to
            Grand Celebrations
          </p>
        </div>
      </div>
      <div className={styles.tabs}>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={handleTabChange}
          centered
        />
      </div>
    </div>
  );
};

export default DiscoverSpaces;
