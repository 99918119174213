import { Button, Form, Input } from "antd";
import { success } from "@/assets/img";
import CustomModal from "@/components/Modal";
import SuccessModal from "@/components/a/Modals/Success";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./index.module.scss";
import Splider from "@/components/splide/splide";
import slideOne from "../../../../../../assets/img/list1.webp";
import slideTwo from "../../../../../../assets/img/list4.webp";
import slideThree from "../../../../../../assets/img/list2.webp";
import slideFour from "../../../../../../assets/img/list3.webp";
import CustomIcons from "@/components/icons";
import { cancelBooking } from "@/services/networkRequests/bookingService";
import { toast } from "react-toastify";

interface Iprops {
  isModalOpen: boolean;
  setIsModalOpen: (arg: boolean) => void;
  bookingId: string;
}

const CancelBookingModal = ({
  isModalOpen,
  setIsModalOpen,
  bookingId,
}: Iprops) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const id = params?.id;
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
    setIsSuccessModalOpen(true);
    handleCancel(bookingId);
  };

  const slides = [slideOne, slideTwo, slideThree, slideFour];
  const space = { title: "slides" };

  const handleCancel = async (bookingId: string) => {
    if (bookingId) {
      try {
        await cancelBooking(bookingId);

        // Clear the form fields after successful submission
        form.resetFields();

        // Show success toast message
        toast.success("Booking cancelled successfully!");

        // Close the modal
      } catch (error) {
        toast.error("An error occured, please try again");
      }
    } else {
      // Handle the case where user_id or workspaceId is undefined
      toast.error(
        "Booking ID is missing. Please log in or provide a workspace ID."
      );
    }
  };

  return (
    <>
      <SuccessModal
        openModal={isSuccessModalOpen}
        setOpenModal={setIsSuccessModalOpen}
        heading={"Booking Cancelled!"}
        closeModal={() => {
          setIsSuccessModalOpen(false);
          navigate(`/customer-profile/booking_history`);
        }}
        icon={success}
        description={
          "Lorem ipsum dolor sit amet consectetur. Amet vitae odio elit a pharetra. Vulputate aliquam purus orci turpis odio in morbi netus. "
        }
      />
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleOk}
        title={"Cancel Booking"}
        footer={
          <div className={styles.footer}>
            <Button
              key="back"
              className={styles.ButtonOne}
              onClick={() => setIsModalOpen(false)}
            >
              Go Back
            </Button>
            <Button
              key="back"
              type="primary"
              className={styles.ButtonTwo}
              onClick={handleOk}
            >
              Yes, Cancel
            </Button>
          </div>
        }
        width={650}
      >
        <div className={styles.wrapper}>
          <p className={styles.para}>
            Are you sure you want to cancel your booking? Please note booking
            cancellation attracts a fee of <span>100USD</span> from your initial
            payment. Click Yes to proceed.
          </p>
          <div className={styles.formTextItems}>
            <Form layout="vertical">
              <Form.Item
                name="reason"
                className={styles.formItem}
                label={
                  <span className={styles.label}>
                    Reason for Cancellation(optional)
                  </span>
                }
              >
                <Input.TextArea className={styles.text} placeholder="" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default CancelBookingModal;
