import styles from "./index.module.scss";
import { TabTitle } from "@/utils/titleFunction";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

import { useWorkspaceQuery } from "@/data/workspace";

import { BookingForm } from "@/components/booking/booking-form";

const BookingPage = () => {
  const { id } = useParams();

  const { workspace, isLoading, error } = useWorkspaceQuery(id!);

  TabTitle("co-spire | BookNow");

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Spin size="large" />
      </div>
    );
  }

  return <BookingForm workspace={workspace!} />;
};

export default BookingPage;
