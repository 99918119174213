import React from "react";

const Map: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0397 22.351C12.07 22.3685 12.0937 22.3821 12.1105 22.3915L12.1384 22.4071C12.3613 22.5294 12.6378 22.5285 12.8608 22.4075L12.8895 22.3915C12.9063 22.3821 12.93 22.3685 12.9603 22.351C13.0207 22.316 13.107 22.265 13.2155 22.1982C13.4325 22.0646 13.7388 21.8676 14.1046 21.6091C14.8351 21.0931 15.8097 20.3274 16.7865 19.3273C18.7307 17.3368 20.75 14.3462 20.75 10.5C20.75 5.94365 17.0563 2.25 12.5 2.25C7.94365 2.25 4.25 5.94365 4.25 10.5C4.25 14.3462 6.26932 17.3368 8.21346 19.3273C9.19025 20.3274 10.1649 21.0931 10.8954 21.6091C11.2612 21.8676 11.5675 22.0646 11.7845 22.1982C11.893 22.265 11.9793 22.316 12.0397 22.351ZM12.5 13.5C14.1569 13.5 15.5 12.1569 15.5 10.5C15.5 8.84315 14.1569 7.5 12.5 7.5C10.8431 7.5 9.5 8.84315 9.5 10.5C9.5 12.1569 10.8431 13.5 12.5 13.5Z"
        fill="#525252"
      />
    </svg>
  );
};

export default Map;
