import React, { useState } from "react";
import styles from "./aboutUs.module.scss";
import {
  about_us,
  community,
  community1,
  community2,
  community3,
  creative_img,
  creative_space_images,
  customer_centric_img,
  innovative_img,
  location,
  office_icon,
  offices,
  secure_and_private_img,
  oneOne,
  oneTwo,
  oneThree,
  oneFour,
  oneFive,
  twoOne,
  twoTwo,
  twoThree,
  twoFour,
  twoFive,
  threeOne,
  threeTwo,
  threeThree,
  threeFour,
  fourOne,
  fourTwo,
  fourThree,
  fourFour,
  fourFive,
  fourSix,
  fiveOne,
  fiveTwo,
  fiveThree,
  fiveFour,
  fiveFive,
  sixOne,
  sixTwo,
  sixThree,
  sixFour,
  sixFive,
  sevenOne,
  sevenTwo,
  sevenThree,
  sevenFour,
  sevenFive,
  sevenSix,
} from "@/assets/img";
import user_group from "@/assets/svgs/user-group-mobile.svg";
import building_office from "@/assets/svgs/building-office-mobile.svg";
import chevrondown from "@/assets/svgs/chevron-down.svg";
import users from "../../assets/svgs/users.svg";
import { TabTitle } from "@/utils/titleFunction";
import { title } from "process";
import Dropdown from "@/assets/svgs/chevron-down";
import { Link, useNavigate } from "react-router-dom";
import { WORKSPACES } from "@/routes/routes";
// import MultiDateSelector from "./testy";
// import { Dayjs } from "dayjs";

const AboutUsx = () => {
  const navigate = useNavigate();
  const [isShowBody, setIsSHowBody] = React.useState(false);

  const onClickHandler = () => {
    setIsSHowBody((isShowBody) => !isShowBody);
  };

  const [isShowBody1, setIsSHowBody1] = React.useState(false);

  const onClickHandler1 = () => {
    setIsSHowBody1((isShowBody1) => !isShowBody1);
  };

  const [isShowBody2, setIsSHowBody2] = React.useState(false);

  const onClickHandler2 = () => {
    setIsSHowBody2((isShowBody2) => !isShowBody2);
  };

  const [isShowBody3, setIsSHowBody3] = React.useState(false);

  const onClickHandler3 = () => {
    setIsSHowBody3((isShowBody3) => !isShowBody3);
  };

  const [isShowBody4, setIsSHowBody4] = React.useState(false);

  const onClickHandler4 = () => {
    setIsSHowBody4((isShowBody4) => !isShowBody4);
  };

  TabTitle("co-spire | AboutUs");

  return (
    <div className={styles.page}>
      <nav className={styles.nav}></nav>
      <div className={styles.banner}>
        <div className={styles.bannerContent}>
          <h1 className={styles.titlehead}>CO-SPIRE</h1>
          <h1 className={styles.title}>
            Africa’s most organized marketplace for creative, productive and fun
            spaces.{" "}
          </h1>
          <p className={styles.description}>
            We are on a mission to help you create your best memories, by
            providing you with quality spaces that best fulfills your needs.
          </p>
        </div>
      </div>

      <section className={styles.insights}>
        {/* <h1>Multi-Date Selector</h1>
				<MultiDateSelector selectedDates={selectedDates} onDatesChange={handleDatesChange} /> */}

        <div className={styles.statsContainer}>
          {/* <div className={styles.stats}>
						<img src={location} alt='map_pin' />
						<span className={styles.subtitle}>100+ Locations</span>
						<p className={styles.description}>
							We are currently in 100+ locations in Lagos, Nigeria and expanding daily across the country and
							the world!
						</p>
					</div>
					<div className={styles.stats}>
						<img src={offices} alt='office' />
						<span className={styles.subtitle}>500+ Companies</span>
						<p className={styles.description}>
							We are trusted by over 500 verified Coworking companies, with several new coworking spaces
							joining every day.
						</p>
					</div>
					<div className={styles.stats}>
						<img src={users} alt='user_group' />
						<span className={styles.subtitle}>100,000+ Users</span>
						<p className={styles.description}>We are building a large community. We keep growing every single day!</p>
					</div> */}
          <div className={styles.insightsgrid}>
            <h3 className={styles.text}>
              We can use this space for some social proof instead, and showcase
              the organizations and teams that trust us with their bookings.
              Organizations and teams love us too.
            </h3>
          </div>
        </div>
      </section>
      <section className={styles.ownerssection}>
        <div className={styles.boxes}>
          <img
            src={office_icon}
            className={styles.ownerIcon}
            alt="building office"
          />
          <img
            src={building_office}
            className={styles.ownerIcon1}
            alt="building office"
          />
          <h3 className={styles.cospireowner}>COSPIRE for Space Owners</h3>
          <p className={styles.text}>
            We have over 5000+verified coworking spaces globally, with more than
            100 new spaces joining each month
          </p>
          <button type="submit" className={styles.btn}>
            Find a space
          </button>
        </div>
        <div className={styles.boxes}>
          <img src={community} className={styles.ownerIcon} alt="user group" />
          <img
            src={user_group}
            className={styles.ownerIcon1}
            alt="building office"
          />
          <h3 className={styles.cospireowner}>COSPIRE for Event Organizers</h3>
          <p className={styles.text}>
            We have over 1Million verified users on Co-Spire each month creating
            in our spaces.
          </p>
          <button
            onClick={() => navigate("/listing")}
            type="submit"
            className={styles.btn}
          >
            List a space
          </button>
        </div>
      </section>
      <section className={styles.creativefacility}>
        <div className={styles.text}>
          <div className={styles.textbox}>
            <h2>People Make Magic Happen!</h2>
            <p>
              At the heart of what we do at CO-SPIRE is bringing people
              together. We know that that’s where the magic happens- when
              creativity, meets opportunities, or when first time acquaintances
              grow into lovers. We’re CO-SPIRE, ready to inspire your next
              greatness!
            </p>
          </div>
        </div>
        <div className={styles.image_container}>
          <div className={styles.frame_1000003923}>
            <img src={oneOne} className={styles.frame_24} alt="space image" />
            <img src={oneTwo} className={styles.frame_29} alt="space image" />
            <img src={oneThree} className={styles.frame_35} alt="space image" />
            <img src={oneFour} className={styles.frame_31} alt="space image" />
            <img src={oneFive} className={styles.frame_36} alt="space image" />
          </div>
          <div className={styles.frame_36}>
            <img src={twoOne} className={styles.frame_30} alt="space image" />
            <img src={twoTwo} className={styles.frame_25} alt="space image" />
            <img src={twoThree} className={styles.frame_33} alt="space image" />
            <img src={twoFour} className={styles.frame_26} alt="space image" />
            <img src={twoFive} className={styles.frame_32} alt="space image" />
          </div>{" "}
          <div className={styles.frame_1000003922}>
            <img src={threeOne} className={styles.frame_35} alt="space image" />
            <img src={threeTwo} className={styles.Frame_27} alt="space image" />
            <img
              src={threeThree}
              className={styles.frame_28}
              alt="space image"
            />
            <img
              src={threeFour}
              className={styles.frame_27}
              alt="space image"
            />
          </div>{" "}
          <div className={styles.frame_37}>
            <img src={fourOne} className={styles.frame_35} alt="space image" />
            <img src={fourTwo} className={styles.frame_30} alt="space image" />
            <img
              src={fourThree}
              className={styles.frame_32}
              alt="space image"
            />
            <img src={fourFour} className={styles.frame_26} alt="space image" />
            <img src={fourFive} className={styles.frame_33} alt="space image" />
            <img src={fourSix} className={styles.frame_34} alt="space image" />
          </div>{" "}
          <div className={styles.frame_36_2}>
            <img src={fiveOne} className={styles.frame_25} alt="space image" />
            <img src={fiveTwo} className={styles.frame_33} alt="space image" />
            <img
              src={fiveThree}
              className={styles.frame_26}
              alt="space image"
            />
            <img src={fiveFour} className={styles.frame_32} alt="space image" />
            <img src={fiveFive} className={styles.frame_30} alt="space image" />
          </div>{" "}
          <div className={styles.frame_1000003924}>
            <img src={sixOne} className={styles.frame_38} alt="space image" />
            <img src={sixTwo} className={styles.frame_39} alt="space image" />
            <img src={sixThree} className={styles.frame_30} alt="space image" />
            <img src={sixFour} className={styles.frame_40} alt="space image" />
            <img src={sixFive} className={styles.frame_41} alt="space image" />
          </div>{" "}
          <div className={styles.frame_40}>
            <img src={sevenOne} className={styles.frame_30} alt="space image" />
            <img src={sevenTwo} className={styles.frame_26} alt="space image" />
            <img
              src={sevenThree}
              className={styles.frame_33}
              alt="space image"
            />
            <img
              src={sevenFour}
              className={styles.frame_32}
              alt="space image"
            />
            <img
              src={sevenFive}
              className={styles.frame_34}
              alt="space image"
            />
            <img src={sevenSix} className={styles.frame_35} alt="space image" />
          </div>
        </div>
        <div className={styles.creativeButtonBox}>
          <button
            onClick={() => navigate(WORKSPACES)}
            type="submit"
            className={styles.creativeButton}
          >
            Find a creative space
          </button>
        </div>
      </section>
      {/* <section className={styles.aboutInfo}>
				<div className={styles.aboutInfoContent}>
					<div className={styles.img1}>
						<img src={about_us} alt='aboutus' />
					</div>

					<div className={styles.aboutInfoText}>
						<p className={styles.title}>CONNECT…COWORK…CO-SPIRE</p>
						<p className={styles.description}>
							Welcome to Nigeria’s fast growing marketplace for Coworking Spaces. <br />
							Now you can easily search for and assess coworking spaces, as well as select a preferred choice
							and make your booking right from the comfort of your home. <br />
							You no longer have to browse the internet for hours, or depend on referrals from friends or try
							several coworking spaces before finding one that meets your needs. Things just got faster and a
							lot easier! <br />
							Here at CO-SPIRE, our professional and welcoming coworking spaces have been carefully
							selected to ensure you get the best of services and do your most productive work.
							<br />However, we believe that opportunities are often everywhere most people aren’t looking.
							<br />So, coworking spaces at CO-SPIRE are not just a place to cowork, but to also meet with new
							people, discover interesting skills and talents, and connect with new opportunities.
							<br />
							As a small business looking to build a corporate structure, or a fast growing brand expanding
							into new frontiers; or even a Freelancer looking for a workspace that feels like home.
							Whichever the case, our coworking spaces were designed to meet and satisfy your every need.
						</p>
					</div>
				</div>
			</section> */}
      <section className={styles.missionsection}>
        <div className={styles.missionbox}>
          <div className={styles.mission}>
            <h2>Our Mission</h2>
            <p>
              Our mission is to reimagine the use of space by creating
              sustainable and inclusive ways of living together. We believe that
              great design can be a shared commodity. This implies a philosophy
              of local sharing and the thoughtful use of resources - all of
              which we want to extend to as many communities as possible
            </p>
          </div>
        </div>
      </section>
      <section className={styles.corevalues}>
        <div className={styles.title}>
          <h2>Core Values</h2>
          <p>At our core we are:</p>
        </div>
        <div className={styles.imagebox}>
          <div className={styles.singlebox}>
            <img src={creative_img} alt="" />
            <p>Creative</p>
          </div>
          <div className={styles.singlebox}>
            <img src={innovative_img} alt="" />
            <p>Innovative</p>
          </div>
          <div className={styles.singlebox}>
            <img src={customer_centric_img} alt="" />
            <p>Customer centric</p>
          </div>
          <div className={styles.singlebox}>
            <img src={secure_and_private_img} alt="" />
            <p>Confidential</p>
          </div>
        </div>
      </section>
      <section className={styles.faqsection}>
        <div className={styles.faqinside}>
          <div className={styles.faqbox}>
            <div className={styles.faqtextbox}>
              <div className={styles.faqtext}>
                <p>FAQS</p>
                <h4>Get answers to any questions you might have</h4>
              </div>
              <div className={styles.faqbuttonbox}>
                <div className={styles.faqbutton}>
                  <p>Not quite the answers you want?</p>
                  <button type="submit" className={styles.btn}>
                    Speak to Us
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.faqcontentbox}>
              <div className={styles.faqcontent}>
                {isShowBody ? (
                  <div className={styles.faqheading1}>
                    <div className={styles.questionWithAnswers}>
                      <div className={styles.question} onClick={onClickHandler}>
                        <h2>What is Co-Spire?</h2>
                        <img src={chevrondown} alt="" />
                      </div>
                      <p>
                        CO-SPIRE is your marketplace for creative, work and
                        event spaces.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.faqheading}>
                    <div className={styles.question} onClick={onClickHandler}>
                      <h2>What is Co-Spire?</h2>
                      <img src={chevrondown} alt="" />
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.faqcontent}>
                {isShowBody1 ? (
                  <div className={styles.faqheading1}>
                    <div className={styles.questionWithAnswers}>
                      <div
                        className={styles.question}
                        onClick={onClickHandler1}
                      >
                        <h2>What kind of spaces can I find?</h2>
                        <img src={chevrondown} alt="" />
                      </div>
                      <p>
                        On CO-SPIRE, you can find a variety of creative, work
                        and event spaces: studios for pod cast or photo-shoots,
                        locations for film productions, event halls, party
                        spaces, clubbing locations and work spaces near you, and
                        so much more.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.faqheading}>
                    <div className={styles.question} onClick={onClickHandler1}>
                      <h2>What kind of spaces can I find?</h2>
                      <img src={chevrondown} alt="" />
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.faqcontent}>
                {isShowBody2 ? (
                  <div className={styles.faqheading1}>
                    <div className={styles.questionWithAnswers}>
                      <div
                        className={styles.question}
                        onClick={onClickHandler2}
                      >
                        <h2>Can anyone list any space?</h2>
                        <img src={chevrondown} alt="" />
                      </div>
                      <p>
                        Provided your space follows our guidelines, you can make
                        money from it by listing it on our platform. And yes,
                        anyone can list a space they own on CO-SPIRE.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.faqheading}>
                    <div className={styles.question} onClick={onClickHandler2}>
                      <h2>Can anyone list any space?</h2>
                      <img src={chevrondown} alt="" />
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.faqcontent}>
                {isShowBody3 ? (
                  <div className={styles.faqheading1}>
                    <div className={styles.questionWithAnswers}>
                      <div
                        className={styles.question}
                        onClick={onClickHandler3}
                      >
                        <h2>How do I list my space on?</h2>
                        <img src={chevrondown} alt="" />
                      </div>
                      <p>
                        Signing up your space on CO-SPIRE is as easy as pie!
                        Simply locate the 'List your space' button on the home
                        page, click on it and follow the prompts. This won't
                        take 2 minutes and once completed, your space will go
                        live after the CO-SPIRE team has confirmed your.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.faqheading}>
                    <div className={styles.question} onClick={onClickHandler3}>
                      <h2>How do I list my space?</h2>
                      <img src={chevrondown} alt="" />
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.faqcontent}>
                {isShowBody4 ? (
                  <div className={styles.faqheading1}>
                    <div className={styles.questionWithAnswers}>
                      <div
                        className={styles.question}
                        onClick={onClickHandler4}
                      >
                        <h2>Are there subscription plans?</h2>
                        <img src={chevrondown} alt="" />
                      </div>
                      <p>
                        Yes. We are building amazing service bundles to enhance
                        our services and ensure that every space enjoys
                        top-notch value for being part of the CO-SPIRE tribe.
                        These bundles will be announced.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.faqheading}>
                    <div className={styles.question} onClick={onClickHandler4}>
                      <h2>Are there subscription plans?</h2>
                      <img src={chevrondown} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.faqbuttonbox1}>
              <div className={styles.faqbutton}>
                <p>Not quite the answers you want?</p>
                <button type="submit" className={styles.btn}>
                  Speak to Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className={styles.community}>
				<div className={styles.communityContent}>
					<p className={styles.title}>THE CO-SPIRE ATTITUDE</p>
					<p className={styles.description}>
						Our community seats at the heart of our core values as a Company. We want to inspire
						a community of like-minded, forward thinking and forward-driven professionals to do
						their most productive work, connect with new opportunities and build great dreams!
					</p>
				</div>
				<div className={styles.communityImages}>
					<img
						src={community1}
						alt='community1'
						className={styles.communityImage}
					/>
					<img
						src={community2}
						alt='community2'
						className={styles.communityImage}
					/>
					<img
						src={community3}
						alt='community2'
						className={styles.communityImage}
					/>
				</div>
			</section> */}
      {/* <section>
				<div className={styles.events}>
					<div className={styles.eventsContent}>
						<p className={styles.eventTitle}>Sponsor an event with us</p>
						<p className={styles.eventDescription}>
						Ready to take your brand to the next level? Join us at CoSpire
						 and let's create memorable experiences together. Contact our team
						  today to explore sponsorship opportunities and be part of our 
						  journey toward a brighter, more connected future. 
						</p>
						<p className={styles.eventDescription}>
							Together, we'll CoSpire greatness! 🚀🌐🤝{" "}
						</p>
						<button type='submit' className={styles.btn}>
							Fill our Event Form
						</button>
					</div>
				</div>
			</section> */}
    </div>
  );
};

export default AboutUsx;
