// CustomModal.tsx
import React from "react";
import { Modal, Button } from "antd";
import CustomIcons from "@/components/icons";
import styles from "./index.module.scss";

interface CustomModalProps {
  isOpen: boolean;
  title: string;
  subTitle?: string;
  onClose: () => void;
  onConfirm: () => void;
  footer?: JSX.Element;
  children: JSX.Element;
  width?: number;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  subTitle,
  footer,
  children,
  width = 700,
}) => {
  return (
    <Modal
      title={
        <div className={styles.confirmAvailTitle}>
          <p>{title}</p>
          <span>{subTitle}</span>
        </div>
      }
      visible={isOpen}
      onOk={onConfirm}
      onCancel={onClose}
      footer={[footer]}
      width={width}
      closeIcon={<CustomIcons name="circumscribedCloseIcon" />}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
