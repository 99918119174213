import { useMutation, useQuery, useQueryClient } from "react-query";
import client from "./client";
import { BookingPaginator, BookingQueryOptions } from "@/types/booking";
import { API_ENDPOINTS } from "./client/api-endpoints";
import { mapPaginatorData } from "@/utils/data-mappers";

export const useCreateBookingMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.bookings.create, {
    // Always refetch after error or success:
    onSettled: () => {
      //   queryClient.invalidateQueries(API_ENDPOINTS.WALLET.ME);
    },
  });
};

export const useCompleteBookingMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.bookings.complete_booking, {
    // Always refetch after error or success:
    onSettled: () => {
      //   queryClient.invalidateQueries(API_ENDPOINTS.WALLET.ME);
    },
  });
};

export const useBookingHistoryQuery = (
  params: Partial<BookingQueryOptions>,
  options = {}
) => {
  const { data, error, isLoading } = useQuery<BookingPaginator, Error>(
    [API_ENDPOINTS.TRANSACTIONS, params],
    ({ queryKey, pageParam }) =>
      client.bookings.paginated(Object.assign({}, queryKey[1], pageParam)),
    {
      keepPreviousData: true,
      ...options,
    }
  );

  return {
    bookings: data?.data ?? [],
    paginatorInfo: mapPaginatorData(data),
    error,
    loading: isLoading,
  };
};
