import React from "react";
import {
  Formik,
  Field,
  ErrorMessage,
  FormikValues,
  FormikHelpers,
} from "formik";
import * as Yup from "yup";
import styles from "./renters-registration.module.scss";
import { CustomInputField } from "@/components/customInput";
import CustomPasswordBox from "@/components/customPasswordBox";
import { Link } from "react-router-dom";
import { HOME_URL, LOGIN, WORKSPACES } from "@/routes/routes";
import { ChevronDownIcon } from "@/components/icons/chevron-down";
import { sendUserVerifyEmail } from "@/services/networkRequests/userService";
import { useAppDispatch } from "@/redux/hook";
import { createUser } from "@/redux/actions/authActions";
import { handleError } from "@/services/networkRequests";
import { login } from "@/services/networkRequests/authService";

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  job_role: string;
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Name must be minimum 2")
    .max(100, "Name must not be more than 100 characters")
    .required("Name is required"),
  last_name: Yup.string()
    .min(2, "Name must be minimum 2")
    .max(100, "Name must not be more than 100 characters")
    .required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  job_role: Yup.string()
    .min(2, "Job is required")
    .max(100, "Name must not be more than 100 characters")
    .required("Job is is required"),
});

const PRIVACY_POLICY =
  "https://drive.google.com/file/d/1BbY8lTb3EwGlsi98fBq4-jtYaxO83SZT/view?usp=sharing";

const sendEmail = async (email: string) => {
  if (email) {
    const payload = {
      userEmail: email,
      callbackUrl: HOME_URL + WORKSPACES,
    };
    await sendUserVerifyEmail(payload);
  }
};

export function RentersRegistration() {
  const initialValues: FormValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    job_role: "",
  };

  const dispatch = useAppDispatch();

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const payload = {
      user_email: values.email,
      user_name_first: values.first_name,
      user_name_last: values.last_name,
      password: values.password,
      user_job_title: values.job_role,
      role_type: "Customer",
    };
    try {
      const response = await dispatch(createUser(payload));
      if (response?.meta?.requestStatus === "fulfilled") {
        sendEmail(payload.user_email);
        await login({ email: values.email, password: values.password });
      }
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <div className={styles.renters}>
        <div className={styles.headerRow}>
          <h2>Renter’s Details </h2>
          <ChevronDownIcon className="text-primaryText cursor-pointer" />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, handleSubmit, touched, ...formik }) => (
            <form onSubmit={handleSubmit}>
              <>
                <div className={styles.inputContainer}>
                  <div className={styles.formRow}>
                    <CustomInputField
                      type={"text"}
                      label={"FirstName"}
                      placeholder="First name"
                      name={"first_name"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={touched.first_name && errors.first_name}
                    />
                    <CustomInputField
                      type={"text"}
                      label={"LastName"}
                      placeholder="Last name"
                      name={"last_name"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={touched.last_name && errors.last_name}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <CustomInputField
                      type={"email"}
                      label={"Email Address"}
                      placeholder="Enter your Email Address"
                      name={"email"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={touched.email && errors.email}
                    />

                    <CustomInputField
                      type={"text"}
                      label={"Job"}
                      placeholder="Job Role"
                      name={"job_role"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={touched.job_role && errors.job_role}
                    />
                  </div>

                  <div className={styles.formRow}>
                    <CustomPasswordBox
                      type={"password"}
                      name={"password"}
                      label="Password"
                      placeholder="Enter your password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={touched.password && errors.password}
                    />
                    <CustomPasswordBox
                      type={"password"}
                      name={"confirm_password"}
                      label="Confirm Password"
                      placeholder="Re-Enter your password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        touched.confirm_password && errors.confirm_password
                      }
                    />
                  </div>
                </div>

                <div className={styles.renterText}>
                  <p>
                    By submitting your details you agree to our{" "}
                    <Link to={PRIVACY_POLICY} style={{ color: "lightBlue" }}>
                      Terms of Service
                    </Link>{" "}
                    and
                    <Link to={PRIVACY_POLICY} style={{ color: "lightBlue" }}>
                      {" "}
                      privacy policy
                    </Link>
                  </p>
                  <p>
                    Your contact info will not appear on your public profile and
                    would not be disclosed to the host or any third party
                    services.
                  </p>
                </div>

                <div className={styles.buttonBox}>
                  <button
                    type="button"
                    onClick={formik.submitForm}
                    className={styles.btn}
                  >
                    {isSubmitting ? "..." : "Continue"}
                  </button>

                  <p>
                    Already on Co-Spire?{"  "}
                    <Link to={LOGIN} style={{ color: "lightBlue" }}>
                      Login
                    </Link>
                  </p>
                </div>
              </>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}
