import React, { Fragment, useRef } from "react";
import styles from "./index.module.scss";
import { CustomButton } from "../../../../../components/customButton";
import { Col, Divider, Row } from "antd";
import { pin, removeIcon, featured_space } from "@/assets/img";
import verified from "../../../../../assets/img/verified_orange.png";
import { StarFilled } from "@ant-design/icons";
import useCheckIsMobileScreen from "../../../../../hooks/useCheckIsMobileScreen";
import useHostWorkspaces from "./useHostWorkspaces";
import { useNavigate } from "react-router-dom";
import { PREVIEW_HOST_WORKSPACE } from "../../../../../routes/routes";
import NoDataPage from "../../../../../components/noDataPage/noDataPage";
import Spinner from "../../../../../components/spinner/Spinner";
import Splider from "../../../../../components/splide/splide";

const HostWorkSpaces = () => {
  const isMobile = useCheckIsMobileScreen();
  const navigate = useNavigate();
  const {
    hostWorkSpaces,
    workspacesIsLoading,
    workspacesIsError,
    getAverageRating,
    getLowestPrice,
  } = useHostWorkspaces();
  return (
    <div className={styles.page}>
      <div className={styles.heading}>
        <h2 className={styles.title}>My Workspaces</h2>
        {isMobile ? (
          <div className={styles.remove}>
            <div className={styles.icon}>
              <img src={removeIcon} alt="remove" />
              <p className={styles.subtitle}>Remove</p>
            </div>
          </div>
        ) : (
          // <CustomButton children={"Get Featured"} className={styles.btn} />
          <></>
        )}
      </div>
      <Divider orientation="left"></Divider>

      {hostWorkSpaces && (
        <Fragment>
          {!workspacesIsLoading &&
            !workspacesIsError &&
            hostWorkSpaces?.length > 0 &&
            hostWorkSpaces?.map((space, index) => {
              return (
                <Fragment key={index.toString() + space.title}>
                  <div className={styles.featured}>
                    <div className={styles.featuredItem}>
                      <div className={styles.left}>
                        <Splider
                          thumbnails={space.photos}
                          space={space.title}
                          className={styles.img}
                        />
                      </div>
                      <div className={styles.workspace}>
                        {isMobile ? (
                          <div className={styles.rightTop}>
                            <div className={styles.content}>
                              <div className={styles.con}>
                                <h1 className={styles.title}>{space.title}</h1>
                                <div className={styles.icon}>
                                  <StarFilled className={styles.iconColor} />
                                  <p className={styles.subtitle}>
                                    {space?.verified_reviews &&
                                      getAverageRating(space?.verified_reviews)}
                                  </p>
                                </div>
                              </div>
                              <div className={styles.con}>
                                {space?.verified_space && (
                                  <div className={styles.icon}>
                                    <img src={verified} alt="verified" />
                                    <p className={styles.subtitle}>
                                      Verified Workspace
                                    </p>
                                  </div>
                                )}
                                <p className={styles.subtitle}>
                                  {space?.verified_reviews?.length} Verified
                                  Reviews
                                </p>
                              </div>
                            </div>

                            <div className={styles.price}>
                              <h1
                                className={styles.title}
                                style={{ color: "#0047AB" }}
                              >
                                Starting from &#8358;
                                {space?.services &&
                                  getLowestPrice(space?.services)}
                              </h1>
                              <div className={styles.location}>
                                <div className={styles.icon}>
                                  <img src={pin} alt="pin" />
                                  <p className={styles.subtitle}>
                                    <span>{space.location}</span>
                                  </p>
                                </div>
                                <Row>
                                  <Col flex="auto">
                                    <CustomButton
                                      children={"Preview"}
                                      className={styles.btnWhite}
                                      onClick={() =>
                                        navigate(
                                          `${PREVIEW_HOST_WORKSPACE}/${space.workspace_id}`
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {" "}
                            <div className={styles.rightTop}>
                              <div className={styles.content}>
                                <h1 className={styles.title}>{space.title}</h1>
                                <div className={styles.icon}>
                                  <StarFilled className={styles.iconColor} />
                                  <p className={styles.subtitle}>
                                    {space?.verified_reviews &&
                                      getAverageRating(space?.verified_reviews)}
                                  </p>
                                </div>
                                {space?.verified_space && (
                                  <div className={styles.icon}>
                                    <img src={verified} alt="verified" />
                                    <p className={styles.subtitle}>
                                      Verified Workspace
                                    </p>
                                  </div>
                                )}
                                <p className={styles.subtitle}>
                                  {space?.verified_reviews?.length} Verified
                                  Reviews
                                </p>
                              </div>
                              <div className={styles.remove}>
                                <div className={styles.icon}>
                                  <img src={removeIcon} alt="remove" />
                                  <p className={styles.subtitle}>Remove</p>
                                </div>
                              </div>
                            </div>
                            <div className={styles.price}>
                              <h1
                                className={styles.title}
                                style={{ color: "#0047AB" }}
                              >
                                Starting from &#8358;
                                {space?.services &&
                                  getLowestPrice(space?.services)}
                              </h1>
                              <div className={styles.location}>
                                <div className={styles.icon}>
                                  <img src={pin} alt="pin" />
                                  <p className={styles.subtitle}>
                                    <span>{space.location}</span>
                                  </p>
                                </div>
                                <Row>
                                  <Col flex="auto">
                                    <CustomButton
                                      children={"Preview"}
                                      className={styles.btnWhite}
                                      onClick={() =>
                                        navigate(
                                          `${PREVIEW_HOST_WORKSPACE}/${space.workspace_id}`
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <Divider orientation="left"></Divider>
                </Fragment>
              );
            })}

          {workspacesIsLoading && <Spinner size={"large"} />}

          {!workspacesIsLoading &&
            !workspacesIsError &&
            hostWorkSpaces.length === 0 && (
              <NoDataPage
                title={"No workspaces available"}
                subtitle="All your workspaces will be displayed here"
              />
            )}
        </Fragment>
      )}
    </div>
  );
};

export default HostWorkSpaces;
