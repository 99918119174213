import React, { CSSProperties } from "react";
import styles from "./index.module.scss";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Button, Statistic } from "antd";
import { formatNumberWithCommas } from "@/utils/formatNumbersWithCommas";
import { cn } from "@/lib/utils";
import { CustomButton } from "@/components/customButton";

type BalanceCardProps = {
  extraStyles?: CSSProperties;
  title: string;
  icon?: any;
  body: string;
  subText?: string;
  handleWithdraw: () => void;
};

export const BalanceCard = ({
  title,
  icon,
  body,
  subText,
  handleWithdraw,
}: BalanceCardProps) => {
  return (
    <div className={styles.page}>
      <div className={styles.heading}>
        <span className={cn(styles.text)}>{title}</span>
        {icon && icon}
      </div>
      <div className="flex flex-col md:flex-row items-start md:justify-between md:items-center">
        <div className="text-[40px] leading-[50px] text-coSpireBlack font-semibold mb-2.5 md:mb-0">
          NGN{body}
        </div>
        <button
          className="bg-alpha-blue py-2.5 px-7 rounded-3xl text-primary font-medium text-lg"
          onClick={handleWithdraw}
        >
          Withdraw Funds
        </button>
      </div>
      <p className={styles.subtext}>{subText}</p>
    </div>
  );
};
