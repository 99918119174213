import { useMutation, useQuery, useQueryClient } from "react-query";
import client from "./client";
import { API_ENDPOINTS } from "./client/api-endpoints";
import { Wallet } from "@/types/wallet";
import { useAuth } from "@/components/auth/use-auth";

export const useRemoveWithdrawalAccountMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.wallet.remove_bank, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(API_ENDPOINTS.WALLET.ME);
    },
  });
};

export const useSetPreferredWithdrawalAccount = () => {
  const queryClient = useQueryClient();
  return useMutation(client.wallet.set_preferred_bank, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(API_ENDPOINTS.WALLET.ME);
    },
  });
};

export const useAddWithdrawalAccountMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.wallet.add_bank, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(API_ENDPOINTS.WALLET.ME);
    },
  });
};

export const useWithdrawalFundsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.wallet.withdraw_fund, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(API_ENDPOINTS.WALLET.ME);
      queryClient.invalidateQueries(API_ENDPOINTS.TRANSACTIONS);
    },
  });
};

export function useMyWallet() {
  const { userLoggedIn } = useAuth();
  const { data, isLoading, error } = useQuery<{ data: Wallet }, Error>(
    [API_ENDPOINTS.WALLET.ME],
    client.wallet.me,
    {
      enabled: userLoggedIn,
    }
  );
  return {
    wallet: data?.data ?? null,
    isLoading,
    error,
  };
}

export const useAddPaymentMethodMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(client.wallet.add_payment_method, {
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(
        API_ENDPOINTS.SUBSCRIPTIONS.PAYMENT_METHODS
      );
    },
  });
};
