import { RequestResponse } from "../../types";
import { CREATE_TRANSACTION } from "../apiEndpoints";
import { axiosPrivate } from "../axiosPrivate";

import { handleError } from "./error";

const createTransactionOld = async (values: any) => {
  // (walletId: string, userId: string, payload: any) => {
  try {
    const endpoint = `${process.env.REACT_APP_BASE_URL + CREATE_TRANSACTION}`;
    const response: RequestResponse = await axiosPrivate.post(endpoint, values);
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};

export { createTransactionOld };
