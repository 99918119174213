import React, { useState, useRef, useEffect, ReactElement } from "react";
import search from "@/assets/svgs/search-normal.svg";
import starfilled from "@/assets/svgs/star-filled.svg";
import starUnfilled from "@/assets/svgs/star-unfilled.svg";
import filterSvg from "@/assets/svgs/filter.svg";
import arrowDown from "@/assets/svgs/arrow-down.svg";
import mobileFilter from "@/assets/svgs/adjustments-horizontal.svg";
import locationSvg from "@/assets/svgs/location.svg";
import heartSvg from "@/assets/svgs/heart.svg";
import useGeoLocation from "@/components/geoTagAddressInput/GeoLocation";
import CustomMap from "./CustomMap";
import cafe from "@/assets/img/cafe.jpeg";
import { useAppDispatch } from "@/redux/hook";
import Map from "./map";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import "./exploreWorkspaces.scss";
import ExploreFilterModal from "@/components/a/Modals/ExploreFilterModal/ExploreFilterModal";
import Pagination from "@/components/c/Pagination";
import { dummyData } from "./data";
import { listWorkSpaces } from "@/services/networkRequests/workspaceService";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useWorkspacesQuery } from "@/data/workspace";

import { WorkspaceNew } from "@/types";
import { Workspace } from "@/types/workspace";

const LIMIT = 10;

type Props = {};

export default function Workspaces({}: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState("");
  const { state } = useLocation();

  const [_, setIsLoading] = useState(false);
  const [allSpaces, setAllSpaces] = useState<Workspace[]>([]);
  const [filteredData, setFilteredData] = useState<Workspace[]>([]);
  const [totalCount, setTotalCount] = useState(10);
  const [currentActivity, setCurrentActivity] = useState("");
  const [selectedCard, setSelectedCard] = useState<Workspace | null>(null);
  const dispatch = useAppDispatch();
  const pageSize = 10;

  const { workspaces, loading: isLoading } = useWorkspacesQuery({
    limit: LIMIT,
  });

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await listWorkSpaces(currentPage, pageSize);
      if (res.code === 200) {
        // console.log(res.data.data, "response");
        setAllSpaces(res.data.data);
        setTotalCount(res.data.availableSpaces);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching workspace data:", error);
    }
  };

  //automatically detect previous input
  const searchValue = state?.searchtitle;
  const searchLocation = state?.searchLocation;
  // console.log(searchValue);

  useEffect(() => {
    getData();
    if (searchValue || searchLocation) {
      setFilter(searchValue.toLocaleLowerCase());
      // dispatch(setSearchValue(""));
      // dispatch(setLocation(""));
    }
  }, [currentPage]);

  useEffect(() => {
    setFilteredData(allSpaces);
    setSelectedCard(filteredData[0]);
  }, [allSpaces]);

  // useEffect(() => {
  //   if (searchValue || searchLocation) {
  //     setFilter(searchValue);
  //     handleSearch()
  //     console.log('searching...');
  //     setFilteredData(allSpaces)
  //     dispatch(setSearchValue(""));
  //     dispatch(setLocation(""));
  //   }

  // }, [allSpaces]);

  let setPageAction = (item: any) => {
    console.log(item);
  };
  const { location, loading, error } = useGeoLocation(
    "1600 Amphitheatre Parkway, Mountain View, CA"
  );
  const toggleFilterModal = () => {
    setShowFilter(!showFilter);
  };
  const addresses = [
    "1600 Amphitheatre Parkway, Mountain View, CA",
    "1 Infinite Loop, Cupertino, CA",
  ];

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLocaleLowerCase();

    setFilter(searchTerm);

    if (!filter.trim()) {
      setFilteredData(allSpaces);
    } else {
      const searchTerms = filter.trim().toLowerCase().split(" ");
      const filtered = allSpaces.filter((item: any) => {
        const address = item.address?.street_address?.toLowerCase() || "";
        const title = item.title?.toLowerCase() || "";
        const spaceCategory = item.space_category?.toLowerCase() || "";
        const activities = item.activity
          ? item.activity.map((activity: string) => activity.toLowerCase())
          : [];

        return searchTerms.every(
          (term) =>
            address.includes(term) ||
            title.includes(term) ||
            spaceCategory.includes(term) ||
            activities.some((activity: string) => activity.includes(term))
        );
      });
      setFilteredData(filtered);
    }
  };

  const handleSearch = () => {
    if (!filter.trim()) {
      setFilteredData(allSpaces);
    } else {
      const searchTerms = filter.trim().toLowerCase().split(" ");
      const filtered = allSpaces.filter((item: any) => {
        const address = item.address?.street_address?.toLowerCase() || "";
        const title = item.title?.toLowerCase() || "";
        const spaceCategory = item.space_category?.toLowerCase() || "";
        const activities = item.activity
          ? item.activity.map((activity: string) => activity.toLowerCase())
          : [];

        return searchTerms.every(
          (term) =>
            address.includes(term) ||
            title.includes(term) ||
            spaceCategory.includes(term) ||
            activities.some((activity: string) => activity.includes(term))
        );
      });
      setFilteredData(filtered);
    }

    setDropdownVisible(true); // Show dropdown when typing in the input field
  };

  const handleFilterApply = (newFilteredData: Workspace[]) => {
    setFilteredData(newFilteredData);
    toggleFilterModal();
  };

  useEffect(() => {
    if (allSpaces.length > 0) {
      handleSearch();
    }
  }, [allSpaces, filter]);

  const spaceActivities = [
    "All",
    "Content Creation",
    "Podcast studio",
    "Party space",
    "Event Spaces",
    "Filming venues",
    "Outdoor wedding",
    "Video shooting venues",
    "Work meetings",
  ];

  const handleSelectActivity = (activity: string) => {
    setCurrentActivity(activity);

    if (activity === "All") {
      getData();
      return;
    }

    const filtered = allSpaces.filter((item: any) => {
      const activities = item.activity
        ? item.activity.map((act: string) => act.toLowerCase())
        : [];
      const spaceCategory = item.space_category?.toLowerCase().trim() || "";
      const spaceTitle = item.title?.toLowerCase().trim() || "";
      return (
        spaceCategory.includes(activity.toLowerCase()) ||
        spaceTitle.includes(activity.toLowerCase()) ||
        activities.some((act: string) => act.includes(activity.toLowerCase()))
      );
    });

    setFilteredData(filtered);
  };

  // const handleSelectActivity = (index: number) => {
  //   const selectedActivity = spaceActivities[index];
  //   const isSelected = selectedActivities.includes(selectedActivity);

  //   if (isSelected) {
  //     // If activity is already selected, remove it from the array
  //     setSelectedActivities(
  //       selectedActivities.filter(
  //         (activity: any) => activity !== selectedActivity
  //       )
  //     );
  //   } else {
  //     // Otherwise, add it to the array
  //     setSelectedActivities([...selectedActivities, selectedActivity]);
  //   }
  // };
  const spaceActivityParentRef = useRef<HTMLDivElement>(null);

  const handleNextClick = () => {
    // Scroll the space-activity-parent to the right
    if (spaceActivityParentRef.current) {
      spaceActivityParentRef.current.scrollLeft += 100; // Adjust the scroll amount as needed
    }
  };
  const handlePrevClick = () => {
    // Scroll the space-activity-parent to the right
    if (spaceActivityParentRef.current) {
      spaceActivityParentRef.current.scrollLeft -= 100; // Adjust the scroll amount as needed
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      if (spaceActivityParentRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } =
          spaceActivityParentRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollWidth > clientWidth + scrollLeft);
      }
    };

    const checkInitialScroll = () => {
      if (spaceActivityParentRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } =
          spaceActivityParentRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollWidth > clientWidth + scrollLeft);
      }
    };

    if (spaceActivityParentRef.current) {
      spaceActivityParentRef.current.addEventListener("scroll", handleScroll);
      checkInitialScroll();
    }

    return () => {
      if (spaceActivityParentRef.current) {
        spaceActivityParentRef.current.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, []);

  const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return <></>;
  };

  return (
    <div>
      {isLoading ? (
        <div className="preload-spinner">
          <Spin />
        </div>
      ) : (
        <div className="explore-workspaces">
          <div className="top-div">
            <div className="search-workspaces">
              <div
                className="filter-function-parent"
                onClick={toggleFilterModal}
              >
                <div className="filter-svg-parent">
                  <img src={filterSvg} alt="filter" />
                </div>
                <div className="filter-text">
                  <p>Filter</p>
                  <img
                    src={arrowDown}
                    alt="search"
                    className="hover clicker "
                  />
                </div>
              </div>

              <div className="search-bar">
                <input
                  type="text"
                  value={filter}
                  onChange={updateSearch}
                  placeholder="Type the space you are looking for here..."
                />

                <button onChange={handleSearch}>
                  <img src={search} alt="search" className="hover" />
                </button>
              </div>
              <button
                className="mobile-filter-button"
                onClick={toggleFilterModal}
              >
                <img src={mobileFilter} alt="mobilefilter" />
              </button>
            </div>

            <div className="space-activity-parent-wrapper">
              <p
                className="control"
                onClick={handlePrevClick}
                style={{
                  marginRight: "1%",
                  // cursor: canScrollLeft ? "pointer" : "not-allowed",
                  // opacity: canScrollLeft ? 1 : 0.5,
                  visibility: canScrollLeft ? "visible" : "hidden",
                }}
              >
                {"<"}
              </p>
              <div
                className="space-activity-parent"
                ref={spaceActivityParentRef}
                style={{
                  overflowX: "auto",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                {spaceActivities.map((spaceActivity, index) => {
                  const isActive = spaceActivity === currentActivity;
                  const className = isActive
                    ? "space-activity active"
                    : "space-activity";

                  return (
                    <p
                      className={className}
                      key={index}
                      onClick={() => handleSelectActivity(spaceActivity)}
                    >
                      {spaceActivity}
                    </p>
                  );
                })}
              </div>
              <p
                className="control"
                onClick={handleNextClick}
                style={{
                  marginLeft: "1%",
                  visibility: canScrollRight ? "visible" : "hidden",
                }}
              >
                {">"}
              </p>
            </div>
          </div>
          <div className={`middle-div ${showFilter ? "hide-me" : ""}`}>
            <div className="middle-div-heading">
              <p>
                Showing {`${filteredData.length}  locations`} near Lagos,
                Nigeria
              </p>
              <div>
                <p>Show map</p>

                <div
                  className={
                    showMap ? "toggle-map-clicker-on" : "toggle-map-clicker-off"
                  }
                  onClick={() => {
                    setShowMap(!showMap);
                  }}
                >
                  <div className="action-circle"></div>
                </div>
              </div>
            </div>
            <div className="middle-div-content">
              <div
                className={`middle-div-space-wrapper ${
                  showMap ? "reduced" : ""
                }`}
              >
                {filteredData.map((item) => {
                  return <Card key={item.workspace_id} item={item} />;
                })}
                {showMap && (
                  <div
                    style={{
                      minWidth: "299px",
                      maxWidth: "299px",
                      width: "299px",
                    }}
                  ></div>
                )}
              </div>
              {showMap && (
                <div className="middle-div-map-wrapper">
                  {/* <div>
                    <h2>Geo Location</h2>
                    {loading && <div>Loading...</div>}
                    {error && <div>{error}</div>}
                    {location && (
                      <div>
                        <p>Latitude: {location.lat}</p>
                        <p>Longitude: {location.lng}</p>
                      </div>
                    )}
                  </div> */}
                  <Wrapper
                    apiKey={process.env.REACT_APP_MAP_KEY as any}
                    render={render}
                  >
                    <Map
                      workspace={filteredData}
                      center={{
                        lat: Number(
                          (filteredData && filteredData[-1]?.map.lat) ||
                            "6.602560"
                        ),
                        lng: Number(
                          (filteredData && filteredData[-1]?.map.lat) ||
                            "3.351630"
                        ),
                      }}
                      zoom={11}
                    />
                  </Wrapper>
                </div>
              )}
              {/* <MapWithCustomPins locations={addresses}/></div>} */}
            </div>
            {/* <div className="middle-div-popular-parent">
              <p>Popular Production Spaces Near Lagos, Nigeria.</p>
              <div className="middle-div-popular-wrapper"></div>
              <p>Popular Production Spaces Near Lagos, Nigeria.</p>
              <div className="middle-div-popular-pagination-wrapper"></div>
            </div> */}
          </div>

          <div className={`bottom-div ${showFilter ? "hide-me" : ""}`}>
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                totalCount={totalCount}
                pageAction={setPageAction}
                pageSize={pageSize}
                onPageChange={(page: number) => {
                  setCurrentPage(page);
                }}
              />
            </div>
          </div>
          {showFilter && (
            <ExploreFilterModal
              toggleFilterModal={toggleFilterModal}
              filteredData={allSpaces}
              onApplyFilter={handleFilterApply} // Add this line
            />
          )}
        </div>
      )}
    </div>
  );
}

const Card = ({ item }: any) => {
  const [currentImage, setCurrentImage] = useState(0);

  // Include the default image in the images array if item.photos is empty
  const images = item.photos.length > 0 ? item.photos : [cafe];

  const handleNext = () => {
    const nextPage = currentImage >= images.length - 1 ? 0 : currentImage + 1;
    setCurrentImage(nextPage);
  };

  const handlePrevious = () => {
    const previousPage =
      currentImage === 0 ? images.length - 1 : currentImage - 1;
    setCurrentImage(previousPage);
  };

  const getDotIndexes = () => {
    const length = images.length;
    const indexes = [];
    for (let i = 0; i < length; i++) {
      indexes.push(i);
    }
    return indexes;
  };

  const navigate = useNavigate();

  return (
    <div className="space-card">
      <div className="space-card-image">
        <div
          className="img-slider"
          onClick={() => navigate(`/workspace/${item.workspace_id}`)}
        >
          <img
            src={images[currentImage]}
            alt={item.spaceTitle || "Workspace Image"}
            className="space-card-slider"
          />
        </div>
        <p className="img-previous" onClick={handlePrevious}>
          &#8592;
        </p>
        <p className="img-next" onClick={handleNext}>
          &#8594;
        </p>
        <div className="image-slider-progress">
          {getDotIndexes().map((index: any) => (
            <div
              key={index}
              className={`slider-dot ${index === currentImage ? "active" : ""}`}
            ></div>
          ))}
        </div>
      </div>
      <div className="space-card-content">
        <p className="space-title">{item.title}</p>

        <div className="space-sub-heading-parent">
          <img src={locationSvg} alt="" />
          <p className="space-sub-heading">{item.address?.city + ", " + item.address?.country}</p>
        </div>

        <div className="space-card-bottom">
          <div>
            <span className="space-price">₦ 5,000</span>
            <span>/hr</span>
          </div>
          <div className="rating-div">
            <div className="star-div">
              {Array(3)
                .fill("")
                .map((_, index) => {
                  return <img src={starfilled} alt="rating" key={index} />;
                })}
              {Array(5 - 3)
                .fill("")
                .map((_, index) => {
                  return <img src={starUnfilled} alt="rating" key={index} />;
                })}
            </div>
            <p>780</p>
          </div>
        </div>
      </div>
    </div>
  );
};
